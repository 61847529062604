class AvalClass {

    static get modelName() {
        return 'AvalClass'
    }

    constructor(object){
        Object.assign(this, object)
    }

}

export default AvalClass;