import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import {TAB_TITLE_BASE, TAB_TITLE_CONNECTOR} from "../../config/constants";

export const SUBMENU_FIRMENHISTORIE = "Firmenhistorie";
export const SUBMENU_CONTACT = "Kontakt / Anfahrt";
export const SUBMENU_UNSER_TEAM = "Unser Team";

class ContactsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedSubmenu: SUBMENU_FIRMENHISTORIE
        }

    }


    loadSubmenu(submenu) {
        this.setState({
            selectedSubmenu: submenu
        })
    }


    render() {
        document.title = TAB_TITLE_BASE + TAB_TITLE_CONNECTOR + "Über uns";

        return(
            <div style={{padding: "30px"}}>
                <div className={"container row"} style={{marginBottom: "30px", borderBottom: "1px solid #BBBBBB"}}>
                    <div className={"btn-group"}>
                        {this.renderButton(SUBMENU_FIRMENHISTORIE)}
                        {this.renderButton(SUBMENU_CONTACT)}
                        {this.renderButton(SUBMENU_UNSER_TEAM)}
                    </div>
                </div>
                {this.renderSubmenu()}
            </div>
        );
    }



    renderButton(menu) {
        const buttonStyle = {
            display: "block",
            color: "#000000"
        }

        if(menu === this.state.selectedSubmenu) {
            buttonStyle.color = "#0000FF";
            buttonStyle.textDecoration = "underline"
        }

        return (
            <button className={"btn btn-link"}
                    style={buttonStyle}
                    onClick={() => this.loadSubmenu(menu)}
            >
                {menu}
            </button>
        );
    }

    renderSubmenu() {
        if(this.state.selectedSubmenu === SUBMENU_FIRMENHISTORIE) {
            return (
              <div>Hier stehen Informationen zur Firmenhistorie...</div>
            );
        } else if(this.state.selectedSubmenu === SUBMENU_CONTACT) {
            return (
                <div>Hier stehen Kontaktinformationen / Informationen zur Anfahrt</div>
            );
        } else if(this.state.selectedSubmenu === SUBMENU_UNSER_TEAM) {
            return (
                <div>Hier stehen Informationen zum Team.</div>
            );
        } else {
            return <div/>
        }
    }

}


export default ContactsPage;