import React, {Component} from 'react';

import * as DataService from '../service/dataService';
import {NotificationContext} from "./contexts/NotificationContext";

import '../fimo.css';
import Link from "react-router-dom/es/Link";
import separator_image from "../static/extern/Hintergrundbild_Karles.jpg";
import {PATH_CLIENT_POLICY_PORTAL, PATH_CONTACT, PATH_ROOT} from "../config/paths";

import {IoIosLogIn, IoIosReturnLeft} from 'react-icons/io'

/**
 * Login Page of the web application.
 */
class LoginPage extends Component {

    static contextType = NotificationContext;

    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
        }

        this.updateUserName = this.updateUserName.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
    }


    updateUserName(event) {
        event.preventDefault();
        this.setState({
            username: event.target.value
        });
    }

    updatePassword(event) {
        event.preventDefault();
        this.setState({
            password: event.target.value
        });
    }

    login(event) {
        event.preventDefault();

        DataService.login(this.state.username, this.state.password)
            .then(response => {
                if (response.status === 200) {
                    return this.props.handleSuccessfulLogin(response.body);
                } else {
                    return this.context.showMessage(null, "Fehler beim Login", "danger")
                }
            })
            .catch(err => {
                if (!err.response) {
                    return this.context.showMessage(null, "Verbindungsfehler", "danger");
                }

                if (err.response.body.errors.user_authentication) {
                    if (err.response.body.errors.user_authentication[0] === "invalid_credentials") {
                        return this.context.showMessage(null, "Bitte überprüfen Sie Ihre Email und Ihr Passwort", "danger")
                    }

                    if (err.response.body.errors.user_authentication[0] === "Error while logging in") {
                        return this.context.showMessage(null, "Fehler beim Login", "danger")
                    }
                }

                if (err.response.body.errors.user_authorization) {
                    if (err.response.body.errors.user_authorization[0] === "Account disabled") {
                        return this.context.showMessage(null, "Account deaktiviert. Bitte kontaktieren Sie den Support.", "danger")
                    }
                }
            });
    }

    render() {
        return (
            <div className="fimo-content-page" >
                    <div className="container" style={{padding: "30px"}}>
                        <form>
                            <div className="form-row">
                                <label className="col-1 col-form-label" style={{minWidth: "120px"}}>E-Mail:</label>
                                <input className="col-2 form-control"
                                       style={{minWidth: "250px"}}
                                       value={this.state.username}
                                       onChange={this.updateUserName}/>
                            </div>
                            <div className={"form-row"}>
                                <label className="col-1 col-form-label" style={{minWidth: "120px"}}>Passwort:</label>
                                <input className="col-2 form-control"
                                       style={{minWidth: "250px"}}
                                       type="password"
                                       value={this.state.password}
                                       onChange={this.updatePassword}/>
                            </div>
                            <div className={"text-left"}>

                                <Link to="/forgot_password">
                                    <button className="btn btn-link"
                                            style={{paddingLeft: "50px"}}
                                            type={"button"}>
                                        Passwort vergessen?
                                    </button>
                                </Link>


                            </div>

                            <div className={"form-row"}>
                                        <button className="btn btn-outline-form"
                                                type={"submit"}
                                                style={{paddingLeft: "20px"}}
                                                onClick={(event) => this.login(event)}>
                                            <div>
                                                <IoIosLogIn/>
                                            </div>
                                            Einloggen
                                        </button>

                                        <Link to={PATH_ROOT} style={{paddingLeft: "5px"}}>
                                            <button className="btn btn-outline-form"
                                                    type={"submit"}>
                                                <div>
                                                    <IoIosReturnLeft/>
                                                </div>
                                                Startseite
                                            </button>
                                        </Link>
                            </div>
                        </form>
                    </div>
                </div>
        );
    }
}

export default LoginPage;