import React, {Component} from 'react';
import {BrowserRouter as Router, Link, Redirect, Route, Switch} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../fimo.css';
import "react-notifications-component/dist/theme.css";
import ReactNotification from "react-notifications-component";
import * as DataService from "../service/dataService";
import * as AuthenticationService from "../service/authenticationService";
import * as Constants from "../config/constants";
import LoginPage from "./LoginPage";
import PortalContainer from "./PortalContainer";
import AdminPortal from "./AdminPortal";
import Portal from "./Portal";
import Header from "./Header";
import Sidebar from "./sidebar/Sidebar";
import ImportInsurances from "./ImportInsurances";
import UpdateRequestsPortal from './updateRequests/UpdateRequestPortal';
import NewClientEmployeeRequest from './clientEmployeeRequest/NewClientEmployeeRequest';
import ClientEmployeeRequestPortal from './clientEmployeeRequest/ClientEmployeeRequestPortal';
import ClientCompanyOverview from './clientCompanies/ClientCompanyOverview';
import UsersOverview from './users/UsersOverview';
import NewQuotationRequest from './quotationRequests/NewQuotationRequest';
import QuotationRequestPortal from './quotationRequests/QuotationRequestPortal';
import ClientPolicyPortal from './clientViews/PolicyPortal';
import FormsPage from "./sidebar/FormsPage";
import ContactsPage from "./sidebar/ContactsPage";
import RegisterPage from "./RegisterPage";
import Imprint from "./sidebar/Imprint";
import User from "../models/User";
import {abilitiesFromRules} from "../Ability";
import {NotificationContext} from "./contexts/NotificationContext";
import UserPage from "./users/UserPage";
import ForgotPassword from "./Authentication/ForgotPassword";
import ChangePassword from "./Authentication/ChangePassword";
import * as Paths from "../config/paths";
import NewUser from "./users/NewUser";
import QuotationRequestPage from "./quotationRequests/QuotationRequestPage";
import ClientEmployeeRequestPage from "./clientEmployeeRequest/ClientEmployeeRequestPage";
import NewClientCompany from "./clientCompanies/NewClientCompany";
import EditClientCompany from "./clientCompanies/EditClientCompany";
import PolicyPortal from "./policyPortal/PolicyPortal";
import EditPolicy from "./policyPortal/EditPolicy";
import NewPolicy from "./policyPortal/NewPolicy";
import NewPolicyDetailsFormsContainer from "./policyDetails/NewPolicyDetailsForms/NewPolicyDetailsFormsContainer";
import PolicyDetailsContainer from "./policyDetails/PolicyDetailsContainer";
import EditPolicyDetailsFormsContainer from "./policyDetails/EditPolicyDetailsForms/EditPolicyDetailsFormsContainer";
import NewProject from "./projects/NewProject";
import NewInsurance from "./insurances/NewInsurance";
import EditInsurance from "./insurances/EditInsurance";
import ShowInsurance from "./insurances/ShowInsurance";
import InsurancesIndex from "./insurances/InsurancesIndex";
import EditProject from "./projects/EditProject";
import NewProjectDetailsFormsContainer from "./projectDetails/NewProjectDetailsForms/NewProjectDetailsFormsContainer";
import ProjectDetailsContainer from "./projectDetails/ProjectDetailsContainer";
import EditProjectDetailsFormsContainer
    from "./projectDetails/EditProjectDetailsForms/EditProjectDetailsFormsContainer";
import DemoPortal from "./demoMode/DemoPortal";
import DemoDetailsContainer from "./demoMode/DemoDetailsContainer";

import NewContactRequest from "./sidebar/NewContactRequest";

import {TAB_TITLE_BASE} from "../config/constants";
import {TAB_TITLE_CONNECTOR} from "../config/constants";
import {MENU_PORTAL} from "../config/constants";
import {MENU_CLIENT_COMPANIES_OVERVIEW} from "../config/constants";
import {MENU_VERTRAGSUEBERSICHT} from "../config/constants";
import Footer from "./Footer";
import InformationPrivacyPage from "./InformationPrivacyPage";
import TermsOfUse from "./TermsOfUse";

/**
 * Top-level component that delegates login/logout and forwards the logged-in user to casehandler or client
 * or admin portal.
 */
class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            authToken: null,
            firstLoginTry: true,
            selectedMenu: MENU_PORTAL,
            clientCompanies: null,
            showRegister: false,
            abilities: null,
            user: null,
            isSidebarCollapsed: this.isMobile()
        }
        this.notificationDOMRef = React.createRef();
        this.showMessage = this.showMessage.bind(this);
        this.executeLogout = this.executeLogout.bind(this);
        this.setSelectedMenu = this.setSelectedMenu.bind(this);
        this.handleSuccessfulLogin = this.handleSuccessfulLogin.bind(this);
        this.renderPageContent = this.renderPageContent.bind(this);
        this.toggleSidebar = this.toggleSidebar.bind(this);
    }

    componentWillMount() {
        this.getUserFromStorage();
    }

    /**
     * Gets called after successfully logging in.
     *
     * @param responseBody
     */
    handleSuccessfulLogin(responseBody) {
        let user = new User(responseBody.user)
        sessionStorage.setItem('auth_token', responseBody.auth_token);
        sessionStorage.setItem('user', JSON.stringify(user));
        sessionStorage.setItem('rules', JSON.stringify(responseBody.rules));
        abilitiesFromRules(responseBody.rules);
        this.setState({
            //TODO remove this property while refactoring
            authToken: responseBody,
            user: user
        });
    }

    /**
     * Gets the user from the session storage and sets it in the state.
     **/
    getUserFromStorage() {
        let auth_token = sessionStorage.getItem("auth_token");
        let user = sessionStorage.getItem("user");
        if (user) {
            try {
                user = JSON.parse(user);
            } catch (e) {
                return false;
            }
        }
        this.setState({
            authToken: auth_token,
            user: user
        });
    }

    executeLogout() {
        localStorage.abilities = null;
        sessionStorage.clear();
        abilitiesFromRules(null);
        this.setState({
            authToken: null,
            firstLoginTry: true,
            user: null
        }, () => {
            this.setSelectedMenu(Constants.MENU_PORTAL);
        });
    }

    setSelectedMenu(menu) {
        this.setState({
            selectedMenu: menu,
            showRegister: false,
            showForgotPassword: false
        });
        if (menu !== Constants.MENU_PORTAL) {
            this.setState({
                showClientEmployeeRequestForm: false
            });
        }
    }

    async loadCompaniesOfEmployee() {
        const userId = this.state.authToken.user ? this.state.authToken.user.id : null;
        const companies = await DataService.getCompanyListOfUser(userId, this.state.authToken);
        if (companies && companies.length > 0) {
            this.setState({
                clientCompanies: companies
            });
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevState.user == null && this.state.user == null) {
            return;
        }



        if(prevState.user && this.state.user && prevState.user.id === this.state.user.id) {
            return;
        }

        let selectedMenu = MENU_PORTAL;
        if(AuthenticationService.isUserClient()) {
            selectedMenu = MENU_VERTRAGSUEBERSICHT;
        } else if(AuthenticationService.isUserCaseHandler()) {
            selectedMenu = MENU_CLIENT_COMPANIES_OVERVIEW;
        }

        this.setSelectedMenu(selectedMenu);
    }

    render() {
        document.title = TAB_TITLE_BASE;

        const userIsCaseHandler = AuthenticationService.isUserCaseHandler();
        const userIsClient = AuthenticationService.isUserClient();
        const userIsAdmin = AuthenticationService.isUserAdmin();
        let title = '';
        if (userIsCaseHandler) {
            title = "Sachbearbeiterportal";
        } else if (userIsClient) {
            title = "Kundenportal";
        } else if (userIsAdmin) {
            title = "Adminportal";
        }

        return (
            <div>
                <NotificationContext.Provider value={{showMessage: this.showMessage}}>
                    <Router>
                        <div className={"page-container"}>
                            <ReactNotification ref={this.notificationDOMRef}/>
                            <style>{'body { background-color: rgb(242, 242, 242); }'}</style>
                            <div className={"container-fluid fimo-background"}>
                                <div className={"row"}>


                                    <div id="sidebar" className={this.state.isSidebarCollapsed ?
                                        "col- sidebar-collapsed" :
                                        "col- sidebar"}>
                                        <Sidebar selectedMenu={this.state.selectedMenu}
                                                 setSelectedMenu={this.setSelectedMenu}
                                                 showForms={this.state.authToken}
                                                 authToken={this.state.authToken}
                                                 user={this.state.user}
                                                 isSidebarCollapsed={this.state.isSidebarCollapsed}
                                                 toggleSidebar={this.toggleSidebar}
                                        />
                                    </div>

                                    <div className={this.state.isSidebarCollapsed ?
                                        "col- main-container-expanded" :
                                        "col- main-container"}>
                                        <div style={{
                                            backgroundColor: "white",
                                            paddingBottom: "10px",
                                            marginBottom: "60px"
                                        }}>
                                            <Header title={title}
                                                    logout={this.executeLogout}
                                            />
                                        </div>
                                        <div className={"content-wrap"}>
                                            <Switch>
                                                <Route exact path={Paths.PATH_ROOT} component={this.renderPageContent}/>
                                                <Route path={Paths.PATH_LOGIN} component={() => this.renderLoginPage()}/>
                                                <Route path={Paths.PATH_SIGNUP} component={RegisterPage}/>
                                                <Route path={`${Paths.PATH_RESET_PASSWORD}/:token`}
                                                       render={(props) => (
                                                           <ChangePassword {...props} isNewExternalUser={false} />
                                                       )}/>
                                                <Route path={`${Paths.PATH_NEW_USER_PASSWORD}/:token`}
                                                       render={(props) => (
                                                           <ChangePassword {...props} isNewExternalUser={true} />
                                                       )}/>
                                                <Route path={Paths.PATH_FORGOT_PASSWORD} component={ForgotPassword}/>
                                                <Route path={Paths.PATH_FORMS} component={FormsPage}/>
                                                {/*<Route path={Paths.PATH_CONTACT} component={ContactsPage}/>*/}
                                                {/*<Route path={Paths.PATH_LEXICON} component={this.renderMenuLexikon}/>*/}
                                                <Route path={Paths.PATH_IMPRINT} component={Imprint}/>
                                                <Route path={Paths.PATH_INFORMATION_PRIVACY} component={InformationPrivacyPage}/>
                                                <Route path={Paths.PATH_CONTACT_REQUEST} component={NewContactRequest}/>
                                                <Route path={Paths.PATH_TERMS_OF_USE} component={TermsOfUse}/>
                                                <Route path={Paths.PATH_DEMO_PORTAL} component={DemoPortal}/>
                                                <Route path={`${Paths.PATH_DEMO_DETAILS}/:id`} component={DemoDetailsContainer}/>

                                                {/*User Management*/}
                                                <ProtectedRoute exact path={Paths.PATH_USERS}
                                                                component={UsersOverview}/>
                                                <ProtectedRoute exact path={`${Paths.PATH_USERS}/create_consultant`}
                                                                component={NewUser} isNewExternalUser={false}/>
                                                <ProtectedRoute exact path={`${Paths.PATH_USERS}/create_client`}
                                                                component={NewUser} isNewExternalUser={true}/>
                                                <ProtectedRoute exact path={`${Paths.PATH_USERS}/:id`}
                                                                component={UserPage}/>
                                                {/*Update Requests*/}
                                                <ProtectedRoute exact path={Paths.PATH_UPDATE_REQUEST}
                                                                component={UpdateRequestsPortal}/>
                                                {/*Quotation Requests*/}
                                                <ProtectedRoute exact path={Paths.PATH_QUOTATION_REQUEST}
                                                                component={QuotationRequestPortal}/>
                                                <Route exact path={`${Paths.PATH_QUOTATION_REQUEST}/new`}
                                                       render={(props) => (
                                                    <NewQuotationRequest {...props} user={this.state.user}/>
                                                )}/>
                                                <ProtectedRoute exact path={`${Paths.PATH_QUOTATION_REQUEST}/:id`}
                                                                component={QuotationRequestPage}/>
                                                {/*Client Employee Requests*/}
                                                <ProtectedRoute exact path={Paths.PATH_CLIENT_EMPLOYEE_REQUESTS}
                                                                component={ClientEmployeeRequestPortal}/>
                                                <ProtectedRoute exact
                                                                path={`${Paths.PATH_CLIENT_EMPLOYEE_REQUESTS}/new`}
                                                                component={NewClientEmployeeRequest}/>
                                                <ProtectedRoute exact
                                                                path={`${Paths.PATH_CLIENT_EMPLOYEE_REQUESTS}/:id`}
                                                                component={ClientEmployeeRequestPage}/>
                                                {/*Client Companies*/}
                                                <ProtectedRoute exact path={Paths.PATH_CLIENT_COMPANIES}
                                                                component={ClientCompanyOverview}/>
                                                <ProtectedRoute exact path={Paths.PATH_CLIENT_COMPANIES_NEW}
                                                                component={NewClientCompany}/>
                                                <ProtectedRoute exact path={Paths.PATH_CLIENT_COMPANIES_EDIT}
                                                                component={EditClientCompany}/>
                                                <ProtectedRoute exact path={Paths.PATH_CLIENT_COMPANIES_POLICIES}
                                                                component={PolicyPortal}/>
                                                <ProtectedRoute exact path={Paths.PATH_CLIENT_COMPANIES_POLICIES_NEW}
                                                                component={NewPolicy}/>
                                                {/*Client Policy Portal*/}
                                                <ProtectedRoute exact path={Paths.PATH_CLIENT_POLICY_PORTAL}
                                                                component={ClientPolicyPortal}/>
                                                {/*Insurance Policies*/}
                                                <ProtectedRoute exact path={Paths.PATH_INSURANCE_POLICIES_EDIT}
                                                                component={EditPolicy}/>
                                                <ProtectedRoute exact
                                                                path={Paths.PATH_INSURANCE_POLICIES_POLICY_DETAILS_NEW}
                                                                component={NewPolicyDetailsFormsContainer}/>
                                                <ProtectedRoute exact path={Paths.PATH_INSURANCE_POLICIES_PROJECTS_NEW}
                                                                component={NewProject}/>
                                                {/*Import Insurances*/}
                                                <ProtectedRoute exact path={Paths.PATH_IMPORT}
                                                                component={ImportInsurances}/>
                                                {/*Insurance Companies*/}
                                                <ProtectedRoute exact path={Paths.PATH_INSURANCES_COMPANIES}
                                                                component={InsurancesIndex}/>
                                                <ProtectedRoute exact path={Paths.PATH_INSURANCES_COMPANY_NEW}
                                                                component={NewInsurance}/>
                                                <ProtectedRoute exact path={Paths.PATH_INSURANCES_COMPANY_EDIT}
                                                                component={EditInsurance}/>
                                                <ProtectedRoute exact path={Paths.PATH_INSURANCES_COMPANY_SHOW}
                                                                component={ShowInsurance}/>
                                                {/*Policy Details*/}
                                                <ProtectedRoute exact path={Paths.PATH_POLICY_DETAILS_EDIT}
                                                                component={EditPolicyDetailsFormsContainer}/>
                                                <ProtectedRoute exact path={`${Paths.PATH_POLICY_DETAILS}/:id`}
                                                                component={PolicyDetailsContainer}/>
                                                {/*Projects*/}
                                                <ProtectedRoute exact path={Paths.PATH_PROJECTS_EDIT}
                                                                component={EditProject}/>
                                                <ProtectedRoute exact path={Paths.PATH_PROJECTS_PROJECT_DETAILS_NEW}
                                                                component={NewProjectDetailsFormsContainer}/>
                                                {/*Project Details*/}
                                                <ProtectedRoute exact path={`${Paths.PATH_PROJECT_DETAILS}/:id`}
                                                                component={ProjectDetailsContainer}/>
                                                <ProtectedRoute exact path={Paths.PATH_PROJECT_DETAILS_EDIT}
                                                                component={EditProjectDetailsFormsContainer}/>
                                            </Switch>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Router>
                </NotificationContext.Provider>
            </div>
        );
    }



    renderPageContent() {
        switch (this.state.selectedMenu) {
            case Constants.MENU_VERTRAGSUEBERSICHT:
                return <ClientPolicyPortal/>;
            case Constants.MENU_CLIENT_COMPANIES_OVERVIEW:
                return <ClientCompanyOverview/>
            default:
                return this.renderPortal();
        }
    }


    renderLoginPage() {
        if (AuthenticationService.isAuthenticated()) {
            console.log("redirecting to portal");
            return <Redirect to={{pathname: `${Paths.PATH_ROOT}`, state: {from: this.props.location}}}/>
        }

        return <LoginPage
            handleSuccessfulLogin={this.handleSuccessfulLogin}/>
    }

    renderPortal() {
        this.renderLoginPage()
        return <Portal authToken={this.state.authToken}></Portal>
    }

    renderPoliciesOverview(userIsCaseHandler, userIsClient) {
        if (userIsCaseHandler) {
            return <PortalContainer user={Constants.USER_FIMO}
                                    authToken={this.state.authToken}
                                    clientCompanies={null}
                                    menu={this.state.selectedMenu}
            />
        } else if (userIsClient) {
            return <ClientPolicyPortal/>
        }
    }

    renderMenuFormulare() {
        return (
            <div style={{marginTop: "20px"}}>
                <FormsPage/>
            </div>
        );
    }

    renderMenuLexikon() {
        document.title = TAB_TITLE_BASE + TAB_TITLE_CONNECTOR + "Lexikon";
        return (
            <div style={{padding: "30px"}}>
                <h3>Lexikon</h3>
                <p>Hier könnte das Lexikon stehen.</p>
            </div>
        );
    }

    showMessage(title, message, type) {
        if (this.notificationDOMRef.current) {
            this.notificationDOMRef.current.addNotification({
                title: title,
                message: message,
                type: type,
                insert: "top",
                container: "top-right",
                dismiss: {
                    duration: 2000
                },
                dismissable: {
                    click: true
                }
            });
        }
    }

    toggleSidebar(){
        this.setState({
                isSidebarCollapsed: !this.state.isSidebarCollapsed
            }
        )
    }

    isMobile(){
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    }
}

const ProtectedRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={(props) => (
        AuthenticationService.isAuthenticated() === true ?
            <Component {...props} {...rest} /> : <Redirect to={{pathname: `${Paths.PATH_LOGIN}`, state: {from: props.location}}}/>
    )}/>
);
export default App;
