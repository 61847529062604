import React, {Component} from 'react';
import Card from "react-bootstrap/Card";
import {withRouter} from "react-router-dom";
import {inputStringToNumber} from "../../../service/formatService";

class EditProjectDetailsVHV extends Component{

    constructor(props){
        super(props);

        this.state = {
            details: props.project.projectDetails,
            productName: props.project.convertedData.product_name,
            policyState: props.project.convertedData.policy_state,
            yearlyFeeObligo: props.project.convertedData.yearlyFeeObligo,
            paymentType: props.project.convertedData.paymentType,
            collateralNominal: props.project.convertedData.collateralsNominal,
            obligosSum: props.project.convertedData.obligos_sum,
            bondLimit: props.project.convertedData.bond_limit,
            singleLimit: props.project.convertedData.single_limit
        };

        this.updateProductName = this.updateProductName.bind(this);
        this.updatePolicyState = this.updatePolicyState.bind(this);
        this.updateCollateralNominal = this.updateCollateralNominal.bind(this);
        this.updateYearlyFeeObligo = this.updateYearlyFeeObligo.bind(this);
        this.updatePaymentType = this.updatePaymentType.bind(this);

        this.updateObligosSum = this.updateObligosSum.bind(this);
        this.updateBondLimit = this.updateBondLimit.bind(this);
        this.updateSingleLimit = this.updateSingleLimit.bind(this);
    }

    updateProductName(event){
        this.setState({
            productName: event.target.value
        }, () => this.updateNewDetails());
    }

    updatePolicyState(event){
        this.setState({
            policyState: event.target.value
        }, () => this.updateNewDetails());
    }

    updateYearlyFeeObligo(event){
        this.setState({
            yearlyFeeObligo: event.target.value
        }, () => this.updateNewDetails());
    }

    updateCollateralNominal(event){
        this.setState({
            collateralNominal: event.target.value
        }, () => this.updateNewDetails());
    }

    updatePaymentType(event){
        this.setState({
            paymentType: event.target.value
        }, () => this.updateNewDetails());
    }

    updateObligosSum(event){
        this.setState({
            obligosSum: event.target.value
        }, () => this.updateNewDetails());
    }

    updateBondLimit(event){
        this.setState({
            bondLimit: event.target.value
        }, () => this.updateNewDetails());
    }

    updateSingleLimit(event){
        this.setState({
            singleLimit: event.target.value
        }, () => this.updateNewDetails());
    }

    updateNewDetails() {
        let newDetails = {
            productName: this.state.productName,
            obligosSum: inputStringToNumber(this.state.obligosSum),
            bondLimit: inputStringToNumber(this.state.bondLimit),
            policyState: this.state.policyState,
            singleLimit: inputStringToNumber(this.state.singleLimit),
            paymentType: this.state.paymentType,
            yearlyFeeObligo: inputStringToNumber(this.state.yearlyFeeObligo),
            collateralNominal: inputStringToNumber(this.state.collateralNominal)
        };

        this.props.updateNewDetails(newDetails);
    }


    renderHeading(heading) {
        return (
            <div style={{marginBottom: "16px"}}>
                <h4>{heading}</h4>
            </div>
        );
    }

    render(){
        return(
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div>
                            {this.renderHeading("Basisinformationen")}

                            <Card className="form-card">
                                <form>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Product:</label>
                                        <input className="col form-control"
                                               value={this.state.productName}
                                               onChange={this.updateProductName}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Status:</label>
                                        <input className="col form-control"
                                               value={this.state.policyState}
                                               onChange={this.updatePolicyState}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label"> Jahresbeitrag:</label>
                                        <input className="col form-control"
                                               value={this.state.yearlyFeeObligo}
                                               onChange={this.updateYearlyFeeObligo}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label"> Zahlweise:</label>
                                        <input className="col form-control"
                                               value={this.state.paymentType}
                                               onChange={this.updatePaymentType}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label"> Soll-Sicherheiten:</label>
                                        <input className="col form-control"
                                               value={this.state.collateralNominal}
                                               onChange={this.updateCollateralNominal}/>
                                    </div>
                                </form>
                            </Card>
                        </div>
                    </div>

                    <div className="col-md-6">
                        {this.renderHeading("Kontostand")}

                        <Card className="form-card">
                            <form>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Gesamtobligo:</label>
                                    <input className="col form-control"
                                           value={this.state.obligosSum}
                                           onChange={this.updateObligosSum}/>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Limit:</label>
                                    <input className="col form-control"
                                           value={this.state.bondLimit}
                                           onChange={this.updateBondLimit}/>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Bürgschaftslimit:</label>
                                    <input className="col form-control"
                                           value={this.state.singleLimit}
                                           onChange={this.updateSingleLimit}/>
                                </div>
                            </form>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(EditProjectDetailsVHV);
