import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';


class AbstractPolicyDetails extends Component {
    renderHeading(heading) {
        return (
            <div style={{marginBottom: "16px"}}>
                <h4>{heading}</h4>
            </div>
        );
    }

    renderLine(attribute, value, indent = false) {
        const style = indent ? {paddingLeft: "16px"} : {paddingLeft: "0px"};

        return (
            <tr style={{fontSize: "1.1em", height: "32px"}}>
                <td style={style}><b>{attribute}</b></td>
                <td style={{paddingLeft: "32px"}}>{value}</td>
            </tr>
        )
    }
}


export default AbstractPolicyDetails;
