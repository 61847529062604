import RequestState from "./RequestState";
import * as Paths from "../config/paths";
import request from "superagent";
import User from "./User";

class QuotationRequest {

    static get modelName() {
        return 'QuotationRequest'
    }

    constructor(object){
        Object.assign(this, object, {
            request_state: object.request_state ? new RequestState(object.request_state) : null,
            processed_by: new User(object.processed_by)})
    }

    /**
     * Gets a list of QuotationRequests from the server.
     *
     * @returns {Promise<any>}
     */
    static getQuotationRequests(){
        return new Promise((resolve, reject) => {
            request.get(Paths.URL_QUOTATION_REQUEST)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Gets the QuotationRequest with the specified ID from the server.
     *
     * @param id The ID of the QuotationRequest
     * @returns {Promise<any>}
     */
    static getQuotationRequest(id){
        const url = `${Paths.URL_QUOTATION_REQUEST}/${id}`;

        return new Promise((resolve, reject) => {
            request.get(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Creates a new QuotationRequest with the given details on the server
     *
     * @param newQuotationRequest The details of the new QuotationRequest
     * @returns {Promise<any>}
     */
    static createQuotationRequest(newQuotationRequest){
        const url = Paths.URL_QUOTATION_REQUEST;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('Access-Control-Allow-Origin', '*')
                .send(newQuotationRequest)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Deletes the QuotationRequest from the server.
     *
     * @returns {Promise<any>}
     */
    deleteQuotationRequest(){
        const url = `${Paths.URL_QUOTATION_REQUEST}/${this.id}`;

        return new Promise((resolve, reject) => {
            request.delete(url)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .parse(({body}) => body)
                .end((err, res) => {
                    if (err) {
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Sets the state of the QuotationRequest to 'in progress'.
     *
     * @returns {Promise<any>}
     */
    startQuotationRequest(){
        const url = `${Paths.URL_QUOTATION_REQUEST}/${this.id}/start`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err) {
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Sets the state of the QuotationRequest to 'paused'.
     *
     * @returns {Promise<any>}
     */
    pauseQuotationRequest(){
        const url = `${Paths.URL_QUOTATION_REQUEST}/${this.id}/pause`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err) {
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Sets the state of the QuotationRequest to 'completed'.
     *
     * @returns {Promise<any>}
     */
    completeQuotationRequest(){
        const url = `${Paths.URL_QUOTATION_REQUEST}/${this.id}/complete`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err) {
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Sets the state of the QuotationRequest to 'cancelled'.
     *
     * @returns {Promise<any>}
     */
    cancelUpdateRequest(){
        const url = `${Paths.URL_QUOTATION_REQUEST}/${this.id}/cancel`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err) {
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }
}

export default QuotationRequest;