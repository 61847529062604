import React, {Component} from 'react';
import {NotificationContext} from "../../contexts/NotificationContext";
import Card from "react-bootstrap/Card";
import {withRouter} from "react-router-dom";
import {inputStringToNumber} from "../../../service/formatService";

class NewPolicyDetails extends Component{

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            productName: undefined,
            obligosSum: "0",
            bondLimit: "0",
            singleLimit: undefined,
            policyState: undefined,
            collateralNominal: undefined,
            collateralCurrent: undefined,
            collateralPercent: undefined,
            remainingBalance: undefined,
            paymentType: undefined,
            yearlyFeeObligo: undefined,
            premiumRate: undefined,
            avalClasses: [],
            hidediv: false,
            hidedivtwo: false
        }

        this.updateProductName = this.updateProductName.bind(this);
        this.updateObligosSum = this.updateObligosSum.bind(this);
        this.updateBondLimit = this.updateBondLimit.bind(this);
        this.updateSingleLimit = this.updateSingleLimit.bind(this);
        this.updatePolicyState = this.updatePolicyState.bind(this);
        this.updateCollateralNominal = this.updateCollateralNominal.bind(this);
        this.updateCollateralCurrent = this.updateCollateralCurrent.bind(this);
        this.updateCollateralPercent = this.updateCollateralPercent.bind(this);
        this.updateRemainingBalance = this.updateRemainingBalance.bind(this);
        this.updatePaymentType = this.updatePaymentType.bind(this);
        this.updateYearlyFeeObligo = this.updateYearlyFeeObligo.bind(this);
        this.updatePremiumRate = this.updatePremiumRate.bind(this);
        this.addAvalClass = this.addAvalClass.bind(this);
        this.removeAvalClass = this.removeAvalClass.bind(this);

        this.updateAvalClassName = this.updateAvalClassName.bind(this);
        this.updateAvalClassObligo = this.updateAvalClassObligo.bind(this);
        this.updateAvalClassBondLimit = this.updateAvalClassBondLimit.bind(this);
        this.updateAvalClassSingleLimit = this.updateAvalClassSingleLimit.bind(this);
        this.updateAvalClassPremium = this.updateAvalClassPremium.bind(this);

        this.setNewDetails()
    }

    handleClick(){
        this.setState({
            hidediv: true
        });
    }
    handleClicktwo(){
        this.setState({
            hidedivtwo: true
        });
    }

    updateProductName(event) {
        this.setState({
            productName: event.target.value
        }, () => this.setNewDetails());
    }

    updateObligosSum(event){
        this.setState({
            obligosSum: event.target.value
        }, () => this.setNewDetails());
    }

    updateBondLimit(event) {
        this.setState({
            bondLimit: event.target.value
        }, () => this.setNewDetails());
    }
    updatePolicyState(event) {
        this.setState({
            policyState: event.target.value
        }, () => this.setNewDetails());
    }

    updateSingleLimit(event){
        this.setState({
            singleLimit: event.target.value
        }, () => this.setNewDetails());
    }


    updateCollateralNominal(event) {
        this.setState({
            collateralNominal: event.target.value
        }, () => this.setNewDetails());
    }

    updateCollateralCurrent(event) {
        this.setState({
            collateralCurrent: event.target.value
        }, () => this.setNewDetails());
    }

    updateCollateralPercent(event) {
        this.setState({
            collateralPercent: event.target.value
        }, () => this.setNewDetails());
    }

    updateRemainingBalance(event) {
        this.setState({
            remainingBalance: event.target.value
        }, () => this.setNewDetails());
    }

    updatePaymentType(event) {
        this.setState({
            paymentType: event.target.value
        }, () => this.setNewDetails());
    }
    updateYearlyFeeObligo(event) {
        this.setState({
            yearlyFeeObligo: event.target.value
        }, () => this.setNewDetails());
    }

    updatePremiumRate(event) {
        this.setState({
            premiumRate: event.target.value
        }, () => this.setNewDetails());
    }

    addAvalClass(){
        this.setState({
            avalClasses: this.state.avalClasses.concat({
                name: '',
                avalClass: '',
                obligo: '',
                bondLimit: '',
                singleLimit: '',
                avalPremium: ''
            })
        });
    }

    removeAvalClass(index){
        let avalClasses = this.state.avalClasses;
        avalClasses.splice(index, 1);

        this.setState({
            avalClasses: avalClasses,
            hidediv: false,
            hidedivtwo: false
        }, () => this.setNewDetails());
    }

    renderHeading(heading) {
        return (
            <div
                className="d-inline-block"
                style={{marginBottom: "16px"}}>
                <h4>{heading}</h4>
            </div>
        );
    }

    updateAvalClassName(event, avalClass, index){
        avalClass.name = event.target.value;

        let avalClasses = this.state.avalClasses;
        avalClasses[index] = avalClass;

        this.setState({
            avalClasses: avalClasses
        }, () => this.setNewDetails());
    }

    updateAvalClassObligo(event, avalClass, index){
        avalClass.obligo = event.target.value;

        let avalClasses = this.state.avalClasses;
        avalClasses[index] = avalClass;

        this.setState({
            avalClasses: avalClasses
        }, () => this.setNewDetails());
    }

    updateAvalClassBondLimit(event, avalClass, index){
        avalClass.bondLimit = event.target.value;

        let avalClasses = this.state.avalClasses;
        avalClasses[index] = avalClass;

        this.setState({
            avalClasses: avalClasses
        }, () => this.setNewDetails());
    }

    updateAvalClassSingleLimit(event, avalClass, index){
        avalClass.singleLimit = event.target.value;

        let avalClasses = this.state.avalClasses;
        avalClasses[index] = avalClass;

        this.setState({
            avalClasses: avalClasses
        }, () => this.setNewDetails());
    }

    updateAvalClassPremium(event, avalClass, index){
        avalClass.avalPremium = event.target.value;

        let avalClasses = this.state.avalClasses;
        avalClasses[index] = avalClass;

        this.setState({
            avalClasses: avalClasses
        }, () => this.setNewDetails());
    }

    setNewDetails(){
        let obligos = [];
        let bonds = [];
        let singleLimits = [];
        let avalPremia = [];
        let collaterals = [];

        collaterals.push({
            nominal: inputStringToNumber(this.state.collateralNominal),
            current: inputStringToNumber(this.state.collateralCurrent),
            percent: this.state.collateralPercent
        });

        avalPremia.push({
            yearly_fee_obligo: inputStringToNumber(this.state.yearlyFeeObligo),
            premium_rate: this.state.premiumRate,
            payment_type: this.state.paymentType
        });

        this.state.avalClasses.map(avalClass => {
            let classNames = avalClass.name.split(/\s*[\n,]\s*/);
            let approved = true
            let avalClassSpecifier = avalClass.avalClass || "";

            obligos.push({
                aval_class: {
                    class_names: classNames,
                    approved: approved,
                    aval_class: avalClassSpecifier
                },
                amount: inputStringToNumber(avalClass.obligo)
            });

            bonds.push({
                aval_class: {
                    class_names: classNames,
                    approved: approved,
                    aval_class: avalClassSpecifier
                },
                amount: inputStringToNumber(avalClass.bondLimit)
            });

            singleLimits.push({
                aval_class: {
                    class_names: classNames,
                    approved: approved,
                    aval_class: avalClassSpecifier
                },
                amount: inputStringToNumber(avalClass.singleLimit)
            });

            avalPremia.push({
                aval_class: {
                    class_names: classNames,
                    approved: approved,
                    aval_class: avalClassSpecifier
                },
                premium_rate: inputStringToNumber(avalClass.avalPremium)
            });
        });

        let newDetails = {
            product_name: this.state.productName,
            obligos_sum: inputStringToNumber(this.state.obligosSum),
            bond_limit: inputStringToNumber(this.state.bondLimit),
            single_limit_max: inputStringToNumber(this.state.singleLimit),
            contract_state: this.state.policyState,
            collaterals: collaterals,
            remaining_balance: inputStringToNumber(this.state.remainingBalance),
            bonds: bonds,
            single_limits: singleLimits,
            obligos: obligos,
            aval_premia: avalPremia,
        };

        this.props.setNewDetails(newDetails);
    }

    renderAvalClassForm(avalClass, index){
        return(
            <Card key={index}
                  style={{marginBottom: "20px"}}>
                <Card.Title className="card-header">
                    <button className="btn btn-danger float-right"
                            onClick={() => this.removeAvalClass(index)}>
                        Entfernen
                    </button>
                    <form >
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Avalklasse</label>
                            <input className="col form-control"
                                   value={avalClass.name}
                                   onChange={(event) => this.updateAvalClassName(event, avalClass, index)}/>
                        </div>
                    </form>
                </Card.Title>
                <Card.Body>
                    <div className="form-group row" >
                        <button
                            className="btn btn-primary center" hidden = {this.state.hidediv}
                            onClick={() => this.handleClick()}>Limit</button></div>
                    <div className="form-group row">
                        <button
                            className="btn btn-primary float-left"hidden = {this.state.hidedivtwo}
                            onClick={() => this.handleClicktwo()}>Einzelstück</button></div>
                    <form>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Obligo</label>
                            <input className="col form-control"
                                   value={avalClass.obligo}
                                   onChange={(event) => this.updateAvalClassObligo(event, avalClass, index)}/>
                        </div>
                        <div className="form-group row" hidden = {!this.state.hidediv}>
                            <label className="col-sm-4 col-form-label">Limit</label>
                            <input className="col form-control"
                                   value={avalClass.bondLimit}
                                   onChange={(event) => this.updateAvalClassBondLimit(event, avalClass, index)}/>
                        </div>

                        <div className="form-group row hidden"  hidden = {!this.state.hidedivtwo}>
                            <label className="col-sm-4 col-form-label">Einzelstück</label>
                            <input className="col form-control"
                                   value={avalClass.singleLimit}
                                   onChange={(event) => this.updateAvalClassSingleLimit(event, avalClass, index)}/>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Avalprämie</label>
                            <input className="col form-control"
                                   value={avalClass.avalPremium}
                                   onChange={(event) => this.updateAvalClassPremium(event, avalClass, index)}/>
                        </div>
                    </form>
                </Card.Body>
            </Card>
        );
    }

    render(){
        return(
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div>
                            {this.renderHeading("Basisinformationen")}

                            <Card className="form-card" style={{padding: '30px'}}>
                                <form>
                                    <div className="form-group row" >
                                        <label className="col-sm-4 col-form-label">Produkt</label>
                                        <input className="col form-control"
                                               value={this.state.productName}
                                               onChange={this.updateProductName}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Gesamtobligo</label>
                                        <input className="col form-control"
                                               value={this.state.obligosSum}
                                               onChange={this.updateObligosSum}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Limit</label>
                                        <input className="col form-control"
                                               value={this.state.bondLimit}
                                               onChange={this.updateBondLimit}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Einzelstück</label>
                                        <input className="col form-control"
                                               value={this.state.singleLimit}
                                               onChange={this.updateSingleLimit}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Status</label>
                                        <input className="col form-control"
                                               value={this.state.policyState}
                                               onChange={this.updatePolicyState}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Sicherheit Soll</label>
                                        <input className="col form-control"
                                               value={this.state.collateralNominal}
                                               onChange={this.updateCollateralNominal}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Sicherheit Ist</label>
                                        <input className="col form-control"
                                               value={this.state.collateralCurrent}
                                               onChange={this.updateCollateralCurrent}/>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Sicherheit in %</label>
                                        <input className="col form-control"
                                               value={this.state.collateralPercent}
                                               onChange={this.updateCollateralPercent}/>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Freies Limit</label>
                                        <input className="col form-control"
                                               value={this.state.remainingBalance}
                                               onChange={this.updateRemainingBalance}/>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Zahlweise</label>
                                        <input className="col form-control"
                                               value={this.state.paymentType}
                                               onChange={this.updatePaymentType}/>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Prämie in EUR</label>
                                        <input className="col form-control"
                                               value={this.state.yearlyFeeObligo}
                                               onChange={this.updateYearlyFeeObligo}/>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Prämiensatz in %</label>
                                        <input className="col form-control"
                                               value={this.state.premiumRate}
                                               onChange={this.updatePremiumRate}/>
                                    </div>
                                </form>
                            </Card>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div>
                            {this.renderHeading("Avalklassen")}

                            <button
                                className="btn btn-primary float-right"
                                onClick={this.addAvalClass}>Hinzufügen</button>
                        </div>

                        {this.state.avalClasses.map((avalClass, index) => this.renderAvalClassForm(avalClass, index))}
                    </div>

                </div>

            </div>
        );
    }
}

export default withRouter(NewPolicyDetails);
