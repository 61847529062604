import Bond from "./Bond";
import Obligo from "./Obligo";
import Collateral from "./Collateral";
import SingleLimit from "./SingleLimit";
import AvalPremium from "./AvalPremium";

import * as Paths from '../config/paths';
import request from "superagent";

class InsurancePolicyDetail {

    static get modelName() {
        return 'InsurancePolicyDetail'
    }

    constructor(object){
        Object.assign(this, object, {
            bonds: object && object.bonds ? object.bonds.map(bond => new Bond(bond)) : null,
            obligos: object && object.obligos ? object.obligos.map(obligo =>  new Obligo(obligo)) : null,
            collaterals: object && object.collaterals ? object.collaterals.map(collateral => new Collateral(collateral)) : null,
            single_limits: object && object.single_limits ? object.single_limits.map(single_limit => new SingleLimit(single_limit)) : null,
            aval_premia: object && object.aval_premia ? object.aval_premia.map(aval_premium => new AvalPremium(aval_premium)) : null})

    }

    /**
     * Gets the InsurancePolicyDetail with the specified ID from the server.
     *
     * @param id The ID of the InsurancePolicyDetail
     * @returns {Promise<any>}
     */
    static getInsurancePolicyDetail(id){
        const url = `${Paths.URL_POLICY_DETAILS}/${id}`;

        return new Promise((resolve, reject) => {
            request.get(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Updates the InsurancePolicyDetails object with the new details.
     *
     * @param updatedDetails The new details
     * @returns {Promise<any>}
     */
    updateInsurancePolicyDetails(updatedDetails){
        const url = `${Paths.URL_POLICY_DETAILS}/${this.id}`;

        return new Promise((resolve, reject) => {
            request.put(url)
                .set('Access-Control-Allow-Origin', '*')
                .send(updatedDetails)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Deletes the InsurancePolicyDetail from the server.
     * @returns {Promise<any>}
     */
    deleteInsurancePolicyDetail(){
        const url = `${Paths.URL_POLICY_DETAILS}/${this.id}`;

        return new Promise((resolve, reject) => {
            request.delete(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .parse(({body}) => body)
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Updates the InsurancePolicyDetails object with the new details.
     *
     * @param updatedDetails The new details
     * @returns {Promise<any>}
     */
    updateProjectDetails(updatedDetails){
        const url = `${Paths.URL_PROJECT_DETAILS}/${this.id}`;

        return new Promise((resolve, reject) => {
            request.put(url)
                .set('Access-Control-Allow-Origin', '*')
                .send(updatedDetails)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Deletes the InsurancePolicyDetail from the server.
     * @returns {Promise<any>}
     */
    deleteProjectDetail(){
        const url = `${Paths.URL_PROJECT_DETAILS}/${this.id}`;

        return new Promise((resolve, reject) => {
            request.delete(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .parse(({body}) => body)
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

}

export default InsurancePolicyDetail;
