import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';

import * as Paths from '../config/paths';

import 'bootstrap/dist/css/bootstrap.min.css';
import * as AuthenticationService from "../service/authenticationService";

import logo_suedvers from '../static/extern/suedvers_logo.jpg';

class Header extends Component {

    constructor(props) {
        super(props);
    }

    logout() {
        this.props.logout();
    }

    render() {
        const {pathname} = this.props.location;

        return (
            <div className="col-" style={{paddingRight: "100px", paddingTop: "10px", overflow: "hidden"}}>
                <div style={{marginLeft: "-15px"}}>
                    <img src={logo_suedvers} width="210px" />
                </div>
                <div className="container"
                    style={{display: "inline", marginLeft: "8px"}}>
                    <span
                        className="text-fimo-green"
                        style={{fontSize: "1.5em"}}>Meine Avale Online</span>
                </div>

                {/*When user not logged in*/}
                {!AuthenticationService.isAuthenticated() &&
                    <div style={{display: "inline", float: "right"}}>
                        {(pathname !== Paths.PATH_LOGIN) &&
                        <Link to={`${Paths.PATH_LOGIN}`}>
                            <button className={"btn btn-link"}>
                                Login
                            </button>
                        </Link>
                        }
                        {(pathname !== Paths.PATH_SIGNUP) &&
                        <Link to="/signup">
                            <button className={"btn btn-link"}>
                                Registrieren
                            </button>
                        </Link>
                        }
                    </div>
                }

                {/*When user is logged in*/}
                {AuthenticationService.isAuthenticated() &&
                    <div style={{display: "inline", float: "right"}}>
                        <Link to="/">
                            <button className={"btn btn-link"} onClick={() => this.props.logout()}>
                                Logout
                            </button>
                        </Link>
                    </div>
                }
            </div>
        )
    }

}


export default withRouter(Header);
