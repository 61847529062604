import React, {Component} from 'react';

import * as ConfigurationService from '../service/configurationService';

import 'bootstrap/dist/css/bootstrap.min.css';

class AdminPortal extends Component {

    constructor(props) {
        super(props);

        this.loadConfiguration = this.loadConfiguration.bind(this);
    }

    loadConfiguration(event) {
        event.preventDefault();
        ConfigurationService.loadConfigurationIntoDatabase(this.props.authToken);
    }

    render() {
        return (
            <div style={{margin: "20px"}}>
                <button className="btn btn-primary" style={{marginLeft: "100px"}}
                        onClick={this.loadConfiguration}>
                    Konfigurationen laden
                </button>
            </div>);
    }

}


export default AdminPortal;