import RequestState from "./RequestState";

import request from 'superagent';
import * as Paths from '../config/paths';
import User from "./User";
import ClientCompany from "./ClientCompany";

class ClientEmployeeRequest {

    static get modelName() {
        return 'ClientEmployeeRequest'
    }

    constructor(object){
        Object.assign(this, object, {request_state: new RequestState(object.request_state),
            client_employee_to_add: new User(object.client_employee_to_add),
            processed_by: new User(object.processed_by),
            client_company: new ClientCompany(object.client_company)});
    }


    /**
     * Gets a list of ClientEmployeeRequests from the server.
     *
     * @returns {Promise<any>}
     */
    static getClientEmployeeRequests(){
        return new Promise((resolve, reject) => {
            request.get(Paths.URL_CLIENT_EMPLOYEE_REQUEST)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Gets the ClientEmployeeRequest with the specified ID from the server.
     *
     * @param id The ID of the ClientEmployeeRequest
     * @returns {Promise<any>}
     */
    static getClientEmployeeRequest(id){
        const url = `${Paths.URL_CLIENT_EMPLOYEE_REQUEST}/${id}`;

        return new Promise((resolve, reject) => {
            request.get(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Creates a new ClientEmployeeRequest on the server.
     *
     * @returns {Promise<any>}
     */
    createClientEmployeeRequest(){
        return new Promise((resolve, reject) => {
            request.post(Paths.URL_CLIENT_EMPLOYEE_REQUEST)
                .set('Access-Control-Allow-Origin', '*')
                .send(this)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Deletes the ClientEmployeeRequest from the server.
     *
     * @returns {Promise<any>}
     */
    deleteClientEmployeeRequest(){
        const url = `${Paths.URL_CLIENT_EMPLOYEE_REQUEST}/${this.id}`;

        return new Promise((resolve, reject) => {
            request.delete(url)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .parse(({body}) => body)
                .end((err, res) => {
                    if (err) {
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Approves the ClientEmployeeRequest and grants the client access to the ClientCompany.
     *
     * @returns {Promise<any>}
     */
    approveClientEmployeeRequest(){
        const url = `${Paths.URL_CLIENT_EMPLOYEE_REQUEST}/${this.id}/approve`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .send(this)
                .end((err, res) => {
                    if (err) {
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    declineClientEmployeeRequest(){
        const url = `${Paths.URL_CLIENT_EMPLOYEE_REQUEST}/${this.id}/decline`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .send(this)
                .end((err, res) => {
                    if (err) {
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

}

export default ClientEmployeeRequest;
