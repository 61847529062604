import React, {Component} from 'react';

class Error extends Component{

    render(){
        return(
            <div>
                <h2 className="page-headline">{this.props.status}</h2>
                <h4 className="text-fimo-blue">{this.props.statusText}</h4>
            </div>
        );
    }

}

export default Error;