import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import ClientEmployeeRequest from '../../models/ClientEmployeeRequest';
import {NotificationContext} from "../contexts/NotificationContext";
import {TAB_TITLE_BASE, TAB_TITLE_CONNECTOR} from "../../config/constants";
import {Redirect, withRouter} from "react-router-dom";

class NewClientEmployeeRequest extends Component{

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            selectedClientCompany: null
        };
    }

    updateSelectedClientCompany = (selectedOption) => {
        this.setState({
            selectedClientCompany: selectedOption.target.value
        });
    }

    createClientEmployeeRequest(){
        let request = new ClientEmployeeRequest({client_company_name: this.state.selectedClientCompany});

        let showMessage = this.context.showMessage;

        request.createClientEmployeeRequest()
            .then(response => {
                if (response.status === 201){
                    showMessage(null, "Anfrage erstellt", "success");
                    this.props.history.push('/');
                    window.location.reload();
                }
                else {
                    showMessage(null, "Fehler beim ertellen der Anfrage", "danger");
                }
            })
            .catch(err => {
                switch (err.response.status) {
                    case 403:
                        showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                        break;
                    case 409:
                        showMessage(null, "Zugangsanfrage existiert bereits", "danger");
                        break;
                    case 422:
                        showMessage(null, "Geben Sie den Namen der Firma an", "warning");
                        break;
                    case 500:
                        showMessage(null, "Unbekannter Fehler", "danger");
                        break;
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    render(){
        document.title = TAB_TITLE_BASE + TAB_TITLE_CONNECTOR + "Zugangsanfrage";

        return(
            <div className={"fimo-content-page"} style={{padding: "30px"}}>
                <h4 className="page-headline"> Neue Zugangsanfrage</h4>

                <div className={"w-50"}>
                    <div>
                        <label>Name der Firma, zu der Sie Zugang erhalten möchten:</label>
                        <input className="col form-control"
                               value={this.state.selectedClientCompany}
                                onChange={this.updateSelectedClientCompany}/>
                    </div>

                    <div className="text-center" style={{paddingTop: "30px"}}>
                        <button className="btn btn-primary"
                                onClick={() => this.createClientEmployeeRequest()}>
                            Zugang anfragen
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(NewClientEmployeeRequest);
