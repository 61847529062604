export function translateRequestState(requestState) {
    switch(requestState) {
        case "in progress":
            return "in Ausführung";
        case "pending":
            return "ausstehend";
        case "paused":
            return "pausiert";
        case "approved":
            return "genehmigt";
        case "cancelled":
            return "abgebrochen";
        case "declined":
            return  "abgelehnt";
        case "completed":
            return  "abgeschlossen";
        default:
             return requestState;
    }
}