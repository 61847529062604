import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import * as FormatService from '../../service/formatService';
import {formatVHVpolicyNumber} from '../../service/formatService';
import Can from '../Can'
import {
    INSURANCE_VHV
} from "../../config/constants";
import InsurancePolicyDetail from "../../models/InsurancePolicyDetail";

import * as Paths from "../../config/paths";
import Link from "react-router-dom/es/Link";

import {IoMdCreate, IoMdAddCircle, IoMdOpen, IoIosTrash} from 'react-icons/io'
import {openAvalRequestURL} from "../../service/externalLinkService";

import logo_ruv from '../../static/extern/rv_logo.png';
import logo_axa from '../../static/extern/axa_logo.png';
import logo_euler_hermes from '../../static/extern/euler_hermes_logo.jpeg';
import logo_vhv from '../../static/extern/vhv_logo.jpeg';
import ergo from '../../static/extern/ergo.png';
import zurich_logo from '../../static/extern/zurich_logo.jpg';
import coface_logo from '../../static/extern/coface_logo.jpg';
import bayern_logo from '../../static/extern/vkb_logo.jpg';
import tryg_logo from '../../static/extern/tryg_logo.png';
import gothaer_logo from '../../static/extern/gothaer_logo.png';
import wuerttembergische_logo from '../../static/extern/wuerttembergische_logo.png';
import allianz_trade_logo from '../../static/extern/allianz_trade_logo.png';
import DeleteDialog from '../DeleteDialog';

class PolicyCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            projects: [],
            showDeletionDialog: false,
            deletionText: "",
            confirmDeleteRequest: () => {}
        };
    }

    componentDidMount() {
        this.loadProjectDetails();
    }

    loadProjectDetails() {
        let projects = [];

        let promises = [];

        this.props.policyData.policy.projects.forEach(project => {
            promises.push(
                project.getLatestDetails()
                    .then(async (response) => {
                        if (response.status === 200) {
                            console.log(response);

                            let details = new InsurancePolicyDetail(response.body);

                            project.projectDetails = details;
                        }

                        projects.push(project);
                    })
                    .catch(err => {
                        console.log(err);
                    })
            )
        });

        Promise.all(promises)
            .then(() => {
                const policyDataSorted = projects.sort(
                    (project1, project2) => (project1.id > project2.id) ? 1 : -1);

                this.setState({
                    projects: projects
                });
            });
    }


    avalRequest() {
        openAvalRequestURL(this.props.policyData.policy.insurance_company.name);
    }

    getLogo() {
        switch (this.props.policyData.policy.insurance_company_id) {
            case 1:
                return logo_axa;
            case 2:
                return logo_vhv;
            case 4:
                return logo_ruv;
            case 5:
                return logo_euler_hermes;
            case 6:
                return ergo;
            case 7:
                return zurich_logo;
            case 8:
                return bayern_logo;
            case 9:
                return coface_logo;
            case 13:
                return wuerttembergische_logo;
            case 14:
                return tryg_logo;
            case 16:
                return allianz_trade_logo;
            case 17:
                return gothaer_logo;
            default:
                return null;
        }
    }

    openlink() {
        window.open(this.props.policyData.policy.insurance_company.homepage)
    }

    render() {
        const policyNumber = this.props.policyData.displayData.policyNumber;
        const formattedPolicyNumber = this.props.policyData.policy.insurance_company.name === INSURANCE_VHV
            ? formatVHVpolicyNumber(policyNumber)
            : policyNumber;

        let obligos_with_projects = this.props.policyData.obligos_sum;
        let bond_limit_with_projects = this.props.policyData.bond_limit;

        let logo = this.getLogo();

        if (this.state.projects.length > 0) {
            this.state.projects.forEach(project => {
                if (project.projectDetails) {
                    obligos_with_projects += project.projectDetails.obligos_sum;
                    bond_limit_with_projects += project.projectDetails.bond_limit;
                }
            });
        }

        return (
            <div style={{
                padding: "30px",
                paddingTop: "12px",
                margin: "10px",
                width: "430px",
                border: "1px solid #000000"
            }}>

                {this.state.showDeletionDialog &&
                    <DeleteDialog
                        elementText={this.state.deletionText}
                        cancel={() =>
                            this.setState({
                                showDeletionDialog: false,
                                deletionText: '',
                                confirmDeleteRequest: () => void 0
                            })
                        }
                        confirmDelete={this.state.confirmDeleteRequest}
                    />
                }

                <div className={"row"} style={{marginRight: "-40px"}}>
                    <div className="col text-right">
                        {this.props.policyData.policy.insurance_company.homepage &&
                        <button className="btn btn-outline-policycard" onClick={() => this.openlink()}>
                            <div>
                                <IoMdOpen/>
                            </div>
                            Aval
                            <br></br>
                            anfordern
                        </button>
                        }

                        {this.props.policyData.updated_at &&
                        <Link to={{
                            pathname: `${Paths.PATH_POLICY_DETAILS}/${this.props.policyData.id}`,
                            clientData: this.props.policyData
                        }}>
                            <button className="btn btn-outline-policycard">
                                <div>
                                    <IoMdOpen/>
                                </div>
                                Details
                                <br></br>
                                anzeigen
                            </button>
                        </Link>
                        }

                        <Can I="update" on={this.props.policyData.policy}>
                            <Link to={{
                                pathname: `${Paths.PATH_INSURANCE_POLICIES}/${this.props.policyData.policy.id}/edit`,
                                policy: this.props.policyData.policy
                            }}>
                                <button
                                    className="btn btn-outline-policycard">
                                    <div>
                                        <IoMdCreate/>
                                    </div>
                                    Police
                                    <br></br>
                                    bearbeiten
                                </button>
                            </Link>
                        </Can>

                        <Can I="destroy" on={this.props.policyData.policy}>
                            <button
                                className="btn btn-outline-policycard"
                                onClick={(event) => {
                                    this.setState({
                                        showDeletionDialog: true,
                                        deletionText: `Vertrag der Firma ${this.props.policyData.policy.client_company.company_name} und Versicherer ${this.props.policyData.displayData.insuranceName} mit VSNR ${formattedPolicyNumber}`,
                                        confirmDeleteRequest: () => this.props.deletePolicy(this.props.policyData.policy)
                                    })
                                }}>
                                <div>
                                    <IoIosTrash/>
                                </div>
                                Police
                                <br/>
                                entfernen
                            </button>
                        </Can>

                        {!this.props.policyData.updated_at &&
                        <Can I="create" on="InsurancePolicyDetail">
                            <Link to={{
                                pathname: `${Paths.PATH_INSURANCE_POLICIES}/${this.props.policyData.policy.id}${Paths.PATH_POLICY_DETAILS}/new`,
                                policy: this.props.policyData.policy
                            }}>
                                <button
                                    className="btn btn-outline-policycard">
                                    <div>
                                        <IoMdAddCircle/>
                                    </div>
                                    Stand
                                    <br></br>
                                    initialisieren
                                </button>
                            </Link>
                        </Can>
                        }
                    </div>
                </div>


                <div>
                    {logo ?
                        <div style={{marginBottom: "24px"}}><img src={logo} height={40}/></div> :
                        <h2 className="d-inline-block">{this.props.policyData.displayData.insuranceName}</h2>
                    }
                </div>

                <div>
                    <span style={{paddingRight: "10px"}}><b>VSNR:</b></span>
                    <span>{formattedPolicyNumber}</span>
                </div>

                {this.props.policyData.updated_at ?
                    <div>
                        <div>
                            <span style={{paddingRight: "10px"}}><b>Stand vom:</b></span>
                            <span>{FormatService.formatDate(this.props.policyData.updated_at)}</span>
                        </div>
                        <div style={{paddingTop: "12px"}}>
                            <div style={{paddingRight: "10px"}}><b>Auslastung:</b></div>
                            <div>
                                <span>{FormatService.formatCurrency(this.props.policyData.obligos_sum)}</span>
                                <span style={{marginLeft: "5px", marginRight: "5px"}}>/</span>
                                <span>{FormatService.formatCurrency(this.props.policyData.bond_limit)}</span>
                            </div>
                        </div>

                        {this.state.projects.length > 0 &&
                        <div style={{paddingTop: "12px"}}>
                            <div style={{paddingRight: "10px"}}><b>Gesamtauslastung:</b></div>
                            <div>
                                <span>{FormatService.formatCurrency(obligos_with_projects)}</span>
                                <span style={{marginLeft: "5px", marginRight: "5px"}}>/</span>
                                <span>{FormatService.formatCurrency(bond_limit_with_projects)}</span>
                            </div>
                        </div>
                        }

                    </div>

                    :

                    <div className={"text-center"} style={{marginTop: "50px", color: "#95989a"}}>
                        <span>Noch keine Aktualisierung vorhanden</span>
                    </div>

                }
            </div>
        )

    }

}


export default PolicyCard;
