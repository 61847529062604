import React, {Component} from 'react';
import Card from "react-bootstrap/Card";
import * as FormatService from "../../service/formatService";

class ProjectDetailsSwissRe extends Component{

    renderHeading(heading) {
        return (
            <div style={{marginBottom: "16px"}}>
                <h4>{heading}</h4>
            </div>
        );
    }

    renderLine(attribute, value, indent = false) {
        const style = indent ? {paddingLeft: "16px"} : {paddingLeft: "0px"};

        return (
            <tr style={{fontSize: "1.1em", height: "32px"}}>
                <td style={style}><b>{attribute}:</b></td>
                <td style={{paddingLeft: "32px"}}>{value}</td>
            </tr>
        )
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className={"col-md-6"}>
                        {this.renderHeading("Basisinformationen")}
                        <Card style={{paddingLeft: "15px"}}>
                            <table>
                                <tbody>
                                {this.renderLine("Gesamtobligo", FormatService.formatCurrency(this.props.project.convertedData.obligos_sum))}
                                {this.renderLine("Limit", FormatService.formatCurrency(this.props.project.convertedData.bond_limit))}
                                {this.renderLine("Freies Limit", FormatService.formatCurrency(this.props.project.convertedData.remaining_balance))}
                                </tbody>
                            </table>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }

}

export default ProjectDetailsSwissRe;