import React, {Component} from 'react';
import {NotificationContext} from "../contexts/NotificationContext";
import QuotationRequest from "../../models/QuotationRequest";
import Table from "react-bootstrap/Table";
import * as FormatService from "../../service/formatService";
import Can from "../Can";

import ability from "../../Ability";
import {Redirect} from "react-router-dom";
import * as Paths from "../../config/paths";
import Link from "react-router-dom/es/Link";

import {
    IoMdOpen
} from 'react-icons/io'
import {translateRequestState} from "../../service/languageService";
import {TAB_TITLE_BASE, TAB_TITLE_CONNECTOR} from "../../config/constants";

class QuotationRequestPortal extends Component{

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            quotationRequests: null
        };
    }

    componentDidMount() {
        this.initData();
    }

    initData(){
        QuotationRequest.getQuotationRequests().then(response =>{
            if (response.status === 200){
                let quotationRequests = response.body;

                this.setState({
                    quotationRequests: quotationRequests.map(request => new QuotationRequest(request))
                });
            }
        }).catch(err => {
            this._handleQuotationRequestErrors(err);
        });
    }

    _handleQuotationRequestErrors(err){
        let showMessage = this.context.showMessage;

        if (!err.response){
            return  showMessage(null, "Verbindungsfehler", "danger");
        }

        switch (err.response.status) {
            case 403:
                return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
            case 404:
                return showMessage(null, "Angebotsanfrage nicht gefunden", "danger");
            case 409:
                return showMessage(null, "Status kann nicht geändert werden", "danger");
            case 500:
                return showMessage(null, "Unbekannter Fehler", "danger");
            default:
                return showMessage(null, "Unbekannter Fehler", "danger");
        }
    }

    renderQuotationRequest(quotationRequest, index){
        const request_state = translateRequestState(quotationRequest.request_state.request_state)

        return(
            <tr key={quotationRequest.id}>
                <td className="align-middle">{quotationRequest.company_name}</td>
                <td className="align-middle">{quotationRequest.contact_name}</td>
                <td className="align-middle">{FormatService.formatDate(quotationRequest.created_at)}</td>
                <td className="align-middle">{request_state}</td>
                <td className="align-middle">{quotationRequest.processed_by.email}</td>
                <td className="align-middle">{FormatService.formatDate(quotationRequest.updated_at)}</td>
                <td className="align-middle">
                    <Can I="show" this={quotationRequest}>
                        <Link to={`${Paths.PATH_QUOTATION_REQUEST}/${quotationRequest.id}`}>
                            <button className="btn btn-outline-form-slim"
                                    style={{marginRight: "5px"}}>
                                <div>
                                    <IoMdOpen />
                                </div>
                                Anzeigen
                            </button>
                        </Link>
                    </Can>
                </td>
            </tr>
        );
    }

    render(){
        document.title = TAB_TITLE_BASE + TAB_TITLE_CONNECTOR + "Angebotsanfragen";

        if (ability.cannot("index", "QuotationRequest")){
            return <Redirect to={Paths.PATH_ROOT}/>
        }

        return(
            <div className={"fimo-content-page"} style={{padding: "30px"}}>
                <div>
                    <h2 className="page-headline">Angebotssanfragen</h2>
                </div>

                {this.state.quotationRequests && this.state.quotationRequests.length > 0 ?
                    <div>
                        <Table responsive className="table-borderless">
                            <thead>
                            <tr>
                                <th>Firma</th>
                                <th>Ansprechpartner</th>
                                <th>Erstellt am</th>
                                <th>Status</th>
                                <th>Bearbeitet von</th>
                                <th>Aktualisiert am</th>
                                <th>Verfügbare Aktionen</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.quotationRequests.map((quotationRequest, index) => this.renderQuotationRequest(quotationRequest,
                                index))}
                            </tbody>
                        </Table>
                    </div>
                    :
                    <div>
                        <span>keine Anfragen gefunden</span>
                    </div>
                }
            </div>
        );
    }
}

export default QuotationRequestPortal;