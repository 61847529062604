/*
 * Specifies whether the user is a case handler, which has access to the case handler portal.
 */
export function isUserCaseHandler() {
    if (!isAuthenticated()){
        return  false;
    }

    let rules = sessionStorage.getItem("rules");

    try {
        rules = JSON.parse(rules);
    }
    catch (e) {
        return  false;
    }

    if(!rules) {
        return false;
    }

    for(let rule of rules) {
        if(rule["consultant"] || rule["field staff"] || rule["department manager'"]) {
            return true;
        }
    }

    return false;
}


/*
 * Specifies whether the user is a client.
 */
export function isUserClient() {
    if (!isAuthenticated()){
        return  false;
    }

    let rules = sessionStorage.getItem("rules");

    try {
        rules = JSON.parse(rules);
    }
    catch (e) {
        return  false;
    }

    if(!rules) {
        return false;
    }

    for(let rule of rules) {
        if(rule["client employee"]) {
            return true;
        }
    }

    return false;
}

/*
 * Specifies whether the user is an admin.
 */
export function isUserAdmin() {
    if (!isAuthenticated()){
        return  false;
    }

    let rules = sessionStorage.getItem("rules");

    try {
        rules = JSON.parse(rules);
    }
    catch (e) {
        return  false;
    }

    if(!rules) {
        return false;
    }

    for(let rule of rules) {
        if(rule["admin"]) {
            return true;
        }
    }

    return false;
}



/*
 * Specifies whether the user is allowed to read other users.
 */
export function mayAccessInsurancePolicies(authData) {
    if(!authData || !authData.rules || authData.rules.length === 0) {
        return false;
    }

    for(let rule of authData.rules) {
        if(rule["admin"]) {
            return true;
        }
        if(rule.subject === "InsurancePolicy" && (rule.action === "manage" || rule.action === "read")) {
            return true;
        }
    }

    return false;
}

/*
 * Specifies whether the user is allowed to read other users.
 */
export function mayAccessDetailsData(authData) {
    if(!authData || !authData.rules || authData.rules.length === 0) {
        return false;
    }

    for(let rule of authData.rules) {
        if(rule["admin"]) {
            return true;
        }

        let rules = [];
        if(rule["consultant"]) {
            rules = rule["consultant"];
        } else if(rule["client employee"]) {
            rules = rule["client employee"];
        }

        for(let rule of rules) {
            if (rule.subject === "InsurancePolicyDetail" && (rule.action === "manage" || rule.action === "read")) {
                return true;
            }
        }
    }

    return false;
}

export function isAuthenticated() {
    let token = sessionStorage.getItem("auth_token")
    return token !== null && token !== undefined;
}
