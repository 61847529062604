import React, {Component} from 'react';
import Card from "react-bootstrap/Card";
import {withRouter} from "react-router-dom";

class EditPolicyDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            details: props.details,
            productName: props.details.convertedData.product_name,
            obligosSum: props.details.convertedData.obligos_sum,
            bondLimit: props.details.convertedData.bond_limit,
            policyState: props.details.convertedData.policy_state,
            collateralNominal: props.details.convertedData.collateralNominal,
            collateralCurrent: props.details.convertedData.collateralCurrent,
            collateralPercent: props.details.convertedData.collateralPercent,
            remainingBalance: props.details.convertedData.remaining_balance,
            paymentType: props.details.convertedData.payment_type,
            yearlyFeeObligo: props.details.convertedData.yearly_fee_obligo,
            premiumRate: props.details.convertedData.premium_rate,
            singleLimit: props.details.convertedData.single_limit,
            avalClasses: props.details.convertedData.avalClasses === undefined ? []
                : props.details.convertedData.avalClasses,
            avalClassesToRemove: []
        };

        this.updateProductName = this.updateProductName.bind(this);
        this.updateObligosSum = this.updateObligosSum.bind(this);
        this.updateBondLimit = this.updateBondLimit.bind(this);
        this.updatePolicyState = this.updatePolicyState.bind(this);
        this.updateCollateralNominal = this.updateCollateralNominal.bind(this);
        this.updateCollateralCurrent = this.updateCollateralCurrent.bind(this);
        this.updateCollateralPercent = this.updateCollateralPercent.bind(this);
        this.updateRemainingBalance = this.updateRemainingBalance.bind(this);
        this.updatePaymentType = this.updatePaymentType.bind(this);
        this.updateYearlyFeeObligo = this.updateYearlyFeeObligo.bind(this);
        this.updatePremiumRate = this.updatePremiumRate.bind(this);
        this.updateSingleLimit = this.updateSingleLimit.bind(this);
        this.addAvalClass = this.addAvalClass.bind(this);
        this.removeAvalClass = this.removeAvalClass.bind(this);

        this.updateAvalClassName = this.updateAvalClassName.bind(this);
        this.updateAvalClassObligo = this.updateAvalClassObligo.bind(this);
        this.updateAvalClassBondLimit = this.updateAvalClassBondLimit.bind(this);
        this.updateAvalClassSingleLimit = this.updateAvalClassSingleLimit.bind(this);
        this.updateAvalClassPremium = this.updateAvalClassPremium.bind(this);

    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.details !== this.props.details) {
            this.setState({
                details: nextProps.details,
                productName: nextProps.details.convertedData.product_name,
                obligosSum: nextProps.details.convertedData.obligos_sum,
                bondLimit: nextProps.details.convertedData.bond_limit,
                policyState: nextProps.details.convertedData.policy_state,
                collateralNominal: nextProps.details.convertedData.collateralNominal,
                collateralCurrent: nextProps.details.convertedData.collateralCurrent,
                collateralPercent: nextProps.details.convertedData.collateralPercent,
                remainingBalance: nextProps.details.convertedData.remainingBalance,
                paymentType: nextProps.details.convertedData.payment_type,
                yearlyFeeObligo: nextProps.details.convertedData.yearly_fee_obligo,
                premiumRate: nextProps.details.convertedData.premium_rate,
                singleLimit: nextProps.details.convertedData.single_limit,
                avalClasses: nextProps.details.convertedData.avalClasses === undefined ? []
                    : nextProps.details.convertedData.avalClasses,
                avalClassesToRemove: []
            });
        }
    }

    updateSingleLimit(event){
        this.setState({
            singleLimit: event.target.value
        }, () => this.updateNewDetails());
    }

    updateYearlyFeeObligo(event) {
        this.setState({
            yearlyFeeObligo: event.target.value
        }, () => this.updateNewDetails());
    }

    updatePremiumRate(event) {
        this.setState({
            premiumRate: event.target.value
        }, () => this.updateNewDetails());
    }

    updateProductName(event) {
        this.setState({
            productName: event.target.value
        }, () => this.updateNewDetails());
    }

    updateObligosSum(event) {
        this.setState({
            obligosSum: event.target.value
        }, () => this.updateNewDetails());
    }

    updateBondLimit(event) {
        this.setState({
            bondLimit: event.target.value
        }, () => this.updateNewDetails());
    }

    updateRemainingBalance(event) {
        this.setState({
            remainingBalance: event.target.value
        }, () => this.updateNewDetails());
    }

    updatePolicyState(event) {
        this.setState({
            policyState: event.target.value
        }, () => this.updateNewDetails());
    }

    updateCollateralNominal(event) {
        this.setState({
            collateralNominal: event.target.value
        }, () => this.updateNewDetails());
    }

    updateCollateralCurrent(event) {
        this.setState({
            collateralCurrent: event.target.value
        }, () => this.updateNewDetails());
    }

    updateCollateralPercent(event) {
        this.setState({
            collateralPercent: event.target.value
        }, () => this.updateNewDetails());
    }

    updatePaymentType(event) {
        this.setState({
            paymentType: event.target.value
        }, () => this.updateNewDetails());
    }

    addAvalClass(){
        this.setState({
            avalClasses: this.state.avalClasses.concat({
                name: '',
                avalClass: '',
                obligo: '',
                bond_limit: '',
                single_limit: '',
                premium_rate: ''
            })
        }, () => this.updateNewDetails());
    }

    removeAvalClass(index){
        let avalClasses = this.state.avalClasses;

        let avalClassesToRemove = this.state.avalClassesToRemove;

        avalClassesToRemove.push(avalClasses[index]);
        avalClasses.splice(index, 1);

        this.setState({
            avalClasses: avalClasses,
            avalClassesToRemove: avalClassesToRemove
        }, () => this.updateNewDetails());
    }

    renderHeading(heading) {
        return (
            <div
                className="d-inline-block"
                style={{marginBottom: "16px"}}>
                <h4>{heading}</h4>
            </div>
        );
    }

    updateAvalClassName(event, avalClass, index){
        avalClass.name = event.target.value;

        let avalClasses = this.state.avalClasses;
        avalClasses[index] = avalClass;

        this.setState({
            avalClasses: avalClasses
        }, () => this.updateNewDetails());
    }

    updateAvalClassObligo(event, avalClass, index){
        avalClass.obligo = event.target.value;

        let avalClasses = this.state.avalClasses;
        avalClasses[index] = avalClass;

        this.setState({
            avalClasses: avalClasses
        }, () => this.updateNewDetails());
    }

    updateAvalClassBondLimit(event, avalClass, index){
        avalClass.bond_limit = event.target.value;

        let avalClasses = this.state.avalClasses;
        avalClasses[index] = avalClass;

        this.setState({
            avalClasses: avalClasses
        }, () => this.updateNewDetails());
    }

    updateAvalClassSingleLimit(event, avalClass, index){
        avalClass.single_limit = event.target.value;

        let avalClasses = this.state.avalClasses;
        avalClasses[index] = avalClass;

        this.setState({
            avalClasses: avalClasses
        }, () => this.updateNewDetails());
    }

    updateAvalClassPremium(event, avalClass, index){
        avalClass.premium_rate = event.target.value;

        let avalClasses = this.state.avalClasses;
        avalClasses[index] = avalClass;

        this.setState({
            avalClasses: avalClasses
        }, () => this.updateNewDetails());
    }

    updateNewDetails() {
        let newDetails = {
            productName: this.state.productName,
            obligosSum: this.state.obligosSum,
            bondLimit: this.state.bondLimit,
            policyState: this.state.policyState,
            collateralNominal: this.state.collateralNominal,
            collateralCurrent: this.state.collateralCurrent,
            collateralPercent: this.state.collateralPercent,
            remainingBalance: this.state.remainingBalance,
            paymentType: this.state.paymentType,
            yearlyFeeObligo: this.state.yearlyFeeObligo,
            premiumRate: this.state.premiumRate,
            avalClasses: this.state.avalClasses,
            avalClassesToRemove: this.state.avalClassesToRemove,
            singleLimit: this.state.singleLimit,
        };
        this.props.updateNewDetails(newDetails);
    }

    renderAvalClassForm(avalClass, index){
        if(avalClass.obligo !== null || avalClass.premium_rate !== null ||
            avalClass.bond_limit !== null || avalClass.single_limit !== null) {
            return(
                <Card key={index}
                      style={{marginBottom: "20px"}}>
                    <Card.Title className="card-header">
                        <button className="btn btn-danger float-right"
                                onClick={() => this.removeAvalClass(index)}>
                            Entfernen
                        </button>
                        <form>
                            <div className="form-group row" >
                                <label className="col-sm-4 col-form-label">Avalklasse:</label>
                                <input className="col form-control"
                                       value={avalClass.name}
                                       onChange={(event) => this.updateAvalClassName(event, avalClass, index)}/>
                            </div>
                        </form>
                    </Card.Title>
                    <Card.Body>
                        <form >
                            <div className="form-group row" >
                                <label className="col-sm-4 col-form-label">Obligo</label>
                                <input className="col form-control"
                                       value={avalClass.obligo}
                                       onChange={(event) => this.updateAvalClassObligo(event, avalClass, index)}/>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Limit</label>
                                <input className="col form-control"
                                       value={avalClass.bond_limit}
                                       onChange={(event) => this.updateAvalClassBondLimit(event, avalClass, index)}/>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Einzelstück</label>
                                <input className="col form-control"
                                       value={avalClass.single_limit}
                                       onChange={(event) => this.updateAvalClassSingleLimit(event, avalClass, index)}/>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Avalprämie</label>
                                <input className="col form-control"
                                       value={avalClass.premium_rate}
                                       onChange={(event) => this.updateAvalClassPremium(event, avalClass, index)}/>
                            </div>
                        </form>
                    </Card.Body>
                </Card>
            );
        }
    }

    render(){
        return(
            <div className="container">
                <div className="row">
                    <div className="col-md-6" >
                        <div>
                            {this.renderHeading("Basisinformationen")}

                            <Card className="form-card" style={{padding: '30px'}}>
                                <form>
                                    <div className="form-group row" >
                                        <label className="col-sm-4 col-form-label">Produkt</label>
                                        <input className="col form-control"
                                               value={this.state.productName}
                                               onChange={this.updateProductName}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Gesamtobligo</label>
                                        <input className="col form-control"
                                               value={this.state.obligosSum}
                                               onChange={this.updateObligosSum}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Limit</label>
                                        <input className="col form-control"
                                               value={this.state.bondLimit}
                                               onChange={this.updateBondLimit}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Einzelstück</label>
                                        <input className="col form-control"
                                               value={this.state.singleLimit}
                                               onChange={this.updateSingleLimit}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Status</label>
                                        <input className="col form-control"
                                               value={this.state.policyState}
                                               onChange={this.updatePolicyState}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Sicherheit Soll</label>
                                        <input className="col form-control"
                                               value={this.state.collateralNominal}
                                               onChange={this.updateCollateralNominal}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Sicherheit Ist</label>
                                        <input className="col form-control"
                                               value={this.state.collateralCurrent}
                                               onChange={this.updateCollateralCurrent}/>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Sicherheit in %</label>
                                        <input className="col form-control"
                                               value={this.state.collateralPercent}
                                               onChange={this.updateCollateralPercent}/>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Freies Limit</label>
                                        <input className="col form-control"
                                               value={this.state.remainingBalance}
                                               onChange={this.updateRemainingBalance}/>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label"> Zahlweise</label>
                                        <input className="col form-control"
                                               value={this.state.paymentType}
                                               onChange={this.updatePaymentType}/>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Prämie in EUR</label>
                                        <input className="col form-control"
                                               value={this.state.yearlyFeeObligo}
                                               onChange={this.updateYearlyFeeObligo}/>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Prämiensatz in %</label>
                                        <input className="col form-control"
                                               value={this.state.premiumRate}
                                               onChange={this.updatePremiumRate}/>
                                    </div>
                                </form>
                            </Card>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div>
                            {this.renderHeading("Avalklassen")}

                            <button
                                className="btn btn-primary float-right"
                                onClick={this.addAvalClass}>Hinzufügen</button>
                        </div>

                        {this.state.avalClasses.map((avalClass, index) => this.renderAvalClassForm(avalClass, index))}
                    </div>

                </div>
            </div>
        );
    }
}

export default withRouter(EditPolicyDetails);
