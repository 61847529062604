import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import UpdateProject from "./UpdateProject";

class NewProject extends Component {

    render() {
        return <UpdateProject updateMode={'new'}></UpdateProject>
    }

}

export default withRouter(NewProject);