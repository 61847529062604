import React, {Component} from 'react';

import InsuranceCompany from "../../models/InsuranceCompany";
import {NotificationContext} from "../contexts/NotificationContext";

import {IoIosSave, IoMdClose} from 'react-icons/io'

class NewInsuranceCompany extends Component{

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            insurance_company_name: '',
            homepage:''
        };

        this.updateName = this.updateName.bind(this);
        this.updateLink = this.updateLink.bind(this);
    }

    updateName(event){
        this.setState({
            insurance_company_name: event.target.value
        });
    }

    updateLink(event){
        this.setState({
            homepage: event.target.value
        });
    }

    submitInsuranceCompany(event){
        event.preventDefault();

        let newInsurance = new InsuranceCompany({
            insurance_company_name: this.state.insurance_company_name,
            homepage: this.state.homepage
        });

        newInsurance.createInsuranceCompany()
            .then(response => {
                if (response.status === 201){
                    this.context.showMessage(null, "Versicherer wurde angelegt", "success");
                    this.props.history.goBack();
                } else {
                    this.context.showMessage(null, "Unbekannter Fehler", "danger");
                }
            })
            .catch(err => {
                console.log(err)
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 422:
                        return showMessage(null, "Überprüfen Sie Ihre Angaben", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    render(){
        return(
            <div>
                <div className={"fimo-content-page"}>
                    <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                        <div className="col text-right" style={{maxWidth: "100%"}}>
                            <button className="btn btn-outline-form"
                                    onClick={(event) => this.submitInsuranceCompany(event)}>
                                <div>
                                    <IoIosSave/>
                                </div>
                                Speichern
                            </button>
                            <button className="btn btn-outline-form"
                                    type="button"
                                    onClick={() => this.props.history.goBack()}>
                                <div>
                                    <IoMdClose style={{color: " red"}}/>
                                </div>
                                Abbrechen
                            </button>
                        </div>
                    </div>
                    <div style={{padding: "30px"}}>
                        <h4 className="page-headline">Neuer Versicherer</h4>

                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <form>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Name:</label>
                                            <input className="col form-control"
                                                   value={this.state.insurance_company_name}
                                                   onChange={this.updateName}/>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Link:</label>
                                            <input className="col form-control"
                                                   placeholder= "http://www.web.com"
                                                   value={this.state.homepage}
                                                   onChange={this.updateLink}/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NewInsuranceCompany;
