import React, {Component} from 'react';


class InformationPrivacyPage extends Component {

    render() {

        return(
            <div className="general-text" style={{paddingLeft: "50px", maxWidth: "1200px"}}>

                <h4>DATENSCHUTZERKLÄRUNG</h4>

                <p><b>Stand August 2018</b></p>

                <p>Im Folgenden informieren wir Sie über den Umgang mit personenbezogenen Daten bei SÜDVERS.</p>

                <p>Seit dem 25.05.2018 gilt die neue EU-Datenschutzgrundverordnung (DSGVO). Mit dieser neuen Datenschutzgrundlage ändert sich das Verhältnis des Kunden zum Versicherungsmakler vom Grundsatz her nicht, in Bezug auf den Datenschutz haben sich Rechte und Transparenzpflichten stärker ausgebildet. </p>

                <p><b>Übersicht:</b></p>

                <ol type={"1"}>
                    <li><b>Erhebung personenbezogener Daten im Rahmen der Maklertätigkeit</b></li>
                    <li><b>Erhebung personenbezogener Daten bei Besuch unserer Webseite</b></li>
                    <li><b>Erhebung personenbezogener Daten im Rahmen von Veranstaltungen und Webinaren</b></li>
                    <li><b>Sicherheitsmaßnahmen</b></li>
                    <li><b>Aktualisierungen</b></li>
                    <li><b>Verantwortlichkeiten</b></li>
                </ol>


                <h5>1. Erhebung personenbezogener Daten im Rahmen der Maklertätigkeit</h5>

                <h5>Gegenstand des Datenschutzes</h5>

                <p>Gegenstand des Datenschutzes sind personenbezogene Daten. Dies sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Darunter fallen auch Angaben, welche die Identifikation einer natürlichen Person (zumindest mittelbar) zulassen. Dies können beispielsweise der Name oder Kontaktdaten (z. B. Telefonnummer, Post- und E-Mail-Adresse) sein. Auch die IP-Adresse kann ein personenbezogenes Datum in diesem Sinne darstellen.</p>

                <p>Mögliche Quellen personenbezogener Daten:</p>

                <ul>
                    <li>Personen und deren Familienangehörigen – online, telefonisch oder in schriftlichen Mitteilungen</li>
                    <li>Arbeitgeber von Personen</li>
                    <li>Bei Versicherungsansprüchen von Dritten einschließlich der Gegenpartei, Sachverständigen, Rechtsanwälten und Schadenbearbeitern, Schadensachbearbeitern, Zeugen</li>
                    <li>Andere Versicherungsmarktteilnehmern, wie Versicherern, Rückversicherern und anderen Vermittlern</li>
                    <li>Wirtschafts-/Kreditauskunfteien (bei Kreditrisiken)</li>
                    <li>Betrugsbekämpfungsdatenbanken und sonstigen Datenbanken von Dritten, einschließlich Sanktionslisten</li>
                    <li>Behörden, z.B. Fahrzeugzulassungsstellen und Steuerbehörden</li>
                    <li>Anspruchs-/Schadenmeldeformulare</li>
                    <li>SÜDVERS erhebt und verarbeitet die folgenden personenbezogenen Daten:</li>
                    <li>Persönliche Daten: Name, Adresse, sonstige Kontaktdaten (z. B. E-Mail-Adresse und Telefonnummer), Geschlecht, Familiendaten, Familienstand, Geburtsdatum und -ort, Beziehung zum Versicherungsnehmer, Versicherten, Begünstigten oder Anspruchsteller, Arbeitgeber, Berufsbezeichnung und ggf. beruflicher Werdegang,</li>
                    <li>Identifikationsdaten: von Behörden oder staatlichen Stellen ausgestellte Identifikationsnummern (z. B. (Reise-) Passnummer, Personalausweisnummer, Steueridentifikationsnummer, Sozialversicherungsnummer, Führerscheinnummer</li>
                    <li>Finanzdaten: Kartennummer (EC-Karte, Kreditkarte usw.) und Bankverbindung, Einkommen und sonstige Finanzdaten</li>
                    <li>Versichertes Risiko: Informationen über das versicherte Risiko, die personenbezogene Daten enthalten können, sofern diese für das zu versichernde Risiko von Belang sind:</li>
                </ul>


                <p>Gesundheitsdaten: Gesundheitszustand, Vorerkrankungen (Anamnese), Informationen über aktuelle oder frühere physische oder psychische Krankheiten, Verletzungen oder Behinderungen, medizinische Behandlungen, relevante persönliche Gewohnheiten (z. B. Rauchen oder Alkoholkonsum), Informationen über verschriebene Arzneimittel,</p>
                <p>Daten über Vorstrafen: strafrechtliche Verurteilungen, einschließlich Verkehrsdelikten</p>
                <p>Andere besondere Kategorien von personenbezogenen Daten: rassische oder ethnische Herkunft, politische Meinungen, religiöse oder weltanschauliche Überzeugungen, Zugehörigkeit zu einer Gewerkschaft, genetische Daten, biometrische Daten, Daten zum Sexualleben oder zur sexuellen Ausrichtung einer Person</p>

                <ul>
                    <li>Policendaten: Informationen über Versicherungsangebote und Policen</li>
                    <li>Kredit- und Betrugsbekämpfungsdaten: Bonität bzw. Bonitätsgeschichte und Kredit-Score, Informationen über Verurteilungen wegen Betrugsdelikten, Straftatvorwürfe und nähere Angaben zu Strafmaßnahmen bzw. Sanktionen aus verschiedenen Betrugsbekämpfungs- und Sanktionsdatenbanken oder von Aufsichts- oder Strafverfolgungsbehörden</li>
                    <li>Aktuelle Versicherungsfälle/-ansprüche: Informationen über aktuelle Versicherungsfälle/-ansprüche, welche Gesundheitsdaten, Daten über Vorstrafen und andere besondere Kategorien von personenbezogenen Daten (wie oben unter der Definition für „Versichertes Risiko“ beschrieben) enthalten können</li>
                    <li>Frühere Versicherungsfälle/-ansprüche: Informationen über frühere Versicherungsfälle/-ansprüche, die Gesundheitsdaten, Daten über Vorstrafen und andere besondere Kategorien von personenbezogenen Daten (wie oben unter der Definition für „Versichertes Risiko“ beschrieben) enthalten können</li>
                    <li>Marketingdaten: Die Tatsache, ob die Person dem Erhalt von Marketingmitteilungen von SÜDVERS zugestimmt hat. Kontaktpersonen und Ansprechpartner von potentiellen Kunden.</li>
                </ul>


                <h5>Zweck der Verarbeitung und rechtliche Grundlage</h5>

                <p>Im Bereich der Vermittlung oder Verwaltung von Versicherungsvertragsverhältnissen sowie der Begleitung von Schadensfällen, benötigt der Makler zur Vertragsumsetzung die in Betracht kommenden personenbezogenen Daten seiner tatsächlichen und potentiellen Kunden. Rechtliche Grundlage, um die erforderlichen personenbezogenen Daten verarbeiten, erhalten, verwenden, speichern, übermitteln und weitergeben zu dürfen, ist hierbei das Angebotserstellungs- bzw. Maklervertragsverhältnis, teilweise ergänzt durch Einwilligungserklärungen. Bereits vor Inkrafttreten der DSGVO abgegebene Einwilligungserklärungen gelten grundsätzlich weiter fort. Zudem ist die Verarbeitung personenbezogener Daten (auch besonderer Datenkategorien) gestattet, wenn sie zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder Behandlungen der Gerichte im Rahmen ihrer justiziellen Tätigkeit erforderlich ist.</p>
                <p>Es ist unabdingbar, Daten zum Zweck der Auftragserfüllung an Versicherer, Rückversicherer, Assekuradeure, Sachverständige, etc. zum Beispiel zur Einholung von Angeboten weiterzuleiten.</p>
                <p>Soweit eine Verarbeitung auf Ihrer Einwilligung beruht, haben Sie das Recht, diese uns gegenüber mit Wirkung für die Zukunft zu widerrufen.</p>
                <p>In Folge kann SÜDVERS die Dienstleistungen für den betreffenden Kunden jedoch nicht mehr erbringen. Wenn eine Person ihre Einwilligung zur Verarbeitung besonderer Kategorien von personenbezogenen Daten durch einen Versicherer oder Rückversicherer widerruft, ist die Fortsetzung des Versicherungsschutzes gegebenenfalls nicht mehr möglich.</p>

                <h5>Datennutzung</h5>

                <p>Versicherer dürfen allgemeine Vertrags-, Abrechnungs- und Leistungsdaten in ge-meinsamen Datenbanken führen und an den Makler weitergeben, soweit dies zur ordnungsgemäßen Durchführung seiner Versicherungsangelegenheiten erforderlich ist.</p>
                <p>Wie bisher dürfen Gesundheitsdaten von den Versicherern nur an betroffene Personen und Rückversicherer übermittelt werden. An Makler dürfen sie nur weitergegeben werden, soweit diese zur Vertragsbetreuung erforderlich sind oder eine Einwilligungserklärung vorliegt.</p>
                <p>Im Rahmen der Betreuung weitergegebene persönliche Daten werden im Sinne unserer Kunden zur Kundenpflege z.B. für Geburtstagsbriefe und Einladungen verwendet.</p>
                <p>Wenn Sie dies nicht möchten, bitten wir um eine kurze Information an:</p>

                <p>datenschutz@suedvers.de bzw. die Sie betreuenden Mitarbeitenden.</p>

                <p>Wenn SÜDVERS Daten über eine dritte Person zur Verfügung gestellt werden, erklärt sich der Übermittler damit einverstanden, diese dritten Personen unter Benennung der Herkunft über die Verwendung zu informieren und gegebenenfalls deren Einwilligung einzuholen.</p>

                <h5>Betroffenenrechte und Hinweise</h5>

                <p>Sie haben nach der neuen DSGVO <b>Recht auf Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung, Widerspruchsrecht und Recht auf Datenübertragbarkeit</b>. Wenn Sie diese Rechte geltend machen wollen, wenden Sie sich bitte an die unten genannte Datenschutzbeauftragte.</p>

                <p>Als Versicherungsmakler sind wir <b>keine Auftragsverarbeiter</b> im Sinne des Art. 28 DSGVO. Ähnlich wie Steuerberater und Rechtsanwälte erbringen wir Dienste höherer Art und arbeiten mit den Daten nicht weisungsbezogen, sondern eigenverantwortlich. Sie sind als Person/ Unternehmen also nicht verpflichtet, mit uns einen Datenschutzvertrag abzuschließen.</p>

                <h5>Informations- und Auskunftsrecht</h5>

                <p>Sie haben das Recht, zu verlangen, dass wir Ihnen bestätigen, ob wir über Sie betreffende personenbezogene Daten verarbeiten. Sie können von uns Auskunft über die von Ihnen bei uns gespeicherten Daten verlangen. Wir bieten Ihnen Zugriff auf die gewünschten personenbezogenen Daten, vorbehaltlich angemessener gesetzlicher Einschränkungen, außer dies verletzt den Datenschutz anderer.</p>

                <p>Wenn wir Ihnen keinen Zugriff bieten können, geben wir Ihnen den Grund hierfür an und informieren Sie über die geltend gemachten Ausnahmen.</p>

                <h5>Beschwerderecht bei der Aufsichtsbehörde</h5>

                <p>Sie haben das Recht, eine Beschwerde bei einer Datenschutzbehörde einzureichen. Sie können sich dazu an die Datenschutzbehörde wenden, die für Ihren Wohnort bzw. Ihr Bundesland zuständig ist oder an die für uns zuständige Datenschutzbehörde:</p>

                <p>Der Landesbeauftragte für den Datenschutz Baden-Württemberg</p>

                <p>Königstraße 10a</p>
                <p>70173 Stuttgart</p>
                <p>Telefon 0711/615541-0</p>
                <p>Telefax 0711/615541-15</p>
                <p>E-Mail: <a href="poststelle@lfd.bwl.de">poststelle@lfd.bwl.de</a></p>
                <p>Homepage: <a href="www.baden-wuerttemberg.datenschutz.de">www.baden-wuerttemberg.datenschutz.de</a></p>

                <h5>Berichtigungs- und Löschungsrecht</h5>

                <p>Sollten Ihre Daten unrichtig oder unvollständig sein, haben Sie das Recht, zu verlangen, dass Ihre Daten berichtigt bzw. vervollständigt werden. Wenn wir Ihre Daten an Dritte weitergegeben haben informieren wir diese über die Berichtigung, soweit dies gesetzlich vorgeschrieben ist.</p>
                <p>Sie können, wenn die gesetzlichen Voraussetzungen vorliegen, von uns unverzügliche Löschung Ihrer personenbezogenen Daten verlangen. Dies ist insbesondere der Fall wenn Ihre personenbezogenen Daten für die Zwecke, für die sie erhoben wurden, nicht länger benötigt werden; die Rechtsgrundlage für die Verarbeitung ausschließlich Ihre Einwilligung war und Sie diese widerrufen haben; Sie der Verarbeitung zu werblichen Zwecken widersprochen haben, Ihre personenbezogenen Daten unrechtmäßig verarbeitet wurden, Sie einer Verarbeitung auf der Basis der Rechtsgrundlage „Interessenabwägung“ aus persönlichen Gründen widersprochen haben und wir nicht nachweisen können, dass es vorrangige berechtigte Gründe für eine Verarbeitung gibt; oder Ihre personenbezogenen Daten gelöscht werden müssen, um gesetzlichen Anforderungen zu entsprechen. Wenn wir Ihre Daten an Dritte weitergegeben haben, informieren wir diese über die Löschung, soweit dies gesetzlich vorgeschrieben ist. Bitte beachten Sie, dass Ihr Löschungsrecht Einschränkungen unterliegen kann. Zum Beispiel müssen bzw. dürfen wir keine Daten löschen, die wir aufgrund gesetzlicher Aufbewahrungsfristen noch weiter vorhalten müssen. Auch Daten, die wir zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, sind von Ihrem Löschungsrecht ausgenommen.</p>


                <h5>Widerspruchsrecht</h5>

                <p>Sie haben das Recht, der Datenverarbeitung durch uns zu widersprechen, soweit Sie aus besonderen Gründen ein berechtigtes Interesse daran haben. Sie können jederzeit der Verarbeitung Ihrer Daten zu werblichen Zwecken widersprechen.</p>

                <h5>Recht auf Einschränkung der Verarbeitung</h5>

                <p>Innerhalb der gesetzlichen Voraussetzungen können Sie von uns verlangen, dass wir die Verarbeitung Ihrer Daten einschränken. Dies ist insbesondere der Fall, wenn die Richtigkeit Ihrer personenbezogenen Daten von Ihnen bestritten wird. Bis wir die Möglichkeit hatten, die Behauptung zu überprüfen, dass
                    die Verarbeitung nicht rechtmäßig erfolgt, können Sie statt der Löschung eine Einschränkung der Nutzung von uns verlangen.</p>

                <p>Wenn ein Recht auf Einschränkung der Verarbeitung besteht, markieren wir die betroffenen Daten, um auf diese Weise sicherzustellen, dass diese nur noch in den engen den engen Grenzen verarbeitet werden, die für solche eingeschränkten Daten gelten (insbesondere zur Verteidigung von Rechtsansprüchen oder mit Ihrer Einwilligung).</p>

                <h5>Datenverarbeitung in der Unternehmensgruppe</h5>

                <p>Manche Datenverarbeitungsaufgaben werden innerhalb der SÜDVERS-GRUPPE kon-zernintern gebündelt. Dies betrifft z.B. IT, Personalverwaltung, Personal-entwicklung, Controlling, Finanzen, die Telefonzentrale, Postbearbeitung, sowie die Unterstützung der Einheiten durch überregionale Stabsstellen und Fachbereichsleiter. Zudem werden unsere Kunden über verschiedene Sparten und Gesellschaften von einem Ansprechpartner betreut und unsere Auszubildenden erhalten verschiedene Einblicke. In diesen Fällen übermitteln wir personenbezogene Daten innerhalb der konzernverbundenen Unternehmen, die auf der Webseite unter Impressum in den Firmenvisitenkarten aufgeführt sind. Dies erfolgt im zulässigen Rahmen, wenn dies zur Erfüllung der aufgeführten Zwecke erforderlich ist. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt in diesen Fällen im Auftrag. Entsprechende Auftragsverarbeitungsverträge sind konzernintern geschlossen.</p>

                <h5>Externe Auftragsverarbeiter und Dienstleister</h5>

                <p>SÜDVERS bedient sich zur Erfüllung der vertraglichen und gesetzlichen Pflichten zum Teil externer Auftragnehmer. Dies betrifft zum Beispiel die Aktenvernichtung oder IT-Supportleistungen. SÜDVERS hat alle Auftragnehmer intern aufgelistet und entsprechende Auftragsverarbeitungsverträge geschlossen. In der Hauptauftragstätigkeit als Versicherungmakler arbeitet SÜDVERS nur in Ausnahmefällen mit externen Dienstleistern. Dort, wo dies der Fall ist, wird dies den Kunden vorab offen kommuniziert. Unsere Beauftragten und Dienstleister sind vertraglich verpflichtet, die Vertraulichkeit personenbezogener Daten zu wahren, sie dürfen die Daten nur für genehmigte Zwecke nutzen und müssen von uns definierte Mindest-Standards einhalten.</p>

                <h5>Dauer der Datenspeicherung</h5>

                <p>Wir löschen die personenbezogenen Daten, sobald sie für die zu erfüllenden Zwecke nicht mehr erforderlich bzw. gesetzlich vorgeschrieben sind (grundsätzlich 10 Jahre). Dabei kann es vorkommen, dass personenbezogene Daten für die Zeit aufbewahrt werden, in denen Ansprüche gegen unser Unternehmen geltend gemacht werden können (bis zu 30 Jahren).</p>

                <h5>Datenübermittlung Drittland</h5>

                <p>Eine Datenübermittlung in Drittstaaten (Staaten außerhalb der EU und des Europäischen Wirtschaftsraums) findet nur statt, soweit dies für die Durchführung des Auftrages bzw. Maklervertrages erforderlich ist. Grundsätzlich arbeitet SÜDVERS zur Betreuung international tätiger Kunden mit den vertrauten Partnern des World Wide Broker Network (WBN) zusammen.</p>

                <h5>Beschwerderecht</h5>

                <p>Als Ansprechpartnerin für Beschwerden steht Ihnen unsere interne Datenschutzbeauftragte unter folgender Adresse zur Verfügung:</p>

                <p>Konzern-Datenschutzbeauftragte SÜDVERS</p>

                <p>Nina Hartmann Am Altberg 1-3 79280 Au bei Freiburg datenschutz@suedvers.de</p>




                <h5>2. Erhebung personenbezogener Daten bei Besuch unserer Webseite</h5>

                <p>Als Betreiber unserer Webseiten nehmen wir den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>

                <p>Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.</p>

                <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>

                <h5>Bewerbung</h5>

                <p>Sie können sich auf elektronischem Wege, z.B.. via E-Mail, bei unserem Unternehmen bewerben. Ihre Angaben werden wir selbstverständlich ausschließlich zur Bearbeitung Ihrer Bewerbung verwenden und nicht an Dritte weitergeben. Bitte beachten Sie, dass unverschlüsselt übersandte E-Mails nicht zugriffsgeschützt übermittelt werden.</p>

                <p>Wenn Sie sich auf eine bestimmte Stelle in einer unserer Einheiten beworben haben und diese bereits besetzt wurde oder wir Sie für eine andere Stelle ebenfalls oder als noch besser geeignet ansehen, werden wir Sie auf diese Möglichkeit hinweisen und Ihre Einwilligung zur Weiterleitung an die andere Einheit einholen.</p>

                <p>Ihre personenbezogenen Daten werden nach Abschluss des Bewerbungsverfahrens gelöscht, sofern Sie uns nicht ausdrücklich Ihre Einwilligung für eine längere Speicherung Ihrer Daten erteilt haben oder es zu einem Vertragsschluss gekommen ist.</p>

                <h5>Cookies</h5>

                <p>Wir verwenden keine Cookies. Dies sind Dateien, die der Webserver auf dem PC des Nutzers ablegt, über die der Benutzer, sofern die Cookies nicht gelöscht wurden, im Folgebesuch identifiziert werden kann; die dort abgelegten Daten sind meist Informationen über abgerufene WWW-Seiten; sie dienen in erster Linie der Erleichterung des Navigierens, aber auch zur Erkundung des Benutzerverhaltens.</p>

                <h5>Server-Log-Files</h5>

                <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>

                <ul>
                <li>Browsertyp und Browserversion</li>
                <li>verwendetes Betriebssystem</li>
                <li>Referrer URL</li>
                <li>Hostname des zugreifenden Rechners</li>
                <li>Uhrzeit der Serveranfrage</li>
                </ul>

                <p>Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.</p>

                <h5>Kontaktformular</h5>

                <p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>

                <h5>Newsletterdaten</h5>

                <p>Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht erhoben. Diese Daten verwenden wir ausschließlich für den Versand der angeforderten Informationen und geben sie nicht an Dritte weiter.</p>

                <p>Die erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters können Sie jederzeit widerrufen, etwa über den „hier abmelden“-Link im Newsletter.</p>

                <h5>Google Analytics</h5>

                <p>Diese Website benutzt keine Funktionen des Webanalysedienstes Google Analytics.</p>

                <h5>Google reCAPTCHA</h5>

                <p>Um für ausreichend Datensicherheit bei der Übermittlung von Formularen Sorge zu tragen, verwenden wir in bestimmten Fällen den Service reCAPTCHA des Unternehmens Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA („Google“). Dies dient vor allem zur Unterscheidung, ob die Eingabe durch eine natürliche Person oder missbräuchlich durch maschinelle und automatisierte Verarbeitung erfolgt. Der Abfrage schließt den Versand der IP-Adresse und ggf. weiterer von Google für den Dienst reCAPTCHA benötigter Daten an Google ein. Hierfür gelten die abweichenden Datenschutzbestimmungen von Google Inc. Weitere Informationen zu den Datenschutzrichtlinien von Google Inc. finden Sie unter <a href="https://www.google.com/intl/de/policies/privacy/">https://www.google.com/intl/de/policies/privacy/</a>.</p>

                <h5>Google Maps</h5>

                <p>Diese Webseite verwendet das Produkt Google Maps von Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA („Google“) um geographische Informationen visuell darzustellen. Durch Nutzung dieser Webseite erklären Sie sich mit der Erfassung, Bearbeitung sowie Nutzung der automatisiert erhobenen Daten durch Google Inc, deren Vertreter sowie Dritter einverstanden.</p>

                <p>Die Nutzungsbedingungen von Google Maps finden sie unter „Nutzungsbedingungen von Google Maps“.</p>

                <h5>SSL-Verschlüsselung</h5>

                <p>Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>

                <p>Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</p>

                <h5>Recht auf Auskunft, Löschung, Sperrung</h5>

                <p>Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung sowie ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.</p>

                <h5>Widerspruch Werbe-Mails</h5>

                <p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.</p>

                <p>Quelle: eRecht24</p>

                <h5>Soziale Netzwerke</h5>
                <h6>Facebook</h6>

                <p>Diese Webseite bietet einen Link zu Facebook an. Wenn Sie diesen Link anklicken, baut Ihr Browser eine Verbindung zu den Facebook Servern auf.  Sogenannte Plug-Ins, die im Hintergrund Daten übermitteln, werden von uns nicht eingesetzt. Wenn Sie bei Facebook eingeloggt sind, kann Facebook erkennen, dass Sie von unserer Seite gekommen sind und diese Informationen mit Ihrem Benutzer-Account verknüpfen. Wenn Sie dies nicht möchten, müssen Sie sich bei Facebook ausloggen. Hinweise zu den Datenschutzbestimmungen von Facebook finden Sie unter: <a href="https://www.facebook.com/privacy/explanation">https://www.facebook.com/privacy/explanation</a></p>

                <h6>XING</h6>

                <p>Diese Webseite bietet einen Link zu XING an. Wenn Sie diesen Link anklicken, baut Ihr Browser eine Verbindung zu den XING Servern auf. Sogenannte Plug-Ins, die im Hintergrund Daten übermitteln, werden von uns nicht eingesetzt. Wenn Sie bei XING eingeloggt sind, kann XING erkennen, dass Sie von unserer Seite gekommen sind und diese Informationen mit Ihrem Benutzer-Account verknüpfen. Wenn Sie dies nicht möchten, müssen Sie sich bei XING ausloggen. Hinweise zu den Datenschutzbestimmungen von XING finden Sie unter: <a href="https://privacy.xing.com/de/datenschutzerklaerung">https://privacy.xing.com/de/datenschutzerklaerung</a>.</p>

                <h6>Twitter</h6>

                <p>Diese Webseite bietet einen Link zu Twitter an. Wenn Sie diesen Link anklicken, baut Ihr Browser eine Verbindung zu den Twitter Servern auf. Sogenannte Plug-Ins, die im Hintergrund Daten übermitteln, werden von uns nicht eingesetzt. Wenn Sie bei Twitter eingeloggt sind, kann Twitter erkennen, dass Sie von unserer Seite gekommen sind und diese Informationen mit Ihrem Benutzer-Account verknüpfen. Wenn Sie dies nicht möchten, müssen Sie sich bei Twitter ausloggen. Hinweise zu den Datenschutzbestimmungen von Twitter finden Sie unter: <a href="https://twitter.com/de/privacy#update">https://twitter.com/de/privacy#update</a>.</p>

                <h6>Google+</h6>

                <p>Diese Webseite bietet einen Link zu Google+ an. Wenn Sie diesen Link anklicken, baut Ihr Browser eine Verbindung zu den Google+ Servern auf. Sogenannte Plug-Ins, die im Hintergrund Daten übermitteln, werden von uns nicht eingesetzt. Wenn Sie bei Google+ eingeloggt sind, kann Google+ erkennen, dass Sie von unserer Seite gekommen sind und diese Informationen mit Ihrem Benutzer-Account verknüpfen. Wenn Sie dies nicht möchten, müssen Sie sich bei Google+ ausloggen. Hinweise zu den Datenschutzbestimmungen von Google+ finden Sie unter: <a href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a>.</p>

                <h6>LinkedIn</h6>

                <p>Diese Webseite bietet einen Link zu LinkedIn an. Wenn Sie diesen Link anklicken, baut Ihr Browser eine Verbindung zu den LinkedIn Servern auf. Sogenannte Plug-Ins, die im Hintergrund Daten übermitteln, werden von uns nicht eingesetzt. Wenn Sie bei LinkedIn eingeloggt sind, kann LinkedIn erkennen, dass Sie von unserer Seite gekommen sind und diese Informationen mit Ihrem Benutzer-Account verknüpfen. Wenn Sie dies nicht möchten, müssen Sie sich bei LinkedIn ausloggen. Hinweise zu den Datenschutzbestimmungen von LinkedIn finden Sie unter: <a href="https://www.linkedin.com/legal/privacy-policy?trk=uno-reg-guest-home-privacy-policy">https://www.linkedin.com/legal/privacy-policy?trk=uno-reg-guest-home-privacy-policy</a>.</p>

                <h5>3. Erhebung personenbezogener Daten im Rahmen von Veranstaltungen und Webinaren</h5>

                <h5>Anmeldung zu Veranstaltungen</h5>

                <p>Sie haben die Möglichkeit, sich bei uns zu verschiedenen Veranstaltungen anzumelden. Für die Anmeldung sind bestimmte persönliche Angaben erforderlich. Die freiwillige Angabe weiterer Daten ermöglicht es uns, auf Ihre Wünsche einzugehen und zukünftige Angebote danach auszurichten. Wir verarbeiten Ihre Daten nur, soweit es für die Anmeldung und Durchführung der jeweiligen Veranstaltung erforderlich ist.</p>

                <p>Abhängig von der jeweiligen Veranstaltung bestehen unterschiedliche Möglichkeiten für die Anmeldung. Die Anmeldung kann u.a. per E-Mail oder über ein Anmeldeformular erfolgen. Für die Anmeldung können je nach Veranstaltung die folgenden Informationen erforderlichen sein:</p>

                <p>Firma; Position; Vorname; Nachname; Adresse; PLZ; Ort; Telefon; Fax; E-Mail-Adresse</p>

                <p>Im Anschluss an die Anmeldung erhalten Sie von uns grundsätzlich eine E-Mail zur Bestätigung der Anmeldung sowie eine E-Mail unmittelbar vor der Veranstaltung als Erinnerung. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.</p>

                <h5>Anmeldung zu Webinaren</h5>

                <p>Sie haben die Möglichkeit, sich zu verschiedenen Webinaren anzumelden. Für die Anmeldung sind bestimmte persönliche Angaben erforderlich. Es ist die Angabe Ihres Vor- und Nachnamens, der Firma und Ihrer E-Mail-Adresse erforderlich. Die freiwillige Angabe weiterer Daten ermöglicht es uns, unser Angebot zu verbessern und Ihnen auf Wunsch bestimmte Angebote gezielt für Ihren Bedarf zur Verfügung zu stellen. Wir verarbeiten Ihre Daten nur, soweit es für die Anmeldung und Durchführung des jeweiligen Webinars erforderlich ist.</p>

                <p>Im Rahmen von Webinaranmeldungen werden Sie ggf. auf die Seite eines externen Dienstleisters geleitet, der das Webinar für uns technisch betreibt. Dieser Dienstleister erhebt, speichert und nutzt Ihre Daten ausschließlich zur Durchführung des Webinars in unserem Auftrag und nicht für eigene Zwecke. Eine Datenübermittlung in Drittländer (Länder außerhalb der EU bzw. des EWR) findet nicht statt.</p>

                <h5>4. Sicherheitsmaßnahmen</h5>

                <p>SÜDVERS hat umfangreiche technische und betriebliche Schutzvorkehrungen getroffen, um die zu verantwortenden Daten vor zufälligen oder vorsätzlichen Manipulationen, Verlust, Zerstörung oder dem Zugriff unberechtigter Personen zu schützen. Die Sicherheitsverfahren werden regelmäßig überprüft und dem technologischen Fortschritt angepasst</p>

                <h5>5. Aktualisierungen</h5>

                <p>Wir werden diese Datenschutzerklärung von Zeit zu Zeit aktualisieren. Wir werden Sie auf dieser Website und erforderlichenfalls auch gesondert über wesentliche Änderungen informieren. Wir bitten Sie darum, diese Datenschutzerklärung regelmäßig durchzulesen. Den aktuellen Stand der Datenschutzerklärung sehen Sie jeweils auf der ersten Seite.</p>

                <h5>6. Verantwortlichkeiten</h5>

                <p>Verantwortlicher ist, gemäß Ihres Maklervertrages oder Kooperationsvertrages, das Sie betreuende Unternehmen. Die vollständigen Unternehmensdaten finden Sie in unserem Impressum bzw in unserer Maklervisitenkarte.</p>

                <h5>Konzern-Datenschutzbeauftragte</h5>

                <p>Ihre Ansprechpartnerin in Sachen Datenschutz steht Ihnen gern unter folgender Adresse zur Verfügung.</p>

                <p>SÜDVERS</p>
                <p>Nina Hartmann</p>
                <p>Am Altberg 1-3</p>
                <p>79280 Au bei Freiburg</p>
                <p>datenschutz@suedvers.de</p>

                <h5>IT-Sicherheitsbeauftragter</h5>

                <p>Ihr Ansprechpartner in Sachen IT-Sicherheit steht Ihnen gern unter folgender Adresse zur Verfügung.</p>
                <p>SÜDVERS Service und Management GmbH</p>
                <p>Dirk Wenning</p>
                <p>Am Altberg 1-3</p>
                <p>79280 Au bei Freiburg</p>
                <p>it-sicherheit@suedvers.de</p>


            </div>
        )

    }

}


export default InformationPrivacyPage