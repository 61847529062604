import * as Paths from "../config/paths";
import request from 'superagent';

class Extension {

    static get Extension() {
        return 'Collateral'
    }

    static downloadExtension(){
        return new Promise((resolve, reject) => {
            request.get(Paths.URL_EXTENSION_DOWNLOAD_LATEST)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', "application/zip")
                .responseType('blob')
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        })
    }
}

export default Extension;