import React, {Component} from 'react';
import Table from 'react-bootstrap/Table';

import {NotificationContext} from "../contexts/NotificationContext";
import User from "../../models/User";
import Can from "../Can";
import Error from "../Error";
import {Link} from "react-router-dom";
import Select from "react-select";
import * as UserService from "../../service/userService";

import * as Paths from '../../config/paths';

import {
    IoIosPersonAdd,
    IoMdOpen,
    IoMdTrash
} from 'react-icons/io'

import {TAB_TITLE_BASE, TAB_TITLE_CONNECTOR} from "../../config/constants";
import DeleteDialog from '../DeleteDialog';

class UsersOverview extends Component {

    static contextType = NotificationContext;

    constructor(props) {
        super(props);

        this.state = {
            allUsers: [],
            usersToShow: [],
            errorResponse: null,
            radiobox_showAll: true,
            showDeletionDialog: false,
            deletionText: "",
            confirmDeleteRequest: () => {}
        };
    }

    componentDidMount() {
        this.initData();
    }

    initData() {
        User.getUsers()
            .then(response => {
                if (response.status === 200) {
					let allUsers = response.body
						.map((user) => new User(user))
						.sort((a, b) => {
							if (a.last_name < b.last_name) {
								return -1;
							} else if (a.last_name > b.last_name) {
								return 1;
							} else {
								return 0;
							}
						});

                    this.setState({
                        allUsers,
                        usersToShow: allUsers,
                        errorResponse: null
                    });
                }
            }).catch(err => {
            this._handleUserErrors(err);
        });
    }

    _handleUserErrors(err) {
        let showMessage = this.context.showMessage;

        if (!err.response) {
            return showMessage(null, "Verbindungsfehler", "danger");
        }

        this.setState({
            errorResponse: err.response
        });

        switch (err.response.status) {
            case 403:
                return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
            case 404:
                return showMessage(null, "Aktualisierungsanfrage nicht gefunden", "danger");
            case 500:
                return showMessage(null, "Unbekannter Fehler", "danger");
            default:
                return showMessage(null, "Unbekannter Fehler", "danger");
        }
    }

    getUserFullName(user) {
        return `${user.first_name} ${user.last_name}`
    }

	getSelectOptions() {
		return this.state.allUsers
			.map((user) => ({
				label: this.getUserFullName(user),
				value: this.getUserFullName(user)
			}));
	}

    executeFilter(selectedOption) {
        this.setState({
            usersToShow: this.state.allUsers.filter(user => this.getUserFullName(user) === selectedOption.value)
        });

    }

    renderUser(user){
        let path = `${Paths.PATH_USERS}/${user.id}`;

        return(
            <tr key={user.id}>
                <td>{user.last_name}</td>
                <td>{user.first_name}</td>
                <td>{user.email}</td>
                <td>{user.phone}</td>
                <td>{user.account_state.account_state}</td>
                <Can I="show" this={user}>
                    <td>
                        <Link to={path}>
                        <button
                            className="btn btn-outline-form-simple">
                                <IoMdOpen style={{marginRight: "10px"}} />
                                Anzeigen</button>
                        </Link>
                    </td>
                    <td>
                        <button className="btn btn-outline-form"
                                style={{padding: "0px"}}
                                onClick={(event) => {
                                    this.setState({
                                        showDeletionDialog: true,
                                        deletionText: `Nutzer ${user.first_name} ${user.last_name} (${user.email})`,
                                        confirmDeleteRequest: () => UserService.deleteUserAccount(event, user, this.context.showMessage, function () {
                                            window.location.reload();
                                        })
                                    })
                                }}>
                            <div>
                                <IoMdTrash/>
                            </div>
                            Löschen
                        </button>
                    </td>

                </Can>
            </tr>
        )
    }

    render(){
        document.title = TAB_TITLE_BASE + TAB_TITLE_CONNECTOR + "Benutzerverwaltung";
        if (this.state.errorResponse){
            return <Error
                status={this.state.errorResponse.status}
                statusText={this.state.errorResponse.statusText}/>
        }

        return (
            <div className={'fimo-content-page'}>

                {this.state.showDeletionDialog &&
                    <DeleteDialog
                        elementText={this.state.deletionText}
                        cancel={() =>
                            this.setState({
                                showDeletionDialog: false,
                                deletionText: '',
                                confirmDeleteRequest: () => void 0
                            })
                        }
                        confirmDelete={this.state.confirmDeleteRequest}
                    />
                }

                <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                    <div className="col text-right" style={{maxWidth: "100%"}}>
                        <Can I="create_consultant" on="User">
                            <Link to={`${Paths.PATH_USERS}/create_consultant`}>
                                <button className="btn btn-outline-form">
                                    <div>
                                        <IoIosPersonAdd />
                                    </div>
                                    Mitarbeiter anlegen
                                </button>
                            </Link>
                        </Can>
                        <Can I="create_client" on="User">
                            <Link to={`${Paths.PATH_USERS}/create_client`}>
                                <button className="btn btn-outline-form">
                                    <div>
                                        <IoIosPersonAdd />
                                    </div>
                                    Kunden anlegen
                                </button>
                            </Link>
                        </Can>
                    </div>
                </div>

                <div className={"row"} style={{backgroundColor: "white", padding: "20px", marginRight: "75px", marginLeft: "75px", height: "38px"}}>

                    <div>
                        <label>
                            <input type="radio"
                                   value={this.state.radiobox_showAll}
                                   checked={this.state.radiobox_showAll}
                                   onChange={() => {
                                       this.setState({
                                           radiobox_showAll: true,
                                           usersToShow: this.state.allUsers
                                       })
                                   }}
                                   style={{marginRight: "8px"}}/>
                            Alle anzeigen
                        </label>
                        <label style={{marginLeft: "20px"}}>
                            <input type="radio"
                                   value={!this.state.radiobox_showAll}
                                   checked={!this.state.radiobox_showAll}
                                   onChange={() => {
                                       this.setState({radiobox_showAll: false})
                                   }}
                                   style={{marginRight: "8px"}}/>
                            Suchfilter
                        </label>
                    </div>

                    {!this.state.radiobox_showAll &&
                    <div className={"row"} style={{marginLeft: "50px"}}>
                        <span style={{maxWidth: "750px", minWidth: "300px"}}>
                                    <Select
                                        className="col"
                                        options={this.getSelectOptions()}
                                        autoFocus={true}
                                        onChange={(selectedOption) => this.executeFilter(selectedOption)}/>
                                </span>
                    </div>
                    }
                </div>

                {this.state.usersToShow && this.state.usersToShow.length > 0 ?
                    <div style={{padding: "30px"}}>
                        <Table responsive className="table-borderless">
                            <thead>
                            <tr>
                                <th>Nachname</th>
                                <th>Vorname</th>
                                <th>Email</th>
                                <th>Telefon</th>
                                <th>Zugangsstatus</th>
                                <th>Verfügbare Aktionen</th>
                            </tr>
                            </thead>

                            <tbody>
                                {this.state.usersToShow.map(user => this.renderUser(user))}
                            </tbody>
                        </Table>
                    </div>
                    :
                    <div>
                        <span>Keine Benutzer gefunden</span>
                    </div>}
            </div>
        )
    }
}

export default UsersOverview;
