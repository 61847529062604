import React, {Component} from 'react';

import * as DataService from '../../service/dataService';
import {NotificationContext} from "../contexts/NotificationContext";

import 'bootstrap/dist/css/bootstrap.min.css';
import TermsOfUse from "../TermsOfUse";
import {IoMdArrowDropdown, IoMdArrowDropup} from 'react-icons/io';
import {Collapse} from "react-bootstrap";

import * as Paths from "../../config/paths";
import {Redirect} from 'react-router-dom'

class ChangePassword extends Component{

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            newPassword: '',
            passwordConfirmation: '',
            agbIsSelected: false,
            collapseDivIn: false,
            redirect: false
        };

        this.updateNewPassword = this.updateNewPassword.bind(this);
        this.updatePasswordConfirmation = this.updatePasswordConfirmation.bind(this);
        if (this.props.isNewExternalUser) {
            this.updateAgbState = this.updateAgbState.bind(this);
            this.setCollapseDivIn = this.setCollapseDivIn.bind(this);
        }
    }

    updateNewPassword(event){
        this.setState({
            newPassword: event.target.value
        });
    }

    updatePasswordConfirmation(event){
        this.setState({
            passwordConfirmation: event.target.value
        });
    }

    arePasswordsEqual(){
        return this.state.newPassword && this.state.passwordConfirmation && this.state.newPassword !== "" &&
            this.state.passwordConfirmation !== "" && this.state.newPassword === this.state.passwordConfirmation
    }

    isSubmitChangePasswordEnabled(){
        return !this.arePasswordsEqual();
    }

    updateAgbState(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            agbIsSelected: value
        });
    }

    setCollapseDivIn(event) {
        event.preventDefault();

        this.setState({
            collapseDivIn: !this.state.collapseDivIn
        });
    }

    /**
     * Sends the new password to the server.
     *
     * @param event
     */
    submitNewPassword(event){
        event.preventDefault();

        if (this.props.isNewExternalUser && this.state.agbIsSelected === false) {
            this.context.showMessage(null, "Sie müssen die allgemeinen Geschäftsbedingungen akzeptieren.", "danger");
            return;
        }

        let showMessage = this.context.showMessage;

        DataService.resetPasswort(this.props.match.params.token, this.state.newPassword)
            .then(response => {
                if (response.status === 200){
                    this.setState({
                        redirect: true
                    })
                    return showMessage(null, "Passwort wurde geändert", "success");
                }

                showMessage(null, "Unbekannter Fehler", "danger");
            })
            .catch(err => {
                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 404:
                        return showMessage(null, "Benutzer wurde nicht gefunden", "danger");
                    case 422:
                        return showMessage(null, "Überprüfen Sie Ihre Angaben", "danger");
                    case 500:
                        if (err.response.body.errors === "Validation failed: Email has already been taken") {
                            return showMessage(null, "Email bereits vergeben", "danger");
                        }

                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    render(){
        const isNewExternalUser = this.props.isNewExternalUser
        if (isNewExternalUser && this.state.redirect) {
            return <Redirect to={{pathname: `${Paths.PATH_LOGIN}`}}/>
        }
        return(
            <div className="fimo-content-page">

                <div className="container" style={{padding: "30px"}}>
                    <div className="row">
                        <div>
                            <h4 className="text-fimo-blue">Passwort zurücksetzen</h4>

                            <p>Geben Sie Ihr neues Passwort ein und bestätigen Sie es nochmals.</p>

                            <form>
                                <div className="form-group row">
                                    <label className="col-1 col-form-label"  style={{minWidth: "200px"}}>Passwort:</label>
                                    <input className="col-2 form-control"
                                           type="password"
                                           value={this.state.newPassword}
                                           onChange={this.updateNewPassword}
                                           style={{minWidth: "250px"}}/>
                                </div>

                                <div className="form-group row">
                                    <label className="col-1 col-form-label"  style={{minWidth: "200px"}}>Passwort bestätigen:</label>
                                    <input className="col-2 form-control"
                                           type="password"
                                           value={this.state.passwordConfirmation}
                                           onChange={this.updatePasswordConfirmation}
                                           style={{minWidth: "250px"}}/>
                                </div>

                                {isNewExternalUser &&
                                <div className="form-group row" style={{paddingLeft: "15px", paddingTop: "15px"}}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={this.state.agbIsSelected}
                                            onChange={this.updateAgbState}>
                                        </input>
                                        <span style={{paddingLeft: "10px"}}>Ich akzeptiere die Nutzungsbedingungen.</span>
                                    </label>
                                </div>
                                }

                                {isNewExternalUser &&
                                <div>
                                    <button className="btn btn-outline-form"
                                            onClick={this.setCollapseDivIn}>
                                        {this.state.collapseDivIn &&
                                        <span>Nutzungsbedingungen ausblenden<IoMdArrowDropup
                                            style={{marginLeft: "5px"}}/></span>
                                        }
                                        {!this.state.collapseDivIn &&
                                        <span>Nutzungsbedingungen einblenden<IoMdArrowDropdown style={{marginLeft: "5px"}}/></span>
                                        }
                                    </button>

                                    <Collapse in={this.state.collapseDivIn}>
                                        <div style={{
                                            border: "1px solid #dddddd",
                                            borderRadius: "8px",
                                            paddingTop: "30px",
                                            paddingBottom: "30px",
                                            paddingRight: "50px"
                                        }}>
                                            <TermsOfUse/>
                                        </div>
                                    </Collapse>
                                </div>
                                }

                                <div
                                    className="text-center"
                                    style={{margin: "10px"}}>
                                    <button className="btn btn-primary"
                                            style={{margin: "20px"}}
                                            disabled={this.isSubmitChangePasswordEnabled()}
                                            onClick={(event) => this.submitNewPassword(event)}>
                                        Speichern
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangePassword;
