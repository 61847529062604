import request from 'superagent';
import * as Paths from '../config/paths';

class InsuranceCompany {

    static get modelName() {
        return 'InsuranceCompany'
    }

    constructor(object){
        Object.assign(this, object)
    }

    /**
     * Gets a list of InsuranceCompanies from the server.
     *
     * @returns {Promise<any>}
     */
    static getInsuranceCompanies(){
        return new Promise((resolve, reject) => {
            request.get(Paths.URL_INSURANCE_COMPANIES)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    static getInsuranceCompaniyById(id){
        const url = `${Paths.URL_INSURANCE_COMPANIES}/${id}`;
        return new Promise((resolve, reject) => {
            request.get(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    updateInsuranceCompany(ensurance){
        const url = `${Paths.URL_INSURANCE_COMPANIES}/${ensurance.id}`;

        return new Promise((resolve, reject) => {
        request.put(url)
            .set('Access-Control-Allow-Origin', '*')
            .send(ensurance)
            .set('accept', 'application/json')
            .set('Authorization', sessionStorage.getItem('auth_token'))
            .end((err, res) =>{
                if (err){
                    reject(err);
                }
                resolve(res);
            });
        });
    }

    static deleteInsurance(insurance){
        const url = `${Paths.URL_INSURANCE_COMPANIES}/${insurance}`;

        return new Promise((resolve, reject) => {
            request.delete(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .parse(({body}) => body)
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    createInsuranceCompany(){
        return new Promise((resolve, reject) => {
            request.post(Paths.URL_INSURANCE_COMPANIES)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .send(this)
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

}

export default InsuranceCompany;
