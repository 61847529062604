import React, {Component} from 'react';
import {NotificationContext} from "../contexts/NotificationContext";

import * as DemoData from "./DemoData";
import {withRouter} from "react-router-dom";


import {IoMdArrowRoundBack, IoMdOpen} from 'react-icons/io'
import {openAvalRequestURL} from "../../service/externalLinkService";
import * as Constants from "../../config/constants";
import PolicyDetails from "../policyDetails/PolicyDetails";

class DemoDetailsContainer extends Component{

    static contextType = NotificationContext;

    constructor(props) {
        super(props);

        this.state = {
            clientData: null,
            projects: null,
            errorResponse: null
        };
    }

    componentWillMount() {
        let {id} = this.props.match.params;

        this.setState({
            clientData: DemoData.policyData.filter(policy => policy.id == id)[0]
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props){
            let {id} = nextProps.match.params;

            this.setState({
                clientData: DemoData.policyData.filter(policy => policy.id == id)[0]
            });
        }
    }

    avalRequest() {
        openAvalRequestURL(this.state.clientData.policy.insurance_company.name);
    }

    renderPolicyDetailsArea() {
        return <PolicyDetails clientData={this.state.clientData}/>
    }

    render(){
        const policyNumber = this.state.clientData.policy.policy_number;

        return(
            <div className={"fimo-content-page"}>
                <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                    <div
                        style={{color: "red"}}>
                        WICHTIG: Hierbei handelt es sich um Beispieldaten!
                    </div>

                    <div className="col text-right" style={{maxWidth: "100%"}}>
                        <button className="btn btn-outline-form"
                                type="button"
                                onClick={() => this.props.history.goBack()}>
                            <div>
                                <IoMdArrowRoundBack />
                            </div>
                            Zurück
                        </button>
                        {(this.state.clientData.policy.insurance_company.homepage.length > 1 ) &&
                            <button className="btn btn-outline-policycard" onClick={() => this.avalRequest()}>
                                <div>
                                    <IoMdOpen/>
                                </div>
                                Aval
                                <br></br>
                                anfordern
                            </button>
                        }
                    </div>
                </div>
                <div style={{padding: "30px", paddingBottom: "75px"}}>
                    <div>
                        <div className="details-header">
                            <h4 className="text-fimo-green">{this.state.clientData.policy.insurance_company.name}</h4>
                            <h6>VSNR: {policyNumber}</h6>
                        </div>

                        {this.renderPolicyDetailsArea()}

                        {/*{this.renderProjects()}*/}

                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(DemoDetailsContainer);
