import {Component} from "react";
import * as AuthenticationService from "../service/authenticationService";
import AdminPortal from "./AdminPortal";
import {Link, Redirect} from "react-router-dom";
import * as Paths from "../config/paths";
import React from "react";

import separator_image from '../static/extern/Hintergrundbild_Karles.jpg';
import PortalText from "./PortalText";
import ClientEmployeeRequest from "../models/ClientEmployeeRequest";
import * as FormatService from "../service/formatService";
import Table from "react-bootstrap/Table";
import {translateRequestState} from "../service/languageService";


class Portal extends Component {

    constructor(props){
        super(props);

        this.state = {
            isAdmin: AuthenticationService.isUserAdmin(),
            isCaseHandler: AuthenticationService.isUserCaseHandler(),
            isClient: AuthenticationService.isUserClient(),
            pendingClientEmployeeRequests: null
        };
    }

    initData() {
        if (this.state.isClient) {
            console.log("getting requests");

            ClientEmployeeRequest.getClientEmployeeRequests()
                .then(response => {
                    if (response.status === 200) {
                        let clientEmployeeRequests = response.body;

                        this.setState({
                            pendingClientEmployeeRequests: clientEmployeeRequests
                                .map(request => new ClientEmployeeRequest(request))
                                .filter(request => request.request_state.request_state === "pending")
                        });
                    }
                });
        }
    }

    componentDidMount() {
        this.initData();
    }

    render() {
        const userIsAdmin = this.state.isAdmin;
        const userIsCaseHandler = this.state.isCaseHandler;
        const userIsClient = this.state.isClient;

        return (
            <div style={{marginTop: "-60px"}}>
                {/*<div style={{minWidth: "100%", display: "block", overflow: "hidden"}}>*/}
                {/*    <img src={separator_image} width={"100%"} height={"300px"} style={{objectFit: "cover"}} />*/}
                {/*</div>*/}
                <div style={{maxWidth: "1400px", paddingLeft: "50px", paddingTop: "50px"}}>
                    <div>

                        {userIsClient &&
                        this.renderClientEmployeeRequests()
                        }

                        {this.renderText()}

                        {userIsAdmin || userIsCaseHandler &&
                            <AdminPortal authToken={this.props.authToken}/>
                        }
                    </div>
                </div>
            </div>
        );
    }


    renderText() {
        return <div><PortalText authThoken={this.props.authToken} handleSuccessfulLogin={this.props.handleSuccessfulLogin}/></div>;
    }

    renderClientEmployeeRequest(request, index) {
        const request_state = translateRequestState(request.request_state.request_state)

        return(
            <tr key={request.id}>
                <td className="align-middle">{request.client_company.company_name ?
                    request.client_company.company_name :
                    request.client_company_name}</td>
                <td className="align-middle">{FormatService.formatDate(request.created_at)}</td>
                <td className="align-middle">{request_state}</td>
            </tr>
        );
    }

    renderClientEmployeeRequests() {
        let isRenderRequests = this.state.pendingClientEmployeeRequests &&
            this.state.pendingClientEmployeeRequests.length > 0;

        return(
            <div>
                {isRenderRequests &&
                <div>
                    <p className="title3">
                        Ausstehende Zugangsanfragen
                    </p>

                    <Table className="table-borderless">
                        <thead>
                        <tr>
                            <th>Firma</th>
                            <th>Erstellt am</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.pendingClientEmployeeRequests.map((request, index) => this.renderClientEmployeeRequest(request, index))}
                        </tbody>
                    </Table>
                </div>}

                <div>
                    <Link to={`${Paths.PATH_CLIENT_EMPLOYEE_REQUESTS}/new`}>
                        <button className="btn btn-primary"
                                style={{float: "right", marginRight: "5px"} }>
                            Zugang anfragen
                        </button>
                    </Link>
                </div>
            </div>
        );
    }


}


export default Portal;
