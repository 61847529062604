import React, {Component} from 'react';

import * as dataService from "../service/dataService";
import {NotificationContext} from "./contexts/NotificationContext";

import * as Paths from "../config/paths";

import { FaFileImport } from 'react-icons/fa';
import { FaDownload } from 'react-icons/fa';
import Table from 'react-bootstrap/Table';

class ImportInsurances extends Component{

    static contextType = NotificationContext;

    constructor(props) {
        super(props);

        this.state = {
            selectedFile: null,
            errors: []
        };

        this.onChangeHandler = this.onChangeHandler.bind(this);

    }

    onChangeHandler(event) {
        this.setState({
            selectedFile: event.target.files[0],
        });
    }


    onClickHandler = () => {

        let formData = new FormData();
            formData.append('file', this.state.selectedFile);

        let options = {
            method: 'POST',
            headers: {"Authorization": localStorage.getItem("token")},
            body: formData
        }

        const url = `${Paths.URL_IMPORT}`;

        dataService.importEnsurances(formData)
            .then(response => {
                if (response.status === 200) {
                    let showMessage = this.context.showMessage;
                    showMessage(null, "Erfolg ", "success");

                    this.setState({
                        errors: response.body
                    })
                }
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response) {
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Benutzer nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    onClickHandlertwo = () => {

        let options = {
            method: 'GET',
            headers: {"Authorization": localStorage.getItem("token")},
        }

        const url = `${Paths.URL_EXPORT}`;

        dataService.ExportEnsurances()
            .then(response => {
                if (response.status === 200){
                    let filename = `Unvollständige_Daten.csv`;

                    this._saveFile(response.body, filename);
                    return response.body;
                }
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response) {
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Benutzer nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    _saveFile(blob, filename){
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        var url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = filename
        a.click();

        window.URL.revokeObjectURL(url);
    }

    ExportButton() {

        if(this.state.errors.length > 0){
            return(
                <div className="col text-right" style={{maxWidth: "100%"}}>
                    <button className="btn btn-outline-form"
                            onClick={this.onClickHandlertwo}>
                        <div>
                            <FaDownload/>
                        </div>
                        Nicht importierte Verträge herunterladen
                    </button>
                </div>
            )
        }
    }

    render(){
        return(
            <div>
                <div className={"fimo-content-page"}>
                    <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                        <div className="col text-right" style={{maxWidth: "100%"}}>
                            <button className="btn btn-outline-form"
                                    onClick={this.onClickHandler}>
                                <div>
                                    <FaFileImport/>
                                </div>
                                Importieren
                            </button>
                        </div>
                    </div>
                    <div style={{padding: "30px"}}>
                        <h4 className="page-headline"> Um Daten zu importieren, wählen Sie eine CSV-Datei aus und klicken Sie auf Importieren. </h4>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <form>
                                    <div className="form-group row">
                                        <input
                                            type="file"
                                            accept=".csv"
                                            name="file"
                                            onChange={this.onChangeHandler}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {this.state.errors.length > 0 &&
                                    <Table responsive style={{marginTop: "30px"}}>
                                        <caption style={{captionSide: "top"}}>Folgende Fehler sind aufgetreten:</caption>
                                        <thead>
                                            <tr>
                                                <th>Zeile</th><th>Versicherer</th><th>Fehlende/Fehlerhafte Felder</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.errors.map(error => (<tr><td>{error.Zeilennummer}</td><td>{error.Versicherer}</td><td>{error.missing_field}</td></tr>))}
                                        </tbody>
                                    </Table>}
                                {this.ExportButton()}
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ImportInsurances;
