import React, {Component} from 'react';
import {NotificationContext} from "../contexts/NotificationContext";
import Project from "../../models/Project";
import {Redirect, withRouter} from "react-router-dom";
import ability from "../../Ability";
import * as Paths from "../../config/paths";
import Error from "../Error";
import InsurancePolicy from "../../models/InsurancePolicy";
import {IoIosSave, IoMdClose} from 'react-icons/io'

class UpdateProject extends Component {

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        if(this.props.updateMode === 'new') {
            this.state = {
                policy: props.location.policy || null,
                projectNumber: '',
                projectName: '',
                startDate: '',
                errorResponse: null
            };
        }

        if(this.props.updateMode === 'edit') {
            this.state = {
                project: props.location.project || null,
                projectNumber: props.location.project ? props.location.project.project_number : '',
                projectName: props.location.project ? props.location.project.project_name : '',
                startDate: props.location.project ? props.location.project.start_date : null,
                errorResponse: null
            };
        }

        this.updateProjectNumber = this.updateProjectNumber.bind(this);
        this.updateProjectName = this.updateProjectName.bind(this);
        this.updateStartDate = this.updateStartDate.bind(this);
    }

    componentWillMount() {
        if (!this.props.location.policy){
            let {id} = this.props.match.params;

            this.getPolicy(id);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props){
            let {id} = nextProps.match.params;

            if(this.props.updateMode === 'new') {
                this.getPolicy(id);
            } else if(this.props.updateMode === 'edit') {
                this.getProject(id);
            }

        }
    }

    getPolicy(id){
        InsurancePolicy.getPolicy(id)
            .then(response => {
                if (response.status === 200){
                    let policy = new InsurancePolicy(response.body);

                    this.setState({
                        policy: policy,
                        errorResponse: null
                    });
                }
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Police nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }


    getProject(id){
        Project.getProject(id)
            .then(response => {
                if (response.status === 200){
                    let project = new Project(response.body);

                    this.setState({
                        project: project,
                        projectNumber: project.project_number,
                        projectName: project.project_name,
                        startDate: project.start_date,
                        errorResponse: null
                    });
                }
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Projekt nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }


    updateProjectNumber(event){
        this.setState({
            projectNumber: event.target.value
        });
    }

    updateProjectName(event){
        this.setState({
            projectName: event.target.value
        });
    }

    updateStartDate(event){
        this.setState({
            startDate: event.target.value
        });
    }

    createProject(event){
        event.preventDefault();

        let project = {
            project_number: this.state.projectNumber,
            project_name: this.state.projectName,
            start_date: this.state.startDate
        };

        let showMessage = this.context.showMessage;

        Project.createProject(this.state.policy.id, project)
            .then(response => {
                if (response.status === 201){
                    showMessage(null, "Projekt erfolgreich angelegt", "success");
                    this.props.history.goBack()
                } else {
                    showMessage(null, "Unbekannter Fehler", "danger");
                }
            })
            .catch(err => {
                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Die Police wurde nicht gefunden", "danger");
                    case 422:
                        return showMessage(null, "Überprüfen Sie Ihre Angaben", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }


    updateProject(event){
        event.preventDefault();

        let newProjectDetails = {
            project_number: this.state.projectNumber,
            project_name: this.state.projectName,
            start_date: this.state.startDate,
            new_insurance_policy_id: this.state.project.insurance_policy_id
        };

        let showMessage = this.context.showMessage;

        this.state.project.updateProject(newProjectDetails)
            .then(response => {
                if (response.status === 200){
                    showMessage(null, "Projekt erfolgreich aktualisiert", "success");
                    this.props.history.goBack()
                } else {
                    showMessage(null, "Unbekannter Fehler", "danger");
                }
            })
            .catch(err => {
                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Das Projekt wurde nicht gefunden", "danger");
                    case 422:
                        return showMessage(null, "Überprüfen Sie Ihre Angaben", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    render(){
        if ((this.props.updateMode === 'new' && ability.cannot("create", "Project"))
            || (this.props.updateMode === 'edit' && ability.cannot("update", this.state.project))){
            return <Redirect to={Paths.PATH_ROOT}/>
        }

        if (this.state.errorResponse){
            return <Error
                status={this.state.errorResponse.status}
                statusText={this.state.errorResponse.statusText}/>
        }

        if ((this.props.updateMode === 'new' && !this.state.policy) || (this.props.updateMode === 'edit' && !this.state.project)){
            return  null;
        }

        const label = this.props.updateMode === 'new' ? "Projekt anlegen" : "Projekt bearbeiten";


        const clickFunction = this.props.updateMode === 'new' ? (event) => this.createProject(event) : (event) => this.updateProject(event);

        return(
            <div>
                <div className={"fimo-content-page"}>
                    <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                        <div className="col text-right" style={{maxWidth: "100%"}}>
                            <button className="btn btn-outline-form"
                                    onClick={clickFunction}>
                                <div>
                                    <IoIosSave/>
                                </div>
                                Speichern
                            </button>
                            <button className="btn btn-outline-form"
                                    type="button"
                                    onClick={() => this.props.history.goBack()}>
                                <div>
                                    <IoMdClose style={{color: " red"}}/>
                                </div>
                                Abbrechen
                            </button>
                        </div>
                    </div>
                    <div style={{padding: "30px"}}>
                        <div>
                            <h4>Neue Policy anlegen</h4>
                        </div>
                        <div className={"row justify-content-center"}>
                            <div className="col-md-6">
                                <form>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Projektnummer:</label>
                                        <input className="col form-control"
                                               value={this.state.projectNumber}
                                               onChange={(event) => this.updateProjectNumber(event)}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Projektname:</label>
                                        <input className="col form-control"
                                               value={this.state.projectName}
                                               onChange={(event) => this.updateProjectName(event)}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Startdatum:</label>
                                        <input className="col form-control"
                                               value={this.state.startDate}
                                               onChange={(event) => this.updateStartDate(event)}/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(UpdateProject);