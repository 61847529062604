import React, {Component} from 'react';

import {Button, Collapse} from 'react-bootstrap'

import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import {NotificationContext} from "./contexts/NotificationContext";
import * as DataService from '../service/dataService';
import {Redirect} from "react-router-dom";
import * as Paths from "../config/paths";
import {TAB_TITLE_BASE, TAB_TITLE_CONNECTOR} from "../config/constants";
import {PATH_TERMS_OF_USE} from "../config/paths";
import TermsOfUse from "./TermsOfUse";

import {IoMdArrowDropdown, IoMdArrowDropup} from 'react-icons/io'

/**
 * Login Page of the web application.
 */
class RegisterPage extends Component {

    static contextType = NotificationContext;

    constructor(props) {
        super(props);

        this.state = {
            title: "",
            firstName: "",
            lastName: "",
            mail: "",
            phone: "",
            password: "",
            passwordRepeated: "",
            registrationSuccessful: false,
            agbIsSelected: false,
            collapseDivIn: false
        }

        this.updateFirstName = this.updateFirstName.bind(this);
        this.updateLastName = this.updateLastName.bind(this);
        this.updateTitle = this.updateTitle.bind(this);
        this.updateMail = this.updateMail.bind(this);
        this.updatePhone = this.updatePhone.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.updatePasswordRepeated = this.updatePasswordRepeated.bind(this);
        this.executeRegistration = this.executeRegistration.bind(this);
        this.updateAgbState = this.updateAgbState.bind(this);
        this.setCollapseDivIn = this.setCollapseDivIn.bind(this);
    }


    updateTitle = (selectedOption) => {
        this.setState({
            title: selectedOption.value
        });
    }

    updateFirstName(event) {
        this.setState({
            firstName: event.target.value
        });
    }

    updateLastName(event) {
        this.setState({
            lastName: event.target.value
        });
    }

    updateMail(event) {
        this.setState({
            mail: event.target.value
        });
    }

    updatePhone(event) {
        this.setState({
            phone: event.target.value
        });
    }

    updatePassword(event) {
        this.setState({
            password: event.target.value
        });
    }

    updatePasswordRepeated(event) {
        this.setState({
            passwordRepeated: event.target.value
        });
    }

    updateAgbState(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            agbIsSelected: value
        });
    }

    setCollapseDivIn(event) {
        event.preventDefault();

        this.setState({
            collapseDivIn: !this.state.collapseDivIn
        });
    }


    executeRegistration(event) {
        event.preventDefault();

        if (this.state.agbIsSelected === false) {
            this.context.showMessage(null, "Sie müssen die allgemeinen Geschäftsbedingungen akzeptieren.", "danger");
            return;
        }

        if (!this.validate()) {
            return;
        }

        let details = {
            title: this.state.title,
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.mail,
            phone: this.state.phone,
            password: this.state.password
        };

        let showMessage = this.context.showMessage;

        DataService.signup(details)
            .then(response => {
                if (response.status === 201) {
                    this.setState({
                        registrationSuccessful: true
                    })
                    return showMessage(null, "Registrierung erfolgreich", "success");
                }

                showMessage(null, "Unbekannter Fehler", "danger");
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response) {
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                console.log(err.response);

                switch (err.response.status) {
                    case 422:
                        return showMessage(null, "Überprüfen Sie Ihre Angaben", "danger");
                    case 500:
                        if (err.response.body.errors === "Validation failed: Email has already been taken") {
                            return showMessage(null, "Email bereits vergeben", "danger");
                        }

                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }


    validate() {
        if (!this.state.title) {
            this.context.showMessage(null, "Bitte wählen Sie eine Anrede aus.", "danger");
            return false;
        }

        if (!this.state.firstName || !/^([a-zA-ZäöüÄÖÜßé]{1,})$/.test(this.state.firstName)) {
            this.context.showMessage(null, "Bitte geben Sie einen gültigen Vornamen an.", "danger");
            return false;
        }

        if (!this.state.lastName || !this.state.lastName.match("[A-Za-zÄÖÜäöüßé-]+")) {
            this.context.showMessage(null, "Bitte geben Sie einen Nachnamen an.", "danger");
            return false;
        }

        if (!this.state.mail || this.state.mail.match(/^ *$/) !== null) {
            this.context.showMessage(null, "Bitte geben Sie eine Emailaddresse an.", "danger");
            return false;
        }

        if (!this.state.phone.match("[0-9]+")) {
            this.context.showMessage(null, "Bitte geben Sie eine Telefonnummer an.", "danger");
            return false;
        }

        return true;
    }

    render() {
        document.title = TAB_TITLE_BASE + TAB_TITLE_CONNECTOR + "Registrierung";

        const options = [{label: "Herr", value: "Herr"}, {label: "Frau", value: "Frau"}];

        if (this.state.registrationSuccessful) {
            return <Redirect to={{pathname: `${Paths.PATH_ROOT}`, state: {from: this.props.location}}}/>
        }

        return (
            <div className="fimo-content-page">
                <div className="container align-self-center" style={{padding: "30px", paddingBottom: "50px"}}>
                    <form>
                        <div>
                            <div className="form-row">
                                <label className="col-form-label" style={{minWidth: "150px"}}>Anrede:</label>
                                <span style={{minWidth: "250px"}}>
                                    <Select
                                        options={options}
                                        onChange={this.updateTitle}
                                        style={{minWidth: "250px"}}/>
                                </span>
                            </div>
                            <div className="form-row">
                                <label className="col-form-label" style={{minWidth: "150px"}}>Vorname:</label>
                                <input className="col-2 form-control"
                                       value={this.state.firstName}
                                       onChange={this.updateFirstName}
                                       style={{minWidth: "250px"}}/>
                            </div>
                            <div className="form-row">
                                <label className="col-1 col-form-label" style={{minWidth: "150px"}}>Nachname:</label>
                                <input className="col-2 form-control"
                                       value={this.state.lastName}
                                       onChange={this.updateLastName}
                                       style={{minWidth: "250px"}}/>
                            </div>
                            <div className="form-row">
                                <label className="col-1 col-form-label"
                                       style={{minWidth: "150px"}}>Telefonnummer:</label>
                                <input className="col-2 form-control"
                                       value={this.state.phone}
                                       onChange={this.updatePhone}
                                       style={{minWidth: "250px"}}/>
                            </div>
                            <div className="form-row">
                                <label className="col-1 col-form-label" style={{minWidth: "150px"}}>E-Mail:</label>
                                <input className="col-2 form-control"
                                       value={this.state.mail}
                                       onChange={this.updateMail}
                                       style={{minWidth: "250px"}}/>
                            </div>
                            <div className={"form-row"}>
                                <label className="col-1 col-form-label" style={{minWidth: "150px"}}>Passwort:</label>
                                <input className="col-2 form-control"
                                       type="password"
                                       value={this.state.password}
                                       onChange={this.updatePassword}
                                       style={{minWidth: "250px"}}
                                />
                            </div>
                            <div className={"form-row"}>
                                <label className="col-1 col-form-label" style={{minWidth: "150px"}}>Passwort
                                    (wdh.):</label>
                                <input className="col-2 form-control"
                                       type="password"
                                       value={this.state.passwordRepeated}
                                       onChange={this.updatePasswordRepeated}
                                       style={{minWidth: "250px"}}
                                />
                            </div>

                            <div className="form-group row" style={{paddingLeft: "15px", paddingTop: "15px"}}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={this.state.agbIsSelected}
                                        onChange={this.updateAgbState}>
                                    </input>
                                    <span style={{paddingLeft: "10px"}}>Ich akzeptiere die Nutzungsbedingungen.</span>
                                </label>
                            </div>

                            <div>
                                <button className="btn btn-outline-form"
                                        onClick={this.setCollapseDivIn}>
                                    {this.state.collapseDivIn &&
                                    <span>Nutzungsbedingungen ausblenden<IoMdArrowDropup
                                        style={{marginLeft: "5px"}}/></span>
                                    }
                                    {!this.state.collapseDivIn &&
                                    <span>Nutzungsbedingungen einblenden<IoMdArrowDropdown style={{marginLeft: "5px"}}/></span>
                                    }


                                </button>
                                <Collapse in={this.state.collapseDivIn}>
                                    <div style={{
                                        border: "1px solid #dddddd",
                                        borderRadius: "8px",
                                        paddingTop: "30px",
                                        paddingBottom: "30px",
                                        paddingRight: "50px"
                                    }}>
                                        <TermsOfUse/>
                                    </div>
                                </Collapse>
                            </div>

                            <div style={{marginTop: "50px", marginLeft: "150px"}}>
                                <button className="btn btn-primary" style={{marginLeft: "20px"}}
                                        onClick={this.executeRegistration}>
                                    Registrieren
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default RegisterPage;
