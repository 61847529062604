import React, {Component} from 'react'
import Table from "react-bootstrap/Table";
import Can from "../Can";
import {IoMdCreate, IoMdEye, IoMdEyeOff, IoMdSearch, IoMdTrash} from "react-icons/io";
import ClientCompanySearchResult from "../clientEmployeeRequest/ClientCompanySearchResult";
import ClientCompany from "../../models/ClientCompany";
import {NotificationContext} from "../contexts/NotificationContext";
import ClientEmployee from "../../models/ClientEmployee";
import DeleteDialog from '../DeleteDialog';

class ClientEmployeesPage extends Component {

    static contextType = NotificationContext;

    constructor(props) {
        super(props);

        this.state = {
            selectCompanyViewOpen: false,
            companySearchTerm: null,
            client_company_list: [],
            selectedClientCompanies: [],
            showDeletionDialog: false,
            deletionText: "",
            confirmDeleteRequest: () => {}
        }
    }

    /**
     * Enables the ClientEmployee relationship.
     *
     * @param event
     * @param clientEmployee The relationship to enable.
     */
    enableClientEmployee(event, clientEmployee) {
        event.preventDefault();

        let showMessage = this.context.showMessage;

        clientEmployee.enableClientEmployee()
            .then(response => {
                if (response.status === 200) {
                    showMessage(null, `Zugriff auf Firma ${clientEmployee.client_company.company_name} wurde
                     genehmigt.`, "success");

                    return this.props.getClientEmployees();
                }
            })
            .catch(err => {
                console.log(err);

                if (!err.response) {
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Beziehung nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    /**
     * Disables the ClientEmployee relationship.
     *
     * @param event
     * @param clientEmployee The relationship to disable.
     */
    disableClientEmployee(event, clientEmployee) {
        event.preventDefault();

        let showMessage = this.context.showMessage;

        clientEmployee.disableClientEmployee()
            .then(response => {
                if (response.status === 200) {
                    showMessage(null, `Zugriff auf Firma ${clientEmployee.client_company.company_name} wurde
                     gesperrt.`, "success");

                    return this.props.getClientEmployees();
                }
            })
            .catch(err => {
                console.log(err);

                if (!err.response) {
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Beziehung nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    /**
     * Deletes the ClientEmployee relationship
     *
     * @param event
     * @param clientEmployee The relationship to delete.
     */
    deleteClientEmployee(event, clientEmployee) {
        event.preventDefault();

        let showMessage = this.context.showMessage;

        clientEmployee.deleteClientEmployee()
            .then(response => {
                if (response.status === 200) {
                    showMessage(null, "Beziehung gelöscht", "success");

                    this.setState({
                        showDeletionDialog: false,
                        deletionText: '',
                        confirmDeleteRequest: () => void 0
                    })
                    return this.props.getClientEmployees();
                }

                showMessage(null, "Unbekannter Fehler", "danger");
            })
            .catch(err => {
                console.log(err);

                if (!err.response) {
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Beziehung nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    _handleClientEmployeeErrors(err) {
        let showMessage = this.context.showMessage;

        if (!err.response) {
            return showMessage(null, "Verbindungsfehler", "danger");
        }

        switch (err.response.status) {
            case 403:
                return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
            case 404:
                return showMessage(null, "Anfrage nicht gefunden", "danger");
            case 409:
                return showMessage(null, "Status konnte nicht geändert werden", "danger");
            case 500:
                return showMessage(null, "Unbekannter Fehler", "danger");
            default:
                return showMessage(null, "Unbekannter Fehler", "danger");
        }
    }

    updateCompanySearchTerm = (event) => {
        this.setState({
            companySearchTerm: event.target.value
        });
    }

    searchClientCompany = (event) => {
        event.preventDefault();

        if (!this.state.companySearchTerm) {
            return
        }

        ClientCompany.getClientCompanies(this.state.companySearchTerm)
            .then(response => {
                let client_companies = response.body;

                const allClientCompanies = client_companies.map(client_company => new ClientCompany(client_company))
                const availableCompanies = allClientCompanies.filter(client_company => {
                    return !this.props.clientEmployees.find(clientEmployee => clientEmployee.client_company.id === client_company.id)
                })

                this.setState({
                    client_company_list: availableCompanies
                });
            })
            .catch(err => {
                this._handleClientEmployeeErrors(err);
            });
    }

    addSelectedClientCompany = (clientCompany) => {
        const newList = this.state.selectedClientCompanies
        newList.push(clientCompany)
        this.setState({
            selectedClientCompanies: newList,
        });
    }

    resetSelectedClientCompany = (clientCompany) => {
        const newList = this.state.selectedClientCompanies.filter(company => company.id !== clientCompany.id)

        this.setState({
            selectedClientCompanies: newList,
        });
    }

    addCompanies = () => {
        if (!this.state.selectedClientCompanies || this.state.selectedClientCompanies.length === 0) {
            return this.context.showMessage(null, "Bitte wählen Sie mindestens eine Firma", "warning");
        }

        let request = new ClientEmployee({user: this.props.user})

        const companyIds = this.state.selectedClientCompanies.map(company => company.id)
        request.client_company_ids = companyIds;

        request.createClientEmployee()
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        request: new ClientEmployee(response.body),
                        selectCompanyViewOpen: false,
                        selectedClientCompanies: [],
                        companySearchTerm: '',
                        client_company_list: '',
                    }, () => {
                        this.resetSelectedClientCompany()
                        this.props.getClientEmployees()
                    });
                }
            })
            .catch(err => {
                this._handleClientEmployeeErrors(err);
            });

    }

    render() {
        return (
            <div>

                {this.state.showDeletionDialog &&
                    <DeleteDialog
                        elementText={this.state.deletionText}
                        cancel={() =>
                            this.setState({
                                showDeletionDialog: false,
                                deletionText: '',
                                confirmDeleteRequest: () => void 0
                            })
                        }
                        confirmDelete={this.state.confirmDeleteRequest}
                    />
                }

                {!this.props.clientEmployees || this.props.clientEmployees.length === 0 ?
                    <div>
                        Es sind keine Firmen vorhanden.
                    </div>
                    :
                    <Table className="table-borderless" style={{
                        marginLeft: "20px",
                        marginTop: "15px",
                        maxWidth: "1000px"
                    }}>
                        <thead>
                        <tr>
                            <th>Firma</th>
                            <th>Status</th>
                            <th>Verfügbare Aktionen</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.props.clientEmployees.map((clientEmployee, index) => this.renderClientEmployee(
                            clientEmployee, index))}
                        </tbody>
                    </Table>
                }

                <Can I="create" on={"ClientEmployee"}>
                    <div>
                        <hr/>
                        <button className="btn btn-outline-form"
                                style={{margin: "5px"}}
                                onClick={() => {
                                    this.setState({selectCompanyViewOpen: true})
                                }}>
                            <div>
                                <IoMdCreate/>
                            </div>
                            Hinzufügen
                        </button>
                    </div>
                </Can>
                {this.state.selectCompanyViewOpen &&
                <div>
                    {this.renderCompanySearch()}
                </div>
                }
            </div>
        );
    }


    renderClientEmployee(clientEmployee, index) {
        return (

            <tr key={clientEmployee.id}>
                <td style={{verticalAlign: 'middle'}}>{clientEmployee.client_company.company_name}</td>
                <td style={{verticalAlign: 'middle'}}>{clientEmployee.account_state.account_state}</td>
                <td>
                    <Can I="enable" this={clientEmployee}>
                        <button className="btn btn-outline-form"
                                style={{margin: "5px"}}
                                onClick={(event) => this.enableClientEmployee(event, clientEmployee)}>
                            <div>
                                <IoMdEye/>
                            </div>
                            Aktivieren
                        </button>
                    </Can>

                    <Can I="disable" this={clientEmployee}>
                        <button className="btn btn-outline-form"
                                style={{margin: "5px"}}
                                onClick={(event) => this.disableClientEmployee(event, clientEmployee)}>
                            <div>
                                <IoMdEyeOff/>
                            </div>
                            Deaktivieren
                        </button>
                    </Can>

                    <Can I="destroy" this={clientEmployee}>
                        <button className="btn btn-outline-form"
                                style={{margin: "5px"}}
                                onClick={(event) =>
                                    {
                                        this.setState({
                                            showDeletionDialog: true,
                                            deletionText: `Trennung der Firma ${clientEmployee.client_company.company_name} von Nutzer ${clientEmployee.user.first_name} ${clientEmployee.user.last_name} (${clientEmployee.user.email})`,
                                            confirmDeleteRequest: () => this.deleteClientEmployee(event, clientEmployee)
                                        })
                                    }
                                }>
                            <div>
                                <IoMdTrash/>
                            </div>
                            Löschen
                        </button>
                    </Can>
                </td>
            </tr>
        );
    }


    renderCompanySearch = () => {
        return (
            <div>
                <div style={{paddingTop: "20px"}}>
                    <label><b>Manuelle Suche:</b></label>
                    <span className={"row"}>
                        <input className="form-control"
                               style={{maxWidth: "200px", margin: "5px", marginLeft: "18px"}}
                               value={this.state.companySearchTerm}
                               onChange={this.updateCompanySearchTerm}
                        />

                        <button className="btn btn-outline-form-simple"
                                style={{margin: "5px"}}
                                onClick={this.searchClientCompany}>
                            <div>
                                <IoMdSearch/>
                            </div>
                        </button>
                        <button className="btn btn-outline-dark"
                                style={{marginLeft: "50px"}}
                                disabled={!this.state.selectedClientCompanies.length}
                                onClick={() => this.addCompanies()}
                        >
                            Elemente hinzufügen
                        </button>
                    </span>
                </div>
                <ClientCompanySearchResult
                    client_company_list={this.state.client_company_list}
                    selectedClientCompanies={this.state.selectedClientCompanies}
                    addSelectedClientCompany={this.addSelectedClientCompany}
                    resetSelectedClientCompany={this.resetSelectedClientCompany}
                />
            </div>
        )
    }

}


export default ClientEmployeesPage
