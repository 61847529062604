import ClientCompany from "./ClientCompany";
import InsuranceCompany from "./InsuranceCompany";

import request from 'superagent';
import * as Paths from '../config/paths';
import Project from "./Project";

class InsurancePolicy {

    static get modelName() {
        return 'InsurancePolicy'
    }

    constructor(object){
        Object.assign(this, object, {
            client_company: object.client_company ? new ClientCompany(object.client_company) : null,
            insurance_company: object.insurance_company ? new InsuranceCompany(object.insurance_company) : null,
            projects: object.projects ? object.projects.map(project => new Project(project)) : null})
    }

    /**
     * Gets the InsurancePolicy with the specified ID from the server.
     *
     * @param id The ID of the InsurancePolicy
     * @returns {Promise<any>}
     */
    static getPolicy(id){
        const url = `${Paths.URL_INSURANCE_POLICIES}/${id}`;

        return new Promise((resolve, reject) => {
            request.get(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Creates the InsurancePolicy on the server.
     *
     * @returns {Promise<any>}
     */
    createPolicy(client_company_id){
        const url = `${Paths.URL_CLIENT_COMPANIES}/${client_company_id}/insurance_policies`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('Access-Control-Allow-Origin', '*')
                .send(this)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    updatePolicy(UpdatedPolicyData){
        const url = `${Paths.URL_INSURANCE_POLICIES}/${this.id};`

        return new Promise((resolve, reject) => {
            request.put(url)
                .set('Access-Control-Allow-Origin', '*')
                .send(UpdatedPolicyData)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Deletes the InsurancePolicy from the server.
     * @returns {Promise<any>}
     */
    deletePolicy(){
        const url = `${Paths.URL_INSURANCE_POLICIES}/${this.id}`;

        return new Promise((resolve, reject) => {
            request.delete(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .parse(({body}) => body)
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Gets the latest details for the InsurancePolicy.
     *
     * @returns {Promise<any>}
     */
    getLatestDetails(){
        const url = `${Paths.URL_INSURANCE_POLICIES}/${this.id}${Paths.PATH_POLICY_DETAILS_LATEST}`;

        return new Promise((resolve, reject) => {
            request.get(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Creates new InsurancePolicyDetails for the InsurancePolicy on the server.
     *
     * @param details
     */
    createDetails(details){
        const url = `${Paths.URL_INSURANCE_POLICIES}/${this.id}/${Paths.PATH_POLICY_DETAILS}`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('Access-Control-Allow-Origin', '*')
                .send(details)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }
}

export default InsurancePolicy;
