import AccountState from "./AccountState";

import * as Paths from "../config/paths";
import request from "superagent";

class User {

    static get modelName() {
        return 'User'
    }

    constructor(object){
        if (!object){
            return null;
        }

        Object.assign(this, object, {account_state: new AccountState(object.account_state)});
    }

    /**
     * Gets all users from the server.
     *
     * @returns {Promise<any>}
     */
    static getUsers(){
        const url = Paths.URL_USERS;

        return new Promise((resolve, reject) => {
            request.get(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Gets the user with the specified ID from the server.
     *
     * @param id The ID of the user
     * @returns {Promise<any>}
     */
    static getUser(id){
        const url = `${Paths.URL_USERS}/${id}`;

        return new Promise((resolve, reject) => {
            request.get(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Updates the user with the new details.
     *
     * @param UpdatedUserData The new details of the user.
     * @returns {Promise<any>}
     */
    updateUser(updatedUserData){
        const url = `${Paths.URL_USERS}/${this.id};`

        return new Promise((resolve, reject) => {
            request.put(url)
                .set('Access-Control-Allow-Origin', '*')
                .send(updatedUserData)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Creates a new user (client or consultant) on the server.
     *
     * @returns {Promise<any>}
     */
    createUser(isNewExternalUser){
        const path = isNewExternalUser ? Paths.PATH_CREATE_CLIENT
            : Paths.PATH_CREATE_CONSULTANT
        const url = `${Paths.URL_USERS}${path}`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('Access-Control-Allow-Origin', '*')
                .send(this)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Changes the password of the user.
     *
     * @param newPassword The new password
     * @returns {Promise<any>}
     */
    changePassword(newPassword){
        const url = `${Paths.URL_USERS}/${this.id}${Paths.PATH_CHANGE_PASSWORD}`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('Access-Control-Allow-Origin', '*')
                .send({password: newPassword})
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Gets the UserRoles of the user and therefore the roles the user is associated with.
     *
     * @returns {Promise<any>}
     */
    getUserRoles(){
        const url = `${Paths.URL_USERS}/${this.id}${Paths.PATH_USER_ROLES}`;

        return new Promise((resolve, reject) => {
            request.get(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Adds the specified role to the user.
     *
     * @param role The role to add
     * @returns {Promise<any>}
     */
    addRole(role){
        const url=`${Paths.URL_USERS}/${this.id}${Paths.PATH_USER_ROLES}`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('Access-Control-Allow-Origin', '*')
                .send({role_id: role.id})
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Enables the account of the user
     *
     * @returns {Promise<any>}
     */
    enableUser(){
        const url = `${Paths.URL_USERS}/${this.id}${Paths.PATH_ENABLE}`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Disables the account of the user
     *
     * @returns {Promise<any>}
     */
    disableUser(){
        const url = `${Paths.URL_USERS}/${this.id}${Paths.PATH_DISABLE}`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    deleteUser(user){
        const url = `${Paths.URL_USERS}/${user.id}`;

        return new Promise((resolve, reject) => {
            request.delete(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .parse(({body}) => body)
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }
}

export default User
