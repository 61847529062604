import React, {Component} from 'react';

import {Card} from "react-bootstrap";
import * as FormatService from "../../service/formatService";

class ProjectDetailsVHV extends Component {

    renderHeading(heading) {
        return (
            <div style={{marginBottom: "16px"}}>
                <h4>{heading}</h4>
            </div>
        );
    }

    renderLine(attribute, value, indent = false) {
        const style = indent ? {paddingLeft: "16px"} : {paddingLeft: "0px"};

        return (
            <tr style={{fontSize: "1.1em", height: "32px"}}>
                <td style={style}><b>{attribute}:</b></td>
                <td style={{paddingLeft: "32px"}}>{value}</td>
            </tr>
        )
    }

    render(){
        return(
            <div className="container">
                <div className="row">
                    <div className={"col-md-6"}>
                        {this.renderHeading("Basisinformationen")}
                        <Card style={{paddingLeft: "15px"}}>
                            <table>
                                <tbody>
                                    {this.renderLine("Status", this.props.project.convertedData.policy_state)}
                                    {this.renderLine("Jahresbeitrag", FormatService.formatCurrency(this.props.project.convertedData.yearlyFeeObligo))}
                                    {this.renderLine("Zahlweise", this.props.project.convertedData.paymentType)}
                                    {this.renderLine("Soll-Sicherheiten", FormatService.formatCurrency(this.props.project.convertedData.collateralsNominal))}
                                </tbody>
                            </table>
                        </Card>
                    </div>
                    <div className={"col-md-6"}>
                        {this.renderHeading("Kontostand")}
                        <Card style={{paddingLeft: "15px"}}>
                            <table>
                                <tbody>
                                {this.renderLine("Gesamtobligo", FormatService.formatCurrency(this.props.project.convertedData.obligos_sum))}
                                {this.renderLine("Limit", FormatService.formatCurrency(this.props.project.convertedData.bond_limit))}
                                {this.renderLine("Bürgscheinlimit", FormatService.formatCurrency(this.props.project.convertedData.single_limit))}
                                </tbody>
                            </table>
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProjectDetailsVHV;