import React, {Component} from 'react';
import {NotificationContext} from "../contexts/NotificationContext";

import ContactRequest from "../../models/ContactRequest";

import {TAB_TITLE_BASE, TAB_TITLE_CONNECTOR, RECAPTCHA_SITE_KEY} from "../../config/constants";
import {IoIosSave} from "react-icons/io";
import ReCAPTCHA from "react-google-recaptcha";

class NewContactRequest extends Component {
    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
            captcha_response: null
        }

        this.updateName = this.updateName.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.updatePhone = this.updatePhone.bind(this);
        this.updateSubject = this.updateSubject.bind(this);
        this.updateMessage = this.updateMessage.bind(this);
        this.updateRecaptchaResponse = this.updateRecaptchaResponse.bind(this);

        this.submitContactRequest = this.submitContactRequest.bind(this);
    }

    updateName(event){
        this.setState({
            name: event.target.value
        });
    }

    updateEmail(event){
        this.setState({
            email: event.target.value
        });
    }

    updatePhone(event){
        this.setState({
            phone: event.target.value
        });
    }

    updateSubject(event){
        this.setState({
            subject: event.target.value
        });
    }

    updateMessage(event){
        this.setState({
            message: event.target.value
        });
    }

    updateRecaptchaResponse(value){
        this.setState({
            captcha_response: value
        })
    }

    isSaveButtonEnabled(){
        return !(this.state.captcha_response !== null &&
            this.state.captcha_response !== '');
    }

    submitContactRequest(event){
        event.preventDefault();

        let newContactRequest = new ContactRequest({
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            subject: this.state.subject,
            message: this.state.message,
            captcha_response: this.state.captcha_response
        });

        newContactRequest.createContactRequest()
            .then(response => {
                if (response.status === 201){
                    this.context.showMessage(null, "Kontaktanfrage wurde erstellt", "success");
                } else {
                    this.context.showMessage(null, "Unbekannter Fehler", "danger");
                }

            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 422:
                        return showMessage(null, "Überprüfen Sie Ihre Angaben.", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    render(){
        document.title = TAB_TITLE_BASE + TAB_TITLE_CONNECTOR + "Kontaktanfrage";

        return(
            <div className="fimo-content-page">
                <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                    <div className="col text-right" style={{maxWidth: "100%"}}>
                        <button className="btn btn-outline-form"
                                onClick={(event) => this.submitContactRequest(event)}
                                disabled={this.isSaveButtonEnabled()}>
                            <div>
                                <IoIosSave/>
                            </div>
                            Speichern
                        </button>
                    </div>
                </div>

                <div className="container" style={{padding: "30px"}}>
                    <div className="row">
                        <div className="col-md-6">
                            <h4 className="text-fimo-blue">Kontakt</h4>

                            <form>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Name: *</label>
                                    <input className="col form-control"
                                           value={this.state.name}
                                           onChange={this.updateName}/>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Email: *</label>
                                    <input className="col form-control"
                                           value={this.state.email}
                                           onChange={this.updateEmail}/>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Telefon:</label>
                                    <input className="col form-control"
                                           value={this.state.phone}
                                           onChange={this.updatePhone}/>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Betreff: *</label>
                                    <input className="col form-control"
                                           value={this.state.subject}
                                           onChange={this.updateSubject}/>
                                </div>

                                <div className="form-group">
                                    <label className="col-form-label">Nachricht: *</label>
                                    <textarea className="col form-control"
                                              value={this.state.message}
                                              onChange={this.updateMessage}/>
                                </div>
                            </form>

                            <ReCAPTCHA
                            sitekey={RECAPTCHA_SITE_KEY}
                            onChange={this.updateRecaptchaResponse}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NewContactRequest;