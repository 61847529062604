import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import ClientCompany from '../../models/ClientCompany';
import {NotificationContext} from "../contexts/NotificationContext";
import ability from "../../Ability";
import {Redirect, withRouter} from "react-router-dom";
import * as Paths from "../../config/paths";

import {IoIosSave, IoMdClose} from 'react-icons/io'

class NewClientCompany extends Component {

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            company_name: null,
            customer_number: null,
            address1: null,
            address2: null,
            zipcode: null,
            city: null,
            country: null
        };

        this.updateCompanyName = this.updateCompanyName.bind(this);
        this.updateCustomerNumber = this.updateCustomerNumber.bind(this);
        this.updateAddress1 = this.updateAddress1.bind(this);
        this.updateAddress2 = this.updateAddress2.bind(this);
        this.updateZipcode = this.updateZipcode.bind(this);
        this.updateCity = this.updateCity.bind(this);
        this.updateCountry = this.updateCountry.bind(this);

        this.createClientCompany = this.createClientCompany.bind(this);
    }

    updateCompanyName(event){
        this.setState({
            company_name: event.target.value
        });
    }

    updateCustomerNumber(event){
        this.setState({
            customer_number: event.target.value
        });
    }

    updateAddress1(event){
        this.setState({
            address1: event.target.value
        });
    }

    updateAddress2(event){
        this.setState({
            address2: event.target.value
        });
    }

    updateZipcode(event){
        this.setState({
            zipcode: event.target.value
        });
    }

    updateCity(event){
        this.setState({
            city: event.target.value
        });
    }

    updateCountry(event){
        this.setState({
            country: event.target.value
        });
    }

    createClientCompany(event){
        event.preventDefault();

        let clientCompany = new ClientCompany({
            company_name: this.state.company_name,
            customer_number: this.state.customer_number,
            address1: this.state.address1,
            address2: this.state.address2,
            zipcode: this.state.zipcode,
            city: this.state.city,
            country: this.state.country
        });

        clientCompany.createClientCompany()
            .then(response => {
                if (response.status === 201){
                    this.context.showMessage(null, "Firma wurde angelegt", "success");
                    this.props.history.goBack();
                }
                else {
                    this.context.showMessage(null, "Unbekannter Fehler", "danger");
                }
            })
            .catch(err => {
                this._handleClientCompanyErrors(err);
            });
    }

    _handleClientCompanyErrors(err){
        let showMessage = this.context.showMessage;

        if (!err.response){
            return  showMessage(null, "Verbindungsfehler", "danger");
        }

        switch (err.response.status) {
            case 403:
                return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
            case 422:
                return showMessage(null, "Überprüfen Sie Ihre Angaben", "danger");
            case 500:
                if (err.response.body.errors === "Validation failed: Customer number has already been taken"){
                    return  showMessage(null, "Kundennummer bereits vergeben", "danger");
                }

                return showMessage(null, "Unbekannter Fehler", "danger");
        }
    }

    render(){
        if (ability.cannot("create", "ClientCompany")){
            return <Redirect to={Paths.PATH_ROOT}/>
        }

        return(
            <div className={"fimo-content-page"}>
                <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                    <div className="col text-right" style={{maxWidth: "100%"}}>
                        <button className="btn btn-outline-form"
                                onClick={this.createClientCompany}>
                            <div>
                                <IoIosSave/>
                            </div>
                            Speichern
                        </button>
                        <button className="btn btn-outline-form"
                                type="button"
                                onClick={() => this.props.history.goBack()}>
                            <div>
                                <IoMdClose style={{color: " red"}}/>
                            </div>
                            Abbrechen
                        </button>
                    </div>
                </div>
                <div className={"row"} style={{padding: "30px"}}>
                    {this.renderForm()}
                </div>
            </div>
        )
    }

    renderForm(){
        return(
            <div className="row" style={{paddingLeft: "100px"}}>
                <div className="col-md-6">
                    <div>
                        <h4>Neuer Kunde:</h4>
                    </div>
                    <form>
                        <div>
                            <div className="form-row">
                                <label className="col-form-label"
                                       style={{minWidth: "350px"}}>
                                    Firmenname: *
                                </label>

                                <input className="col form-control"
                                    value={this.state.company_name}
                                    onChange={this.updateCompanyName}/>
                            </div>

                            <div className="form-row">
                                <label className="col-form-label"
                                       style={{minWidth: "350px"}}>
                                    Kundennummer: *
                                </label>

                                <input className="col form-control"
                                       value={this.state.customer_number}
                                       onChange={this.updateCustomerNumber}/>
                            </div>

                            <div className="form-row">
                                <label className="col-form-label"
                                       style={{minWidth: "350px"}}>
                                    Addresse: *
                                </label>

                                <input className="col form-control"
                                       value={this.state.address1}
                                       onChange={this.updateAddress1}/>
                            </div>

                            <div className="form-row">
                                <label className="col-form-label"
                                       style={{minWidth: "350px"}}>
                                    Addresszusatz:
                                </label>

                                <input className="col form-control"
                                       value={this.state.address2}
                                       onChange={this.updateAddress2}/>
                            </div>

                            <div className="form-row">
                                <label className="col-form-label"
                                       style={{minWidth: "350px"}}>
                                    Postleitzahl: *
                                </label>

                                <input className="col form-control"
                                       value={this.state.zipcode}
                                       onChange={this.updateZipcode}/>
                            </div>

                            <div className="form-row">
                                <label className="col-form-label"
                                       style={{minWidth: "350px"}}>
                                    Stadt: *
                                </label>

                                <input className="col form-control"
                                       value={this.state.city}
                                       onChange={this.updateCity}/>
                            </div>

                            <div className="form-row">
                                <label className="col-form-label"
                                       style={{minWidth: "350px"}}>
                                    Land:
                                </label>

                                <input className="col form-control"
                                       value={this.state.country}
                                       onChange={this.updateCountry}/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default withRouter(NewClientCompany);