import {accounting} from "accounting";

export function inputStringToNumber(input, notifactionContext) {
    if(input === null || input === undefined) {
        return ""
    }

    if(!isNaN(input)) { // Is already a number
        input = input.toString()
    }

    let resultString = input.trim()
    let usesPercent = resultString.includes("%")
    let usesEuro = resultString.includes("€")

    if(usesPercent || usesEuro) {
        resultString = resultString.substring(0, resultString.length-1).trim()
    }

    const commas = resultString.match(new RegExp(",", "g"))
    const decimalPoints = resultString.match(new RegExp("\\.", "g"))

    const numberOfCommas = commas != null ? commas.length : 0
    const numberOfDecimalPoints = decimalPoints != null ? decimalPoints.length : 0

    if(numberOfCommas > 0 && numberOfDecimalPoints > 0) {  // Mixed commas and points
        const lastIndexOfComma = resultString.lastIndexOf(",")
        const lastIndexOfDecimalPoint = resultString.lastIndexOf(".")

        if(lastIndexOfComma > lastIndexOfDecimalPoint) {   // German format
            resultString = resultString.replace(/\./g, '')
            resultString = resultString.replace(/,/g, '.')
        } else {
            resultString = resultString.replace(/,/g, '')
        }

    } else {  // Either commas or points, but not both
        if(numberOfCommas === 1) {
            const indexOfComma = resultString.lastIndexOf(',')
            const relativPositionComma = resultString.length - indexOfComma - 1

            if(relativPositionComma === 1) {
                resultString = resultString + '0'
                resultString = resultString.replace(/,/g, '.')  // comma, such as 125,9
            } else if(relativPositionComma === 2) {
                resultString = resultString.replace(/,/g, '.')  // comma, such as 125,90
            } else if(relativPositionComma === 3) {
                resultString = resultString.replace(/,/g, '')  // thoussand delimiter, such as 12,500
            } else if(relativPositionComma > 3) {
                return null   // Illegal format, such as 456,0005
            }
        } else if(numberOfCommas > 1) {
            resultString = resultString.replace(/,/g, '')
        }

        if(numberOfDecimalPoints === 1) {
            const indexOfDecimalPoint = resultString.lastIndexOf('.')
            const relativPositionDecimalPoint = resultString.length - indexOfDecimalPoint - 1
             if(relativPositionDecimalPoint === 3) {
                 resultString = resultString.replace(/\./g, '')   // thoussand delimiter, such as 12.500
            } else if(relativPositionDecimalPoint > 3) {
                return null   // Illegal format, such as 456.0005
            }
        } else if(numberOfDecimalPoints > 1) {
            resultString = resultString.replace(/\./g, '')
        }
    }

    if(isNaN(resultString)) {
        return "<Fehlerhafte Eingabe>: " + input
    } else {
        if(usesPercent) {
            return resultString + " %"
        } else if(usesEuro) {
            return resultString + " €"
        }
        return resultString
    }

}

export function formatCurrency(value) {
    const format = {
        symbol: "€",
        format: "%v %s",
        decimal: ",",
        thousand: "."
    };

    return accounting.formatMoney(value, format);
}

export function formatPercentage(number) {
    if(!number || number === "") {
        return "";
    }

    const format = {
        symbol: "%",
        format: "%v %s",
        decimal: ",",
        thousand: "."
    };

    return accounting.formatMoney(number, format);

    //return `${parseFloat(number).toFixed(2)} %`;
}

export function formatDate(timestamp) {
    if(!timestamp) {
        return "";
    }

    const date = new Date(timestamp);
    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
    };


    return date.toLocaleDateString("de-DE", options);
}


export function formatVHVpolicyNumber(policyNumber) {
    if(policyNumber.substring(1,2) === "-") {
        const cleanedNumber = policyNumber.substring(0,1) + " " + policyNumber.substring(2);
        return cleanedNumber;
    } else {
        return policyNumber;
    }
}
