export const AVAL_REQUEST_AXA = "https://entry.axa.de/nova-portal/sls/auth?cmd=auth&RequestedPage=/novaInet/inet";
export const AVAL_REQUEST_RV = "https://kredit.ruv.de";
export const AVAL_REQUEST_VHV = "https://kreditportal.vhv.de/web/start";
export const AVAL_REQUEST_EULER_HERMES = "https://mybonding.eulerhermes.com/portal/login?initialURI=/portal/Bond/";
export const AVAL_REQUEST_SWISS_RE = "https://www.swissre.com/"
export const AVAL_INSURANCE_ERGO = ""
export const AVAL_INSURANCE_ZURICH = ""
export const AVAL_INSURANCE_Bayern = ""
export const AVAL_INSURANCE_Coface = ""
export const AVAL_INSURANCE_Tokio = ""
export const AVAL_INSURANCE_CREDENDO = ""
