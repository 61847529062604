import React, {Component} from "react";
import * as Paths from "../config/paths";
import {Link} from "react-router-dom";
import {PATH_IMPRINT} from "../config/paths";
import {PATH_INFORMATION_PRIVACY} from "../config/paths";
import {PATH_CONTACT_REQUEST} from "../config/paths";
import {PATH_TERMS_OF_USE} from "../config/paths";

class Footer extends Component {


    render(){
        return (
            <div className={"footer"}>
                <div className={"container"} style={{maxWidth: "1200px"}}>
                    <div className={"row"}>
                        <div className={"col-md-4"} style={{color: "white"}}>
                            <p><b>Kontakt:</b></p>
                            <p>
                                Telefon: +49 341 - 99 28 05 0
                                <br/>
                                E-Mail: info@suedvers-fimo.de
                            </p>
                        </div>
                        <div className={"col-md-8"} style={{paddingTop: "60px"}}>
                            <span>
                                <Link to={PATH_CONTACT_REQUEST}>
                                    <button className={"btn btn-link footer-button"}>
                                        Kontaktformular
                                    </button>
                                </Link>
                                <Link to={PATH_TERMS_OF_USE}>
                                    <button className={"btn btn-link footer-button"}>
                                        Nutzungsbedingungen
                                    </button>
                                </Link>
                                <Link to={PATH_INFORMATION_PRIVACY}>
                                    <button className={"btn btn-link footer-button"}>
                                        Datenschutz
                                    </button>
                                </Link>
                                <Link to={PATH_IMPRINT}>
                                    <button className={"btn btn-link footer-button"}>
                                        Impressum
                                    </button>
                                </Link>
                            </span>
                        </div>
                    </div>
                </div>

            </div>

        )
    }

}



export default Footer;
