import React, {Component} from 'react';
import {NotificationContext} from "../../contexts/NotificationContext";
import Card from "react-bootstrap/Card";
import {withRouter} from "react-router-dom";
import {inputStringToNumber} from "../../../service/formatService";

class NewProjectDetailsSwissRe extends Component{

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            obligosSum: '',
            bondLimit: '',
            remainingBalance: ''
        };

        this.updateObligsSum = this.updateObligsSum.bind(this);
        this.updateBondLimit = this.updateBondLimit.bind(this);
        this.updateRemainingBalance = this.updateRemainingBalance.bind(this);
    }

    updateObligsSum(event){
        this.setState({
            obligosSum: event.target.value
        }, () => this.updateDetails());
    }

    updateBondLimit(event){
        this.setState({
            bondLimit: event.target.value
        }, () => this.updateDetails());
    }

    updateRemainingBalance(event){
        this.setState({
            remainingBalance: event.target.value
        }, () => this.updateDetails());
    }

    updateDetails(){
        let newDetails = {
            obligos_sum: inputStringToNumber(this.state.obligosSum),
            bond_limit: inputStringToNumber(this.state.bondLimit),
            remaining_balance: inputStringToNumber(this.state.remainingBalance)
        };

        this.props.setNewDetails(newDetails);
    }

    renderHeading(heading) {
        return (
            <div style={{marginBottom: "16px"}}>
                <h4>{heading}</h4>
            </div>
        );
    }

    render(){
        return(
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div>
                            {this.renderHeading("Basisinformationen")}

                            <Card className="form-card">
                                <form>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Gesamtobligo:</label>
                                        <input className="col form-control"
                                               value={this.state.obligosSum}
                                               onChange={this.updateObligsSum}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Limit:</label>
                                        <input className="col form-control"
                                               value={this.state.bondLimit}
                                               onChange={this.updateBondLimit}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Freies Limit::</label>
                                        <input className="col form-control"
                                               value={this.state.remainingBalance}
                                               onChange={this.updateRemainingBalance}/>
                                    </div>
                                </form>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(NewProjectDetailsSwissRe);
