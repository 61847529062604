import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import {INSURANCE_VHV} from "../../config/constants";
import PolicyDetails from "./PolicyDetails";

import {NotificationContext} from "../contexts/NotificationContext";
import ProjectCard from '../projects/ProjectCard';


import Can from "../Can";
import {formatVHVpolicyNumber} from "../../service/formatService";
import ability from '../../Ability';
import InsurancePolicyDetail from "../../models/InsurancePolicyDetail";

import {convertBackendDataToPortalData, convertProjectDetailsForDisplay} from '../../service/conversionService';
import {withRouter} from "react-router-dom";
import * as Paths from "../../config/paths";
import Error from "../Error";
import InsurancePolicy from "../../models/InsurancePolicy";
import Link from "react-router-dom/es/Link";

import {IoMdCreate, IoIosTrash, IoMdArrowRoundBack, IoMdOpen} from 'react-icons/io'
import DeleteDialog from '../DeleteDialog';


class PolicyDetailsContainer extends Component {

    static contextType = NotificationContext;

    constructor(props) {
        super(props);

        this.state = {
            clientData: props.location.clientData || null,
            projects: props.location.clientData ? props.location.clientData.policy.projects : null,
            errorResponse: null,
            showDeletionDialog: false,
            deletionText: "",
            confirmDeleteRequest: () => {}
        };
    }

    componentWillMount() {
        if (this.props.location.clientData){
            return this.loadProjectDetails();
        }

        let {id} = this.props.match.params;

        this.loadPolicyDetails(id);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props){
            let {id} = nextProps.match.params;

            this.loadPolicyDetails(id);
        }
    }

    loadPolicyDetails(id){
        return InsurancePolicyDetail.getInsurancePolicyDetail(id)
            .then(response => {
                if (response.status === 200){
                    let details = new InsurancePolicyDetail(response.body);
                    return details;
                }

                return  null;
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Details nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            })
            .then(details => {
                if (details){
                    this.getPolicy(details.insurance_policy_id)
                        .then(async (policy) => {
                            if (policy){
                                details.convertedData = await convertBackendDataToPortalData(details,
                                    policy.insurance_company.name,
                                    sessionStorage.getItem('auth_token'));

                                details.displayData = {
                                    policyNumber: policy.policy_number,
                                    insuranceName: policy.insurance_company.name
                                };

                                details.policy = policy;

                                this.setState({
                                    clientData: details,
                                    projects: policy.projects,
                                    errorResponse: null
                                }, () => {
                                    this.loadProjectDetails()
                                });
                            }
                        })
                }

                return details;
            })
    }

    getPolicy(id){
        return InsurancePolicy.getPolicy(id)
            .then(response => {
                if (response.status === 200){
                    let policy = new InsurancePolicy(response.body);

                    return policy;
                }

                return  null;
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Police nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    loadProjectDetails(){
        let projects = [];

        let promises = [];

        this.state.projects.forEach(project =>{
            promises.push(
                project.getLatestDetails()
                    .then(async (response) => {
                        if (response.status === 200){
                            console.log(response);

                            let details = new InsurancePolicyDetail(response.body);

                            project.projectDetails = details;

                            project.convertedData = await convertProjectDetailsForDisplay(details, this.state.clientData.policy);
                        }

                        projects.push(project);
                    })
                    .catch(err => {
                        console.log(err);
                    })
            )
        });

        return Promise.all(promises)
            .then(() => {
                this.setState({
                    projects: projects
                });
            });
    }

    deletePolicyDetails(event){
        event.preventDefault();

        this.state.clientData.deleteInsurancePolicyDetail()
            .then(response => {
                if (response.status === 200){
                    this.context.showMessage(null, "Stand wurde gelöscht", "success");

                    return this.props.history.goBack();
                }

                this.context.showMessage(null, "Unbekannter Fehler", "danger");
            })
            .catch(err => {
                this._handlePolicyDetailsErrors(err);
            });
    }

    _handlePolicyDetailsErrors(err){
        let showMessage = this.context.showMessage;

        if (!err.response){
            return  showMessage(null, "Verbindungsfehler", "danger");
        }

        switch (err.response.status) {
            case 403:
                return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
            case 404:
                return showMessage(null, "Die Details wurden nicht gefunden", "danger");
            case 422:
                return showMessage(null, "Überprüfen Sie Ihre Angaben", "danger");
            case 500:
                return showMessage(null, "Unbekannter Fehler", "danger");
        }
    }

    avalRequest() {
        window.open(this.state.clientData.policy.insurance_company.homepage)
    }

    render() {
        if (this.state.errorResponse){
            return <Error
                status={this.state.errorResponse.status}
                statusText={this.state.errorResponse.statusText}/>
        }

        if (!this.state.clientData){
            return  null;
        }

        const policyNumber = this.state.clientData.policy.insurance_company.name === INSURANCE_VHV
                                ? formatVHVpolicyNumber(this.state.clientData.policy.policy_number)
                                : this.state.clientData.policy.policy_number;
        return (
            <div className={"fimo-content-page"}>

                {this.state.showDeletionDialog &&
                    <DeleteDialog
                        elementText={this.state.deletionText}
                        cancel={() =>
                            this.setState({
                                showDeletionDialog: false,
                                deletionText: '',
                                confirmDeleteRequest: () => void 0
                            })
                        }
                        confirmDelete={this.state.confirmDeleteRequest}
                    />
                }

                <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                    <div className="col text-right" style={{maxWidth: "100%"}}>
                        <button className="btn btn-outline-form"
                                type="button"
                                onClick={() => this.props.history.goBack()}>
                            <div>
                                <IoMdArrowRoundBack />
                            </div>
                            Zurück
                        </button>
                        <button className="btn btn-outline-policycard" onClick={() => this.avalRequest()}>
                            <div>
                                <IoMdOpen/>
                            </div>
                            Aval
                            <br></br>
                            anfordern
                        </button>
                        <Can I="update" this={this.state.clientData}>
                            <Link to={{pathname: `${Paths.PATH_POLICY_DETAILS}/${this.state.clientData.id}/edit`,
                                details: this.state.clientData}}>
                                <button className="btn btn-outline-form">
                                    <div>
                                        <IoMdCreate/>
                                    </div>
                                    Bearbeiten
                                </button>
                            </Link>
                        </Can>
                        <Can I="destroy" this={this.state.clientData}>
                            <button className="btn btn-outline-form"
                                    onClick={(event) =>
                                        {
                                            this.setState({
                                                showDeletionDialog: true,
                                                deletionText: `Police (VSNR:${policyNumber}) der Firma ${this.state.clientData.policy.insurance_company.name}`,
                                                confirmDeleteRequest: () => this.deletePolicyDetails(event)
                                            })
                                        }
                                    }>
                                <div>
                                    <IoIosTrash/>
                                </div>
                                Löschen
                            </button>
                        </Can>
                    </div>
                </div>
                <div style={{padding: "30px"}}>
                    <div className="details-header">
                        <h4 className="text-fimo-green">{this.state.clientData.policy.insurance_company.name}</h4>
                        <h6>VSNR: {policyNumber}</h6>
                    </div>
                        {this.renderPolicyDetailsArea()}

                       {this.renderProjects()}

                </div>
            </div>
        )
    }

    renderProjects(){
        if ((!this.state.projects || this.state.projects.length === 0) &&
            ability.cannot("create", "Project")){
            return null;
        }

        const className = (this.state.projects && this.state.projects.length > 0) ? "details-header" : "";

        const padding = this.state.projects.length > 0 ? "50px" : "10px";

        return (
            <div className="container">
                <div>
                    <div className={className}>

                        {this.state.projects.length > 0 &&
                            <div style={{marginTop: padding}}>
                                <h2 className="text-fimo-green d-inline-block">Projekte</h2>
                            </div>
                        }
                    </div>

                    {this.state.projects.map(project => this.renderProject(project))}
                </div>
            </div>
        )
    }

    renderProject(project){
        return(
            <ProjectCard
                key={project.id}
                project={project}
                policy={this.state.clientData.policy}
                openEditprojectForm={this.updateShowEditProjectForm}
                openProjectDetails={this.updateShowProjectDetails}
                openProjectDetailsForm={this.updateShowNewProjectDetailsForm}/>)
    }

    renderPolicyDetailsArea() {
        return <PolicyDetails clientData={this.state.clientData}/>
    }
}



export default withRouter(PolicyDetailsContainer)
