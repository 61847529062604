import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import InsuranceCompany from "../../models/InsuranceCompany";
import {NotificationContext} from "../contexts/NotificationContext";
import {Redirect, withRouter} from "react-router-dom";

class ShowInsurance extends Component{

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            name: props.name || null,
            homepage: props.homepage || null
        };
    }

    componentWillMount() {
        let {id} = this.props.match.params;

        this.getInsurance(id);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props){
            let {id} = nextProps.match.params;

            this.getPolicy(id);
        }
    }

    getInsurance(id){
        InsuranceCompany.getInsuranceCompaniyById(id)
            .then(response => {
                if (response.status === 200){
                    let insurance = new InsuranceCompany(response.body);

                    this.setState({
                        name: insurance.name,
                        homepage: insurance.homepage,
                    });
                }
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Police nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    render(){
        return(
            <div>
                <div className={"fimo-content-page"}>
                    <div style={{padding: "30px"}}>
                        <h4 className="page-headline">Versicherer</h4>

                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <form>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Name:</label>
                                            <input className="col form-control"
                                                   value={this.state.name}/>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Link:</label>
                                            <input className="col form-control"
                                                   value={this.state.homepage}/>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(ShowInsurance);
