import React, {Component} from "react";



class TermsOfUse extends Component {

    render() {
        return (
            <div className="general-text" style={{paddingLeft: "50px", maxWidth: "1200px", paddingBottom: "50px"}}>

                <h3>Nutzungsbedingungen SÜDVERS-Kundenportal „Meine Avale Online“</h3>

                <p>Für die Nutzung des SÜDVERS Kundenportals gelten die folgenden Nutzungsbedingungen. In dem Portal stellt die SÜDVERS GMBH Assekuranzmakler dem Kunden einen Überblick über seine Kautionsversicherungsverträge zur Verfügung. Die Nutzung ist nur möglich, wenn der Kunde die Nutzungsbedingungen akzeptiert.</p>

                <h5>1. Registrierung</h5>

                <p>Voraussetzung für die Nutzung ist ein gültiger Maklervertrag des Kunden mit der SÜDVERS GMBH Assekuranzmakler. Die Registrierung erfolgt über den per E-Mail zugesandten Link. Die E-Mail wird im Vorfeld durch den Nutzer über die Website meine-avale.de/registrierung angefordert. Der Kunde kann intern – leistungsberechtigt bzw. Nutzer bleibt allein der Kunde – einen oder mehrere Mitarbeiter zur individualisierten Anmeldung berechtigen. Der berechtigte Mitarbeiter erhält ein Startpasswort und wird nach der erstmaligen Anmeldung zur Vergabe eines individuellen Passwortes aufgefordert. Der Kunde ist dafür verantwortlich, dass sich nur berechtigte Mitarbeiter anmelden und dass ausscheidenden Mitarbeitern durch Mitteilung an kautionsversicherung@suedvers.de unverzüglich der Zugang gesperrt wird. Der Kunde ist für die Geheimhaltung der individualisierten Passwörter verantwortlich und verpflichtet seine Mitarbeiter, diese entsprechend vertraulich zu behandeln.</p>

                <h5>2. Leistungen der SÜDVERS GMBH Assekuranzmakler</h5>

                <p>Die Nutzung des Portals ist kostenfrei. Die SÜDVERS GMBH Assekuranzmakler versucht, den Kunden das Kautionsportal ohne größere Ausfallszeit verfügbar zu machen. Die SÜDVERS GMBH Assekuranzmakler übernimmt keine darüber hinausgehenden Pflichten. Seitens der Nutzer besteht kein Anspruch auf eine ständige Verfügbarkeit des Dienstes. Die SÜDVERS GMBH Assekuranzmakler ist berechtigt, z.B. aus Gründen der Wartung, zur Lösung technischer Probleme oder auf Grund von Maßnahmen zur Verbesserung der Qualität des Portal-Services ausnahmsweise den Betrieb des Portals für einen zeitlich möglichst eng begrenzten Zeitraum einzuschränken. Es steht SÜDVERS GMBH Assekuranzmakler auch frei, das Kundenportal ganz abzuschaffen oder durch eine andere Lösung zu ersetzen.</p>


                <h5>3. Haftung für Pflichtverletzung von SÜDVERS GMBH Assekuranzmakler</h5>

                <p>Die Nutzung des Kunden-Portals erfolgt unter folgenden Haftungsbeschränkungen:</p>
                <p>Die SÜDVERS GMBH Assekuranzmakler haftet gegenüber dem Kunden bei Vorsatz und grober Fahr-lässigkeit nach den gesetzlichen Vorschriften. Bei leichter Fahrlässigkeit haftet die SÜDVERS GMBH Assekuranzmakler nur, wenn eine wesentliche Vertragspflicht (Kardinalspflicht) verletzt wird oder ein Fall des Verzugs oder der Unmöglichkeit vorliegt. Im Fall einer Haftung aus leichter Fahrlässigkeit wird diese Haftung auf solche Schäden begrenzt, die vorhersehbar bzw. typisch sind. Eine Haftung für das Fehlen der garantierten Beschaffenheit, wegen Arglist, für Personenschäden, Rechtsmängel, nach dem Produkthaftungsgesetz und dem Bundesdatenschutzgesetz bleibt unberührt.</p>
                <p>Die SÜDVERS GMBH Assekuranzmakler übernimmt vorbehaltlich anderer Vereinbarungen mit dem Kunden keine Gewähr für die Richtigkeit, Aktualität und Vollständigkeit der in diesem Portal zur Verfügung gestellten Inhalte und Informationen.</p>

                <p>Im Falle einer Inanspruchnahme der SÜDVERS GMBH Assekuranzmakler durch den Kunden ist ein Mitverschulden des Kunden sowie seiner Mitarbeiter angemessen zu berücksichtigen. Dies gilt insbesondere bei unzureichenden Fehlermeldungen oder unzureichender Datensicherung. Unzureichende Datensicherung liegt insbesondere dann vor, wenn der Kunde oder seine Mitarbeiter es versäumt haben, durch angemessene, dem Stand der Technik entsprechende Sicherungsmaßnahmen zu treffen, die gegen Einwirkungen von außen, insbesondere gegen Computerviren und sonstige Phänomene, die einzelne Daten oder einen gesamten Datenbestand gefährden können, schützen.</p>


                <h5>4. Abschlussbemerkung</h5>

                <p>4.1. Die SÜDVERS GMBH Assekuranzmakler behält sich vor, die Nutzungsbedingungen jederzeit ganz oder teilweise ohne gesonderte Ankündigung zu verändern oder das Angebot einzustellen. Die Nutzungsbedingungen sind selbständig auf Veränderungen zu überprüfen.</p>
                <p>4.2. Der Kunde kann Rechte aus dieser Vereinbarung nur mit schriftlicher Zustimmung der SÜDVERS GMBH Assekuranzmakler abtreten.</p>
                <p>4.3. Die SÜDVERS GMBH Assekuranzmakler ist berechtigt, Drittunternehmen mit der Erfüllung ihrer Verpflichtungen aus diesem Vertrag ganz oder teilweise zu beauftragen. Eine derartige Unterbeauftragung führt zu keiner Einschränkung der Haftung oder Verpflichtung SÜDVERS GMBH Assekuranzmakler gegenüber dem Kunden.</p>
                <p>4.4. Sollte eine oder mehrere der vorstehenden Nutzungsbedingungen unwirksam sein oder werden, so wird davon die Wirksamkeit der übrigen Bestimmungen nicht betroffen. Die unwirksame Bestimmung ist durch eine wirksame Bestimmung zu ersetzen, die den mit ihr verfolgten Zweck soweit wie möglich verwirklicht.</p><p>4.5. Nebenabreden bestehen nicht. Änderungen bedürfen der Schriftform. Gleichfalls bedarf die Aufhebung dieser Schriftformklausel der Schriftform.</p>
                <p>4.6. Es gilt ausschließlich deutsches materielles Recht unter Ausschluss des Internationalen Privatrechts.</p>
                <p>4.7. Gerichtsstand ist Freiburg.</p>

                <h5>Gender-Hinweis</h5>

                <p>Wir verwenden auf dieser Website aus Gründen eines besseren Leseflusses das generische Maskulinum. Weibliche und andere Geschlechteridentitäten werden dabei ausdrücklich mitgemeint.</p>

                <h5>Copyright</h5>

                <p>Inhalt und Aufbau der SÜDVERS Website sind urheberrechtlich geschützt. Die Vervielfältigung von Informationen oder Daten, insbesondere die Verwendung von Texten, Textteilen oder Bildmaterial bedarf der vorherigen Zustimmung.</p>

            </div>
        )
    }

}


export default TermsOfUse