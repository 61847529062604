import * as Paths from "../config/paths";
import request from "superagent";

import axaConfiguration from "../static/config/Axa";
import vhvConfiguration from "../static/config/VHV";
import swissReConfiguration from "../static/config/SwissRe";
import rvConfiguration from "../static/config/Rv";
import eulerHermesConfiguration from "../static/config/EulerHermes";
import iframeTestConfiguration from "../static/config/iframeTest";

import * as AuthenticationService from '../service/authenticationService';

/**
 * Load the local configruation files into the database.
 * @param clientCompanyId
 * @returns {Promise<any>}
 */
export async function loadConfigurationIntoDatabase(authToken) {

    if(!AuthenticationService.isUserAdmin()) {
        alert("Authorization error! Not allowed to perform this action.");
        throw 500;
    }

    const websites = await getAllWebsites(authToken);

    try {
        for (let website of websites) {
            const companyId = website.insurance_company_id;
            const websiteId = website.id;

            const configuration = getConfigurationByCompanyId(companyId);
            if (configuration) {
                postConfiguration(websiteId, companyId, website.address, configuration, authToken);
            } else {
                console.error("No configuration found for company. Company ID = ", companyId);
            }
        }
    } catch(e) {
        alert("Error! Could not insert configuration.")
        return;
    }

    alert("Konfigurationen wurden geladen.");
}


async function getAllWebsites(authToken) {
    const url = Paths.URL_INSURANCE_WEBSITES

    let response = await fetch(url, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": authToken.auth_token
        }
    });
    let data = await response.json();
    return data;
}


function postConfiguration(websiteId, companyId, address, configuration, authToken) {
    const url = Paths.URL_INSURANCE_WEBSITES + "/" + websiteId;

    const configurationObj = {
        new_insurance_company_id: companyId,
        configuration: configuration,
        url: address,
    }

    request
        .put(url)
        .set('Access-Control-Allow-Origin', '*')
        .set('accept', 'application/json')
        .set('Authorization', authToken.auth_token)
        .send(configurationObj)
        .end((err, res) => {
                if (err) {
                    console.log("Error while trying to post configuration of url= " + address + ": ", err);
                    throw 500;
                }
            }
        );

}


function getConfigurationByCompanyId(companyId) {

    switch(companyId) {
        case 1:
            return JSON.stringify(axaConfiguration);
        case 2:
            return JSON.stringify(vhvConfiguration);
        case 3:
            return JSON.stringify(swissReConfiguration);
        case 4:
            return JSON.stringify(rvConfiguration);
        case 5:
            return JSON.stringify(eulerHermesConfiguration);
        case 6:
            return JSON.stringify(iframeTestConfiguration);
        default:
            return null;
    }

}