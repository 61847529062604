import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import UpdateProject from "./UpdateProject";

class EditProject extends Component {

    render() {
        return <UpdateProject updateMode={'edit'}></UpdateProject>
    }

}

export default withRouter(EditProject);