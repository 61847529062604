import RequestState from "./RequestState";

import request from 'superagent';
import * as Paths from '../config/paths';
import User from "./User";
import ClientCompany from "./ClientCompany";

class UpdateRequest {

    static get modelName() {
        return 'UpdateRequest'
    }

    constructor(object){
        Object.assign(this, object, {request_state: new RequestState(object.request_state),
                requested_by: new User(object.requested_by),
                processed_by: new User(object.processed_by),
                client_company: new ClientCompany(object.client_company)});
    }

    /**
     * Gets a list of UpdateRequests from the server.
     *
     * @returns {Promise<any>}
     */
    static getUpdateRequests(){
        return new Promise((resolve, reject) => {
            request.get(Paths.URL_UPDATE_REQUEST)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Creates the UpdateRequest on the server.
     *
     * @returns {Promise<any>}
     */
    createUpdateRequest(){
        const url = Paths.URL_UPDATE_REQUEST;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('Access-Control-Allow-Origin', '*')
                .send(this)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Deletes the UpdateRequest from the server.
     *
     * @returns {Promise<any>}
     */
    deleteUpdateRequest(){
        const url = `${Paths.URL_UPDATE_REQUEST}/${this.id}`;

        return new Promise((resolve, reject) => {
            request.delete(url)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .parse(({body}) => body)
                .end((err, res) => {
                    if (err) {
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Sets the state of the UpdateRequest to 'in progress'.
     *
     * @returns {Promise<any>}
     */
    startUpdateRequest(){
        const url = `${Paths.URL_UPDATE_REQUEST}/${this.id}/start`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err) {
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Sets the state of the UpdateRequest to 'paused'.
     *
     * @returns {Promise<any>}
     */
    pauseUpdateRequest(){
        const url = `${Paths.URL_UPDATE_REQUEST}/${this.id}/pause`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err) {
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Sets the state of the UpdateRequest to 'completed'.
     *
     * @returns {Promise<any>}
     */
    completeUpdateRequest(){
        const url = `${Paths.URL_UPDATE_REQUEST}/${this.id}/complete`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err) {
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Sets the state of the UpdateRequest to 'cancelled'.
     *
     * @returns {Promise<any>}
     */
    cancelUpdateRequest(){
        const url = `${Paths.URL_UPDATE_REQUEST}/${this.id}/cancel`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err) {
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }
}

export default UpdateRequest;