import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import Can from '../Can';
import ClientEmployeeRequest from "../../models/ClientEmployeeRequest";
import ClientCompany from "../../models/ClientCompany";
import ClientCompanyCard from '../clientCompanies/ClientCompanyCard';
import {NotificationContext} from "../contexts/NotificationContext";
import Error from "../Error";
import {withRouter} from "react-router-dom";

import {
    IoMdArrowRoundBack,
    IoMdCheckmarkCircleOutline,
    IoIosHand,
    IoMdTrash,
    IoMdSearch
} from 'react-icons/io'
import {TAB_TITLE_BASE, TAB_TITLE_CONNECTOR} from "../../config/constants";
import ClientCompanySearchResult from "./ClientCompanySearchResult";
import DeleteDialog from '../DeleteDialog';
import { PATH_CLIENT_EMPLOYEE_REQUESTS } from '../../config/paths';

class ClientEmployeeRequestPage extends Component {

    static contextType = NotificationContext;

    constructor(props) {
        super(props);

        this.state = {
            request: null,
            request_rejection_reason: null,
            client_company_list: null,
            selectedClientCompanies: [],
            searchTerm: null,
            errorResponse: null,
            assignedCompanies: null,
            showDeletionDialog: false,
            deletionText: "",
            confirmDeleteRequest: () => {}
        };

        this.addSelectedClientCompany = this.addSelectedClientCompany.bind(this);
        this.resetSelectedClientCompany = this.resetSelectedClientCompany.bind(this);
        this.updateSearchTerm = this.updateSearchTerm.bind(this);
        this.searchClientCompany = this.searchClientCompany.bind(this);
    }

    componentWillMount() {
        let {id} = this.props.match.params;

        this.getClientEmployeeRequest(id)
        this.loadCompanies()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            let {id} = this.props.match.params;

            this.getClientEmployeeRequest(id);
            this.loadCompanies()
        }
    }

    _handleClientEmployeeRequestErrors(err) {
        let showMessage = this.context.showMessage;

        if (!err.response) {
            return showMessage(null, "Verbindungsfehler", "danger");
        }

        switch (err.response.status) {
            case 403:
                return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
            case 404:
                return showMessage(null, "Anfrage nicht gefunden", "danger");
            case 409:
                return showMessage(null, "Status konnte nicht geändert werden", "danger");
            case 500:
                return showMessage(null, "Unbekannter Fehler", "danger");
            default:
                return showMessage(null, "Unbekannter Fehler", "danger");
        }
    }

    /**
     * Gets the user from the server.
     */
    getClientEmployeeRequest(id) {
        return ClientEmployeeRequest.getClientEmployeeRequest(id)
            .then(response => {
                if (response.status === 200) {
                    let clientEmployeeRequest = new ClientEmployeeRequest(response.body);

                    this.setState({
                        request: clientEmployeeRequest,
                        request_rejection_reason: clientEmployeeRequest.request_rejection_reason,
                        errorResponse: null
                    });

                    return;
                }
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response) {
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Anfrage nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    loadCompanies = () => {
        return ClientCompany.listClientCompanies()
            .then(response => {
                let allCompanies = response.body;
                const companies = allCompanies.filter(companyObject => {
                    return this.state.request.assigned_companies.includes(companyObject.id.toString())
                })

                this.setState({
                    assignedCompanies: companies ? companies : []
                })
            })
            .catch(err => {
                this._handleClientEmployeeRequestErrors(err);
            });
    }


    _isApproveButtonDisabled() {
        return !this.state.selectedClientCompanies || this.state.selectedClientCompanies.length === 0;
    }

    updateRequestRejectionReason(event) {
        this.setState({
            request_rejection_reason: event.target.value
        });
    }

    updateSearchTerm(event) {
        this.setState({
            searchTerm: event.target.value
        });
    }

    searchClientCompany(event) {
        event.preventDefault();

        let queryValue = this.state.searchTerm ? this.state.searchTerm : this.state.request.client_company_name;

        ClientCompany.getClientCompanies(queryValue)
            .then(response => {
                let client_companies = response.body;

                this.setState({
                    client_company_list: client_companies.map(client_company => new ClientCompany(client_company))
                });
            })
            .catch(err => {
                this.setState({
                    selectedClientCompanies: [],
                    searchTerm: ""
                })
                this._handleClientEmployeeRequestErrors(err);
            });
    }

    addSelectedClientCompany(clientCompany, index) {
        const newList = this.state.selectedClientCompanies
        newList.push(clientCompany)
        this.setState({
            selectedClientCompanies: newList,
            selectedClientCompanyIndex: index
        });
    }


    approveClientEmployeeRequest() {
        if (!this.state.selectedClientCompanies || this.state.selectedClientCompanies.length === 0) {
            return this.context.showMessage(null, "Bitte wählen Sie mindestens eine Firma", "warning");
        }

        let request = this.state.request;

        const companyIds = []
        this.state.selectedClientCompanies.forEach(company => {
            companyIds.push(company.id)
        })

        request.client_company_ids = companyIds;

        request.approveClientEmployeeRequest()
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        request: new ClientEmployeeRequest(response.body),
                        client_company_list: null,
                        selectedClientCompany: null
                    }, () => {
                        this.loadCompanies()
                    });
                }
            })
            .catch(err => {
                this._handleClientEmployeeRequestErrors(err);
            });

    }

    declineClientEmployeeRequest() {
        let request = this.state.request;
        request.request_rejection_reason = this.state.request_rejection_reason;

        request.declineClientEmployeeRequest()
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        request: new ClientEmployeeRequest(response.body)
                    });
                }
            })
            .catch(err => {
                this._handleClientEmployeeRequestErrors(err);
            });
    }

    deleteClientEmployeeRequest() {
        this.state.request.deleteClientEmployeeRequest()
            .then(response => {
                if (response.status === 200) {
                    this.props.history.push(PATH_CLIENT_EMPLOYEE_REQUESTS);
                }
            })
            .catch(err => {
                this._handleClientEmployeeRequestErrors(err);
            });
    }

    addSelectedClientCompany = (clientCompany, index) => {
        const newList = this.state.selectedClientCompanies
        newList.push(clientCompany)
        this.setState({
            selectedClientCompanies: newList,
            selectedClientCompanyIndex: index
        });
    }

    resetSelectedClientCompany = (clientCompany) => {
        const newList = this.state.selectedClientCompanies.filter(company => company.id !== clientCompany.id)

        this.setState({
            selectedClientCompanies: newList,
            selectedClientCompanyIndex: null
        });
    }


    renderClientCompany(clientCompany) {

        // Previous records do not have an assigned_companies array --> Return the company name in this case
        if (!this.state.request.assigned_companies || this.state.request.assigned_companies.length === 0) {
            const clientCompany = this.state.request.client_company
            if (!clientCompany.id) {
                return;
            } else {
                return (
                    <div key={clientCompany.id}
                         style={{margin: "20px"}}>
                        <div className="card card-default">
                            <ClientCompanyCard
                                key={clientCompany.id}
                                clientCompany={clientCompany}
                                selectClientCompany={!this.state.selectedClientCompanies.includes(clientCompany) &&
                                !this.state.request.client_company.id ?
                                    () => this.addSelectedClientCompany(clientCompany) : null}
                                deselectClientCompany={this.state.selectedClientCompanies.includes(clientCompany) ?
                                    () => this.resetSelectedClientCompany(clientCompany) : null}
                            />
                        </div>
                    </div>
                );
            }
        }


        // Newer record have a list of assigned companies: Return those if available

        if (this.state.assignedCompanies === null || this.state.assignedCompanies.length === 0) {
            return <div>No content</div>
        } else {
            return (
                <div className="container-fluid">
                    <div className="row">
                        {this.state.assignedCompanies.map((clientCompany) => {
                            return <div key={clientCompany.id}
                                 style={{margin: "20px", minHeight: "100"}}>
                                <div className="card card-default" style={{padding: "10px", minHeight: "100%", display: "table"}}>
                                    <ClientCompanyCard
                                        key={clientCompany.id}
                                        clientCompany={clientCompany}
                                    />
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            )
        }

    }

    renderClientCompanyList() {
        const requestClientCompany = this.props.request && this.props.request.client_company ? this.props.request.client_company.id : null

        return <ClientCompanySearchResult
            client_company_list={this.state.client_company_list}
            selectedClientCompanies={this.state.selectedClientCompanies}
            requestClientCompanyId={requestClientCompany}
            addSelectedClientCompany={this.addSelectedClientCompany}
            resetSelectedClientCompany={this.resetSelectedClientCompany}
        />
    }

    renderClient() {
        return (
            <div>
                <h6>Name: {this.state.request.client_employee_to_add.first_name} {this.state.request.client_employee_to_add.last_name}</h6>

                <span>Email: {this.state.request.client_employee_to_add.email}<br/></span>
                <span>Telefon: {this.state.request.client_employee_to_add.phone}<br/></span>
            </div>
        );
    }

    render() {
        document.title = TAB_TITLE_BASE + TAB_TITLE_CONNECTOR + "Zugangsanfragen";

        if (this.state.errorResponse) {
            return <Error
                status={this.state.errorResponse.status}
                statusText={this.state.errorResponse.statusText}/>
        }

        if (!this.state.request) {
            return null;
        }

        return (
            <div className={"fimo-content-page"}>

                {this.state.showDeletionDialog &&
                    <DeleteDialog
                        elementText={this.state.deletionText}
                        cancel={() =>
                            this.setState({
                                showDeletionDialog: false,
                                deletionText: '',
                                confirmDeleteRequest: () => void 0
                            })
                        }
                        confirmDelete={this.state.confirmDeleteRequest}
                    />
                }

                <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                    <div className="col-2" style={{maxWidth: "100%"}}>
                        <button className="btn btn-outline-form"
                                onClick={() => this.props.history.goBack()}>
                            <div>
                                <IoMdArrowRoundBack/>
                            </div>
                            Zurück
                        </button>
                    </div>

                    <div className={"col-10 text-right"} style={{maxWidth: "100%"}}>
                        <Can I="approve" this={this.state.request}>
                            <button className="btn btn-outline-form"
                                    disabled={this._isApproveButtonDisabled()}
                                    onClick={() => this.approveClientEmployeeRequest()}>
                                <div>
                                    <IoMdCheckmarkCircleOutline/>
                                </div>
                                Zugriff erlauben

                            </button>
                        </Can>

                        <Can I="decline" this={this.state.request}>
                            <button className="btn btn-outline-form"
                                    onClick={() => this.declineClientEmployeeRequest()}>
                                <div>
                                    <IoIosHand/>
                                </div>
                                Zugriff verweigern
                            </button>
                        </Can>

                        <Can I="destroy" this={this.state.request}>
                            <button className="btn btn-outline-form"
                                    onClick={(event) =>
                                        {
                                            this.setState({
                                                showDeletionDialog: true,
                                                deletionText: `Zugangsanfrage zu ${this.state.request.client_company.company_name ?? this.state.request.client_company_name} von ${this.state.request.client_employee_to_add.first_name} ${this.state.request.client_employee_to_add.last_name} (${this.state.request.client_employee_to_add.email})`,
                                                confirmDeleteRequest: () => this.deleteClientEmployeeRequest()
                                            })
                                        }
                                    }>
                                <div>
                                    <IoMdTrash style={{color: "red"}}/>
                                </div>
                                Anfrage löschen
                            </button>
                        </Can>
                    </div>
                </div>

                <div style={{padding: "30px"}}>
                    <div className="row">
                        <div className="col">
                            <h4>Zugang zu Firma:</h4>

                            <div><b>Angegebener Name:</b> {this.state.request.client_company_name}</div>


                            <Can I="approve" this={this.state.request}>
                                <div style={{paddingTop: "20px"}}>

                                    <label><b>Manuelle Suche:</b></label>
                                    <span className={"row"}>
                                        <input className="form-control"
                                               style={{maxWidth: "200px", margin: "5px", marginLeft: "18px"}}
                                               value={this.state.searchTerm}
                                               onChange={this.updateSearchTerm}/>

                                        <button className="btn btn-outline-form-simple"
                                                style={{margin: "5px"}}
                                                onClick={this.searchClientCompany}>
                                            <div>
                                                <IoMdSearch/>
                                            </div>
                                        </button>
                                    </span>
                                </div>
                            </Can>

                            {this.renderClientCompany()}
                        </div>

                        <div className="col">
                            <h4>Beantragt von:</h4>

                            {this.renderClient()}
                        </div>
                    </div>

                    <div
                        style={{margin: "10px"}}>
                        {this.renderClientCompanyList()}
                    </div>

                    {this.state.request.request_state.request_state === 'declined' &&
                    <div style={{paddingTop: "30px"}}>
                        Ablehnungsgrund: {this.state.request.request_rejection_reason}
                    </div>
                    }

                    <Can I="decline" this={this.state.request}>
                        <div style={{paddingTop: "30px"}}>
                            <label><b>Ablehnungsgrund:</b></label>
                        </div>
                        <textarea className="form-control"
                                  style={{minHeight: "100px", margin: "5px"}}
                                  type={"text"}
                                  onChange={(event) => this.updateRequestRejectionReason(event)}/>
                    </Can>


                </div>
            </div>
        );
    }

}

export default withRouter(ClientEmployeeRequestPage);
