import React, {Component} from 'react';
import Table from 'react-bootstrap/Table';

import 'bootstrap/dist/css/bootstrap.min.css';
import InsuranceCompany from "../../models/InsuranceCompany";

import Can from '../Can';
import {NotificationContext} from "../contexts/NotificationContext";
import ability from "../../Ability";
import {Redirect, withRouter} from "react-router-dom";
import * as Paths from "../../config/paths";
import Link from "react-router-dom/es/Link";

import {IoMdTrash} from 'react-icons/io'

import {IoIosAddCircleOutline, IoIosOpen, IoMdCreate, IoMdDownload} from 'react-icons/io'
import {TAB_TITLE_BASE, TAB_TITLE_CONNECTOR} from "../../config/constants";

class InsurancesIndex extends Component {

    static contextType = NotificationContext;

    constructor(props) {
        super(props);

        this.state = {
            insuranceCompanies: null,
            insuranceCompaniesToShow: null,
        };
    }

    initData() {
        InsuranceCompany.getInsuranceCompanies()
            .then(response => {
                if (response.status === 200) {
                    let InsuranceCompanies = response.body;

                    let allCompanies = InsuranceCompanies.map(insuranceCompany => new InsuranceCompany(insuranceCompany));

                    allCompanies = allCompanies.sort((a, b) => {
                        if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
                            return -1;
                        } else if (a.name > b.name) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });

                    this.setState({
                        insuranceCompanies: allCompanies,
                        insuranceCompaniesToShow: allCompanies
                    });
                }
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response) {
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    componentDidMount() {
        this.initData();
    }


    _saveFile(blob, filename){
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";

        var url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = filename
        a.click();

        window.URL.revokeObjectURL(url);
    }

    render() {
        document.title = TAB_TITLE_BASE + TAB_TITLE_CONNECTOR + "Kundenübersicht";

        if (ability.cannot("index", "InsuranceCompany")) {

            return <Redirect to={Paths.PATH_ROOT}/>
        }

        return (
            <div className={"fimo-content-page"}>
                <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                    <div className="col text-right" style={{maxWidth: "100%"}}>
                        <Link to={Paths.PATH_INSURANCES_COMPANY_NEW}>
                            <button
                                className="btn btn-outline-form float-right">
                                <div>
                                    <IoIosAddCircleOutline />
                                </div>
                                Versicherer anlegen
                            </button>
                        </Link>
                    </div>
                </div>
                <div className={"row"} style={{backgroundColor: "white", padding: "20px", marginRight: "75px", marginLeft: "75px"}}>

                    <div style={{marginTop: "50px"}}>
                        {this.state.insuranceCompaniesToShow === null || this.state.insuranceCompaniesToShow === 0
                            ?
                            <span>Keine Firmen gefunden</span>
                            :
                            <div>
                                {this.renderClientCompaniesTable()}
                            </div>}
                    </div>
                </div>
            </div>
        )
    }

    renderClientCompaniesTable() {
        return (
            <div>
                <Table responsive>
                    <thead>
                    <tr>
                        <th>Firmenname</th>
                        <th>Id</th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.insuranceCompaniesToShow.map(insuranceCompany => this.renderInsuranteCompanyCell(insuranceCompany))}
                    </tbody>
                </Table>
            </div>
        );
    }

    HandlerDelete = async (id) => {
        if (window.confirm('Versicherungsunternehmen endgültig löschen?')) {
                const showMessage = this.context.showMessage;
                const response = await InsuranceCompany.deleteInsurance(id)

            if(response.status === 200){
                showMessage(null, "Account wurde gelöscht", "success");
                window.location.reload();
            } else {
                if (!response.response){
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (response.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Benutzer mit 'id'=:id nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Fehler beim Löschen", "danger");
                }
            }
        }
    }

    renderInsuranteCompanyCell(insuranceCompany) {
        return (
            <tr key={insuranceCompany.id}>
                <td>{insuranceCompany.name}</td>
                <td>{insuranceCompany.id}</td>
                <td>
                    <Can I="update" this={insuranceCompany}>
                        <Link to={`${Paths.PATH_INSURANCES_COMPANIES}/${insuranceCompany.id}/edit`}>
                            <button
                                className="btn btn-outline-form-slim"
                                style={{margin: "5px"}}>
                                <IoMdCreate style={{marginRight: "10px"}} />
                                Bearbeiten
                            </button>
                        </Link>
                    </Can>
                </td>
            </tr>
        );
    }
}

export default withRouter(InsurancesIndex);
