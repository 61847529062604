import Role from "./Role";

import * as Paths from "../config/paths";
import request from "superagent";

class UserRole {

    static get modelName() {
        return 'UserRole'
    }

    constructor(object){
        if (!object){
            return null;
        }

        Object.assign(this, object, {
            role: object.role ? new Role(object.role) : null
        });
    }

    /**
     * Deletes the UserRole from the server.
     * @returns {Promise<any>}
     */
    deleteUserRole(){
        const url = `${Paths.URL_USER_ROLES}/${this.id}`;

        return new Promise((resolve, reject) => {
            request.delete(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .parse(({body}) => body)
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }
}

export default UserRole;