import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import ClientEmployeeRequest from '../../models/ClientEmployeeRequest';
import Table from "react-bootstrap/Table";

import * as FormatService from '../../service/formatService';

import Can from '../Can';
import {NotificationContext} from "../contexts/NotificationContext";
import ability from "../../Ability";
import {Redirect} from "react-router-dom";
import * as Paths from "../../config/paths";
import Link from "react-router-dom/es/Link";

import {
    IoMdOpen,
    IoMdTrash
} from 'react-icons/io'
import {translateRequestState} from "../../service/languageService";
import {TAB_TITLE_BASE, TAB_TITLE_CONNECTOR} from "../../config/constants";
import DeleteDialog from '../DeleteDialog';


class ClientEmployeeRequestPortal extends Component {

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            clientEmployeeRequests: null,
            radioShowAll: false,
            clientEmployeeRequestsToShow: null,
            showDeletionDialog: false,
            deletionText: "",
            confirmDeleteRequest: () => {}
        };
    }

    componentWillMount() {
        this.initData();
    }

    initData(){
        ClientEmployeeRequest.getClientEmployeeRequests()
            .then(response => {
                if (response.status === 200){
                    let clientCompanyRequests = response.body;
                    let mappedRequests = clientCompanyRequests.map(request => new ClientEmployeeRequest(request));

                    this.setState({
                        clientEmployeeRequests: mappedRequests
                    });

                    this._executeFilter(mappedRequests);
                }
            })
            .catch(err => {
                this._handleClientEmployeeRequestErrors(err);
            });
    }

    deleteClientEmployeeRequest(request, index){
        request.deleteClientEmployeeRequest()
            .then(response => {
                if (response.status === 200){
                    window.location.reload();
                }
            })
            .catch(err => {
                alert(err.message);
            });
    }

    _handleClientEmployeeRequestErrors(err){
        let showMessage = this.context.showMessage;

        if (!err.response){
            return  showMessage(null, "Verbindungsfehler", "danger");
        }

        switch (err.response.status) {
            case 403:
                return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
            case 500:
                return showMessage(null, "Unbekannter Fehler", "danger");
            default:
                return showMessage(null, "Unbekannter Fehler", "danger");
        }
    }

    _executeFilter(requests = null) {
        let allRequests = requests ? requests : this.state.clientEmployeeRequests;

        let filteredRequests = this.state.radioShowAll ? allRequests :
            allRequests.filter(request => request.request_state.request_state === "pending");

        this.setState({
            clientEmployeeRequestsToShow: filteredRequests
        });
    }

    renderClientEmployeeRequests(request, index){
        const request_state = translateRequestState(request.request_state.request_state)

        return(
            <tr key={request.id}>
                <td className="align-middle">{request.client_company.company_name ?
                    request.client_company.company_name :
                    request.client_company_name}</td>
                <td className="align-middle">{request.client_employee_to_add.email}</td>
                <td className="align-middle">{FormatService.formatDate(request.created_at)}</td>
                <td className="align-middle">{request_state}</td>
                <td className="align-middle">{request.processed_by.email}</td>
                <td className="align-middle">{FormatService.formatDate(request.updated_at)}</td>
                <td className="align-middle">
                    <Can I="read" on={request}>
                        <Link to={`${Paths.PATH_CLIENT_EMPLOYEE_REQUESTS}/${request.id}`}>
                            <button className="btn btn-outline-form-slim">
                                <div>
                                    <IoMdOpen/>
                                </div>
                                Anzeigen
                            </button>
                        </Link>
                    </Can>
                    <Can I="destroy" on={request}>
                        <button className="btn btn-outline-form-slim"
                                onClick={(event) =>
                                    {
                                        this.setState({
                                            showDeletionDialog: true,
                                            deletionText: `Zugangsanfrage zu ${request.client_company?.company_name ?? request.client_company_name} von ${request.client_employee_to_add.first_name} ${request.client_employee_to_add.last_name} (${request.client_employee_to_add.email})`,
                                            confirmDeleteRequest: () => this.deleteClientEmployeeRequest(request, index)
                                        })
                                    }
                                }>
                            <div>
                                <IoMdTrash style={{color: "red"}}/>
                            </div>
                            Löschen
                        </button>
                    </Can>
                </td>
            </tr>
        );
    }
    
    render(){
        document.title = TAB_TITLE_BASE + TAB_TITLE_CONNECTOR + "Zugangsanfragen";

        if (ability.cannot("index", "ClientEmployeeRequest")){
            return <Redirect to={Paths.PATH_ROOT}/>
        }

        if (this.state.clientEmployeeRequestsToShow === null || this.state.clientEmployeeRequestsToShow.length === 0){
            return(
                <div className={"fimo-content-page"} style={{padding: "30px"}}>
                    <div>
                        <label>
                            <input type="radio"
                                   value={this.state.radioSshowAll}
                                   checked={this.state.radioShowAll}
                                   onClick={() => {
                                       this.setState({
                                               radioShowAll: true
                                           },
                                           () => this._executeFilter())
                                   }}
                                   onChange={() => {
                                   }}
                                   style={{marginRight: "8px"}}/>
                            Alle anzeigen
                        </label>

                        <label style={{marginLeft: "20px"}}>
                            <input type="radio"
                                   value={!this.state.radioSshowAll}
                                   checked={!this.state.radioShowAll}
                                   onClick={() => {
                                       this.setState({
                                               radioShowAll: false
                                           },
                                           () => this._executeFilter())
                                   }}
                                   onChange={() => {
                                   }}
                                   style={{marginRight: "8px"}}/>
                            ausstehend
                        </label>
                    </div>

                        Keine Anfragen vorhanden.
                </div>
            )
        }
        else {
            return (
                <div className={"fimo-content-page"} style={{padding: "30px"}}>


                    {this.state.showDeletionDialog &&
                        <DeleteDialog
                            elementText={this.state.deletionText}
                            cancel={() =>
                                this.setState({
                                    showDeletionDialog: false,
                                    deletionText: '',
                                    confirmDeleteRequest: () => void 0
                                })
                            }
                            confirmDelete={this.state.confirmDeleteRequest}
                        />
                    }

                    <div>
                        <label>
                            <input type="radio"
                                   value={this.state.radioSshowAll}
                                   checked={this.state.radioShowAll}
                                   onClick={() => {
                                       this.setState({
                                           radioShowAll: true
                                           },
                                           () => this._executeFilter())
                                   }}
                                   style={{marginRight: "8px"}}/>
                                Alle anzeigen
                        </label>

                        <label style={{marginLeft: "20px"}}>
                            <input type="radio"
                                   value={!this.state.radioSshowAll}
                                   checked={!this.state.radioShowAll}
                                   onClick={() => {
                                       this.setState({
                                               radioShowAll: false
                                           },
                                           () => this._executeFilter())
                                   }}
                                   style={{marginRight: "8px"}}/>
                            ausstehend
                        </label>
                    </div>

                    <Table responsive className="table-borderless">
                        <thead>
                            <tr>
                                <th>Firma</th>
                                <th>Angefragt von</th>
                                <th>Erstellt am</th>
                                <th>Status</th>
                                <th>Bearbeitet von</th>
                                <th>Aktualisiert am</th>
                                <th>Verfügbare Aktionen</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.clientEmployeeRequestsToShow.map((request, index) => this.renderClientEmployeeRequests(request,
                            index))}
                        </tbody>
                    </Table>

                </div>
            );
        }
    }
    
}

export default ClientEmployeeRequestPortal;
