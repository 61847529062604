import React from 'react';
import {Card} from "react-bootstrap";

import * as FormatService from '../../service/formatService';
import AbstractPolicyDetails from "./AbstractPolicyDetails";

class PolicyDetails extends AbstractPolicyDetails {

    render() {
        const data = this.props.clientData.convertedData;
        const maxLimit = data.single_limit ? FormatService.formatCurrency(data.single_limit) : "--";
        const product =  data.product_name ? data.product_name  : "--";
        const gesamtobligo =  data.obligos_sum !== null ? FormatService.formatCurrency(data.obligos_sum) : "--";
        const limit =  data.bond_limit !== null ? FormatService.formatCurrency(data.bond_limit) : "--";
        const status =  data.policy_state ? data.policy_state : "--";
        const freiesLimit =  data.remaining_balance ? FormatService.formatCurrency(data.remaining_balance) : "--";
        const zahlweise =  data.payment_type ? data.payment_type : "--";
        const collateralNominal =  data.collateralNominal ? FormatService.formatCurrency(data.collateralNominal) : "--";
        const collateralCurrent =  data.collateralCurrent ? FormatService.formatCurrency(data.collateralCurrent) : "--";
        const collateralPercent =  data.collateralPercent ? FormatService.formatPercentage(data.collateralPercent) : "--";
        const premium_rate =  data.premium_rate ? FormatService.formatPercentage(data.premium_rate)  : "--";
        const yearly_fee_obligo =  data.yearly_fee_obligo ? FormatService.formatCurrency(data.yearly_fee_obligo) : "--";

        return (
            <div className="container">
                <div className="row">
                    <div className={"col-md-6"}>
                        {this.renderHeading("Basisinformationen")}
                        <Card style={{paddingLeft: "15px"}}>
                            <table>
                                <tbody>
                                {this.renderLine("Produkt", product)}
                                {this.renderLine("Gesamtobligo", gesamtobligo) }
                                {this.renderLine("Limit", limit )}
                                {this.renderLine("Einzelstück", maxLimit )}
                                {this.renderLine("Status", status)}
                                {this.renderLine("Sicherheit Soll", collateralNominal)}
                                {this.renderLine("Sicherheit Ist", collateralCurrent)}
                                {this.renderLine("Sicherheit in %", collateralPercent)}
                                {this.renderLine("Freies Limit", freiesLimit)}
                                {this.renderLine("Zahlweise", zahlweise)}
                                {this.renderLine("Prämie in EUR", yearly_fee_obligo)}
                                {this.renderLine("Prämiensatz in %", premium_rate)}
                                </tbody>
                            </table>
                        </Card>
                    </div>
                    <div className={"col-md-6"}>
                        {this.renderHeading("Avalklassen")}
                        <React.Fragment>
                            {this.renderAvalClasses(data.avalClasses)}
                        </React.Fragment>
                    </div>
                </div>
            </div>
        )
    }


    renderAvalClasses(avalClasses_raw) {
        let avalClassElements = [];

        let avalClasses = avalClasses_raw.filter(aval => aval.obligo !== null || aval.premium_rate !== null || aval.bond_limit !== null || aval.single_limit !== null);

        for (let i = 0; i < avalClasses.length; i++) {
            const obligoValue = avalClasses[i].obligo !== null ? FormatService.formatCurrency(avalClasses[i].obligo) : "";
            const bondLimitValue = avalClasses[i].bond_limit ? FormatService.formatCurrency(avalClasses[i].bond_limit) : "";
            const singletonValue = avalClasses[i].single_limit ? FormatService.formatCurrency(avalClasses[i].single_limit) : "";
            const avalPremiumValue = avalClasses[i].premium_rate ? FormatService.formatPercentage(avalClasses[i].premium_rate) : "";

            const avalClass = (
                <Card key={`avalClass_panel${i}`} style={{marginBottom: "20px"}}>
                    <Card.Title className="card-header">
                        {avalClasses[i].name}
                    </Card.Title>
                    <Card.Body>
                        <table>
                            <tbody>
                            {this.renderLine("Obligo", obligoValue)}
                            {avalClasses[i].approved === false ? this.renderLine("Limit", "nicht genehmigt") : this.renderLine("Limit", bondLimitValue)}
                            {this.renderLine("Einzelstück", singletonValue)}
                            {this.renderLine("Avalprämie (Beitragssatz)", avalPremiumValue)}
                            </tbody>
                        </table>
                    </Card.Body>
                </Card>
            );

            avalClassElements.push(avalClass);
        }

        return avalClassElements;
    }

}


export default PolicyDetails;
