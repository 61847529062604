import React, {Component} from 'react';
import {NotificationContext} from "../contexts/NotificationContext";
import FormCheck from "react-bootstrap/FormCheck";
import QuotationRequest from "../../models/QuotationRequest";

import {IoIosSave, IoMdClose} from 'react-icons/io'
import {TAB_TITLE_BASE, TAB_TITLE_CONNECTOR} from "../../config/constants";
import Can from "../Can";
import * as Constants from "../../config/constants";
import * as Paths from "../../config/paths";

const protectionSellers = [
    "ausschließlich Bank/Sparkasse",
    "Bank/Sparkasse und Kreditversicherer",
    "ausschließlich Kreditversicherer"
]

const clientRegions = [
    "nur Deutschland",
    "EU / EFTA",
    "weltweit"
]

class NewQuotationRequest extends Component{

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            companyName: '',
            contactName: '',
            address: '',
            zipcode: '',
            city: '',
            email: '',
            phone: '',
            fax: '',
            sector: '',
            becameAwareByOptions: [
                {label: "Recherche im Internet", isChecked: false},
                {label: "Empfehlung", isChecked: false}
            ],
            recommendedBy: '',
            selectedProtectionSeller: '',
            bondsExist: '',
            existingInsuranceCompanies: '',
            currentDemandBonds: '',
            requiredBondTypes: [
                {label: "für Mängelansprüche", isChecked: false},
                {label: "Ausführung-/Vertragserfüllung", isChecked: false},
                {label: "Anzahlung", isChecked: false},
                {label: "Absicherung von Arbeitszeitkonten/Altersteilzeit", isChecked: false},
                {label: "Mietbürgschaft", isChecked: false},
                {label: "sonstige", isChecked: false}
            ],
            selectedClientRegion: '',
            jurisdiction: '',
            suedversExist: '',
            revenue: '',
            profit:'',
            totalAssets: '',
            equity: '',
            currentBusinessBacklog: '',
            expectedRevenue: '',
            expectedTotalOutput: '',
            expectedNetProfit: ''
        };

        this.updateCompanyName = this.updateCompanyName.bind(this);
        this.updateContactName = this.updateContactName.bind(this);
        this.updateAddress = this.updateAddress.bind(this);
        this.updateZipcode = this.updateZipcode.bind(this);
        this.updateCity = this.updateCity.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.updatePhone = this.updatePhone.bind(this);
        this.updateFax = this.updateFax.bind(this);
        this.updateSector = this.updateSector.bind(this);

        this.updateRecommendedBy = this.updateRecommendedBy.bind(this);
        this.updateExistingInsuranceCompanies = this.updateExistingInsuranceCompanies.bind(this);
        this.updateCurrentDemandBonds = this.updateCurrentDemandBonds.bind(this);
        this.updateJurisdiction = this.updateJurisdiction.bind(this);

        this.updateRevenue = this.updateRevenue.bind(this);
        this.updateProfit = this.updateProfit.bind(this);
        this.updateTotalAssests = this.updateTotalAssests.bind(this);
        this.updateEquity = this.updateEquity.bind(this);
        this.updateCurrentBusisnessBacklog = this.updateCurrentBusisnessBacklog.bind(this);
        this.updateExpectedRevenue = this.updateExpectedRevenue.bind(this);
        this.updateExpectedTotalOutput = this.updateExpectedTotalOutput.bind(this);
        this.updateExpectedNetProfit = this.updateExpectedNetProfit.bind(this);
    }

    updateCompanyName(event){
        this.setState({
            companyName: event.target.value
        });
    }

    updateContactName(event){
        this.setState({
            contactName: event.target.value
        });
    }

    updateAddress(event){
        this.setState({
            address: event.target.value
        });
    }

    updateZipcode(event){
        this.setState({
            zipcode: event.target.value
        });
    }

    updateCity(event){
        this.setState({
            city: event.target.value
        });
    }

    updateEmail(event){
        this.setState({
            email: event.target.value
        });
    }

    updatePhone(event){
        this.setState({
            phone: event.target.value
        });
    }

    updateFax(event){
        this.setState({
            fax: event.target.value
        });
    }

    updateSector(event){
        this.setState({
            sector: event.target.value
        });
    }

    updateBecameAwareOptions(event, index){
        let becameAwareOptions = this.state.becameAwareByOptions;

        becameAwareOptions[index].isChecked = !becameAwareOptions[index].isChecked;

        this.setState({
            becameAwareByOptions: becameAwareOptions
        });
    }

    updateRecommendedBy(event){
        this.setState({
            recommendedBy: event.target.value
        });
    }

    updateSelectedProtectionSeller(selectedProtectionSeller){
        this.setState({
            selectedProtectionSeller: selectedProtectionSeller
        });
    }

    updateBondsExist(bondsExist){
        this.setState({
            bondsExist: bondsExist
        });
    }

    updateExistingInsuranceCompanies(event){
        this.setState({
            existingInsuranceCompanies: event.target.value
        });
    }

    updateCurrentDemandBonds(event){
        this.setState({
            currentDemandBonds: event.target.value
        });
    }

    updateRequiredBondTypes(event, index){
        let requiredBondTypes = this.state.requiredBondTypes;

        requiredBondTypes[index].isChecked = !requiredBondTypes[index].isChecked;

        this.setState({
            requiredBondTypes: requiredBondTypes
        });
    }

    updateSelectedClientRegion(selectedClientRegion){
        this.setState({
            selectedClientRegion: selectedClientRegion
        });
    }

    updateJurisdiction(event){
        this.setState({
            jurisdiction: event.target.value
        });
    }
    updateSuedversExist(suedversExist){
        this.setState({
            suedversExist: suedversExist
        });
    }


    updateRevenue(event){
        this.setState({
            revenue: event.target.value
        });
    }

    updateProfit(event){
        this.setState({
            profit: event.target.value
        });
    }

    updateTotalAssests(event){
        this.setState({
            totalAssets: event.target.value
        });
    }

    updateEquity(event){
        this.setState({
            equity: event.target.value
        });
    }

    updateCurrentBusisnessBacklog(event){
        this.setState({
            currentBusinessBacklog: event.target.value
        });
    }

    updateExpectedRevenue(event){
        this.setState({
            expectedRevenue: event.target.value
        });
    }

    updateExpectedTotalOutput(event){
        this.setState({
            expectedTotalOutput: event.target.value
        });
    }

    updateExpectedNetProfit(event){
        this.setState({
            expectedNetProfit: event.target.value
        });
    }

    submitNewQuotationRequest(event){
        event.preventDefault();

        let newQuotationRequest = {
            company_name: this.state.companyName,
            contact_name: this.state.contactName,
            address: this.state.address,
            zipcode: this.state.zipcode,
            city: this.state.city,
            email: this.state.email,
            phone: this.state.phone,
            fax: this.state.fax,
            sector: this.state.sector,
            became_aware_by: this.state.becameAwareByOptions.map(option => {
                if (option.isChecked){
                    return option.label;
                }
                return  null;
            }),
            recommended_by: this.state.recommendedBy,
            current_protection_seller: this.state.selectedProtectionSeller,
            bonds_exist: this.state.bondsExist,
            existing_insurance_companies: this.state.existingInsuranceCompanies,
            current_demand_bonds: this.state.currentDemandBonds,
            required_bond_types: this.state.requiredBondTypes.map(bondType => {
                if (bondType.isChecked){
                    return bondType.label;
                }
                return null;
            }),
            client_region: this.state.selectedClientRegion,
            jurisdiction: this.state.jurisdiction,
            suedvers_exist: this.state.suedversExist,
            revenue: this.state.revenue,
            profit: this.state.profit,
            total_assets: this.state.totalAssets,
            equity: this.state.equity,
            current_business_backlog: this.state.currentBusinessBacklog,
            expected_revenue: this.state.expectedRevenue,
            expected_total_output: this.state.expectedTotalOutput,
            expected_net_profit: this.state.expectedNetProfit
        };

        QuotationRequest.createQuotationRequest(newQuotationRequest)
            .then(response => {
                if (response.status === 201){
                    this.context.showMessage(null, "Anfrage wurde abgeschickt", "success");
                    this.props.history.goBack();
                } else {
                    this.context.showMessage(null, "Unbekannter Fehler", "danger");
                }

            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 422:
                        return showMessage(null, "Überprüfen Sie Ihre Angaben", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    render(){
        document.title = TAB_TITLE_BASE + TAB_TITLE_CONNECTOR + "Angebotsanfrage";
        console.log(this.props.user)

        return(
            <div className={"fimo-content-page"} >

                <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                    <div className="col text-right" style={{maxWidth: "100%"}}>
                        <button className="btn btn-outline-form"
                                onClick={(event) => this.submitNewQuotationRequest(event)}>
                            <div>
                                <IoIosSave/>
                            </div>
                            Speichern
                        </button>
                    </div>
                </div>

                <div className="container" style={{padding: "30px"}}>
                    <div className="row">
                        <div className="col-md-6">

                            <h4 className="text-fimo-blue">Angaben zum Unternehmen</h4>

                            <form>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Firma: *</label>
                                    <input className="col form-control"
                                           value={this.state.companyName}
                                           onChange={this.updateCompanyName}/>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Ansprechpartner: *</label>
                                    <input className="col form-control"
                                           value={this.state.contactName}
                                           onChange={this.updateContactName}/>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Straße, Hausnummer: {!this.props.user && '*'}</label>
                                    <input className="col form-control"
                                           value={this.state.address}
                                           onChange={this.updateAddress}/>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Postleitzahl: {!this.props.user && '*'}</label>
                                    <input className="col form-control"
                                           value={this.state.zipcode}
                                           onChange={this.updateZipcode}/>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Ort: {!this.props.user && '*'}</label>
                                    <input className="col form-control"
                                           value={this.state.city}
                                           onChange={this.updateCity}/>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Telefon: {!this.props.user && '*'}</label>
                                    <input className="col form-control"
                                           value={this.state.phone}
                                           onChange={this.updatePhone}/>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Fax:</label>
                                    <input className="col form-control"
                                           value={this.state.fax}
                                           onChange={this.updateFax}/>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Email: {!this.props.user && '*'}</label>
                                    <input className="col form-control"
                                           value={this.state.email}
                                           onChange={this.updateEmail}/>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Branche: {!this.props.user && '*'}</label>
                                    <input className="col form-control"
                                           value={this.state.sector}
                                           onChange={this.updateSector}/>
                                </div>
                            </form>

                            <div style={{marginTop: "50px",
                                marginBottom: "50px"}} >
                                <p>Um Ihre Anfragen schnell bearbeiten zu können, bitten wir nachfolgende Fragen zu beantworten. Wenn Sie ggf. zu Details keine Angaben machen wollen, lassen Sie die Felder bitte frei. Die von Ihnen gemachten Angaben werden nur für interne Zwecke genutzt und streng vertraulich behandelt.</p>
                            </div>

                            {!this.props.user &&
                                <div className="form-group">
                                    <label className="col-form-label"><b>Wie sind Sie auf uns aufmerksam geworden?</b></label>
                                    {this.state.becameAwareByOptions.map((option, index) => {
                                        return <FormCheck
                                            key={option.label}
                                            label={option.label}
                                            onChange={(event) => this.updateBecameAwareOptions(event, index)}
                                            checked={option.isChecked}/>
                                    })}
                                </div>
                            }

                            {!this.props.user &&
                                <div className="form-group">
                                    <label className="col-form-label">Wer hat Sie empfohlen?</label>
                                    <input className="col form-control"
                                           value={this.state.recommendedBy}
                                           onChange={this.updateRecommendedBy}/>
                                </div>
                            }

                            {!this.props.user &&
                                <div className="form-group">
                                    <label className="col-form-label"><b>Welche Sicherungsgeber nutzen Sie derzeit für Bürgschaften?</b></label>
                                    {protectionSellers.map((option, index) => {
                                        return <FormCheck
                                            key={option}
                                            label={option}
                                            type="radio"
                                            onChange={() => this.updateSelectedProtectionSeller(option)}
                                            checked={option === this.state.selectedProtectionSeller}/>
                                    })}
                                </div>
                            }

                            <div className="form-group">
                                <label className="col-form-label"><b>Gibt es bestehende Bürgschafts-/Avalrahmen bei Kreditversicherern? {!this.props.user && '*'}</b></label>

                                 <FormCheck
                                        label="Nein"
                                        type="radio"
                                        onChange={() => this.updateBondsExist(false)}
                                        checked={!this.state.bondsExist && this.state.bondsExist !== ''}/>

                                <FormCheck
                                    label="Ja"
                                    type="radio"
                                    onChange={() => this.updateBondsExist(true)}
                                    checked={this.state.bondsExist}/>
                            </div>

                            <div className="form-group">
                                <label className="col-form-label">Wenn ja, bei welchen Kreditversicherern? {!this.props.user && '*'}</label>
                                <textarea className="col form-control"
                                       disabled={!this.state.bondsExist}
                                       value={this.state.existingInsuranceCompanies}
                                       onChange={this.updateExistingInsuranceCompanies}/>
                            </div>

                            <div className="form-group">
                                <label className="col-form-label"><b>Wie hoch ist aktuell Ihr Gesamtbedarf an Bürgschaften/Avalen? *</b></label>
                                <input className="col form-control"
                                       value={this.state.currentDemandBonds}
                                       onChange={this.updateCurrentDemandBonds}/>
                            </div>

                            <div className="form-group">
                                <label className="col-form-label"><b>Welche Bürgschafts-/Avalarten benötigen Sie im täglichen Geschäft? *</b></label>
                                {this.state.requiredBondTypes.map((bondType, index) => {
                                    return <FormCheck
                                        key={bondType.label}
                                        label={bondType.label}
                                        onChange={(event) => this.updateRequiredBondTypes(event, index)}
                                        checked={bondType.isChecked}/>
                                })}
                            </div>

                            <div className="form-group">
                                <label className="col-form-label"><b>Benötigen Sie Bürgschaften/Avale für Auftraggeber mit Sitz außerhalb von Deutschland? {!this.props.user && '*'}</b></label>
                                {clientRegions.map((clientRegion, index) => {
                                    return <FormCheck
                                        key={clientRegion}
                                        label={clientRegion}
                                        type="radio"
                                        onChange={() => this.updateSelectedClientRegion(clientRegion)}
                                        checked={clientRegion === this.state.selectedClientRegion}/>
                                })}
                            </div>

                            <div className="form-group">
                                <label className="col-form-label">Bei EU/EFTA bzw Weltweit - welches Recht und welcher Gerichtsstand soll gelten? {!this.props.user && '*'}</label>
                                <textarea className="col form-control"
                                          value={this.state.jurisdiction}
                                          onChange={this.updateJurisdiction}/>
                            </div>

                            <div className="form-group">
                                <label className="col-form-label"><b>SÜDVERS vorliegend? *</b></label>

                                <FormCheck
                                    label="Nein"
                                    type="radio"
                                    onChange={() => this.updateSuedversExist(false)}
                                    checked={!this.state.suedversExist && this.state.suedversExist !== ''}/>

                                <FormCheck
                                    label="Ja"
                                    type="radio"
                                    onChange={() => this.updateSuedversExist(true)}
                                    checked={this.state.suedversExist}/>
                            </div>

                            {(!this.state.suedversExist) &&
                                <div>
                                    <div style={{marginTop: "50px",
                                        marginBottom: "50px"}} >
                                        <p>Zur weiteren Bearbeitung Ihrer Anfrage benötigen wir noch betriebswirtschaftliche Informationen zum Unternehmen</p>
                                    </div>

                                    <h4 className="text-fimo-blue">Angaben zum abgelaufenen Geschäftsjahr</h4>

                                    <form>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Umsatz: *</label>
                                            <input className="col form-control"
                                                   value={this.state.revenue}
                                                   onChange={this.updateRevenue}/>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Ergebnis: *</label>
                                            <input className="col form-control"
                                                   value={this.state.profit}
                                                   onChange={this.updateProfit}/>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Bilanzsumme: *</label>
                                            <input className="col form-control"
                                                   value={this.state.totalAssets}
                                                   onChange={this.updateTotalAssests}/>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Eigenkapital: *</label>
                                            <input className="col form-control"
                                                   value={this.state.equity}
                                                   onChange={this.updateEquity}/>
                                        </div>
                                    </form>

                                    <h4 className="text-fimo-blue">Angaben zum laufenden Geschäft</h4>

                                    <form>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Auftragsbestand aktuell: *</label>
                                            <input className="col form-control"
                                                   value={this.state.currentBusinessBacklog}
                                                   onChange={this.updateCurrentBusisnessBacklog}/>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">erwarteter Umsatz: *</label>
                                            <input className="col form-control"
                                                   value={this.state.expectedRevenue}
                                                   onChange={this.updateExpectedRevenue}/>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">erwartete Gesamtleistung: *</label>
                                            <input className="col form-control"
                                                   value={this.state.expectedTotalOutput}
                                                   onChange={this.updateExpectedTotalOutput}/>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">erwarteter Jahresüberschuss: *</label>
                                            <input className="col form-control"
                                                   value={this.state.expectedNetProfit}
                                                   onChange={this.updateExpectedNetProfit}/>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NewQuotationRequest;