import React, {Component} from 'react';

import CasehandlerPortal from "./CasehandlerPortal";

import PolicyDetailsContainer from "./policyDetails/PolicyDetailsContainer";
import PolicyPortal from './policyPortal/PolicyPortal';

import * as Constants from "../config/constants";
import * as DataService from "../service/dataService";


/**
 * Main component to render the insurance policies view.
 */
class PortalContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            policyDetailsToShow: null,
            clientCompanyName: null,
            clientCompany: null,
            clientCompanies: props.clientCompanies,
            clientDataList: null
        }

        this.updateCompany = this.updateCompany.bind(this);
        this.setPolicyDetailsToShow = this.setPolicyDetailsToShow.bind(this);
        this.closePolicyDetails = this.closePolicyDetails.bind(this);
        this.resetClientData = this.resetClientData.bind(this);
        this.renderPolicyPortal = this.renderPolicyPortal.bind(this);

        this.initClientData();
    }

    async initClientData(){

        if(this.props.user && this.props.user !== Constants.USER_FIMO && this.props.clientCompanies) {
            for(let company of this.props.clientCompanies) {
                const clientDataList = await this.loadClientData(company.id);
                if(clientDataList && clientDataList.length > 0) {
                    this.setState({
                        clientDataList: clientDataList
                    });
                }
            }
        }
    }


    async updateCompany(clientCompany, clientCompanyName) {
        const clientCompanyIdList = [clientCompany.id];
        const clientDataList = await this.loadClientData(clientCompanyIdList);   // Fetch client data for the new company
        this.setState({
            clientDataList: clientDataList,
            clientCompanyName: clientCompanyName,
            clientCompany: clientCompany
        })
    }


    setPolicyDetailsToShow(policyDetailsObj) {
        this.setState( {
            policyDetailsToShow: policyDetailsObj
        });
    }

    closePolicyDetails() {
        this.setState({
            policyDetailsToShow: null
        })
    }

    resetClientData() {
        if (this.state.clientDataList) {
            this.setState({
                clientDataList: null
            });
        }
    }

    async loadClientData(companyId) {
        try {
            //console.log("auth token:", sessionStorage.getItem('auth_token'));
            const clientDataList = await DataService.getClientData([companyId], sessionStorage.getItem('auth_token'));
            return [clientDataList];
        } catch(e) {
            console.log("Error:", e)
            alert("Network Connection Error after Load Client Data!");
        }
    }

    async componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.user && nextProps.user !== Constants.USER_FIMO && nextProps.clientCompanies) {
            for(let company of nextProps.clientCompanies) {
                const clientDataList = await this.loadClientData(company.id);
                if(clientDataList && clientDataList.length > 0) {
                    this.setState({
                        clientDataList: clientDataList
                    });
                }
            }
        }
    }


    render() {
        if(this.props.user === Constants.USER_FIMO) {
            if(!this.state.policyDetailsToShow) {
                // return this.renderCasehandlerView();
                return  null;
            } else {
                return this.renderPolicyDetails();
            }
        } else if(this.props.user === Constants.USER_CLIENT) {
            if(!this.state.clientDataList || this.state.clientDataList.length == 0) {
                return this.renderNoDataAvailable();
            }

            if(!this.state.policyDetailsToShow) {
                return (
                    <div>
                        {this.renderPolicyPortal(this.state.clientDataList)}
                        {this.props.closeClientCompany ?
                            (<div style={{width: "250px", margin: "auto", marginBottom: "15px"}}>
                                <button className="btn btn-primary" style={{marginTop: "60px"}}
                                    onClick={this.props.closeClientCompany}>
                                    Zurück zur Übersicht
                                </button>
                            </div>) :
                            (<div/>)
                        }
                    </div>
                    );
            } else {
                return this.renderPolicyDetails();
            }
        } else {
            return <div>Interner Fehler (Benutzerart nicht vorhanden)</div>
        }
    }


    renderCasehandlerView() {
        return (
            <div>
                <CasehandlerPortal authToken={this.props.authToken}
                                   clientCompanyName={this.state.clientCompanyName}
                                   clientCompany={this.state.clientCompany}
                                   clientDataList={this.state.clientDataList}
                                   setPolicyDetailsToShow={this.setPolicyDetailsToShow}
                                   renderPolicyPortal={this.renderPolicyPortal}
                                   updateCompany={this.updateCompany}
                                   renderPolicyPortal={this.renderPolicyPortal}
                                   resetClientData={this.resetClientData}
                                  />
            </div>
        );
    }


    renderPolicyPortal(clientDataList) {
        let policies = [];
        for(let clientData of clientDataList) {
            policies.push(
                <div>
                    <div className={"d-flex justify-content-start flex-wrap"}>
                        <PolicyPortal policyData={clientData}
                                      setPolicyDetailsToShow={this.setPolicyDetailsToShow}
                                      clientCompany={this.state.clientCompany ? this.state.clientCompany :
                                        this.props.clientCompanies[0]}
                        />
                    </div>
                </div>
            );
        }

        return policies;
    }

    renderPolicyDetails() {
        return (
            <div>
                <PolicyDetailsContainer clientData={this.state.policyDetailsToShow}
                                        closePolicyDetails={this.closePolicyDetails}
                />
            </div>
        );
    }


    renderNoDataAvailable() {
        return (
            <div style={{padding: "20px"}}>
                Es sind keine Daten zum Anzeigen vorhanden.
            </div>
        );
    }
}


export default PortalContainer;
