import request from 'superagent';
import * as Paths from '../config/paths';

class ClientCompany {

    static get modelName() {
        return 'ClientCompany'
    }

    constructor(object) {
        Object.assign(this, object)
    }

    /**
     * Gets all ClientCompanies accessible by the user
     *
     * @returns {Promise<any>}
     */
    static getClientCompanies(company_name){
        return new Promise((resolve, reject) => {
            request.get(Paths.URL_CLIENT_COMPANIES)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .query({company_name: company_name})
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Gets the ClientCompany with the specified ID from the server.
     *
     * @param id The ID of the ClientCompany
     * @returns {Promise<any>}
     */
    static getClientCompany(id){
        const url = `${Paths.URL_CLIENT_COMPANIES}/${id}`;

        return new Promise((resolve, reject) => {
            request.get(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Gets all ClientCompanies regardless of the access permissions of the user.
     *
     * @returns {Promise<any>}
     */
    static listClientCompanies(){
        return new Promise((resolve, reject) => {
            request.get(Paths.URL_CLIENT_COMPANIES + '/list')
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Creates the ClientCompany on the server.
     *
     * @returns {Promise<any>}
     */
    createClientCompany(){
        const url = Paths.URL_CLIENT_COMPANIES;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('Access-Control-Allow-Origin', '*')
                .send(this)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Updates the ClientCompany with the given details.
     *
     * @param updatedClientCompanyDetails The new details for the ClientCompany
     */
    updateClientCompany(updatedClientCompanyDetails){
        const url = `${Paths.URL_CLIENT_COMPANIES}/${this.id}`;

        return new Promise((resolve, reject) => {
            request.put(url)
                .set('Access-Control-Allow-Origin', '*')
                .send(updatedClientCompanyDetails)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Deletes the ClientCompany.
     *
     * @returns {Promise<any>}
     */
    deleteClientCompany(){
        const url = `${Paths.URL_CLIENT_COMPANIES}/${this.id}`;

        return new Promise((resolve, reject) => {
            request.delete(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .parse(({body}) => body)
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Gets all insurance policies associated with the ClientCompany.
     *
     * @returns {Promise<any>}
     */
    getInsurancePolicies(){
        const url = `${Paths.URL_CLIENT_COMPANIES}/${this.id}/insurance_policies`;

        return new Promise((resolve, reject) => {
            request.get(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    getPoliciesAsExcel(){
        const url = `${Paths.URL_CLIENT_COMPANIES}/${this.id}${Paths.PATH_INSURANCE_POLICIES_AS_EXCEL}`

        return new Promise((resolve, reject) => {
            request.get(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .responseType('blob')
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }
}

export default ClientCompany;