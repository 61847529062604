import React, {Component} from 'react';
import {NotificationContext} from "../contexts/NotificationContext";
import Can from "../Can";

import ability from '../../Ability';
import UserRole from "../../models/UserRole";

import Table from 'react-bootstrap/Table';
import Select from "react-select";
import Role from "../../models/Role";
import User from "../../models/User";
import ClientEmployee from '../../models/ClientEmployee';
import Error from "../Error";
import * as UserService from "../../service/userService";

import {
    IoIosSave,
    IoMdClose,
    IoMdEye,
    IoMdEyeOff,
    IoMdTrash,
    IoMdPersonAdd,
    IoMdInformationCircle
} from 'react-icons/io'
import {TAB_TITLE_BASE, TAB_TITLE_CONNECTOR} from "../../config/constants";
import ClientEmployeesPage from "./ClientEmployeesPage";
import DeleteDialog from '../DeleteDialog';

const MENU_USER_INFORMATION = "1";
const MENU_CHANGE_PASSWORD = "2";
const MENU_ACCOUNT_STATE = "3";
const MENU_USER_ROLES = "4";
const MENU_COMPANIES = "5";


class UserPage extends Component {

    static contextType = NotificationContext;

    constructor(props) {
        super(props);

        this.state = {
            user: null,
            title: null,
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            newPassword: '',
            passwordConfirmation: '',
            userRoles: [],
            rolesSelectList: [],
            roleToAdd: null,
            clientEmployees: [],
            selectedTab: MENU_USER_INFORMATION,
            errorResponse: null,
            showDeletionDialog: false,
            deletionText: "",
            confirmDeleteRequest: () => {}
        };

        this.updateTitle = this.updateTitle.bind(this);
        this.updateFirstName = this.updateFirstName.bind(this);
        this.updateLastName = this.updateLastName.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.updatePhone = this.updatePhone.bind(this);

        this.updateNewPassword = this.updateNewPassword.bind(this);
        this.updatePasswordConfirmation = this.updatePasswordConfirmation.bind(this);
        this.updateRoleToAdd = this.updateRoleToAdd.bind(this);
        this.arePasswordsEqual = this.arePasswordsEqual.bind(this);
        this.isSubmitChangePasswordEnabled = this.isSubmitChangePasswordEnabled.bind(this);
    }

    componentWillMount() {
        let {id} = this.props.match.params;

        this.getUser(id)
            .then(() => {

                if (ability.can("index", "UserRole")) {
                    this.getUserRoles();
                }

                if (ability.can("index", "Role")) {
                    this.getRoles();
                }

                if (ability.can('index', 'ClientEmployee')) {
                    this.getClientEmployees();
                }
            });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            let {id} = nextProps.match.params;

            this.getUser(id)
                .then(() => {

                    if (ability.can("index", "UserRole")) {
                        this.getUserRoles();
                    }

                    if (ability.can("index", "Role")) {
                        this.getRoles();
                    }

                    if (ability.can('index', 'ClientEmployee')) {
                        this.getClientEmployees();
                    }
                });
        }
    }

    showTabContent() {
        switch (this.state.selectedTab) {
            case MENU_USER_INFORMATION:
                return this.renderUser();
            case MENU_CHANGE_PASSWORD:
                return this.renderChangePasswordForm();
            case MENU_ACCOUNT_STATE:
                return this.renderAccountState();
            case MENU_USER_ROLES:
                return this.renderUserRoles();
            case MENU_COMPANIES:
                return this.renderClientEmployees();
            default:
                return;
        }
    }

    switchTab(tabName) {
        this.setState({
            selectedTab: tabName
        })
    }

    updateTitle(event) {
        this.setState({
            title: event.target.value
        });
    }

    updateFirstName(event) {
        this.setState({
            firstName: event.target.value
        });
    }

    updateLastName(event) {
        this.setState({
            lastName: event.target.value
        });
    }

    updateEmail(event) {
        this.setState({
            email: event.target.value
        });
    }

    updatePhone(event) {
        this.setState({
            phone: event.target.value
        });
    }

    updateNewPassword(event) {
        this.setState({
            newPassword: event.target.value
        });
    }

    updatePasswordConfirmation(event) {
        this.setState({
            passwordConfirmation: event.target.value
        });
    }

    updateRoleToAdd(selectedOption) {
        this.setState({
            roleToAdd: selectedOption.value
        });
    }

    arePasswordsEqual() {
        return this.state.newPassword && this.state.passwordConfirmation && this.state.newPassword !== "" &&
            this.state.passwordConfirmation !== "" && this.state.newPassword === this.state.passwordConfirmation
    }

    isSubmitChangePasswordEnabled() {
        return !this.arePasswordsEqual();
    }

    /**
     * Gets the user from the server.
     */
    getUser(id) {
        return User.getUser(id)
            .then(response => {
                if (response.status === 200) {
                    let user = new User(response.body);

                    this.setState({
                        user: user,
                        title: user.title,
                        firstName: user.first_name,
                        lastName: user.last_name,
                        email: user.email,
                        phone: user.phone,
                        errorResponse: null
                    });

                    return;
                }
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response) {
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Benutzer nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    /**
     * Gets the roles associated with the user from the server.
     *
     * @returns {Promise<any | never>}
     */
    getUserRoles() {
        return this.state.user.getUserRoles()
            .then(response => {
                if (response.status === 200) {
                    let userRoles = response.body.map(userRole => new UserRole(userRole));

                    this.setState({
                        userRoles: userRoles
                    });

                    return;
                }
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response) {
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Benutzer nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    /**
     * Gets all roles from the server.
     */
    getRoles() {
        Role.getRoles()
            .then(response => {
                if (response.status === 200) {
                    let rolesSelectList = [];

                    response.body.forEach(role => {
                        rolesSelectList.push({
                            label: role.role_name,
                            value: new Role(role)
                        });
                    });

                    this.setState({
                        rolesSelectList: rolesSelectList
                    });

                    return;
                }
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response) {
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    /**
     * Gets all ClientEmplyoee relationships associated with the user from the server.
     */
    getClientEmployees = () => {
        let query = {
            user_id: this.state.user.id
        };

        ClientEmployee.getClientEmployees(query)
            .then(response => {
                if (response.status === 200) {
                    let clientEmployees = response.body.map(clientEmployee => new ClientEmployee(clientEmployee));

                    this.setState({
                        clientEmployees: clientEmployees
                    });

                    return;
                }
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response) {
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    updateUser(event) {
        event.preventDefault();

        let showMessage = this.context.showMessage;

        let updatedUserData = {
            title: this.state.title,
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
            phone: this.state.phone
        };

        this.state.user.updateUser(updatedUserData)
            .then(response => {
                if (response.status === 200) {
                    showMessage(null, "Nutzerinformationen aktualisiert", "success");

                    let user = new User(response.body);

                    this.setState({
                        user: user,
                        title: user.title,
                        firstName: user.first_name,
                        lastName: user.last_name,
                        email: user.email,
                        phone: user.phone
                    });

                    return;
                }

                showMessage(null, "Unbekannter Fehler", "danger");
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response) {
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Nutzer nicht gefunden", "danger");
                    case 422:
                        return showMessage(null, "Überprüfen Sie Ihre Angaben", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    resetUserData(event) {
        event.preventDefault();

        this.setState({
            title: this.state.user.title,
            firstName: this.state.user.first_name,
            lastName: this.state.user.last_name,
            email: this.state.user.email,
            phone: this.state.user.phone
        });
    }

    /**
     * Sends the new password to the server and updates the auth_token in the session store.
     *
     * @param event
     */
    submitNewPassword(event) {
        event.preventDefault();

        let showMessage = this.context.showMessage;

        this.state.user.changePassword(this.state.newPassword)
            .then(response => {
                if (response.status === 200) {
                    sessionStorage.setItem('auth_token', response.body.auth_token);

                    return showMessage(null, "Passwort wurde geändert", "success");
                }

                showMessage(null, "Unbekannter Fehler", "danger");
            })
            .catch(err => {
                if (!err.response) {
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 401:
                        return showMessage(null, "Fehler beim Login", "danger");
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 422:
                        return showMessage(null, "Überprüfen Sie Ihre Angaben", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    /**
     * Adds the specified role to the user
     *
     * @param event
     */
    addRoleToUser(event) {
        event.preventDefault();

        let showMessage = this.context.showMessage;

        this.state.user.addRole(this.state.roleToAdd)
            .then(response => {
                if (response.status === 201) {
                    showMessage(null, "Rolle hinzugefügt", "success");

                    return this.getUserRoles();
                }

                showMessage(null, "Unbekannter Fehler", "danger");
            })
            .catch(err => {

                if (!err.response) {
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Benutzer oder Rolle nicht gefunden", "danger");
                    case 409:
                        return showMessage(null, "Benutzer verfügt brereits über die Rolle", "danger");
                    case 422:
                        return showMessage(null, "Überprüfen Sie Ihre Angaben", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    /**
     * Removes the specified role from the user.
     *
     * @param event
     * @param userRole The role to remove.
     */
    removeRoleFromUser(event, userRole) {
        event.preventDefault();

        let showMessage = this.context.showMessage;

        userRole.deleteUserRole()
            .then(response => {
                if (response.status === 200) {
                    showMessage(null, "Rolle entfernt", "success");

                    return this.getUserRoles();
                }

                showMessage(null, "Unbekannter Fehler", "danger");
            })
            .catch(err => {
                if (!err.response) {
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Rolle nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    /**
     * Enables the user's account.
     *
     * @param event
     */
    enableUserAccount(event) {
        event.preventDefault();

        let showMessage = this.context.showMessage;

        this.state.user.enableUser()
            .then(response => {
                if (response.status === 200) {
                    showMessage(null, "Account wurde aktiviert", "success");

                    return this.getUser(this.state.user.id);
                }
            })
            .catch(err => {
                console.log(err);

                if (!err.response) {
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Rolle nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    /**
     * Disables the user's account.
     *
     * @param event
     */
    disableUserAccount(event) {
        event.preventDefault();

        let showMessage = this.context.showMessage;

        this.state.user.disableUser()
            .then(response => {
                if (response.status === 200) {
                    showMessage(null, "Account wurde deaktiviert", "success");

                    return this.getUser(this.state.user.id);
                }
            })
            .catch(err => {
                console.log(err);

                if (!err.response) {
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Rolle nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }


    renderUser() {
        if (ability.can("update", this.state.user)) {
            return (
                <div>
                    <div className="container" style={{marginLeft: "20px", marginTop: "15px"}}>
                        <div className="row">
                            <div className="col-md-6">
                                {this.renderUserUpdateForm()}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            {this.renderUserFromNoUpdate()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    renderUserUpdateForm() {
        return (
            <div>
                <form>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Titel:</label>
                        <input className="col form-control"
                               value={this.state.title}
                               onChange={this.updateTitle}/>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Vorname:</label>
                        <input className="col form-control"
                               value={this.state.firstName}
                               onChange={this.updateFirstName}/>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Nachname:</label>
                        <input className="col form-control"
                               value={this.state.lastName}
                               onChange={this.updateLastName}/>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Email:</label>
                        <input className="col form-control"
                               value={this.state.email}
                               onChange={this.updateEmail}/>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Telefon:</label>
                        <input className="col form-control"
                               value={this.state.phone}
                               onChange={this.updatePhone}/>
                    </div>
                </form>

                <div className="text-right"
                     style={{paddingTop: "20px"}}>
                    <button className="btn btn-outline-form"
                            onClick={(event) => this.updateUser(event)}>
                        <div>
                            <IoIosSave style={{paddingRight: "10px"}}/>
                        </div>
                        Speichern
                    </button>

                    <button className="btn btn-outline-form"
                            style={{marginLeft: "20px"}}
                            onClick={event => this.resetUserData(event)}>
                        <div>
                            <IoMdClose style={{paddingRight: "10px"}}/>
                        </div>
                        Abbrechen
                    </button>

                    <button className="btn btn-outline-form"
                            style={{marginLeft: "20px"}}
                            onClick={(event) =>
                                {
                                    this.setState({
                                        showDeletionDialog: true,
                                        deletionText: `Nutzer ${this.state.user.first_name} ${this.state.user.last_name} (${this.state.user.email})`,
                                        confirmDeleteRequest: () => UserService.deleteUserAccount(event, this.state.user,
                                            this.context.showMessage, () => {this.props.history.goBack()})
                                    })
                                }
                            }>
                        <div>
                            <IoMdTrash style={{paddingRight: "10px"}}/>
                        </div>
                        Löschen
                    </button>
                </div>
            </div>
        )
    }


    renderUserFromNoUpdate() {
        return (
            <Table className="table-borderless">
                <tbody>
                <tr>
                    <td>Titel:</td>
                    <td>{this.state.user.title}</td>
                </tr>
                <tr>
                    <td>Vorname:</td>
                    <td>{this.state.user.first_name}</td>
                </tr>
                <tr>
                    <td>Nachname:</td>
                    <td>{this.state.user.last_name}</td>
                </tr>
                <tr>
                    <td>Email:</td>
                    <td>{this.state.user.email}</td>
                </tr>
                <tr>
                    <td>Telefon:</td>
                    <td>{this.state.user.phone}</td>
                </tr>
                </tbody>
            </Table>
        )
    }


    renderChangePasswordForm() {
        return (
            <div>
                <div className="container" style={{marginLeft: "20px", marginTop: "15px"}}>
                    <div className="row">
                        <div className="col-md-6">
                            <form>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Passwort:</label>
                                    <input className="col form-control"
                                           type="password"
                                           value={this.state.newPassword}
                                           onChange={this.updateNewPassword}/>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Passwort bestätigen:</label>
                                    <input className="col form-control"
                                           type="password"
                                           value={this.state.passwordConfirmation}
                                           onChange={this.updatePasswordConfirmation}/>
                                </div>
                            </form>

                            <div
                                className="text-right"
                                style={{margin: "10px"}}>
                                <button className="btn btn-outline-form"
                                        style={{margin: "20px"}}
                                        disabled={this.isSubmitChangePasswordEnabled()}
                                        onClick={(event) => this.submitNewPassword(event)}>
                                    <div>
                                        <IoIosSave style={{paddingRight: "10px"}}/>
                                    </div>
                                    Speichern
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderUserRole(userRole) {
        return (
            <tr key={userRole.id}>
                <td className="align-middle" style={{fontWeight: "bolder"}}>{userRole.role.role_name}</td>
                <Can I="destroy" this={userRole}>
                    <td>
                        <button
                            className="btn btn-outline-form-slim"
                            onClick={(event) => this.removeRoleFromUser(event, userRole)}>
                            <div>
                                <IoMdClose/>
                            </div>
                            Entfernen
                        </button>
                    </td>
                </Can>
                <Can I="read" this={userRole}>
                    <td>
                        <button
                            className="btn btn-outline-form-slim">
                            <div class="permission-tooltip">
                                <IoMdInformationCircle/>
                                Berechtigungen
                                <span class="tooltipText">{this.renderRolePermissions(userRole.role)}</span>
                            </div>
                        </button>
                    </td>
                </Can>
            </tr>
        );
    }

    renderUserRoles() {
        return (
            <div>
                <div className="container" style={{marginLeft: "20px", marginTop: "15px"}}>
                    <div className="row">
                        <div className="col-md-5"
                             style={{border: "1px solid #dddddd", borderRadius: "8px", marginRight: "60px"}}>
                            <Table className="table-borderless">
                                <thead>
                                <tr>
                                    <th>Rolle</th>
                                    <th>Verfügbare Aktionen</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.userRoles.map(userRole => this.renderUserRole(userRole))}
                                </tbody>
                            </Table>
                        </div>

                        <Can I="create" on="UserRole">
                            <div className="col-md-5">
                                <h6>Benutzerrollen:</h6>

                                <form>
                                    <div className="form-group">
                                        <div>
                                            <Select
                                                className={"w-50"}
                                                options={this.state.rolesSelectList}
                                                ref={(input) => {
                                                    this.clientInput = input;
                                                }}
                                                default=""
                                                autoFocus={true}
                                                onChange={this.updateRoleToAdd}/>
                                        </div>
                                        <div className={"w-50 text-center"} style={{paddingTop: "15px"}}>
                                            <button
                                                className="btn btn-outline-form"
                                                disabled={!this.state.roleToAdd}
                                                onClick={(event) => this.addRoleToUser(event)}>
                                                <div>
                                                    <IoMdPersonAdd/>
                                                </div>
                                                Rolle Hinzufügen
                                            </button>
                                        </div>
                                        <div className={"w-50 text-center"}>
                                            <button
                                                className="btn btn-outline-form-slim"
                                                disabled={!this.state.roleToAdd}>
                                                <div class="permission-tooltip">
                                                    <div>
                                                        <IoMdInformationCircle/>
                                                    </div>
                                                    Berechtigungen
                                                    {this.state.roleToAdd &&
                                                        <span class="tooltipText">{this.renderRolePermissions(this.state.roleToAdd)}</span>
                                                    }
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Can>
                    </div>
                </div>
            </div>
        );
    }

    renderRolePermissions(role) {
        if (!role) {
            return null
        }
        const uniqueRolePermissionArray = role.permissions.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
        return (
            <Table className="table-borderless" style={{color: "white", marginBottom: 0}}>
                <thead>
                <tr style={{position: "sticky", top: "0px", zIndex: 999, backgroundColor: "#555"}}>
                    <th>Aktion</th>
                    <th>Element</th>
                </tr>
                </thead>
                <tbody>
                {uniqueRolePermissionArray.map((permission, i) => {
                    const rolePermission = permission.role_permissions.find(rp => rp.role_id === role.id);
                    const hasCondition = rolePermission ? Object.keys(rolePermission.condition).length > 0 : false;
                    return(
                        <tr style={{paddingBottom: "20px"}}>
                            <td style={{padding: "1px 0 0 12px"}}>{permission.action}</td>
                            <td style={{padding: "1px 12px 0 12px"}}>{permission.subject_class}{hasCondition ? "*" : ""}</td>
                        </tr>
                    )
                })}
                <tr style={{position: "sticky", bottom: "0px", zIndex: 999, backgroundColor: "#555"}}>
                    <td colSpan="2" style={{padding: "12px"}}>Mit * markierte Berechtigungen haben eine zusätzliche Bedingung</td></tr>
                </tbody>
            </Table>
        )
    }

    renderAccountState() {
        return (
            <div>
                <div className="container" style={{marginLeft: "20px", marginTop: "15px"}}>
                    <div className="row">
                        <div className="col-md-12">
                            <div>
                                <b>Aktueller Status:</b>
                                <span style={{
                                    marginLeft: "20px",
                                    marginRight: "40px"
                                }}>{this.state.user.account_state.account_state}</span>

                                <Can I="enable" this={this.state.user}>
                                    <button className="btn btn-outline-form"
                                            style={{marginLeft: "12px"}}
                                            onClick={(event) => this.enableUserAccount(event)}>
                                        <div>
                                            <IoMdEye/>
                                        </div>
                                        Aktivieren
                                    </button>
                                </Can>

                                <Can I="disable" this={this.state.user}>
                                    <button className="btn btn-outline-form"
                                            style={{marginLeft: "12px"}}
                                            onClick={(event) => this.disableUserAccount(event)}>
                                        <div>
                                            <IoMdEyeOff/>
                                        </div>
                                        Deaktivieren
                                    </button>
                                </Can>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderClientEmployees = () => {
        return <ClientEmployeesPage clientEmployees={this.state.clientEmployees}
                                    user={this.state.user}
                                    getClientEmployees={this.getClientEmployees}
                                    {...this.props}
        />
    }

    render() {
        document.title = TAB_TITLE_BASE + TAB_TITLE_CONNECTOR + "Profil";
        const buttonWidth = "150px"

        if (this.state.errorResponse) {
            return <Error
                status={this.state.errorResponse.status}
                statusText={this.state.errorResponse.statusText}/>
        }

        if (!this.state.user) {
            return null;
        }

        const activeTab = this.state.selectedTab;

        const classesUserInformation = activeTab === MENU_USER_INFORMATION ? "btn btn-link-menu-selected" : "btn btn-link-menu";
        const classesChangePassword = activeTab === MENU_CHANGE_PASSWORD ? "btn btn-link-menu-selected" : "btn btn-link-menu";
        const classesAccountState = activeTab === MENU_ACCOUNT_STATE ? "btn btn-link-menu-selected" : "btn btn-link-menu";
        const classesUserRoles = activeTab === MENU_USER_ROLES ? "btn btn-link-menu-selected" : "btn btn-link-menu";
        const classesCompanies = activeTab === MENU_COMPANIES ? "btn btn-link-menu-selected" : "btn btn-link-menu";

        return (

            <div className={"fimo-content-page"} style={{padding: "30px"}}>

                {this.state.showDeletionDialog &&
                    <DeleteDialog
                        elementText={this.state.deletionText}
                        cancel={() =>
                            this.setState({
                                showDeletionDialog: false,
                                deletionText: '',
                                confirmDeleteRequest: () => void 0
                            })
                        }
                        confirmDelete={this.state.confirmDeleteRequest}
                    />
                }

                {this.props.closeUserPage &&
                <button
                    className="btn btn-primary"
                    onClick={this.props.closeUserPage}>
                    Zurück</button>
                }

                <h2 className="page-headline">{this.state.user.first_name} {this.state.user.last_name}</h2>

                <div className="btn-group flex-wrap" role="group" selectedIndex="0"
                     style={{paddingTop: "30px", paddingBottom: "25px"}}>

                    <button className={classesUserInformation}
                            select="true"
                            style={{minWidth: buttonWidth, outline: "1px !important"}}
                            onClick={this.switchTab.bind(this, MENU_USER_INFORMATION)}>
                        Nutzerinformationen
                    </button>

                    <Can I="change_password" this={this.state.user}>
                        <button className={classesChangePassword}
                                style={{minWidth: buttonWidth, outline: "1px !important"}}
                                onClick={this.switchTab.bind(this, MENU_CHANGE_PASSWORD)}>
                            Passwort ändern
                        </button>
                    </Can>

                    <button className={classesAccountState}
                            style={{minWidth: buttonWidth}}
                            onClick={this.switchTab.bind(this, MENU_ACCOUNT_STATE)}>
                        Account Status
                    </button>

                    <Can I="index" on="UserRole">
                        <button className={classesUserRoles}
                                style={{minWidth: buttonWidth}}
                                onClick={this.switchTab.bind(this, MENU_USER_ROLES)}>
                            Benutzerrollen
                        </button>
                    </Can>

                    <Can I="index" on="ClientEmployee">
                        <button className={classesCompanies}
                                style={{minWidth: buttonWidth}}
                                onClick={this.switchTab.bind(this, MENU_COMPANIES)}>
                            Firmen
                        </button>
                    </Can>
                </div>

                <div style={{marginTop: "30px", marginLeft: "12px"}}>
                    {this.showTabContent()}
                </div>

            </div>
        );
    }
}

export default UserPage;
