import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import InsuranceCompany from "../../models/InsuranceCompany";
import {NotificationContext} from "../contexts/NotificationContext";
import {Redirect, withRouter} from "react-router-dom";

import {IoIosSave, IoMdClose} from 'react-icons/io'

class EditInsurance extends Component{

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            insuranceCompany: null,
            insurance_company_name: props.name || null,
            homepage: null,
            id: props.id || null,
        };

        this.updateInsuranceName = this.updateInsuranceName.bind(this);
        this.updateInsuranceLink = this.updateInsuranceLink.bind(this);
    }

    componentWillMount() {
         let {id} = this.props.match.params;

         this.getInsurance(id);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props){
            let {id} = nextProps.match.params;

            this.getInsurance(id);
        }
    }

    getInsurance(id){
        InsuranceCompany.getInsuranceCompaniyById(id)
            .then(response => {
                if (response.status === 200){
                    let insurance = new InsuranceCompany(response.body);

                    this.setState({
                        insuranceCompany: insurance,
                        insurance_company_name: insurance.name,
                        homepage: insurance.homepage,
                        id: insurance.id
                    });
                }
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Police nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    updateInsuranceName(event){
        this.setState({
            insurance_company_name: event.target.value,
        });
    }
    updateInsuranceLink(event){
        this.setState({
            homepage: event.target.value,
        });
    }

    submitInsuranceCompany(event) {
        event.preventDefault();

        let updatedInsuranceCompany = {
            insurance_company_name: this.state.insurance_company_name,
            homepage: this.state.homepage,
            id: this.state.id
        };


        this.state.insuranceCompany.updateInsuranceCompany(updatedInsuranceCompany)
            .then(response => {
                if (response.status === 200) {
                    this.context.showMessage(null, "Versicherer wurde aktualisiert", "success");

                    this.setState({
                        name: new InsuranceCompany(response.body)
                    }, () => this.props.history.goBack());
                } else {
                    this.context.showMessage(null, "Unbekannter Fehler", "danger");
                }
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response) {
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Die Firma wurde nicht gefunden", "danger");
                    case 422:
                        return showMessage(null, "Überprüfen Sie Ihre Angaben", "danger");
                    case 500:
                        if (err.response.body.errors === "Validation failed: Customer number has already been taken") {
                            return showMessage(null, "Kundennummer bereits vergeben", "danger");
                        }

                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    render(){
        return(
            <div>
                <div className={"fimo-content-page"}>
                    <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                        <div className="col text-right" style={{maxWidth: "100%"}}>
                            <button className="btn btn-outline-form"
                                    onClick={(event) => this.submitInsuranceCompany(event)}>
                                <div>
                                    <IoIosSave/>
                                </div>
                                Speichern
                            </button>
                            <button className="btn btn-outline-form"
                                    type="button"
                                    onClick={() => this.props.history.goBack()}>
                                <div>
                                    <IoMdClose style={{color: " red"}}/>
                                </div>
                                Abbrechen
                            </button>
                        </div>
                    </div>
                    <div style={{padding: "30px"}}>
                        <h4 className="page-headline">Versicherer bearbeiten</h4>

                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <form>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Name:</label>
                                            <input className="col form-control"
                                                   value={this.state.insurance_company_name}
                                                   onChange={this.updateInsuranceName}/>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Link:</label>
                                            <input className="col form-control"
                                                   value={this.state.homepage}
                                                   placeholder= "http://www.web.com"
                                                   onChange={this.updateInsuranceLink}/>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(EditInsurance);
