import React, {Component} from 'react';
import Table from 'react-bootstrap/Table';

import * as FormatService from '../../service/formatService';

import UpdateRequest from '../../models/UpdateRequest';

import Can from '../Can';
import {NotificationContext} from "../contexts/NotificationContext";
import ability from "../../Ability";
import {Redirect} from "react-router-dom";
import * as Paths from "../../config/paths";

import {IoIosArrowForward, IoMdExit, IoMdTrash, IoIosHourglass, IoMdClose} from 'react-icons/io'
import {translateRequestState} from "../../service/languageService";
import {TAB_TITLE_BASE, TAB_TITLE_CONNECTOR} from "../../config/constants";
import DeleteDialog from '../DeleteDialog';


class UpdateRequestPortal extends Component {

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            updateRequests: null,
            showDeletionDialog: false,
            deletionText: "",
            confirmDeleteRequest: () => {}
        }

        this.deleteUpdateRequest = this.deleteUpdateRequest.bind(this);
    }

    componentDidMount() {
        this.initData();
    }

    initData(){
        UpdateRequest.getUpdateRequests().then(response =>{
            if (response.status === 200){
                let updateRequests = response.body;

                this.setState({
                    updateRequests: updateRequests.map(request => new UpdateRequest(request))
                });
            }
        }).catch(err => {
            this._handleUpdateRequestErrors(err);
        });
    }

    deleteUpdateRequest(updateRequest, index){
        updateRequest.deleteUpdateRequest().then(response => {
            if (response.status === 200){
                let updateRequests = this.state.updateRequests;
                updateRequests.splice(index, 1);

                this.context.showMessage(null, "Aktualisierungsanfrage gelöscht", "success");

                this.setState({
                    updateRequests: updateRequests,
                    showDeletionDialog: false,
                    deletionText: "",
                    confirmDeleteRequest: () => {}
                });
            }
        }).catch(err => {
            this._handleUpdateRequestErrors(err);
        });
    }

    startUpdateRequest(updateRequest, index){
        updateRequest.startUpdateRequest().then(response => {
            if (response.ok){
                let updatedRequest = new UpdateRequest(response.body);
                let updateRequests = this.state.updateRequests;
                updateRequests[index] = updatedRequest;

                this.setState({
                    updateRequests: updateRequests
                });
            }
        }).catch(err => {
            this._handleUpdateRequestErrors(err);
        });
    }

    pauseUpdateRequest(updateRequest, index){
        updateRequest.pauseUpdateRequest().then(response => {
            if (response.ok){
                let updatedRequest = new UpdateRequest(response.body);
                let updateRequests = this.state.updateRequests;
                updateRequests[index] = updatedRequest;

                this.setState({
                    updateRequests: updateRequests
                });
            }
        }).catch(err => {
            this._handleUpdateRequestErrors(err);
        });
    }

    completeUpdateRequest(updateRequest, index){
        updateRequest.completeUpdateRequest().then(response => {
            if (response.ok){
                let updatedRequest = new UpdateRequest(response.body);
                let updateRequests = this.state.updateRequests;
                updateRequests[index] = updatedRequest;

                this.setState({
                    updateRequests: updateRequests
                });
            }
        }).catch(err => {
            this._handleUpdateRequestErrors(err);
        });
    }

    cancelUpdateRequest(updateRequest, index){
        updateRequest.cancelUpdateRequest().then(response => {
            if (response.ok){
                let updatedRequest = new UpdateRequest(response.body);
                let updateRequests = this.state.updateRequests;
                updateRequests[index] = updatedRequest;

                this.setState({
                    updateRequests: updateRequests
                });
            }
        }).catch(err => {
            this._handleUpdateRequestErrors(err);
        });
    }

    _handleUpdateRequestErrors(err){
        let showMessage = this.context.showMessage;

        if (!err.response){
            return  showMessage(null, "Verbindungsfehler", "danger");
        }

        switch (err.response.status) {
            case 403:
                return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
            case 404:
                return showMessage(null, "Aktualisierungsanfrage nicht gefunden", "danger");
            case 409:
                return showMessage(null, "Status kann nicht geändert werden", "danger");
            case 500:
                return showMessage(null, "Unbekannter Fehler", "danger");
        }
    }

    renderUpdateRequest(updateRequest, index){
        const request_state = translateRequestState(updateRequest.request_state.request_state);

        return(
            <tr className="border-bottom" key={updateRequest.id}>
                <td className="align-middle">{updateRequest.client_company.company_name}</td>
                <td className="align-middle">{updateRequest.requested_by.email}</td>
                <td className="align-middle">{FormatService.formatDate(updateRequest.created_at)}</td>
                <td className="align-middle">{request_state}</td>
                <td className="align-middle">{updateRequest.processed_by.email}</td>
                <td className="align-middle">{FormatService.formatDate(updateRequest.updated_at)}</td>
                <td className="align-middle">
                    <Can I="start" this={updateRequest}>
                        <button className="btn btn-outline-table"
                                onClick={() => this.startUpdateRequest(updateRequest, index)}>
                            <div>
                                <IoIosArrowForward />
                            </div>
                            Vorgang<br></br>starten
                        </button>
                    </Can>
                    <Can I="pause" this={updateRequest}>
                        <button className="btn btn-outline-table"
                                onClick={() => this.pauseUpdateRequest(updateRequest, index)}>
                            <div>
                                <IoIosHourglass />
                            </div>
                            Vorgang<br></br>pausieren
                        </button>
                    </Can>
                    <Can I="complete" this={updateRequest}>
                        <button className="btn btn-outline-table"
                                onClick={() => this.completeUpdateRequest(updateRequest, index)}>
                            <div>
                                <IoMdExit />
                            </div>
                            Vorgang<br></br>abschließen
                        </button>
                    </Can>
                    <Can I="cancel" this={updateRequest}>
                        <button className="btn btn-outline-table"
                                onClick={() => this.cancelUpdateRequest(updateRequest, index)}>
                            <div>
                                <IoMdClose style={{color: "red"}}/>
                            </div>
                            Vorgang<br></br>abbrechen
                        </button>
                    </Can>
                    <Can I="destroy" this={updateRequest}>
                        <button className="btn btn-outline-table"
                                onClick={(event) =>
                                    {
                                        this.setState({
                                            showDeletionDialog: true,
                                            deletionText: `Aktualisierungsanfrage zu ${updateRequest.client_company.company_name} von ${updateRequest.requested_by.first_name} ${updateRequest.requested_by.last_name} (${updateRequest.requested_by.email})`,
                                            confirmDeleteRequest: () => this.deleteUpdateRequest(updateRequest, index)
                                        })
                                    }
                                }>
                            <div>
                                <IoMdTrash style={{color: "red"}}/>
                            </div>
                            Vorgang<br></br>löschen
                        </button>
                    </Can>
                </td>
            </tr>

        );
    }

    render(){
        document.title = TAB_TITLE_BASE + TAB_TITLE_CONNECTOR + "Aktualisierungsanfragen";

        if (ability.cannot("read", "UpdateRequest")){
            return <Redirect to={Paths.PATH_ROOT}/>
        }

        return(
            <div className={"fimo-content-page"} style={{padding: "30px"}}>

                {this.state.showDeletionDialog &&
                    <DeleteDialog
                        elementText={this.state.deletionText}
                        cancel={() =>
                            this.setState({
                                showDeletionDialog: false,
                                deletionText: '',
                                confirmDeleteRequest: () => void 0
                            })
                        }
                        confirmDelete={this.state.confirmDeleteRequest}
                    />
                }

                <div>
                    <h4 className="page-headline">Aktualisierungsanfragen</h4>
                </div>

                {this.state.updateRequests && this.state.updateRequests.length > 0 ?
                    <div>
                        <Table responsive className="table-borderless">
                            <thead>
                                <tr>
                                    <th>Firma</th>
                                    <th>Angefragt von</th>
                                    <th>Erstellt am</th>
                                    <th>Status</th>
                                    <th>Bearbeitet von</th>
                                    <th>Aktualisiert am</th>
                                    <th>Verfügbare Aktionen</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.updateRequests.map((updateRequest, index) => this.renderUpdateRequest(updateRequest,
                                index))}
                            </tbody>
                        </Table>
                    </div>
                    :
                    <div>
                        <span>keine Anfragen gefunden</span>
                    </div>
                }
            </div>
        );
    }

}

export default UpdateRequestPortal;