import React, {Component} from 'react';
import Card from "react-bootstrap/Card";
import {withRouter} from "react-router-dom";
import {inputStringToNumber} from "../../../service/formatService";

class EditProjectDetailsSwissRe extends Component {

    constructor(props){
        super(props);

        this.state = {
            details: props.project.projectDetails,
            obligosSum: props.project.convertedData.obligos_sum,
            bondLimit: props.project.convertedData.bond_limit,
            remainingBalance: props.project.convertedData.remaining_balance
        };

        this.updateObligsSum = this.updateObligsSum.bind(this);
        this.updateBondLimit = this.updateBondLimit.bind(this);
        this.updateRemainingBalance = this.updateRemainingBalance.bind(this);
    }

    updateObligsSum(event){
        this.setState({
            obligosSum: event.target.value
        }, () => this.updateNewDetails());
    }

    updateBondLimit(event){
        this.setState({
            bondLimit: event.target.value
        }, () => this.updateNewDetails());
    }

    updateRemainingBalance(event){
        this.setState({
            remainingBalance: event.target.value
        }, () => this.updateNewDetails());
    }

    updateNewDetails() {
        let newDetails = {
            obligosSum: inputStringToNumber(this.state.obligosSum),
            bondLimit: inputStringToNumber(this.state.bondLimit),
            remainingBalance: inputStringToNumber(this.state.remainingBalance)
        };

        this.props.updateNewDetails(newDetails);
    }

    renderHeading(heading) {
        return (
            <div style={{marginBottom: "16px"}}>
                <h4>{heading}</h4>
            </div>
        );
    }

    render(){
        return(
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div>
                            {this.renderHeading("Basisinformationen")}

                            <Card className="form-card">
                                <form>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Gesamtobligo:</label>
                                        <input className="col form-control"
                                               value={this.state.obligosSum}
                                               onChange={this.updateObligsSum}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Limit:</label>
                                        <input className="col form-control"
                                               value={this.state.bondLimit}
                                               onChange={this.updateBondLimit}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Freies Limit::</label>
                                        <input className="col form-control"
                                               value={this.state.remainingBalance}
                                               onChange={this.updateRemainingBalance}/>
                                    </div>
                                </form>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(EditProjectDetailsSwissRe);
