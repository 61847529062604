import * as Paths from "../config/paths";
import request from "superagent";

class ContactRequest {

    static get modelName() {
        return 'ContactRequest'
    }

    constructor(object) {
        Object.assign(this, object)
    }

    /**
     * Creates the ContactRequest on the server.
     *
     * @returns {Promise<any>}
     */
    createContactRequest(){
        const url = Paths.URL_CONTACT_REQUEST;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('Access-Control-Allow-Origin', '*')
                .send(this)
                .set('accept', 'application/json')
                .parse(({body}) => body)
                .end((err, res) =>{
                    console.log("err", err);
                    console.log("resr", res);
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

}

export default ContactRequest;