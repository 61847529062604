import * as Paths from "../config/paths";
import request from "superagent";
import User from "./User";
import ClientCompany from "./ClientCompany";
import AccountState from "./AccountState";

class ClientEmployee {

    static get modelName() {
        return 'ClientEmployee'
    }

    constructor(object){
        if (!object){
            return null;
        }

        Object.assign(this, object, {
            user: object.user ? new User(object.user) : null,
            client_company: object.client_company ? new ClientCompany(object.client_company) : null,
            account_state: object.account_state ? new AccountState(object.account_state) : null
        });
    }

    /**
     * Gets the the ClientEmployees from the server
     * @returns {Promise<any>}
     */
    static getClientEmployees(query){
        const url = Paths.URL_CLIENT_EMPLOYEES;

        return new Promise((resolve, reject) => {
            request.get(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .query(query)
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Enables the ClientEmployee relationship to give the User access to the ClientCompany
     *
     * @returns {Promise<any>}
     */
    enableClientEmployee(){
        const url = `${Paths.URL_CLIENT_EMPLOYEES}/${this.id}${Paths.PATH_ENABLE}`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Disables the ClientEmployee relationship to deny the User access to the ClientCompany
     *
     * @returns {Promise<any>}
     */
    disableClientEmployee(){
        const url = `${Paths.URL_CLIENT_EMPLOYEES}/${this.id}${Paths.PATH_DISABLE}`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Deleted the ClientEmployee relationship from the server.
     *
     * @returns {Promise<any>}
     */
    deleteClientEmployee(){
        const url = `${Paths.URL_CLIENT_EMPLOYEES}/${this.id}`;

        return new Promise((resolve, reject) => {
            request.delete(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .parse(({body}) => body)
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }


    /**
     * Approves the ClientEmployeeRequest and grants the client access to the ClientCompany.
     *
     * @returns {Promise<any>}
     */
    createClientEmployee(){
        const url = `${Paths.URL_CLIENT_EMPLOYEES}`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .send(this)
                .end((err, res) => {
                    if (err) {
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }
}

export default ClientEmployee;
