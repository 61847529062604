import React, {Component} from 'react';
import Card from "react-bootstrap/Card";
import {NotificationContext} from "../../contexts/NotificationContext";
import {withRouter} from "react-router-dom";
import {inputStringToNumber} from "../../../service/formatService";

class EditProjectDetailsRuV extends Component{

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            details: props.project.projectDetails,
            obligosSum: props.project.convertedData.obligos_sum,
            bondLimit: props.project.convertedData.bond_limit,
            remainingBalance: props.project.convertedData.remaining_balance,
            avalClasses: props.project.convertedData.avalClasses,
            avalClassesToRemove: []
        };

        this.updateObligosSum = this.updateObligosSum.bind(this);
        this.updateBondLimit = this.updateBondLimit.bind(this);
        this.updateRemainingBalance = this.updateRemainingBalance.bind(this);

        this.addAvalClass = this.addAvalClass.bind(this);
        this.removeAvalClass = this.removeAvalClass.bind(this);

        this.updateAvalClassName = this.updateAvalClassName.bind(this);
        this.updateAvalClassAvalClass = this.updateAvalClassAvalClass.bind(this);
        this.updateAvalClassObligo = this.updateAvalClassObligo.bind(this);
        this.updateAvalClassBondLimit = this.updateAvalClassBondLimit.bind(this);
        this.updateAvalClassSingleLimit = this.updateAvalClassSingleLimit.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.project !== this.props.project){
            this.setState({
                details: nextProps.project.projectDetails,
                obligosSum: nextProps.project.convertedData.obligos_sum,
                bondLimit: nextProps.project.convertedData.bond_limit,
                remainingBalance: nextProps.project.convertedData.remaining_balance,
                avalClasses: nextProps.project.convertedData.avalClasses,
                avalClassesToRemove: []
            });
        }
    }

    updateObligosSum(event){
        this.setState({
            obligosSum: event.target.value
        }, () => this.updateNewDetails());
    }

    updateBondLimit(event){
        this.setState({
            bondLimit: event.target.value
        }, () => this.updateNewDetails());
    }

    updateRemainingBalance(event){
        this.setState({
            remainingBalance: event.target.value
        }, () => this.updateNewDetails());
    }

    addAvalClass(){
        this.setState({
            avalClasses: this.state.avalClasses.concat({
                name: '',
                avalClass: '',
                obligo: '',
                bond_limit: '',
                single_limit: '',
                premium_rate: ''
            })
        }, () => this.updateNewDetails());
    }

    removeAvalClass(index){
        let avalClasses = this.state.avalClasses;

        let avalClassesToRemove = this.state.avalClassesToRemove;

        avalClassesToRemove.push(avalClasses[index]);

        avalClasses.splice(index, 1);

        this.setState({
            avalClasses: avalClasses,
            avalClassesToRemove: avalClassesToRemove
        }, () => this.updateNewDetails());
    }

    updateAvalClassName(event, avalClass, index){
        avalClass.name = event.target.value;

        let avalClasses = this.state.avalClasses;
        avalClasses[index] = avalClass;

        this.setState({
            avalClasses: avalClasses
        }, () => this.updateNewDetails());
    }

    updateAvalClassAvalClass(event, avalClass, index){
        avalClass.aval_class = event.target.value;

        let avalClasses = this.state.avalClasses;
        avalClasses[index] = avalClass;

        this.setState({
            avalClasses: avalClasses
        }, () => this.updateNewDetails());
    }

    updateAvalClassObligo(event, avalClass, index){
        avalClass.obligo = event.target.value;

        let avalClasses = this.state.avalClasses;
        avalClasses[index] = avalClass;

        this.setState({
            avalClasses: avalClasses
        }, () => this.updateNewDetails());
    }

    updateAvalClassBondLimit(event, avalClass, index){
        avalClass.bond_limit = event.target.value;

        let avalClasses = this.state.avalClasses;
        avalClasses[index] = avalClass;

        this.setState({
            avalClasses: avalClasses
        }, () => this.updateNewDetails());
    }

    updateAvalClassSingleLimit(event, avalClass, index){
        avalClass.single_limit = event.target.value;

        let avalClasses = this.state.avalClasses;
        avalClasses[index] = avalClass;

        this.setState({
            avalClasses: avalClasses
        }, () => this.updateNewDetails());
    }

    updateNewDetails() {
        let newDetails = {
            remainingBalance: inputStringToNumber(this.state.remainingBalance),
            obligosSum: inputStringToNumber(this.state.obligosSum),
            bondLimit: inputStringToNumber(this.state.bondLimit),
            avalClasses: this.state.avalClasses,
            avalClassesToRemove: this.state.avalClassesToRemove
        };

        this.props.updateNewDetails(newDetails);
    }

    renderHeading(heading) {
        return (
            <div
                className="d-inline-block"
                style={{marginBottom: "16px"}}>
                <h4>{heading}</h4>
            </div>
        );
    }

    renderAvalClassForm(avalClass, index){
        return(
            <Card key={index}
                  style={{marginBottom: "20px"}}>
                <Card.Title className="card-header">
                    <button className="btn btn-danger float-right"
                            onClick={() => this.removeAvalClass(index)}>
                        Entfernen
                    </button>
                    <form>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Avalklasse:</label>
                            <input className="col form-control"
                                   value={avalClass.aval_class}
                                   onChange={(event) => this.updateAvalClassAvalClass(event, avalClass, index)}/>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Klassenname:</label>
                            <textarea className="col form-control"
                                      value={avalClass.name}
                                      onChange={(event) => this.updateAvalClassName(event, avalClass, index)}/>
                        </div>
                    </form>
                </Card.Title>
                <Card.Body>
                    <form>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Obligo:</label>
                            <input className="col form-control"
                                   value={avalClass.obligo}
                                   onChange={(event) => this.updateAvalClassObligo(event, avalClass, index)}/>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Limit:</label>
                            <input className="col form-control"
                                   value={avalClass.bond_limit}
                                   onChange={(event) => this.updateAvalClassBondLimit(event, avalClass, index)}/>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Einzelstück:</label>
                            <input className="col form-control"
                                   value={avalClass.single_limit}
                                   onChange={(event) => this.updateAvalClassSingleLimit(event, avalClass, index)}/>
                        </div>
                    </form>
                </Card.Body>
            </Card>
        );
    }

    render(){
        return(
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div>
                            {this.renderHeading("Basisinformationen")}

                            <Card className="form-card">
                                <form>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Gesamtobligo:</label>
                                        <input className="col form-control"
                                               value={this.state.obligosSum}
                                               onChange={this.updateObligosSum}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Limit:</label>
                                        <input className="col form-control"
                                               value={this.state.bondLimit}
                                               onChange={this.updateBondLimit}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Freies Limit:</label>
                                        <input className="col form-control"
                                               value={this.state.remainingBalance}
                                               onChange={this.updateRemainingBalance}/>
                                    </div>
                                </form>
                            </Card>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div>
                            {this.renderHeading("Avalklassen")}

                            <button
                                className="btn btn-primary float-right"
                                onClick={this.addAvalClass}>Hinzufügen</button>
                        </div>

                        {this.state.avalClasses.map((avalClass, index) => this.renderAvalClassForm(avalClass, index))}
                    </div>

                </div>
            </div>
        );
    }

}

export default withRouter(EditProjectDetailsRuV);
