import React, {Component} from 'react';
import {NotificationContext} from "../../contexts/NotificationContext";
import Card from "react-bootstrap/Card";
import {withRouter} from "react-router-dom";
import {inputStringToNumber} from "../../../service/formatService";

class NewProjectDetailsVHV extends Component{

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            productName: undefined,
            policyState: undefined,
            yearlyFeeObligo: undefined,
            paymentType: undefined,
            collateralNominal: undefined,
            obligosSum: undefined,
            bondLimit: undefined,
            singleLimit: undefined
        }

        this.updateProductName = this.updateProductName.bind(this);
        this.updatePolicyState = this.updatePolicyState.bind(this);
        this.updateCollateralNominal = this.updateCollateralNominal.bind(this);
        this.updateYearlyFeeObligo = this.updateYearlyFeeObligo.bind(this);
        this.updatePaymentType = this.updatePaymentType.bind(this);

        this.updateObligosSum = this.updateObligosSum.bind(this);
        this.updateBondLimit = this.updateBondLimit.bind(this);
        this.updateSingleLimit = this.updateSingleLimit.bind(this);

    }

    updateProductName(event){
        this.setState({
            productName: event.target.value
        }, () => this.updateDetails());
    }

    updatePolicyState(event){
        this.setState({
            policyState: event.target.value
        }, () => this.updateDetails());
    }

    updateYearlyFeeObligo(event){
        this.setState({
            yearlyFeeObligo: event.target.value
        }, () => this.updateDetails());
    }

    updateCollateralNominal(event){
        this.setState({
            collateralNominal: event.target.value
        }, () => this.updateDetails());
    }

    updatePaymentType(event){
        this.setState({
            paymentType: event.target.value
        }, () => this.updateDetails());
    }

    updateObligosSum(event){
        this.setState({
            obligosSum: event.target.value
        }, () => this.updateDetails());
    }

    updateBondLimit(event){
        this.setState({
            bondLimit: event.target.value
        }, () => this.updateDetails());
    }

    updateSingleLimit(event){
        this.setState({
            singleLimit: event.target.value
        }, () => this.updateDetails());
    }

    updateDetails(event){
        let avalPremia = [];
        let collaterals = [];

        avalPremia.push({
            yearly_fee_obligo: inputStringToNumber(this.state.yearlyFeeObligo),
            payment_type: this.state.paymentType
        });

        collaterals.push({
            nominal: inputStringToNumber(this.state.collateralNominal)
        });

        let newDetails = {
            product_name: this.state.productName,
            obligos_sum: inputStringToNumber(this.state.obligosSum),
            bond_limit: inputStringToNumber(this.state.bondLimit),
            contract_state: this.state.policyState,
            single_limit_max: inputStringToNumber(this.state.singleLimit),
            aval_premia: avalPremia,
            collaterals: collaterals
        };

        this.props.setNewDetails(newDetails);
    }

    renderHeading(heading) {
        return (
            <div style={{marginBottom: "16px"}}>
                <h4>{heading}</h4>
            </div>
        );
    }

    render(){
        return(
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div>
                            {this.renderHeading("Basisinformationen")}

                            <Card className="form-card">
                                <form>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Product:</label>
                                        <input className="col form-control"
                                               value={this.state.productName}
                                               onChange={this.updateProductName}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Status:</label>
                                        <input className="col form-control"
                                               value={this.state.policyState}
                                               onChange={this.updatePolicyState}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label"> Jahresbeitrag:</label>
                                        <input className="col form-control"
                                               value={this.state.yearlyFeeObligo}
                                               onChange={this.updateYearlyFeeObligo}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label"> Zahlweise:</label>
                                        <input className="col form-control"
                                               value={this.state.paymentType}
                                               onChange={this.updatePaymentType}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label"> Soll-Sicherheiten:</label>
                                        <input className="col form-control"
                                               value={this.state.collateralNominal}
                                               onChange={this.updateCollateralNominal}/>
                                    </div>
                                </form>
                            </Card>
                        </div>
                    </div>

                    <div className="col-md-6">
                        {this.renderHeading("Kontostand")}

                        <Card className="form-card">
                            <form>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Gesamtobligo:</label>
                                    <input className="col form-control"
                                           value={this.state.obligosSum}
                                           onChange={this.updateObligosSum}/>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Limit:</label>
                                    <input className="col form-control"
                                           value={this.state.bondLimit}
                                           onChange={this.updateBondLimit}/>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Bürgschaftslimit:</label>
                                    <input className="col form-control"
                                           value={this.state.singleLimit}
                                           onChange={this.updateSingleLimit}/>
                                </div>
                            </form>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(NewProjectDetailsVHV);
