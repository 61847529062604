import React, {Component} from 'react';
import Link from "react-router-dom/es/Link";
import * as Paths from "../../config/paths";
import Can from "../Can";
import * as FormatService from "../../service/formatService";

import {IoMdCreate, IoMdAddCircle, IoMdOpen} from 'react-icons/io'
import {openAvalRequestURL} from "../../service/externalLinkService";

import logo_ruv from '../../static/extern/rv_logo.png';
import logo_axa from '../../static/extern/axa_logo.png';
import logo_euler_hermes from '../../static/extern/euler_hermes_logo.jpeg';
import logo_vhv from '../../static/extern/vhv_logo.jpeg';
import ergo from '../../static/extern/ergo.png';
import zurich_logo from '../../static/extern/zurich_logo.jpg';
import coface_logo from '../../static/extern/coface_logo.jpg';
import bayern_logo from '../../static/extern/vkb_logo.jpg';
import tryg_logo from '../../static/extern/tryg_logo.png';
import gothaer_logo from '../../static/extern/gothaer_logo.png';
import wuerttembergische_logo from '../../static/extern/wuerttembergische_logo.png';
import allianz_trade_logo from '../../static/extern/allianz_trade_logo.png';

class DemoPolicyCard extends Component{

    constructor(props) {
        super(props);

        this.state = {
            projects: []
        };
    }

    openlink(){
        window.open(this.props.policyData.policy.insurance_company.homepage)
    }

    getLogo() {
        switch (this.props.policyData.policy.insurance_company_id) {
            case 1: return logo_axa;
            case 2: return logo_vhv;
            case 4: return logo_ruv;
            case 5: return allianz_trade_logo;
            case 6: return ergo;
            case 7: return zurich_logo;
            case 8: return bayern_logo;
            case 9: return coface_logo;
            case 13: return wuerttembergische_logo;
            case 14: return tryg_logo;
            case 15: return gothaer_logo;
            default: return  null;
        }
    }

    render(){
        let obligos_with_projects = this.props.policyData.obligos_sum;
        let bond_limit_with_projects = this.props.policyData.bond_limit;

        let logo = this.getLogo();

        return(
            <div style={{
                padding: "30px",
                paddingTop: "12px",
                margin: "10px",
                width: "430px",
                border: "1px solid #000000"
            }}>
                <div className={"row"} style={{marginRight: "-40px", maxWidth: "100%"}}>
                    <div className="col text-right">
                        {this.props.policyData.policy.insurance_company.homepage !== " " &&
                        <button className="btn btn-outline-policycard" onClick={() => this.openlink()}>
                            <div>
                                <IoMdOpen/>
                            </div>
                            Aval
                            <br></br>
                            anfordern
                        </button>
                        }
                        {this.props.policyData.updated_at &&
                        <Link to={{
                            pathname: `${Paths.PATH_DEMO_DETAILS}/${this.props.policyData.id}`,
                            clientData: this.props.policyData
                        }}>
                            <button className="btn btn-outline-policycard">
                                <div>
                                    <IoMdOpen/>
                                </div>
                                Details
                                <br></br>
                                anzeigen
                            </button>
                        </Link>
                        }
                    </div>
                </div>


                <div>
                    {logo ?
                        <div style={{marginBottom: "24px"}}><img src={logo} height={40}/></div> :
                        <h2 className="d-inline-block">{this.props.policyData.displayData.insuranceName}</h2>
                    }
                </div>

                <div>
                    <span style={{paddingRight: "10px"}}><b>VSNR:</b></span>
                    <span>{this.props.policyData.displayData.policyNumber}</span>
                </div>

                {this.props.policyData.updated_at ?
                    <div>
                        <div>
                            <span style={{paddingRight: "10px"}}><b>Stand vom:</b></span>
                            <span>{FormatService.formatDate(this.props.policyData.updated_at)}</span>
                        </div>
                        <div style={{paddingTop: "12px"}}>
                            <div style={{paddingRight: "10px"}}><b>Auslastung:</b></div>
                            <div>
                                <span>{FormatService.formatCurrency(this.props.policyData.obligos_sum)}</span>
                                <span style={{marginLeft: "5px", marginRight: "5px"}}>/</span>
                                <span>{FormatService.formatCurrency(this.props.policyData.bond_limit)}</span>
                            </div>
                        </div>

                        {this.state.projects.length > 0 &&
                        <div style={{paddingTop: "12px"}}>
                            <div style={{paddingRight: "10px"}}><b>Gesamtauslastung:</b></div>
                            <div>
                                <span>{FormatService.formatCurrency(obligos_with_projects)}</span>
                                <span style={{marginLeft: "5px", marginRight: "5px"}}>/</span>
                                <span>{FormatService.formatCurrency(bond_limit_with_projects)}</span>
                            </div>
                        </div>
                        }

                    </div>

                    :

                    <div className={"text-center"} style={{marginTop: "50px", color: "#95989a"}}>
                        <span>Noch keine Aktualisierung vorhanden</span>
                    </div>

                }
            </div>
        );
    }
}

export default DemoPolicyCard;
