export const INSURANCE_AXA = "AXA Versicherung AG";
export const INSURANCE_VHV = "VHV Allgemeine Versicherung AG";
export const INSURANCE_SWISSRE = "Swiss Re International SE";
export const INSURANCE_RV = "R+V Allgemeine Versicherung AG";
export const INSURANCE_EULERHERMES = "Euler Hermes Kreditversicherungs-AG";
export const INSURANCE_ALLIANZTRADE = "Euler Hermes alias Allianz Trade"
export const INSURANCE_ERGO = "ERGO Versicherungen";
export const INSURANCE_ZURICH = "ZURICH Insurance PLC";
export const INSURANCE_Bayern = "Versicherungskammer Bayern";
export const INSURANCE_Coface = "Coface Deutschland";
export const INSURANCE_Tokio = "Tokio Marine Europa S.A.";
export const INSURANCE_CREDENDO = "CREDENDO Excess & SureteyUm";

export const USER_CLIENT = "client";
export const USER_FIMO = "fimo";

export const MENU_PORTAL = "Portal";
export const MENU_VERTRAGSUEBERSICHT = "Vertragsübersicht";
export const MENU_FORMULARE = "Formularwesen";
export const MENU_LEXIKON = "Lexikon";
export const MENU_ANFAHRT = "Über uns";
export const MENU_UPDATE_REQUESTS = "Aktualisierungsanfragen";
export const MENU_CLIENT_COMPANY_REQUESTS = "Zugangsanfragen";
export const MENU_CLIENT_COMPANIES_OVERVIEW = "Kunden";
export const MENU_USERS = "Benutzerverwaltung";
export const MENU_PROFILE = "Profil";
export const MENU_INSURANCES = "Versicherer";
export const MENU_IMPORT = "Vertragsimporter";
export const MENU_SHOW_QUOTATION_REQUESTS = "Angebotsanfragen";
export const MENU_NEW_QUOTATION_REQUEST = "Angebot anfordern";
export const MENU_IMPRINT = "Impressum";
export const MENU_DEMO = "Demo";

export const UEBER_UNS = "https://www.suedvers.de/kennenlernen/ueber-uns/";

export const TAB_TITLE_BASE = "Meine Avale";
export const TAB_TITLE_CONNECTOR = " | ";

export const RECAPTCHA_SITE_KEY = "6LcPxrAUAAAAAP98GMHZLjfCW226n444KNwcOvxm"
