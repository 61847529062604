import {
    INSURANCE_AXA,
    INSURANCE_ALLIANZTRADE,
    INSURANCE_RV,
    INSURANCE_VHV,
    INSURANCE_ERGO,
    INSURANCE_ZURICH, INSURANCE_Coface, INSURANCE_Tokio
} from "../../config/constants";

const rvPolicy = {
    id: 1,
    insurance_company_id: 4,
    policy_number: "1111",
    details_updated_at: Date.now(),
    updated_at: Date.now(),
    insurance_company: {
        name: INSURANCE_RV,
        homepage: "https://kredit.ruv.de"
    }
}

const vhvPolicy = {
    id: 2,
    policy_number: "B 2222",
    insurance_company_id: 2,
    details_updated_at: Date.now(),
    updated_at: Date.now(),
    insurance_company: {
        name: INSURANCE_VHV,
        homepage: "https://kreditportal.vhv.de/web/start"
    }
}

const allianzTradePolicy = {
    id: 3,
    policy_number: "3333",
    insurance_company_id: 5,
    details_updated_at: Date.now(),
    updated_at: Date.now(),
    insurance_company: {
        name: INSURANCE_ALLIANZTRADE,
        homepage: "https://mybonding.eulerhermes.com/portal/login?initialURI=/portal/Bond/"
    }
}

const axaPolicy = {
    id: 5,
    policy_number: "5555",
    insurance_company_id: 1,
    details_updated_at: Date.now(),
    updated_at: Date.now(),
    insurance_company: {
        name: INSURANCE_AXA,
        homepage: "https://entry.axa.de/nova-portal/sls/auth?cmd=auth&RequestedPage=/novaInet/inet"
    }
}

const ergoPolicy = {
    id: 6,
    policy_number: "6666",
    insurance_company_id: 6,
    details_updated_at: Date.now(),
    updated_at: Date.now(),
    insurance_company: {
        name: INSURANCE_ERGO,
        homepage: " "
    }
}
const zurichPolicy = {
    id: 7,
    policy_number: "7777",
    insurance_company_id: 7,
    details_updated_at: Date.now(),
    updated_at: Date.now(),
    insurance_company: {
        name: INSURANCE_ZURICH,
        homepage: " "
    }
}

const bayernPolicy = {
    id: 8,
    policy_number: "8888",
    insurance_company_id: 8,
    details_updated_at: Date.now(),
    updated_at: Date.now(),
    insurance_company: {
        name: "Versicherungskammer Bayern",
        homepage: " "
    }
}

const cofacePolicy = {
    id: 9,
    policy_number: "9999",
    insurance_company_id: 9,
    details_updated_at: Date.now(),
    updated_at: Date.now(),
    insurance_company: {
        name: INSURANCE_Coface,
        homepage: " "
    }
}

const tokioPolicy = {
    id: 10,
    policy_number: "1010",
    insurance_company_id: 10,
    details_updated_at: Date.now(),
    updated_at: Date.now(),
    insurance_company: {
        name: INSURANCE_Tokio,
        homepage: " "
    }
}

const wuerttembergischePolicy = {
    id: 13,
    policy_number: "1313",
    insurance_company_id: 13,
    details_updated_at: Date.now(),
    updated_at: Date.now(),
    insurance_company: {
        name: "Württembergische",
        homepage: " "
    }
}

const trygPolicy = {
    id: 14,
    policy_number: "1414",
    insurance_company_id: 14,
    details_updated_at: Date.now(),
    updated_at: Date.now(),
    insurance_company: {
        name: "Tryg Garanti",
        homepage: " "
    }
}

const gothaerPolicy = {
    id: 14,
    policy_number: "1515",
    insurance_company_id: 15,
    details_updated_at: Date.now(),
    updated_at: Date.now(),
    insurance_company: {
        name: "Gothaer Allgemeine Versicherung AG",
        homepage: "https://gothaer.de/kautionsportal"
    }
}


const rvDetails = {
    id: 1,
    policy: rvPolicy,
    displayData: {
        policyNumber: rvPolicy.policy_number,
        insuranceName: rvPolicy.insurance_company.name
    },
    bond_limit: 100000,
    obligos_sum: 64561.40,
    remaining_balance: 35438.60,
    updated_at: Date.now(),
    convertedData: {
        bond_limit: 100000,
        obligos_sum: 64561.40,
        remaining_balance: 35438.60,
        avalClasses: [{
            name: "Verbraucherbaubürgschaft gem. §650m Abs.2 BGB",
            obligo: 64561.40,
            bond_limit: 100000,
            single_limit: 100000
        }]
    }
}


const vhvDetails = {
    id: 2,
    policy: vhvPolicy,
    displayData: {
        policyNumber: vhvPolicy.policy_number,
        insuranceName: vhvPolicy.insurance_company.name
    },
    bond_limit: 6000000,
    obligos_sum: 3983345,
    updated_at: Date.now(),
    convertedData: {
        bond_limit: 6000000,
        obligos_sum: 3983345,
        policy_state: "aktiv",
        collateralNominal: 3000000,
        collateralCurrent: 3000000,
        avalClasses: [{
            name: "Gewährleistungsbürgschaften",
            obligo: 1545881,
            bond_limit: 6000000,
            single_limit: 300000,
            premium_rate: 1.0
        },
            {
                name: "Vertragserfüllungsbürgschaften",
                obligo: 1062464,
                bond_limit: 3000000,
                single_limit: 300000,
                premium_rate: 1.1
            },
            {
                name: "Vorauszahlungsbürgschaften",
                obligo: 1375000,
                bond_limit: 500000,
                single_limit: 250000,
                premium_rate: 1.3
            }]
    }
}

const allianzTradeDetails = {
    id: 3,
    policy: allianzTradePolicy,
    displayData: {
        policyNumber: allianzTradePolicy.policy_number,
        insuranceName: allianzTradePolicy.insurance_company.name
    },
    bond_limit: 6000000,
    obligos_sum: 3983345,
    updated_at: Date.now(),
    convertedData: {
        bond_limit: 6000000,
        obligos_sum: 3983345,
        policy_state: "aktiv",
        collateralNominal: 3000000,
        collateralCurrent: 3000000,
        avalClasses: [{
            name: "Gewährleistungsbürgschaften",
            obligo: 1545881,
            bond_limit: 6000000,
            single_limit: 300000,
            premium_rate: 1.0
        },
            {
                name: "Vertragserfüllungsbürgschaften",
                obligo: 1062464,
                bond_limit: 3000000,
                single_limit: 300000,
                premium_rate: 1.1
            },
            {
                name: "Vorauszahlungsbürgschaften",
                obligo: 1375000,
                bond_limit: 500000,
                single_limit: 250000,
                premium_rate: 1.3
            }]
    }
}

const axaDetails = {
    id: 5,
    policy: axaPolicy,
    displayData: {
        policyNumber: axaPolicy.policy_number,
        insuranceName: axaPolicy.insurance_company.name
    },
    bond_limit: 6000000,
    obligos_sum: 3983345,
    updated_at: Date.now(),
    convertedData: {
        bond_limit: 6000000,
        obligos_sum: 3983345,
        policy_state: "aktiv",
        collateralNominal: 3000000,
        collateralCurrent: 3000000,
        avalClasses: [{
            name: "Gewährleistungsbürgschaften",
            obligo: 1545881,
            bond_limit: 6000000,
            single_limit: 300000,
            premium_rate: 1.0
        },
            {
                name: "Vertragserfüllungsbürgschaften",
                obligo: 1062464,
                bond_limit: 3000000,
                single_limit: 300000,
                premium_rate: 1.1
            },
            {
                name: "Vorauszahlungsbürgschaften",
                obligo: 1375000,
                bond_limit: 500000,
                single_limit: 250000,
                premium_rate: 1.3
            }]
    }
}
const ergoDetails = {
    id: 6,
    policy: ergoPolicy,
    displayData: {
        policyNumber: ergoPolicy.policy_number,
        insuranceName: ergoPolicy.insurance_company.name
    },
    bond_limit: 5000000,
    obligos_sum: 3983345,
    updated_at: Date.now(),
    convertedData: {
        bond_limit: 5000000,
        obligos_sum: 3983345,
        policy_state: "aktiv",
        collateralNominal: 3000000,
        collateralCurrent: 3000000,
        avalClasses: [{
            name: "Gewährleistungsbürgschaften",
            obligo: 1545881,
            bond_limit: 6000000,
            single_limit: 300000,
            premium_rate: 1.0
        },
            {
                name: "Vertragserfüllungsbürgschaften",
                obligo: 1062464,
                bond_limit: 3000000,
                single_limit: 300000,
                premium_rate: 1.1
            },
            {
                name: "Vorauszahlungsbürgschaften",
                obligo: 1375000,
                bond_limit: 500000,
                single_limit: 250000,
                premium_rate: 1.3
            }]
    }
}

const zurichDetails = {
    id: 7,
    policy: zurichPolicy,
    displayData: {
        policyNumber: zurichPolicy.policy_number,
        insuranceName: zurichPolicy.insurance_company.name
    },
    bond_limit: 6000000,
    obligos_sum: 3983345,
    updated_at: Date.now(),
    convertedData: {
        bond_limit: 6000000,
        obligos_sum: 3983345,
        policy_state: "aktiv",
        collateralNominal: 3000000,
        collateralCurrent: 3000000,
        avalClasses: [{
            name: "Gewährleistungsbürgschaften",
            obligo: 1545881,
            bond_limit: 6000000,
            single_limit: 300000,
            premium_rate: 1.0
        },
            {
                name: "Vertragserfüllungsbürgschaften",
                obligo: 1062464,
                bond_limit: 3000000,
                single_limit: 300000,
                premium_rate: 1.1
            },
            {
                name: "Vorauszahlungsbürgschaften",
                obligo: 1375000,
                bond_limit: 500000,
                single_limit: 250000,
                premium_rate: 1.3
            }]
    }
}

const bayernDetails = {
    id: 8,
    policy: bayernPolicy,
    displayData: {
        policyNumber: bayernPolicy.policy_number,
        insuranceName: bayernPolicy.insurance_company.name
    },
    bond_limit: 6000000,
    obligos_sum: 3983345,
    updated_at: Date.now(),
    convertedData: {
        bond_limit: 6000000,
        obligos_sum: 3983345,
        policy_state: "aktiv",
        collateralNominal: 3000000,
        collateralCurrent: 3000000,
        avalClasses: [{
            name: "Gewährleistungsbürgschaften",
            obligo: 1545881,
            bond_limit: 6000000,
            single_limit: 300000,
            premium_rate: 1.0
        },
            {
                name: "Vertragserfüllungsbürgschaften",
                obligo: 1062464,
                bond_limit: 3000000,
                single_limit: 300000,
                premium_rate: 1.1
            },
            {
                name: "Vorauszahlungsbürgschaften",
                obligo: 1375000,
                bond_limit: 500000,
                single_limit: 250000,
                premium_rate: 1.3
            }]
    }
}

const cofaceDetails = {
    id: 9,
    policy: cofacePolicy,
    displayData: {
        policyNumber: cofacePolicy.policy_number,
        insuranceName: cofacePolicy.insurance_company.name
    },
    bond_limit: 6000000,
    obligos_sum: 3983345,
    updated_at: Date.now(),
    convertedData: {
        bond_limit: 6000000,
        obligos_sum: 3983345,
        policy_state: "aktiv",
        collateralNominal: 3000000,
        collateralCurrent: 3000000,
        avalClasses: [{
            name: "Gewährleistungsbürgschaften",
            obligo: 1545881,
            bond_limit: 6000000,
            single_limit: 300000,
            premium_rate: 1.0
        },
            {
                name: "Vertragserfüllungsbürgschaften",
                obligo: 1062464,
                bond_limit: 3000000,
                single_limit: 300000,
                premium_rate: 1.1
            },
            {
                name: "Vorauszahlungsbürgschaften",
                obligo: 1375000,
                bond_limit: 500000,
                single_limit: 250000,
                premium_rate: 1.3
            }]
    }
}

const tokioDetails = {
    id: 10,
    policy: tokioPolicy,
    displayData: {
        policyNumber: tokioPolicy.policy_number,
        insuranceName: tokioPolicy.insurance_company.name
    },
    bond_limit: 6000000,
    obligos_sum: 3983345,
    updated_at: Date.now(),
    convertedData: {
        bond_limit: 6000000,
        obligos_sum: 3983345,
        policy_state: "aktiv",
        collateralNominal: 3000000,
        collateralCurrent: 3000000,
        avalClasses: [{
            name: "Gewährleistungsbürgschaften",
            obligo: 1545881,
            bond_limit: 6000000,
            single_limit: 300000,
            premium_rate: 1.0
        },
            {
                name: "Vertragserfüllungsbürgschaften",
                obligo: 1062464,
                bond_limit: 3000000,
                single_limit: 300000,
                premium_rate: 1.1
            },
            {
                name: "Vorauszahlungsbürgschaften",
                obligo: 1375000,
                bond_limit: 500000,
                single_limit: 250000,
                premium_rate: 1.3
            }]
    }
}

const wuertemmbergischeDetails = {
    id: 13,
    policy: wuerttembergischePolicy,
    displayData: {
        policyNumber: wuerttembergischePolicy.policy_number,
        insuranceName: wuerttembergischePolicy.insurance_company.name
    },
    bond_limit: 6000000,
    obligos_sum: 3983345,
    updated_at: Date.now(),
    convertedData: {
        bond_limit: 6000000,
        obligos_sum: 3983345,
        policy_state: "aktiv",
        collateralNominal: 3000000,
        collateralCurrent: 3000000,
        avalClasses: [{
            name: "Gewährleistungsbürgschaften",
            obligo: 85000,
            bond_limit: 85000,
            single_limit: 85000,
            premium_rate: 1.0
        },
            {
                name: "Vertragserfüllungsbürgschaften",
                obligo: 1062464,
                bond_limit: 3000000,
                single_limit: 300000,
                premium_rate: 1.1
            },
            {
                name: "Vorauszahlungsbürgschaften",
                obligo: 1375000,
                bond_limit: 500000,
                single_limit: 250000,
                premium_rate: 1.3
            }]
    }
}

const trygDetails = {
    id: 14,
    policy: trygPolicy,
    displayData: {
        policyNumber: trygPolicy.policy_number,
        insuranceName: trygPolicy.insurance_company.name
    },
    bond_limit: 6000000,
    obligos_sum: 3983345,
    updated_at: Date.now(),
    convertedData: {
        bond_limit: 6000000,
        obligos_sum: 3983345,
        policy_state: "aktiv",
        collateralNominal: 3000000,
        collateralCurrent: 3000000,
        avalClasses: [{
            name: "Gewährleistungsbürgschaften",
            obligo: 85000,
            bond_limit: 85000,
            single_limit: 85000,
            premium_rate: 1.0
        },
            {
                name: "Vertragserfüllungsbürgschaften",
                obligo: 1062464,
                bond_limit: 3000000,
                single_limit: 300000,
                premium_rate: 1.1
            },
            {
                name: "Vorauszahlungsbürgschaften",
                obligo: 1375000,
                bond_limit: 500000,
                single_limit: 250000,
                premium_rate: 1.3
            }]
    }
}

const gothaerDetails = {
    id: 15,
    policy: gothaerPolicy,
    displayData: {
        policyNumber: gothaerPolicy.policy_number,
        insuranceName: gothaerPolicy.insurance_company.name
    },
    bond_limit: 6000000,
    obligos_sum: 3983345,
    updated_at: Date.now(),
    convertedData: {
        bond_limit: 6000000,
        obligos_sum: 3983345,
        policy_state: "aktiv",
        collateralNominal: 3000000,
        collateralCurrent: 3000000,
        avalClasses: [{
            name: "Gewährleistungsbürgschaften",
            obligo: 1545881,
            bond_limit: 6000000,
            single_limit: 300000,
            premium_rate: 1.0
        },
            {
                name: "Vertragserfüllungsbürgschaften",
                obligo: 1062464,
                bond_limit: 3000000,
                single_limit: 300000,
                premium_rate: 1.1
            },
            {
                name: "Vorauszahlungsbürgschaften",
                obligo: 1375000,
                bond_limit: 500000,
                single_limit: 250000,
                premium_rate: 1.3
            }]
    }
}


export const policies = [rvPolicy, axaPolicy, vhvPolicy, allianzTradePolicy, zurichPolicy, ergoPolicy,
                            cofacePolicy, tokioPolicy, bayernPolicy, wuerttembergischePolicy, trygPolicy, gothaerPolicy];
export const policyData = [rvDetails, axaDetails, vhvDetails, allianzTradeDetails, zurichDetails, ergoDetails,
                            cofaceDetails, tokioDetails, bayernDetails, wuertemmbergischeDetails, trygDetails, gothaerDetails];
