import React, {Component} from 'react';
import {NotificationContext} from "../../contexts/NotificationContext";

import * as Constants from "../../../config/constants";
import {INSURANCE_VHV} from "../../../config/constants";
import InsurancePolicyDetail from "../../../models/InsurancePolicyDetail";
import {convertBackendDataToPortalData} from '../../../service/conversionService';

import EditPolicyDetails from "./EditPolicyDetails";
import {formatVHVpolicyNumber, inputStringToNumber} from "../../../service/formatService";
import {Redirect, withRouter} from "react-router-dom";
import ability from "../../../Ability";
import * as Paths from "../../../config/paths";
import Error from "../../Error";
import InsurancePolicy from "../../../models/InsurancePolicy";

import {IoIosSave, IoMdClose} from 'react-icons/io';


class EditPolicyDetailsFormsContainer extends Component {

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            details: props.location.details || null,
            errorResponse: null
        };

        this.updateNewDetails = this.updateNewDetails.bind(this);
        this.updatePolicyDetails = this.updatePolicyDetails.bind(this);
    }

    componentWillMount() {
        if (!this.props.location.details) {
            let {id} = this.props.match.params;

            this.loadPolicyDetails(id);

        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props){
            let {id} = nextProps.match.params;

            this.loadPolicyDetails(id);
        }
    }

    loadPolicyDetails(id){
        return InsurancePolicyDetail.getInsurancePolicyDetail(id)
            .then(response => {
                if (response.status === 200){
                    let details = new InsurancePolicyDetail(response.body);

                    return details;
                }

                return  null;
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Details nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            })
            .then(details => {
                if (details){
                    this.getPolicy(details.insurance_policy_id)
                        .then(async (policy) => {
                            if (policy){
                                details.convertedData = await convertBackendDataToPortalData(details,
                                    policy.insurance_company.name,
                                    sessionStorage.getItem('auth_token'));

                                details.displayData = {
                                    policyNumber: policy.policy_number,
                                    insuranceName: policy.insurance_company.name
                                };

                                details.policy = policy;

                                this.setState({
                                    details: details,
                                    errorResponse: null
                                });
                            }
                        })
                }

                return details;
            })
    }

    getPolicy(id){
        return InsurancePolicy.getPolicy(id)
            .then(response => {
                if (response.status === 200){
                    let policy = new InsurancePolicy(response.body);

                    return policy;
                }

                return  null;
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Police nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    updatePolicyDetails(updatedDetails){
        this.state.details.updateInsurancePolicyDetails(updatedDetails)
            .then(async (response) => {
                if (response.status === 200){
                    let details = new InsurancePolicyDetail(response.body);

                    details.convertedData = await convertBackendDataToPortalData(response.body,
                        this.state.details.policy.insurance_company.name,
                        sessionStorage.getItem('auth_token'));

                    details.displayData = {
                        policyNumber: this.state.details.policy.policy_number,
                        insuranceName: this.state.details.policy.insurance_company.name
                    };

                    details.policy = this.state.details.policy;

                    this.setState({
                        details: details
                    });

                    this.context.showMessage(null, "Stand wurde aktualisiert", "success");
                    this.props.history.goBack()
                }
                else {
                    this.context.showMessage(null, "Es ist ein unbekannter Fehler aufgetreten", "danger");
                }
            })
            .catch(err => {
                this._handlePolicyDetailsErrors(err);
            })
    }


    submitPolicyDetails(){
        //console.log("1 Submit new details:", this.state.newDetails);

        if(!this.state.newDetails) {
            this.context.showMessage(null, "Es gibt keine Änderungen zu speichern.", "danger");
            return;
        }

        let obligos = this.state.details.obligos || [];
        let bonds = this.state.details.bonds || [];
        let singleLimits = this.state.details.single_limits || [];
        let avalPremia = this.state.details.aval_premia || [];
        let collaterals = this.state.details.collaterals || [];

        let collaterals_w_a = this.state.details.collaterals.filter( (aval) => aval.aval_class_id == null) || [];
        let avalPremia_w_a = this.state.details.aval_premia.filter( (aval) => aval.aval_class_id == null) || [];

        // Delete the properties of the aval classes that shall be removed
        if(this.state.newDetails.avalClassesToRemove) {
            this.state.newDetails.avalClassesToRemove.map(avalClass => {
                let removeTasks = [];
                if (avalClass.id) {
                    let obligoIndex = obligos.findIndex((obligo) => obligo.aval_class.id === avalClass.id);
                    if (obligoIndex > -1) {
                        removeTasks.push(obligos[obligoIndex].deleteObligo());
                        obligos.splice(obligoIndex, 1);
                    }

                    let bondIndex = bonds.findIndex((bond) => bond.aval_class.id === avalClass.id);

                    if (bondIndex > -1) {
                        removeTasks.push(bonds[bondIndex].deleteBond());
                        bonds.splice(bondIndex, 1);
                    }

                    let limitIndex = singleLimits.findIndex((limit) => limit.aval_class.id === avalClass.id);

                    if (limitIndex > -1) {
                        removeTasks.push(singleLimits[limitIndex].deleteSingleLimit());
                        singleLimits.splice(limitIndex, 1);
                    }

                    let premiumIndex = avalPremia.findIndex((premium) => premium.aval_class.id === avalClass.id);

                    if (premiumIndex > -1) {
                        removeTasks.push(avalPremia[premiumIndex].deleteAvalPremium());
                        avalPremia.splice(premiumIndex, 1);
                    }

                }

                return Promise.all(removeTasks);
            });
        }

        // Build the remaining aval classes and their properties to save
        if (collaterals_w_a[0]) {
            collaterals_w_a[0].nominal = inputStringToNumber(this.state.newDetails.collateralNominal);
            collaterals_w_a[0].current = inputStringToNumber(this.state.newDetails.collateralCurrent);
            collaterals_w_a[0].percent = inputStringToNumber(this.state.newDetails.collateralPercent);
        }
        else {
            collaterals.push({
                nominal: inputStringToNumber(this.state.newDetails.collateralNominal),
                current: this.state.newDetails.collateralCurrent,
                percent: inputStringToNumber(this.state.newDetails.collateralPercent)
            });
        }

        if (avalPremia_w_a[0]) {
            avalPremia_w_a[0].yearly_fee_obligo = inputStringToNumber(this.state.newDetails.yearlyFeeObligo);
            avalPremia_w_a[0].premium_rate = inputStringToNumber(this.state.newDetails.premiumRate);
            avalPremia_w_a[0].payment_type = {name: this.state.newDetails.paymentType};
            avalPremia_w_a[0].payment_type_id = null;
        }
        else {
            avalPremia.push({
                yearly_fee_obligo: inputStringToNumber(this.state.newDetails.yearlyFeeObligo),
                payment_type: {name: this.state.newDetails.paymentType},
                premium_rate: inputStringToNumber(this.state.newDetails.premiumRate),
            });
        }


        if(this.state.newDetails.avalClasses) {
            this.state.newDetails.avalClasses.map(avalClass => {
                let classNames = avalClass.name.split(/\s*[\n,]\s*/);
                let approved = avalClass.approved
                let avalClassSpecifier = avalClass.avalClass || avalClass.aval_class || "";

                if (avalClass.id) {
                    let obligoIndex = obligos.findIndex((obligo) => {
                        if(obligo.aval_class) {
                            return obligo.aval_class.id === avalClass.id;
                        }
                    });

                    if (obligoIndex > -1) {
                        obligos[obligoIndex].amount = inputStringToNumber(avalClass.obligo);
                        obligos[obligoIndex].aval_class_id = null;
                        obligos[obligoIndex].aval_class = {
                            class_names: classNames,
                            approved: approved,
                            aval_class: avalClassSpecifier
                        };
                    } else {
                        obligos.push({
                            aval_class: {
                                class_names: classNames,
                                approved: approved,
                                aval_class: avalClassSpecifier
                            },
                            amount: inputStringToNumber(avalClass.obligo)
                        });
                    }

                    let bondIndex = bonds.findIndex((bond) => {
                        if(bond.aval_class) {
                            return bond.aval_class.id === avalClass.id
                        }
                    });


                    if (bondIndex > -1) {
                        bonds[bondIndex].amount = inputStringToNumber(avalClass.bond_limit);
                        bonds[bondIndex].aval_class_id = null;
                        bonds[bondIndex].aval_class = {
                            class_names: classNames,
                            approved: approved,
                            aval_class: avalClassSpecifier
                        };
                    } else {
                        bonds.push({
                            aval_class: {
                                class_names: classNames,
                                approved: approved,
                                aval_class: avalClassSpecifier
                            },
                            amount: inputStringToNumber(avalClass.bond_limit)
                        });
                    }

                    let singleLimitIndex = singleLimits.findIndex((singleLimit) => {
                        if(singleLimit.aval_class) {
                            return singleLimit.aval_class.id === avalClass.id
                        }});

                    if (singleLimitIndex > -1) {
                        singleLimits[singleLimitIndex].amount = inputStringToNumber(avalClass.single_limit);
                        singleLimits[singleLimitIndex].aval_class_id = null;
                        singleLimits[singleLimitIndex].aval_class = {
                            class_names: classNames,
                            approved: approved,
                            aval_class: avalClassSpecifier
                        };
                    } else {
                        singleLimits.push({
                            aval_class: {
                                class_names: classNames,
                                approved: approved,
                                aval_class: avalClassSpecifier
                            },
                            amount: inputStringToNumber(avalClass.single_limit)
                        });
                    }

                    let premiumIndex = avalPremia.findIndex((premium) => {
                        if(premium.aval_class) {
                            return premium.aval_class.id === avalClass.id
                        }});

                    if (premiumIndex > -1) {
                        avalPremia[premiumIndex].premium_rate = inputStringToNumber(avalClass.premium_rate);
                        avalPremia[premiumIndex].aval_class_id = null;
                        avalPremia[premiumIndex].aval_class = {
                            class_names: classNames,
                            approved: approved,
                            aval_class: avalClassSpecifier
                        };
                    } else {
                        avalPremia.push({
                            aval_class: {
                                class_names: classNames,
                                approved: approved,
                                aval_class: avalClassSpecifier
                            },
                            premium_rate: inputStringToNumber(avalClass.premium_rate)
                        });
                    }
                } else {
                    obligos.push({
                        aval_class: {
                            class_names: classNames,
                            approved: approved,
                            aval_class: avalClassSpecifier
                        },
                        amount: inputStringToNumber(avalClass.obligo)
                    });

                    bonds.push({
                        aval_class: {
                            class_names: classNames,
                            approved: approved,
                            aval_class: avalClassSpecifier
                        },
                        amount: inputStringToNumber(avalClass.bond_limit)
                    });

                    singleLimits.push({
                        aval_class: {
                            class_names: classNames,
                            approved: approved,
                            aval_class: avalClassSpecifier
                        },
                        amount: inputStringToNumber(avalClass.single_limit)
                    });

                    avalPremia.push({
                        aval_class: {
                            class_names: classNames,
                            approved: approved,
                            aval_class: avalClassSpecifier
                        },
                        premium_rate: inputStringToNumber(avalClass.premium_rate)
                    });

                    collaterals.push({
                        aval_class: {
                            class_names: classNames,
                            approved: approved,
                            aval_class: avalClassSpecifier
                        },
                        collateral: inputStringToNumber(avalClass.collateral)
                    });
                }
            });
        }


        let newDetails = {
            product_name: this.state.newDetails.productName,
            obligos_sum: inputStringToNumber(this.state.newDetails.obligosSum),
            bond_limit: inputStringToNumber(this.state.newDetails.bondLimit),
            contract_state: this.state.newDetails.policyState,
            remaining_balance: inputStringToNumber(this.state.newDetails.remainingBalance),
            single_limit_max: inputStringToNumber(this.state.newDetails.singleLimit),
            collaterals: collaterals,
            bonds: bonds,
            obligos: obligos,
            single_limits: singleLimits,
            aval_premia: avalPremia
        };

        this.updatePolicyDetails(newDetails);
    }

    updateNewDetails(details) {
        this.setState({
            newDetails: details
        })
    }

    _handlePolicyDetailsErrors(err){
        let showMessage = this.context.showMessage;

        if (!err.response){
            return  showMessage(null, "Verbindungsfehler", "danger");
        }

        switch (err.response.status) {
            case 403:
                return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
            case 404:
                return showMessage(null, "Die Details wurden nicht gefunden", "danger");
            case 422:
                return showMessage(null, "Überprüfen Sie Ihre Angaben", "danger");
            case 500:
                return showMessage(null, "Unbekannter Fehler", "danger");
            default:
                return showMessage(null, "Unbekannter Fehler", "danger");
        }
    }

    renderSpecificForm(){
        return <EditPolicyDetails
            details={this.state.details}
            updateNewDetails={this.updateNewDetails}/>;
    }

    render(){
        if (ability.cannot("update", this.state.details)){
            return <Redirect to={Paths.PATH_ROOT}/>
        }

        if (this.state.errorResponse){
            return <Error
                status={this.state.errorResponse.status}
                statusText={this.state.errorResponse.statusText}/>
        }

        if (!this.state.details){
            return  null;
        }

        const policyNumber = this.state.details.policy.insurance_company.name === INSURANCE_VHV
            ? formatVHVpolicyNumber(this.state.details.policy.policy_number)
            : this.state.details.policy.policy_number;

        return(
            <div className={"fimo-content-page"}>
                <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                    <div className="col text-right" style={{maxWidth: "100%"}}>
                        <button className="btn btn-outline-form"
                                onClick={() => this.submitPolicyDetails()}>
                            <div>
                                <IoIosSave/>
                            </div>
                            Speichern
                        </button>
                        <button className="btn btn-outline-form"
                                type="button"
                                onClick={() => this.props.history.goBack()}>
                            <div>
                                <IoMdClose style={{color: " red"}}/>
                            </div>
                            Abbrechen
                        </button>
                    </div>
                </div>
                <div style={{padding: "30px"}}>
                    <div className="details-header">
                        <h4 className="text-fimo-green">{this.state.details.policy.insurance_company.name}</h4>
                        <h6>VSNR: {policyNumber}</h6>
                    </div>
                    <div className={"row"}>
                        {this.renderSpecificForm()}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(EditPolicyDetailsFormsContainer);
