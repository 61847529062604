import React, {Component} from 'react';
import {NotificationContext} from "../../contexts/NotificationContext";

import NewProjectDetailsVHV from './NewProjectDetailsVHV';

import * as Constants from "../../../config/constants";
import NewProjectDetailsSwissRe from "./NewProjectDetailsSwissRe";
import NewProjectDetailsAXA from "./NewProjectDetailsAXA";
import NewProjectDetailsEulerHermes from "./NewProjectDetailsEulerHermes";
import NewProjectDetailsRuV from "./NewProjectDetailsRuV";
import ability from "../../../Ability";
import {Redirect, withRouter} from "react-router-dom";
import * as Paths from "../../../config/paths";
import Error from "../../Error";
import Project from "../../../models/Project";
import InsurancePolicy from "../../../models/InsurancePolicy";

import {IoIosSave, IoMdClose} from 'react-icons/io'


class NewProjectDetailsFormsContainer extends Component{

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            project: props.location.project || null,
            policy: props.location.policy || null,
            newDetails: null,
            errorResponse: null
        };

        this.setNewDetails = this.setNewDetails.bind(this);
        this.createNewProjectDetails = this.createNewProjectDetails.bind(this);
    }

    componentWillMount() {
        if (!this.props.location.project){
            let {id} = this.props.match.params;

            this.getProject(id)
                .then(project => {
                    if (project) {
                        this.getPolicy(project.insurance_policy_id)
                            .then(policy => {
                                this.setState({
                                    project: project,
                                    policy: policy,
                                    errorResponse: null
                                });
                            });
                    }
                });
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props){
            let {id} = nextProps.match.params;

            this.getProject(id)
                .then(project => {
                    if (project) {
                        this.getPolicy(project.insurance_policy_id)
                            .then(policy => {
                                this.setState({
                                    project: project,
                                    policy: policy,
                                    errorResponse: null
                                });
                            });
                    }
                });
        }
    }

    getProject(id){
        return Project.getProject(id)
            .then(response => {
                if (response.status === 200){
                    let project = new Project(response.body);

                    return project;
                }

                return  null;
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Projekt nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    getPolicy(id){
        return InsurancePolicy.getPolicy(id)
            .then(response => {
                if (response.status === 200){
                    let policy = new InsurancePolicy(response.body);

                    return policy;
                }

                return  null;
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Police nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    setNewDetails(details) {
        this.setState({
            newDetails: details
        })
    }

    createNewProjectDetails(){
        this.state.project.createDetails(this.state.newDetails)
            .then(response => {
                if (response.status === 201){
                    this.context.showMessage(null, "Stand wurde aktualisiert", "success");
                    this.props.history.goBack();
                }
                else {
                    this.context.showMessage(null, "Es ist ein unbekannter Fehler aufgetreten", "danger");
                }
            })
            .catch(err => {
                this._handlePolicyDetailsErrors(err);
            })
    }

    render(){
        if (ability.cannot("create", "InsurancePolicyDetail")){
            return <Redirect to={Paths.PATH_ROOT}/>
        }

        if (this.state.errorResponse){
            return <Error
                status={this.state.errorResponse.status}
                statusText={this.state.errorResponse.statusText}/>
        }

        if (!this.state.project){
            return  null;
        }

        if (!this.state.policy){
            return  null;
        }

        return(
            <div className={"fimo-content-page"}>
                <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                    <div className="col text-right" style={{maxWidth: "100%"}}>
                        <button className="btn btn-outline-form"
                                onClick={this.createNewProjectDetails}>
                            <div>
                                <IoIosSave/>
                            </div>
                            Speichern
                        </button>
                        <button className="btn btn-outline-form"
                                type="button"
                                onClick={() => this.props.history.goBack()}>
                            <div>
                                <IoMdClose style={{color: " red"}}/>
                            </div>
                            Abbrechen
                        </button>
                    </div>
                </div>

                <div style={{padding: "30px"}}>
                    <h4 className="page-headline">Aktuellen Stand anlegen</h4>

                    <div className="container">
                        <div className="details-header">
                            <h4 className="text-fimo-green">{this.state.project.project_name}</h4>
                            {this.state.project.project_number &&
                                <h5>VSNR: {this.state.project.project_number}</h5>}
                        </div>
                    </div>

                    {this.renderSpecificForm()}
                </div>
            </div>
        )
    }
    
    renderSpecificForm(){
        switch (this.state.policy.insurance_company.name) {
            case Constants.INSURANCE_VHV:
                return <NewProjectDetailsVHV
                    setNewDetails={this.setNewDetails}/>;
            case Constants.INSURANCE_SWISSRE:
                return <NewProjectDetailsSwissRe
                    setNewDetails={this.setNewDetails}/>
            case Constants.INSURANCE_AXA:
                return <NewProjectDetailsAXA
                    setNewDetails={this.setNewDetails}/>
            case Constants.INSURANCE_EULERHERMES:
                return  <NewProjectDetailsEulerHermes
                    setNewDetails={this.setNewDetails}/>
            case Constants.INSURANCE_RV:
                return <NewProjectDetailsRuV
                    setNewDetails={this.setNewDetails}/>
        }
    }
}

export default withRouter(NewProjectDetailsFormsContainer);