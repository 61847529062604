class PaymentType {

    static get modelName() {
        return 'PaymentType'
    }

    constructor(object){
        Object.assign(this, object)
    }

}

export default PaymentType;