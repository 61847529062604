const DOMAIN = process.env.REACT_APP_HOST;

const BASE_URL = DOMAIN + "/api/v1";

// Authentication
export const URL_LOGIN = BASE_URL + "/login";
export const URL_SIGNUP = BASE_URL + "/signup";
export const URL_FORGOT_PASSWORD = BASE_URL + "/forgot_password";
export const URL_RESET_PASSWORD = BASE_URL + "/reset_password";
export const URL_NEW_USER_PASSWORD = BASE_URL + "/new_user_password";
export const URL_IMPORT =  BASE_URL + "/import_insurances";
export const URL_EXPORT=  BASE_URL + "/export_insurances";

export const URL_USERS = BASE_URL + "/users";
export const URL_CLIENT_COMPANIES = BASE_URL + "/client_companies";
export const URL_INSURANCE_COMPANIES = BASE_URL + "/insurance_companies";
export const URL_INSURANCE_POLICIES = BASE_URL + "/insurance_policies";
export const URL_POLICY_DETAILS = BASE_URL + "/policy_details";
export const URL_INSURANCE_WEBSITES = BASE_URL + "/insurance_websites";

export const URL_CLIENT_EMPLOYEE_REQUEST = BASE_URL +  "/client_employee_requests";
export const URL_ROLES = BASE_URL +  "/roles";
export const URL_USER_ROLES = BASE_URL +  "/user_roles";
export const URL_CLIENT_EMPLOYEES = BASE_URL +  "/client_employees";
export const URL_OBLIGOS = BASE_URL +  "/obligos";
export const URL_BONDS = BASE_URL +  "/bonds";
export const URL_COLLATERALS = BASE_URL +  "/collaterals";
export const URL_AVAL_PREMIA = BASE_URL +  "/aval_premia";
export const URL_SINGLE_LIMITS = BASE_URL +  "/single_limits";

export const URL_UPDATE_REQUEST = BASE_URL + "/update_requests";
export const URL_QUOTATION_REQUEST = BASE_URL + "/quotation_requests";

export const URL_PROJECT = BASE_URL + "/projects";
export const URL_PROJECT_DETAILS = BASE_URL + "/project_details";

export const URL_EXTENSION_DOWNLOAD_LATEST = BASE_URL + "/extensions/download_latest";

export const URL_CONTACT_REQUEST = BASE_URL + "/contact_requests";

//Paths

export const PATH_ROOT = "/";

export const PATH_CONTACT = "/contact";
export const PATH_FORMS = "/forms";
export const PATH_LEXICON = "/lexicon";
export const PATH_IMPRINT = "/imprint";
export const PATH_INFORMATION_PRIVACY = "/information_privacy";
export const PATH_TERMS_OF_USE = "/terms_of_use";

export const PATH_LOGIN = "/login";
export const PATH_SIGNUP = "/signup";
export const PATH_FORGOT_PASSWORD = "/forgot_password";
export const PATH_RESET_PASSWORD = "/reset_password";
export const PATH_NEW_USER_PASSWORD = "/new_user_password";

export const PATH_AVAL_CLASSES = "/aval_classes";
export const PATH_INSURANCE_WEBSITES = "/insurance_websites";
export const PATH_CREATE_CONSULTANT = "/create_consultant";
export const PATH_CREATE_CLIENT = "/create_client";
export const PATH_CHANGE_PASSWORD = "/change_password";
export const PATH_USER_ROLES = "/user_roles";
export const PATH_ENABLE = "/enable";
export const PATH_DISABLE = "/disable";
export const PATH_CLIENT_EMPLOYEE_REQUESTS = "/client_employee_requests";
export const PATH_PROJECT = "/projects";

export const PATH_USERS = "/users";

export const PATH_UPDATE_REQUEST = "/update_requests";
export const PATH_QUOTATION_REQUEST = "/quotation_requests";

// Client Company Paths
export const PATH_CLIENT_COMPANIES = "/client_companies";
export const PATH_CLIENT_COMPANIES_NEW = "/client_companies/new";
export const PATH_CLIENT_COMPANIES_EDIT = "/client_companies/:id/edit";
export const PATH_CLIENT_COMPANIES_POLICIES = "/client_companies/:id/insurance_policies";
export const PATH_CLIENT_COMPANIES_POLICIES_NEW = "/client_companies/:id/insurance_policies/new";

// Insurance Policy Paths
export const PATH_INSURANCE_POLICIES = "/insurance_policies";
export const PATH_INSURANCE_POLICIES_EDIT = "/insurance_policies/:id/edit";
export const PATH_INSURANCE_POLICIES_POLICY_DETAILS_NEW = "/insurance_policies/:id/policy_details/new";
export const PATH_INSURANCE_POLICIES_PROJECTS_NEW = "/insurance_policies/:id/projects/new";
export const PATH_INSURANCE_POLICIES_AS_EXCEL = "/insurance_policies/as_excel";

// Insurance Policy Details Paths
export const PATH_POLICY_DETAILS = "/policy_details";
export const PATH_POLICY_DETAILS_EDIT = "/policy_details/:id/edit";
export const PATH_POLICY_DETAILS_LATEST = PATH_POLICY_DETAILS + "/latest";

//Project Paths
export const PATH_PROJECTS = "/projects";
export const PATH_PROJECTS_EDIT = "/projects/:id/edit";
export const PATH_PROJECTS_PROJECT_DETAILS_NEW = "/projects/:id/project_details/new";

// Project Details Paths
export const PATH_PROJECT_DETAILS = "/project_details";
export const PATH_PROJECT_DETAILS_EDIT = "/project_details/:id/edit";
export const PATH_PROJECT_DETAILS_LATEST = PATH_PROJECT_DETAILS + "/latest";

// Client Paths
export const PATH_CLIENT_POLICY_PORTAL = "/contracts";

// Contact Requests
export const PATH_CONTACT_REQUEST = "/contact_requests";

// Demo portal
export const PATH_DEMO_PORTAL = "/demo_portal";
export const PATH_DEMO_DETAILS = "/demo_details";

//New Ensurence
export const PATH_INSURANCES_COMPANY_NEW = "/insurance_companies/new";
export const PATH_INSURANCES_COMPANIES = "/insurance_companies";
export const PATH_INSURANCES_COMPANY_EDIT = "/insurance_companies/:id/edit";
export const PATH_INSURANCES_COMPANY_SHOW = "/insurance_companies/:id/show";

//import
export const PATH_IMPORT =  "/import_insurances";

