import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

class ClientCompanyCard extends Component {

    render() {
        return (
            <div style={{
                padding: "30px",
                width: "400px",
                minHeight: "100%",
                display: "table-cell",
                border: "1px solid #000000",
            }}>
                <div style={{position: "relatove"}}>
                    <h2>{this.props.clientCompany.company_name}</h2>
                    <div>
                        <span style={{paddingRight: "10px"}}><b>Kundennummer:</b></span>
                        <span>{this.props.clientCompany.customer_number}</span>
                    </div>
                    <div>
                        <span style={{paddingRight: "10px"}}><b>Addresse:</b></span>
                        <span>{this.props.clientCompany.address1}<br/></span>
                        <span>{this.props.clientCompany.address2}<br/></span>
                        <span>{this.props.clientCompany.zipcode}<br/></span>
                        <span>{this.props.clientCompany.city}<br/></span>
                        <span>{this.props.clientCompany.country}<br/></span>
                    </div>

                </div>
                <div style={{height: "60px"}}>

                    <div style={{position: "absolute", bottom: "0", paddingBottom: "30px"}}>
                        {this.props.openClientCompany &&
                        <button className="btn btn-primary" style={{width: "340px"}}
                                onClick={() => this.props.openClientCompany(this.props.clientCompany)}>
                            Ansehen
                        </button>
                        }


                        {this.props.selectClientCompany &&
                        <button className="btn btn-primary" style={{width: "340px"}}
                                onClick={() => this.props.selectClientCompany(this.props.clientCompany,
                                    this.props.index)}>
                            Auswählen
                        </button>
                        }

                        {this.props.deselectClientCompany &&
                        <button className="btn btn-warning" style={{width: "340px"}}
                                onClick={() => this.props.deselectClientCompany()}>
                            Abwählen
                        </button>
                        }

                        {this.props.showPolicies &&
                        <button className="btn btn-primary" style={{width: "340px"}}
                                onClick={() => this.props.showPolicies(this.props.clientCompany)}>
                            Verträge anzeigen
                        </button>
                        }
                    </div>
                </div>

            </div>
        )
    }
}

export default ClientCompanyCard;
