import React, {Component} from 'react';
import {NotificationContext} from "../../contexts/NotificationContext";
import {INSURANCE_VHV} from "../../../config/constants";
import NewPolicyDetails from "./NewPolicyDetails";
import {formatVHVpolicyNumber} from "../../../service/formatService";
import InsurancePolicy from "../../../models/InsurancePolicy";
import {Redirect, withRouter} from "react-router-dom";
import ability from "../../../Ability";
import * as Paths from "../../../config/paths";
import Error from "../../Error";

import {IoIosSave, IoMdClose} from 'react-icons/io'

import logo_fimo from '../../../static/extern/rv_logo.png';

class NewPolicyDetailsFormsContainer extends Component{

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            policy: props.location.policy || null,
            errorResponse: null,
            newDetails: null
        };

        this.createNewPolicyDetails = this.createNewPolicyDetails.bind(this);
        this.setNewDetails = this.setNewDetails.bind(this);
    }

    componentWillMount() {
        if (!this.props.location.policy){
            let {id} = this.props.match.params;

            this.getPolicy(id);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props){
            let {id} = nextProps.match.params;

            this.getPolicy(id);
        }
    }

    getPolicy(id){
        InsurancePolicy.getPolicy(id)
            .then(response => {
                if (response.status === 200){
                    let policy = new InsurancePolicy(response.body);

                    this.setState({
                        policy: policy,
                        errorResponse: null
                    });
                }
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Police nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    setNewDetails(details) {
        this.setState({
            newDetails: details
        })
    }

    createNewPolicyDetails(){

        this.state.policy.createDetails(this.state.newDetails)
            .then(response => {
                if (response.status === 201){
                    this.context.showMessage(null, "Stand wurde aktualisiert", "success");
                    this.props.history.goBack();
                }
                else {
                    this.context.showMessage(null, "Es ist ein unbekannter Fehler aufgetreten", "danger");
                }
            })
            .catch(err => {
                this._handlePolicyDetailsErrors(err);
            })
    }

    _handlePolicyDetailsErrors(err){
        let showMessage = this.context.showMessage;

        if (!err.response){
            return  showMessage(null, "Verbindungsfehler", "danger");
        }

        switch (err.response.status) {
            case 403:
                return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
            case 404:
                return showMessage(null, "Die Police wurde nicht gefunden", "danger");
            case 422:
                return showMessage(null, "Überprüfen Sie Ihre Angaben", "danger");
            case 500:
                return showMessage(null, "Unbekannter Fehler", "danger");
            default:
                return showMessage(null, "Unbekannter Fehler", "danger");
        }
    }

    render(){
        if (ability.cannot("create", "InsurancePolicyDetail")){
            return <Redirect to={Paths.PATH_ROOT}/>
        }

        if (this.state.errorResponse){
            return <Error
                status={this.state.errorResponse.status}
                statusText={this.state.errorResponse.statusText}/>
        }

        if (!this.state.policy){
            return  null;
        }

        const policyNumber = this.state.policy.insurance_company.name === INSURANCE_VHV
            ? formatVHVpolicyNumber(this.state.policy.policy_number)
            : this.state.policy.policy_number;

        return(
            <div className={"fimo-content-page"}>
                <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                    <div className="col text-right" style={{maxWidth: "100%"}}>
                        <button className="btn btn-outline-form"
                                onClick={() => this.createNewPolicyDetails()}>
                            <div>
                                <IoIosSave/>
                            </div>
                            Speichern
                        </button>
                        <button className="btn btn-outline-form"
                                type="button"
                                onClick={() => this.props.history.goBack()}>
                            <div>
                                <IoMdClose style={{color: " red"}}/>
                            </div>
                            Abbrechen
                        </button>
                    </div>
                </div>
                <div style={{padding: "30px"}}>
                    <div className="details-header">
                        {this.state.policy.insurance_company_id === 4 ?
                            <div style={{marginBottom: "50px"}}><img src={logo_fimo} height={28}/></div> :
                            <h4 className="text-fimo-green">{this.state.policy.insurance_company.name}</h4>
                        }
                        <h6>VSNR: {policyNumber}</h6>
                    </div>
                    <div className={"row"}>
                        {this.renderSpecificForm()}
                    </div>
                </div>
            </div>
        )
    }

    renderSpecificForm(){
        return <NewPolicyDetails
            setNewDetails={this.setNewDetails}/>;
    }
}

export default withRouter(NewPolicyDetailsFormsContainer);
