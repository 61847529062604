import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';

import * as DataService from "../service/dataService";


class CasehandlerPortal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            companyList: null,
            clientCompanyName: null,
            clientDataList: null,
            clientCompany: null,
            showNewPolicyForm: false
        }

        this.loadCompanyList = this.loadCompanyList.bind(this);
        this.updateclientCompanyName = this.updateclientCompanyName.bind(this);
        this.updatePage = this.updatePage.bind(this);
    }

    async loadCompanyList() {
        const companyData = await DataService.getCompanyList(this.props.authToken);

        let companyList = [];

        for(let company of companyData) {
            if(company) {
                companyList.push({
                    label: company.company_name,
                    value: company
                })
            }
        }

        this.setState({
            companyList: companyList
        })
    }

    updateclientCompanyName = (selectedOption) => {
        this.setState({
            clientCompanyName: selectedOption.label,
            clientDataList: null,
            clientCompany: selectedOption.value
        }, () => {
            this.updatePage();
        });
    }


    updatePage() {
        let clientCompanyName = this.state.clientCompanyName;
        let clientCompany = this.state.clientCompany;
        if(!clientCompanyName) {
            return;
        }

        this.props.updateCompany(clientCompany, clientCompanyName);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            clientCompanyName: nextProps.clientCompanyName,
            clientDataList: nextProps.clientDataList,
            clientCompany: nextProps.clientCompany
        });
    }


    componentDidMount() {
        this.setState({
            clientCompanyName: this.props.clientCompanyName,
            clientDataList: this.props.clientDataList,
            clientCompany: this.props.clientCompany
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.clientInput.focus();
    }


    render() {
        const options = this.state.companyList ? this.state.companyList : [];

        return (
            <div style={{padding: "20px"}}>
                <div>
                    <div className="form-group row">
                        <label className="col-1 col-form-label" style={{minWidth: "130px"}}>Klient-Name:</label>
                        <Select className="col-2"
                               options={options}
                               ref={(input) => { this.clientInput = input; }}
                               style={{maxWidth: "250px"}}
                               default={this.state.clientCompanyName}
                               autoFocus={true}
                               onChange={this.updateclientCompanyName}/>
                    </div>
                </div>

                <div style={{marginBottom: "40px"}}>
                    <hr></hr>
                </div>

                {this.renderInsurancePoliciesView()}
            </div>
        );
    }


    renderInsurancePoliciesView() {
        if(!this.props.clientDataList || this.props.clientDataList.length === 0
                || this.props.clientDataList[0] === null
                || this.props.clientDataList[0].numberOfPolicies === 0) {
            return this.renderNoDataForClient();
        }

        return (
            this.props.renderPolicyPortal(this.props.clientDataList)
        );
    }


    renderClientDoesNotExist() {
        return (
            <div style={{padding: "20px"}}>
                Es ist kein Kunde mit dem angegebenen Namen im System vorhanden.
            </div>
        )
    }


    renderNoDataForClient() {
        return (
            <div style={{padding: "20px"}}>
                Zu dem Kunden gibt es noch keine Datensätze.
            </div>
        )
    }
}


export default CasehandlerPortal;