import AvalClass from "./AvalClass";
import * as Paths from "../config/paths";
import request from 'superagent';

class SingleLimit {

    static get modelName() {
        return 'SingleLimit'
    }

    constructor(object){
        Object.assign(this, object, {aval_class: new AvalClass(object.aval_class)})
    }

    /**
     * Deletes the SingleLimit from the server.
     * @returns {Promise<any>}
     */
    deleteSingleLimit(){
        const url = `${Paths.URL_SINGLE_LIMITS}/${this.id}`;

        return new Promise((resolve, reject) => {
            request.delete(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .parse(({body}) => body)
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }
}

export default SingleLimit;