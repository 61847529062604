import * as Paths from "../config/paths";

import request from "superagent";

class Role {

    static get modelName() {
        return 'Role'
    }

    constructor(object){
        if (!object){
            return null;
        }

        Object.assign(this, object);
    }

    /**
     * Gets all roles from the server.
     *
     * @returns {Promise<any>}
     */
    static getRoles(){
        const url = Paths.URL_ROLES;

        return new Promise((resolve, reject) => {
            request.get(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }
}

export default Role;