import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Select from "react-select";
import InsuranceCompany from "../../models/InsuranceCompany";
import InsurancePolicy from "../../models/InsurancePolicy";
import {NotificationContext} from "../contexts/NotificationContext";
import {Redirect, withRouter} from "react-router-dom";
import ability from "../../Ability";
import * as Paths from "../../config/paths";
import Error from "../Error";

import {IoIosSave, IoMdClose} from 'react-icons/io'

class EditPolicy extends Component{

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            policy: props.location.policy || null,
            selectedInsuranceCompany: null,
            policyNumber: props.location.policy ? props.location.policy.policy_number : null,
            insuranceCompaniesSelectList: [],
            errorResponse: null
        };

        this.updatePolicyNumber = this.updatePolicyNumber.bind(this);
        this.updateSelectedInsuranceCompany = this.updateSelectedInsuranceCompany.bind(this);
        this.updatePolicy = this.updatePolicy.bind(this);
    }

    componentWillMount() {
        this.getInsuranceCompanies();

        if (!this.props.location.policy){
            let {id} = this.props.match.params;

            this.getPolicy(id);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props){
            let {id} = nextProps.match.params;

            this.getPolicy(id);
        }
    }

    getPolicy(id){
        InsurancePolicy.getPolicy(id)
            .then(response => {
                if (response.status === 200){
                    let policy = new InsurancePolicy(response.body);

                    this.setState({
                        policy: policy,
                        policyNumber: policy.policy_number,
                        errorResponse: null
                    });
                }
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Police nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    getInsuranceCompanies(){
        InsuranceCompany.getInsuranceCompanies()
            .then(response => {
                if (response.status === 200){
                    let insuranceCompanies = response.body;

                    let insuranceCompaniesSelectList = [];

                    for (let insuranceCompany of insuranceCompanies){
                        if (insuranceCompany){
                            insuranceCompaniesSelectList.push({
                                label: insuranceCompany.name,
                                value: insuranceCompany
                            });
                        }
                    }

                    this.setState({
                        insuranceCompaniesSelectList: insuranceCompaniesSelectList
                    });
                }
            })
            .catch(err => {
                this.context.showMessage(null, "Fehler beim Laden der Versicherungen", "danger");
            });
    }

    updatePolicyNumber(event){
        this.setState({
            policyNumber: event.target.value
        });
    }

    updateSelectedInsuranceCompany(selectedOption){
        this.setState({
            selectedInsuranceCompany: selectedOption.value
        });
    }

    _handlePolicyErrors(err){
        let showMessage = this.context.showMessage;

        if (!err.response){
            return  showMessage(null, "Verbindungsfehler", "danger");
        }

        switch (err.response.status) {
            case 403:
                return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
            case 404:
                return showMessage(null, "Die Versicherung wurde nicht gefunden", "danger");
            case 422:
                return showMessage(null, "Überprüfen Sie Ihre Angaben", "danger");
            case 500:
                return showMessage(null, "Unbekannter Fehler", "danger");
            default:
                return showMessage(null, "Unbekannter Fehler", "danger");
        }
    }

    updatePolicy(event){
        event.preventDefault();

        const selectedInsuranceCompany = this.state.selectedInsuranceCompany
                                            ? this.state.selectedInsuranceCompany
                                            : this.state.policy.insurance_company;

        let updatedPolicy = {
            insurance_company_id: selectedInsuranceCompany.id,
            policy_number: this.state.policyNumber
        };

        this.state.policy.updatePolicy(updatedPolicy)
            .then(response => {
                if (response.status === 200){
                    this.context.showMessage(null, "Police aktualisiert", "success");

                    this.setState({
                        policy: new InsurancePolicy(response.body),
                        selectedInsuranceCompany: selectedInsuranceCompany
                    }, () => this.props.history.goBack());
                }
                else{
                    this.context.showMessage(null, "Unbekannter Fehler", "danger");
                }
            })
            .catch(err => {
                this._handlePolicyErrors(err);
            });
    }

    render(){
        if (ability.cannot("update", this.state.policy)){
            return <Redirect to={Paths.PATH_ROOT}/>
        }

        if (this.state.errorResponse){
            return <Error
                status={this.state.errorResponse.status}
                statusText={this.state.errorResponse.statusText}/>
        }

        if (!this.state.policy){
            return  null;
        }

        const insurance_company = this.state.selectedInsuranceCompany
                                        ? this.state.selectedInsuranceCompany
                                        : this.state.policy.insurance_company;

        return(
            <div className={"fimo-content-page"}>
                <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                    <div className="col text-right" style={{maxWidth: "100%"}}>
                        <button className="btn btn-outline-form"
                                onClick={this.updatePolicy}>
                            <div>
                                <IoIosSave/>
                            </div>
                            Speichern
                        </button>
                        <button className="btn btn-outline-form"
                                type="button"
                                onClick={() => this.props.history.goBack()}>
                            <div>
                                <IoMdClose style={{color: " red"}}/>
                            </div>
                            Abbrechen
                        </button>
                    </div>
                </div>
                <div style={{padding: "30px"}}>
                    <div>
                        <h4>Policy bearbeiten</h4>
                    </div>
                    <div className={"row"}>
                        <form>
                            <div className="form-row">
                                <label className="col-form-label" style={{width: "200px"}}>
                                    <b>Versicherer:</b>
                                </label>

                                <Select
                                    className="col"
                                    options={this.state.insuranceCompaniesSelectList}
                                    ref={(input) => {this.clientInput = input;}}
                                    style={{maxWidth: "250px", minWidth: "130px"}}
                                    defaultValue={{label: insurance_company.name,
                                        value: insurance_company}}
                                    autoFocus={true}
                                    onChange={this.updateSelectedInsuranceCompany}/>
                            </div>

                            <div className="form-row">
                                <label className="col-form-label" style={{width: "200px"}}>
                                    <b>Vertragsnummer (*):</b>
                                </label>

                                <input className="col form-control"
                                       style={{marginLeft: "5px", marginRight: "5px"}}
                                       value={this.state.policyNumber}
                                       onChange={this.updatePolicyNumber}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(EditPolicy);
