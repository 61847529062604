import React, {Component} from 'react';

import User from "../../models/User";
import {NotificationContext} from "../contexts/NotificationContext";

import ability from "../../Ability";
import * as Paths from "../../config/paths";
import {Redirect} from "react-router-dom";

import {IoIosSave, IoMdClose} from 'react-icons/io'

class NewUser extends Component{

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            title: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            password: ''
        };

        this.updateTitle = this.updateTitle.bind(this);
        this.updateFirstName = this.updateFirstName.bind(this);
        this.updateLastName = this.updateLastName.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.updatePhone = this.updatePhone.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
    }

    updateTitle(event){
        this.setState({
            title: event.target.value
        });
    }

    updateFirstName(event){
        this.setState({
            firstName: event.target.value
        });
    }

    updateLastName(event){
        this.setState({
            lastName: event.target.value
        });
    }

    updateEmail(event){
        this.setState({
            email: event.target.value
        });
    }

    updatePhone(event){
        this.setState({
            phone: event.target.value
        });
    }

    updatePassword(event){
        this.setState({
            password: event.target.value
        });
    }

    submitNewUser(event){
        event.preventDefault();

        let newUser = new User({
            title: this.state.title,
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
            phone: this.state.phone,
            password: this.state.password
        });

        const isNewExternalUser = this.props.isNewExternalUser

        newUser.createUser(isNewExternalUser)
            .then(response => {
                if (response.status === 201){
                    this.context.showMessage(null, "Benutzer wurde angelegt", "success");
                    this.props.history.goBack();
                } else {
                    this.context.showMessage(null, "Unbekannter Fehler", "danger");
                }
            })
            .catch(err => {
                console.log(err)
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 422:
                        return showMessage(null, "Überprüfen Sie Ihre Angaben", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    render(){
        const isNewExternalUser = this.props.isNewExternalUser
        let actionName;
        let headline;
        if(isNewExternalUser) {
            actionName = "create_client"
            headline = "Kunden Anlegen"
        } else {
            actionName = "create_consultant"
            headline = "Mitarbeiter Anlegen"
        }

        if (ability.cannot(actionName, "User")){
            return <Redirect to={Paths.PATH_ROOT}/>
        }

        return(
            <div>
                <div className={"fimo-content-page"}>
                    <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                        <div className="col text-right" style={{maxWidth: "100%"}}>
                            <button className="btn btn-outline-form"
                                    onClick={(event) => this.submitNewUser(event)}>
                                <div>
                                    <IoIosSave/>
                                </div>
                                Speichern
                            </button>
                            <button className="btn btn-outline-form"
                                    type="button"
                                    onClick={() => this.props.history.goBack()}>
                                <div>
                                    <IoMdClose style={{color: " red"}}/>
                                </div>
                                Abbrechen
                            </button>
                        </div>
                    </div>
                    <div style={{padding: "30px"}}>
                        <h4 className="page-headline">{headline}</h4>

                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <form>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Titel:</label>
                                            <input className="col form-control"
                                                   value={this.state.title}
                                                   onChange={this.updateTitle}/>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Vorname:</label>
                                            <input className="col form-control"
                                                   value={this.state.firstName}
                                                   onChange={this.updateFirstName}/>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Nachname:</label>
                                            <input className="col form-control"
                                                   value={this.state.lastName}
                                                   onChange={this.updateLastName}/>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Email:</label>
                                            <input className="col form-control"
                                                   value={this.state.email}
                                                   onChange={this.updateEmail}/>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Telefon:</label>
                                            <input className="col form-control"
                                                   value={this.state.phone}
                                                   onChange={this.updatePhone}/>
                                        </div>

                                        {!isNewExternalUser &&
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Passwort:</label>
                                                <input className="col form-control"
                                                       type="password"
                                                       value={this.state.password}
                                                       onChange={this.updatePassword}/>
                                            </div>
                                        }
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NewUser;
