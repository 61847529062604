import React, {Component} from 'react';
import ClientCompanyCard from "../clientCompanies/ClientCompanyCard";

class ClientCompanySearchResult extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.client_company_list === null) {
            return <div></div>;
        }

        if (this.props.client_company_list.length === 0) {
            return (
                <div>
                    Keine passenden Firmen gefunden
                </div>
            );
        }

        if (this.props.client_company_list.length > 0) {
            return (
                <div className="container-fluid">
                    <div className="row">
                        {this.props.client_company_list.map((clientCompany) => this.renderClientCompany(clientCompany))}
                    </div>
                </div>
            )
        }
    }



    renderClientCompany(clientCompany) {
        if (!clientCompany.id) {
            return;
        }

        return (
            <div key={clientCompany.id}
                 style={{margin: "20px", minHeight: "100"}}>
                <div className="card card-default" style={{padding: "10px", minHeight: "100%", display: "table"}}>
                    <ClientCompanyCard
                        key={clientCompany.id}
                        clientCompany={clientCompany}
                        selectClientCompany={!this.props.selectedClientCompanies.includes(clientCompany) &&
                        !this.props.requestClientCompanyId ?
                            () => this.props.addSelectedClientCompany(clientCompany) : null}
                        deselectClientCompany={this.props.selectedClientCompanies.includes(clientCompany) ?
                            () => this.props.resetSelectedClientCompany(clientCompany) : null}
                    />
                </div>
            </div>
        );
    }

}


export default ClientCompanySearchResult
