import {Ability, AbilityBuilder} from "@casl/ability";

let ability = new Ability([]);

export function abilitiesFromRules(userRules) {
    const {rules, can, cannot} = AbilityBuilder.extract();

    if (!userRules){
        ability.update([]);

        return;
    }

    for (let rule of userRules){
        for (let role in rule){
            if (role === "admin" || role === "consultant" || role === "department manager"){
                can("download_latest", "Extension");
            }

            for (let permission of rule[role]){
                can(permission['action'], permission['subject'], permission['condition'])
            }
        }
    }

    ability.update(rules)
}

export default ability;