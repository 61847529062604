import React, {Component} from 'react';

import ClientCompany from "../../models/ClientCompany";
import {NotificationContext} from "../contexts/NotificationContext";
import {Redirect, withRouter} from "react-router-dom";
import ability from "../../Ability";
import Error from "../Error";
import * as Paths from "../../config/paths";

import {IoIosSave, IoMdClose} from 'react-icons/io'

class EditClientCompany extends Component {

    static contextType = NotificationContext;

    constructor(props) {
        super(props);

        this.state = {
            clientCompany: null,
            companyName: null,
            customerNumber: null,
            address1: null,
            address2: null,
            zipcode: null,
            city: null,
            country: null,
            errorResponse: null
        }

        this.updateCompanyName = this.updateCompanyName.bind(this);
        this.updateCustomerNumber = this.updateCustomerNumber.bind(this);
        this.updateAddress1 = this.updateAddress1.bind(this);
        this.updateAddress2 = this.updateAddress2.bind(this);
        this.updateZipcode = this.updateZipcode.bind(this);
        this.updateCity = this.updateCity.bind(this);
        this.updateCountry = this.updateCountry.bind(this);
    }

    componentWillMount() {
        let {id} = this.props.match.params;

        this.getClientCompany(id);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            let {id} = nextProps.match.params;

            this.getClientCompany(id);
        }
    }

    /**
     * Gets the client company from the server.
     */
    getClientCompany(id) {
        return ClientCompany.getClientCompany(id)
            .then(response => {
                if (response.status === 200) {
                    let clientCompany = new ClientCompany(response.body);

                    this.setState({
                        clientCompany: clientCompany,
                        companyName: clientCompany.company_name,
                        customerNumber: clientCompany.customer_number,
                        address1: clientCompany.address1,
                        address2: clientCompany.address2,
                        zipcode: clientCompany.zipcode,
                        city: clientCompany.city,
                        country: clientCompany.country,
                        errorResponse: null
                    });

                    return;
                }
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response) {
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Benutzer nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    updateCompanyName(event) {
        this.setState({
            companyName: event.target.value
        });
    }

    updateCustomerNumber(event) {
        this.setState({
            customerNumber: event.target.value
        });
    }

    updateAddress1(event) {
        this.setState({
            address1: event.target.value
        });
    }

    updateAddress2(event) {
        this.setState({
            address2: event.target.value
        });
    }

    updateZipcode(event) {
        this.setState({
            zipcode: event.target.value
        });
    }

    updateCity(event) {
        this.setState({
            city: event.target.value
        });
    }

    updateCountry(event) {
        this.setState({
            country: event.target.value
        });
    }

    updateClientCompany(event) {
        event.preventDefault();

        let updatedClientCompany = {
            company_name: this.state.companyName,
            customer_number: this.state.customerNumber,
            address1: this.state.address1,
            address2: this.state.address2,
            zipcode: this.state.zipcode,
            city: this.state.city,
            country: this.state.country
        };

        this.state.clientCompany.updateClientCompany(updatedClientCompany)
            .then(response => {
                if (response.status === 200) {
                    this.context.showMessage(null, "Firma wurde aktualisiert", "success");

                    this.setState({
                        clientCompany: new ClientCompany(response.body)
                    }, () => this.props.history.goBack());
                } else {
                    this.context.showMessage(null, "Unbekannter Fehler", "danger");
                }
            })
            .catch(err => {
                this._handleClientCompanyErrors(err);
            });
    }

    _handleClientCompanyErrors(err) {
        let showMessage = this.context.showMessage;

        if (!err.response) {
            return showMessage(null, "Verbindungsfehler", "danger");
        }

        switch (err.response.status) {
            case 403:
                return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
            case 404:
                return showMessage(null, "Die Firma wurde nicht gefunden", "danger");
            case 422:
                return showMessage(null, "Überprüfen Sie Ihre Angaben", "danger");
            case 500:
                if (err.response.body.errors === "Validation failed: Customer number has already been taken") {
                    return showMessage(null, "Kundennummer bereits vergeben", "danger");
                }

                return showMessage(null, "Unbekannter Fehler", "danger");
            default:
                return showMessage(null, "Unbekannter Fehler", "danger");
        }
    }

    render() {
        if (ability.cannot("update", "ClientCompany")) {
            console.log("redirect");
            return <Redirect to={Paths.PATH_ROOT}/>
        }

        if (this.state.errorResponse) {
            return <Error
                status={this.state.errorResponse.status}
                statusText={this.state.errorResponse.statusText}/>
        }

        if (!this.state.clientCompany) {
            return null;
        }

        return (
            <div>
                <div className={"fimo-content-page"}>
                    <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                        <div className="col text-right" style={{maxWidth: "100%"}}>
                            <button className="btn btn-outline-form"
                                    onClick={(event) => this.updateClientCompany(event)}>
                                <div>
                                    <IoIosSave/>
                                </div>
                                Speichern
                            </button>
                            <button className="btn btn-outline-form"
                                    type="button"
                                    onClick={() => this.props.history.goBack()}>
                                <div>
                                    <IoMdClose style={{color: " red"}}/>
                                </div>
                                Abbrechen
                            </button>
                        </div>
                    </div>
                    <div className={"row"} style={{padding: "30px"}}>
                        <div className="col-md-6">
                            <div>
                                <h4>Kunde bearbeiten:</h4>
                            </div>
                            <form>
                                <div>
                                    <div className="form-row">
                                        <label className="col-form-label"
                                               style={{minWidth: "350px"}}>
                                            Firmenname: *
                                        </label>

                                        <input className="col form-control"
                                               value={this.state.companyName}
                                               onChange={this.updateCompanyName}/>
                                    </div>

                                    <div className="form-row">
                                        <label className="col-form-label"
                                               style={{minWidth: "350px"}}>
                                            Kundennummer: *
                                        </label>

                                        <input className="col form-control"
                                               value={this.state.customerNumber}
                                               onChange={this.updateCustomerNumber}/>
                                    </div>

                                    <div className="form-row">
                                        <label className="col-form-label"
                                               style={{minWidth: "350px"}}>
                                            Addresse: *
                                        </label>

                                        <input className="col form-control"
                                               value={this.state.address1}
                                               onChange={this.updateAddress1}/>
                                    </div>

                                    <div className="form-row">
                                        <label className="col-form-label"
                                               style={{minWidth: "350px"}}>
                                            Addresszusatz:
                                        </label>

                                        <input className="col form-control"
                                               value={this.state.address2}
                                               onChange={this.updateAddress2}/>
                                    </div>

                                    <div className="form-row">
                                        <label className="col-form-label"
                                               style={{minWidth: "350px"}}>
                                            Postleitzahl: *
                                        </label>

                                        <input className="col form-control"
                                               value={this.state.zipcode}
                                               onChange={this.updateZipcode}/>
                                    </div>

                                    <div className="form-row">
                                        <label className="col-form-label"
                                               style={{minWidth: "350px"}}>
                                            Stadt: *
                                        </label>

                                        <input className="col form-control"
                                               value={this.state.city}
                                               onChange={this.updateCity}/>
                                    </div>

                                    <div className="form-row">
                                        <label className="col-form-label"
                                               style={{minWidth: "350px"}}>
                                            Land:
                                        </label>

                                        <input className="col form-control"
                                               value={this.state.country}
                                               onChange={this.updateCountry}/>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(EditClientCompany);