import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';

import * as serviceWorker from './serviceWorker';
import {abilitiesFromRules} from "./Ability";
import User from "./models/User";

/**
 * Checks if the user is logged in and sets the abilities if he is.
 * @returns {Promise<any>}
 */
function  checkLoginState(){
    return  new Promise((resolve, reject) => {

        let auth_token = sessionStorage.getItem("auth_token");
        let user = sessionStorage.getItem("user");

        if (user) {
            try {
                user = JSON.parse(user);
            } catch (e) {
                resolve();
            }
        }

        if (!auth_token || !user) {
            resolve();
        }

        return  User.getUser(user.id)
            .then(response => {
                if (response.status === 200) {
                    let refreshedUser = new User(response.body);

                    sessionStorage.setItem("user", JSON.stringify(refreshedUser));

                    let rules = JSON.parse(sessionStorage.getItem('rules'));

                    abilitiesFromRules(rules);
                }

                resolve();
            })
            .catch(err => {
                sessionStorage.clear();

                resolve();
            });
    });
}

checkLoginState().then(() => {
    ReactDOM.render(<App/>, document.getElementById('root'));

    serviceWorker.unregister();
})
    .catch(() => {
        ReactDOM.render(<App/>, document.getElementById('root'));

        serviceWorker.unregister();
    });