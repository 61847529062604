import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import PolicyCard from './PolicyCard';

import Can from '../Can';
import UpdateRequest from "../../models/UpdateRequest";
import InsurancePolicy from "../../models/InsurancePolicy";
import InsurancePolicyDetail from "../../models/InsurancePolicyDetail";
import {convertBackendDataToPortalData} from '../../service/conversionService';
import {NotificationContext} from "../contexts/NotificationContext";
import ClientCompany from "../../models/ClientCompany";
import Error from "../Error";
import {withRouter} from "react-router-dom";

import * as Paths from "../../config/paths";
import Link from "react-router-dom/es/Link";

import {IoMdArrowRoundBack, IoIosLocate, IoIosAddCircleOutline, IoMdGrid} from 'react-icons/io'
import {TAB_TITLE_BASE, TAB_TITLE_CONNECTOR} from "../../config/constants";


class PolicyPortal extends Component {

    static contextType = NotificationContext;

    constructor(props) {
        super(props);

        this.state = {
            clientCompany: null,
            policies: [],
            policyData: [],
            errorResponse: null
        };

        this.createUpdateRequest = this.createUpdateRequest.bind(this);
        this.deletePolicy = this.deletePolicy.bind(this);
    }

    componentWillMount() {
        let id = null;

        if (this.props.clientCompany){
            id = this.props.clientCompany.id;
        }
        else {
            id = this.props.match.params.id;
        }

        this.getClientCompany(id);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props){
            let id = null;

            if (nextProps.clientCompany){
                id = nextProps.clientCompany.id;
            }
            else {
                id = nextProps.match.params.id;
            }

            this.getClientCompany(id);
        }
    }

    /**
     * Gets the client company from the server.
     */
    getClientCompany(id){
        return ClientCompany.getClientCompany(id)
            .then(response => {
                if (response.status === 200){
                    let clientCompany = new ClientCompany(response.body);

                    this.setState({
                        clientCompany: clientCompany,
                        showNewPolicyForm: false,
                        policies: [],
                        policyData: [],
                        errorResponse: null
                    }, () =>{
                        this.loadPolicies();
                    });
                }
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Benutzer nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    loadPolicies(){
        return this.state.clientCompany.getInsurancePolicies()
            .then(response => {
                if (response.status === 200){
                    let policies = response.body.map(policy => new InsurancePolicy(policy));

                    let policyData = [];

                    let promises = [];

                    policies.forEach(policy => {
                        promises.push(
                            policy.getLatestDetails()
                                .then(async (response) => {
                                    if (response.status === 200) {
                                        let details = new InsurancePolicyDetail(response.body);

                                        details.convertedData = await convertBackendDataToPortalData(response.body,
                                            policy.insurance_company.name,
                                            sessionStorage.getItem('auth_token'));

                                        details.displayData = {
                                            policyNumber: policy.policy_number,
                                            insuranceName: policy.insurance_company.name
                                        };

                                        details.policy = policy;

                                        policyData.push(details);
                                    }
                                    else {
                                        let details = new InsurancePolicyDetail();
                                        details.displayData = {
                                            policyNumber: policy.policy_number,
                                            insuranceName: policy.insurance_company.name
                                        };

                                        details.policy = policy;

                                        policyData.push(details);
                                    }
                                })
                                .catch(err => {
                                    console.log("error while fetching policydetails", err);
                                })
                        );
                    });

                    Promise.all(promises)
                        .then(() => {
                            const rvData = policyData.filter((policy) => policy.policy.insurance_company_id === 4);
                            const rvvDataSorted = rvData.sort(
                                (policy1, policy2) => (parseInt(policy1.policy.id) > parseInt(policy2.policy.id)) ? 1 : -1);

                            const nonRvData = policyData.filter((policy) => policy.policy.insurance_company_id !== 4);
                            const nonRvDataSorted = nonRvData.sort(
                                (policy1, policy2) => (parseInt(policy1.policy.id) > parseInt(policy2.policy.id)) ? 1 : -1);

                            const sortedData = rvvDataSorted.concat(nonRvDataSorted);

                            this.setState({
                                policies: policies,
                                policyData: sortedData
                            });
                        });
                }
            })
            .catch(err => {
                console.log(err);

                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Beziehung nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    loadPoliciesAsExcel(){
        this.state.clientCompany.getPoliciesAsExcel()
            .then(response => {
                if (response.status === 200){
                    let today = new Date();
                    let filename = `Bürgschaftsmanagement ${this.state.clientCompany.company_name} ${today.getDate()}_${today.getMonth() + 1}_${today.getFullYear()}`;
                    filename = filename.replace(/\./g,' ')

                    this._saveFile(response.body, filename);

                    return response.body;
                }

        })
            .catch(err => {
                console.log(err);

                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Beziehung nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    _saveFile(blob, filename){
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";

        var url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = filename
        a.click();

        window.URL.revokeObjectURL(url);
    }

    createUpdateRequest(){
        let updateRequest = new UpdateRequest({
            client_company_id: this.state.clientCompany.id
        });

        updateRequest.createUpdateRequest().then(response => {
            if (response.status === 201){
                this.context.showMessage(null, "Aktualisierungsanfrage erfolgreich erstellt", "success");
            }
            else {
                this.context.showMessage(null, "Aktualisierungsanfrage nicht erstellt \n" +
                    `Grund: ${response.statusText}`, "danger");
            }
        }).catch(e => {
            this.context.showMessage(null, "Aktualisierungsanfrage nicht erstellt. \n" +
                `Grund: ${e.response.statusText}`, "danger");
        });
    }

    deletePolicy(policy) {
        policy.deletePolicy().then(response => {
            if (response.status === 200) {
                this.context.showMessage(null, "Vertrag erfolgreich entfernt", "success");
                this.loadPolicies();
            }
            else {
                this.context.showMessage(null, "Vertrag konnte nicht gelöscht werden", "danger");
            }
        }).catch(e => {
            this.context.showMessage(null, "Vertrag konnte nicht gelöscht werden. \n" +
                `Grund: ${e.response.statusText}`, "danger");
        })
    }

    renderPolicy(policy){
        return(
            <PolicyCard
                key={policy.policy.id}
                policyData={policy}
                deletePolicy={this.deletePolicy}
            />
        )
    }

    renderPolicies(){
        if (this.state.policies.length === 0){
            return(
                <div>
                    Keine Kautionen vorhanden.
                </div>
            );
        }

        return (
            <div className="container-fluid">
                <div className="row">
                    {this.state.policyData.map(policy => this.renderPolicy(policy))}
                </div>
            </div>
        )
    }

    render() {
        document.title = TAB_TITLE_BASE + TAB_TITLE_CONNECTOR + "Vertragsübersicht";

        if (this.state.errorResponse){
            return <Error
                status={this.state.errorResponse.status}
                statusText={this.state.errorResponse.statusText}/>
        }

        if (!this.state.clientCompany){
            return  null;
        }

        return (
            <div className={"fimo-content-page"}>
                <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                    <div className="col text-right" style={{maxWidth: "100%"}}>
                        <div style={{paddingBottom: "10px"}}>
                            {this.props.location.pathname !== Paths.PATH_CLIENT_POLICY_PORTAL &&
                                <button className="btn btn-outline-form"
                                    onClick={() => this.props.history.goBack()}>
                                    <div>
                                        <IoMdArrowRoundBack />
                                    </div>
                                    Zurück
                                </button>
                            }
                            <Can I="create" on="UpdateRequest">
                                <button className="btn btn-outline-form"
                                        onClick={this.createUpdateRequest}>
                                    <div>
                                        <IoIosLocate />
                                    </div>
                                    Aktualisierung anfordern
                                </button>
                            </Can>
                            <Can I="create" on="InsurancePolicy">
                                <Link to={{ pathname: `${Paths.PATH_CLIENT_COMPANIES}/${this.state.clientCompany.id}${Paths.PATH_INSURANCE_POLICIES}/new`,
                                    clientCompany: this.state.clientCompany}}>
                                    <button className="btn btn-outline-form">
                                        <div>
                                            <IoIosAddCircleOutline />
                                        </div>
                                        Police anlegen
                                    </button>
                                </Link>
                            </Can>
                            {this.state.clientCompany &&
                                <Can I="as_excel" on="InsurancePolicy">
                                    <button className="btn btn-outline-form"
                                            onClick={() => this.loadPoliciesAsExcel()}>
                                        <div>
                                            <IoMdGrid />
                                        </div>
                                        Excel-Datei laden
                                    </button>
                                </Can>
                            }
                        </div>
                    </div>
                </div>

                <div style={{padding: "30px"}}>
                    <h4 className="page-headline">Firma: {this.state.clientCompany.company_name}</h4>
                    <div>
                        {this.renderPolicies()}
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(PolicyPortal);
