import React, {Component} from 'react';
import Table from 'react-bootstrap/Table';

import 'bootstrap/dist/css/bootstrap.min.css';
import ClientCompany from "../../models/ClientCompany";

import Can from '../Can';
import {NotificationContext} from "../contexts/NotificationContext";
import ability from "../../Ability";
import {Redirect, withRouter} from "react-router-dom";
import * as Paths from "../../config/paths";
import Link from "react-router-dom/es/Link";
import Select from "react-select";
import Extension from "../../models/Extension";
import * as ClientCompanyService from "../../service/clientCompanyService";


import {IoIosAddCircleOutline, IoIosOpen, IoMdCreate, IoMdDownload, IoMdTrash} from 'react-icons/io'
import {TAB_TITLE_BASE, TAB_TITLE_CONNECTOR} from "../../config/constants";
import DeleteDialog from '../DeleteDialog';

class ClientCompanyOverview extends Component {

    static contextType = NotificationContext;

    constructor(props) {
        super(props);

        this.state = {
            clientCompanies: null,
            clientCompaniesToShow: null,
            radiobox_showAll: true,
            radiobox_searchName: true,
            showDeletionDialog: false,
            deletionText: "",
            confirmDeleteRequest: () => {}
        };

        this.getSelectionOptions = this.getSelectionOptions.bind(this);
        this.loadExtension = this.loadExtension.bind(this);
    }

    initData() {
        ClientCompany.getClientCompanies()
            .then(response => {
                if (response.status === 200) {
                    let clientCompanies = response.body;
                    let allCompanies = clientCompanies.map(clientCompany => new ClientCompany(clientCompany));

                    allCompanies = allCompanies.sort((a, b) => {
                        if (a.company_name.toLocaleLowerCase() < b.company_name.toLocaleLowerCase()) {
                            return -1;
                        } else if (a.company_name > b.company_name) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });

                    this.setState({
                        clientCompanies: allCompanies,
                        clientCompaniesToShow: allCompanies
                    });
                }
            })
            .catch(err => {
                this._handleClientCompanyErrors(err);
            });
    }

    _handleClientCompanyErrors(err) {
        let showMessage = this.context.showMessage;

        if (!err.response) {
            return showMessage(null, "Verbindungsfehler", "danger");
        }

        switch (err.response.status) {
            case 403:
                return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
            case 500:
                return showMessage(null, "Unbekannter Fehler", "danger");
            default:
                return showMessage(null, "Unbekannter Fehler", "danger");
        }
    }

    componentDidMount() {
        this.initData();
    }

    getSelectionOptions() {
        let companies = this.state.clientCompanies.map((clientCompany) => clientCompany.company_name);
        let customerNumbers = this.state.clientCompanies.map((clientCompany) => clientCompany.customer_number);

        companies = companies.sort();
        customerNumbers = customerNumbers.sort((a, b) => Number(a) > Number(b));

        let list = this.state.radiobox_searchName ? companies : customerNumbers;


        let options = [];

        for (let item of list) {
            options.push({
                label: item,
                value: item
            })
        }

        return options;
    }

    executeFilter(selectedOption) {
        const selectedIem = selectedOption.value;

        const clientCompaniesToShow = this.state.radiobox_searchName
            ? this.state.clientCompanies.filter(clientCompany => clientCompany.company_name === selectedIem)
            : this.state.clientCompanies.filter(clientCompany => clientCompany.customer_number === selectedIem)

        this.setState({
            clientCompaniesToShow: clientCompaniesToShow
        });

    }

    loadExtension(){
        Extension.downloadExtension()
            .then(response => {
                if (response.status === 200){
                    let today = new Date();
                    let filename = `fimo-extension.zip`;

                    this._saveFile(response.body, filename);

                    return response.body;
                }

            })
            .catch(err => {
                console.log(err);

                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Beziehung nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    _saveFile(blob, filename){
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";

        var url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = filename
        a.click();

        window.URL.revokeObjectURL(url);
    }

    render() {
        document.title = TAB_TITLE_BASE + TAB_TITLE_CONNECTOR + "Kundenübersicht";

        if (ability.cannot("index", "ClientCompany")) {
            console.log("redirect");
            return <Redirect to={Paths.PATH_ROOT}/>
        }

        return (
            <div className={"fimo-content-page"}>

                {this.state.showDeletionDialog &&
                    <DeleteDialog
                        elementText={this.state.deletionText}
                        cancel={() =>
                            this.setState({
                                showDeletionDialog: false,
                                deletionText: '',
                                confirmDeleteRequest: () => void 0
                            })
                        }
                        confirmDelete={this.state.confirmDeleteRequest}
                    />
                }

                <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                    <div className="col text-right" style={{maxWidth: "100%"}}>
                        <Can I="create" on="ClientCompany">
                            <Link to={Paths.PATH_CLIENT_COMPANIES_NEW}>
                                <button
                                    className="btn btn-outline-form float-right">
                                    <div>
                                        <IoIosAddCircleOutline />
                                    </div>
                                    Firma anlegen
                                </button>
                            </Link>
                        </Can>

                        <Can I="download_latest" on="Extension">
                            <button
                                className="btn btn-outline-form float-right"
                                onClick={this.loadExtension}>
                                <div>
                                    <IoMdDownload/>
                                </div>
                                Extension laden
                            </button>
                        </Can>
                    </div>
                </div>


                <div className={"row"} style={{backgroundColor: "white", padding: "20px", marginRight: "75px", marginLeft: "75px"}}>

                    <div>
                        <label>
                            <input type="radio"
                                   value={this.state.radiobox_showAll}
                                   checked={this.state.radiobox_showAll}
                                   onClick={() => {
                                       this.setState({
                                           radiobox_showAll: true,
                                           clientCompaniesToShow: this.state.clientCompanies
                                       })
                                   }}
                                   style={{marginRight: "8px"}}/>
                            Alle anzeigen
                        </label>
                        <label style={{marginLeft: "20px"}}>
                            <input type="radio"
                                   value={!this.state.radiobox_showAll}
                                   checked={!this.state.radiobox_showAll}
                                   onClick={() => {
                                       this.setState({radiobox_showAll: false})
                                   }}
                                   style={{marginRight: "8px"}}/>
                            Suchfilter
                        </label>
                    </div>

                    {!this.state.radiobox_showAll &&
                    <div className={"row"}>
                                <span style={{marginLeft: "154px", marginRight: "30px", marginTop: "5px"}}>
                                    <label>
                                        <input type="radio"
                                               value={this.state.radiobox_searchName}
                                               checked={this.state.radiobox_searchName}
                                               onClick={() => {
                                                   this.setState({radiobox_searchName: true})
                                               }}
                                               style={{marginRight: "8px"}}/>
                                        Firmenname
                                    </label>
                                    <label style={{marginLeft: "20px"}}>
                                        <input type="radio"
                                               value={!this.state.radiobox_searchName}
                                               checked={!this.state.radiobox_searchName}
                                               onClick={() => {
                                                   this.setState({radiobox_searchName: false})
                                               }}
                                               style={{marginRight: "8px"}}/>
                                        Kundennummer
                                    </label>
                                </span>


                        <span style={{maxWidth: "750px", minWidth: "300px"}}>
                                    <Select
                                        className="col"
                                        options={this.getSelectionOptions()}
                                        autoFocus={true}
                                        onChange={(selectedOption) => this.executeFilter(selectedOption)}/>
                                </span>
                    </div>
                    }
                </div>

                <div style={{marginTop: "50px"}}>
                    {this.state.clientCompaniesToShow === null || this.state.clientCompaniesToShow === 0
                        ?
                        <span>Keine Firmen gefunden</span>
                        :
                        <div>
                            {this.renderClientCompaniesTable()}
                        </div>}
                </div>
            </div>
        )
    }

    renderClientCompaniesTable() {
        return (
            <div>
                <Table responsive>
                    <thead>
                    <tr>
                        <th>Firmenname</th>
                        <th>Knr.</th>
                        <th>Addresse</th>
                        <th>Postleitzahl</th>
                        <th>Stadt</th>
                        <th>Land</th>
                        <th>Verfügbare Aktionen</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.clientCompaniesToShow.map(clientCompany => this.renderClientCompanyCell(clientCompany))}
                    </tbody>
                </Table>
            </div>
        );
    }

    renderClientCompanyCell(clientCompany) {
        return (
            <tr key={clientCompany.id}>
                <td>{clientCompany.company_name}</td>
                <td>{clientCompany.customer_number}</td>
                <td>
                    {clientCompany.address1}<br/>
                    {clientCompany.address2}
                </td>
                <td>{clientCompany.zipcode}</td>
                <td>{clientCompany.city}</td>
                <td>{clientCompany.country}</td>
                <td>
                    <Can I="update" this={clientCompany}>
                        <Link to={`${Paths.PATH_CLIENT_COMPANIES}/${clientCompany.id}/edit`}>
                            <button
                                className="btn btn-outline-form-slim"
                                style={{margin: "5px"}}>
                                <IoMdCreate style={{marginRight: "10px"}} />
                                Bearbeiten
                            </button>
                        </Link>
                    </Can>

                    <Can I="show" on="InsurancePolicy">
                        <Link to={`${Paths.PATH_CLIENT_COMPANIES}/${clientCompany.id}${Paths.PATH_INSURANCE_POLICIES}`}>
                            <button
                                className="btn btn-outline-form-slim"
                                style={{margin: "5px"}}>
                                <IoIosOpen style={{marginRight: "10px"}} />
                                Verträge anzeigen
                            </button>
                        </Link>
                    </Can>

                    <Can I="destroy" this={clientCompany}>
                        <button
                            className="btn btn-outline-form-slim"
                            style={{margin: "5px"}}
                            onClick={(event) =>
                                {
                                    this.setState({
                                        showDeletionDialog: true,
                                        deletionText: `Firma ${clientCompany.company_name}`,
                                        confirmDeleteRequest: () => ClientCompanyService.deleteClientCompany(event, clientCompany,
                                            this.context.showMessage, () => {window.location.reload();})
                                    })
                                }
                            }>
                            <IoMdTrash style={{marginRight: "10px"}} />
                            Löschen
                        </button>
                    </Can>
                </td>
            </tr>
        );
    }
}

export default withRouter(ClientCompanyOverview);
