import React, {Component} from 'react';
import {NotificationContext} from "../contexts/NotificationContext";
import * as Constants from "../../config/constants";

import ProjectDetailsVHV from './ProjectDetailsVHV';
import Can from "../Can";

import EditProjectDetailsFormsContainer from './EditProjectDetailsForms/EditProjectDetailsFormsContainer';
import ProjectDetailsSwissRe from "./ProjectDetailsSwissRe";
import ProjectDetailsAXA from "./ProjectDetailsAXA";
import ProjectDetailsEulerHermes from "./ProjectDetailsEulerHermes";
import ProjectDetailsRuV from "./ProjectDetailsRuV";
import {withRouter} from "react-router-dom";
import Project from "../../models/Project";
import InsurancePolicy from "../../models/InsurancePolicy";
import InsurancePolicyDetail from "../../models/InsurancePolicyDetail";
import {convertProjectDetailsForDisplay} from "../../service/conversionService";
import Error from "../Error";

import * as Paths from "../../config/paths";
import Link from "react-router-dom/es/Link";

import {IoMdCreate, IoIosTrash, IoMdArrowRoundBack} from 'react-icons/io'


class ProjectDetailsContainer extends Component {

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            project: props.location.project || null,
            policy: props.location.policy || null,
            errorResponse: null
        };
    }

    componentWillMount() {
        if (!this.props.location.project){
            let {id} = this.props.match.params;

            this.loadProjectDetails(id);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props){
            let {id} = nextProps.match.params;

            this.loadProjectDetails(id);
        }
    }

    loadProjectDetails(id){
        return InsurancePolicyDetail.getInsurancePolicyDetail(id)
            .then(response => {
                if (response.status === 200){
                    let details = new InsurancePolicyDetail(response.body);

                    return details;
                }

                return  null;
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Details nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            })
            .then(details => {
                if (details){
                    this.getProject(details.project_id)
                        .then(project => {
                            if (project) {
                                this.getPolicy(project.insurance_policy_id)
                                    .then(async (policy) => {
                                        project.projectDetails = details;

                                        project.convertedData = await convertProjectDetailsForDisplay(details, policy);

                                        this.setState({
                                            project: project,
                                            policy: policy,
                                            errorResponse: null
                                        });
                                    });
                            }
                        });
                }
            });
    }

    getProject(id){
        return Project.getProject(id)
            .then(response => {
                if (response.status === 200){
                    let project = new Project(response.body);

                    return project;
                }

                return  null;
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Projekt nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    getPolicy(id){
        return InsurancePolicy.getPolicy(id)
            .then(response => {
                if (response.status === 200){
                    let policy = new InsurancePolicy(response.body);

                    return policy;
                }

                return  null;
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Police nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    deleteProjectDetails(event){
        event.preventDefault();

        this.state.project.projectDetails.deleteInsurancePolicyDetail()
            .then(response => {
                if (response.status === 200){
                    this.context.showMessage(null, "Stand wurde gelöscht", "success");

                    return this.props.history.goBack();
                }

                this.context.showMessage(null, "Unbekannter Fehler", "danger");
            })
            .catch(err => {
                this._handleProjectDetailsErrors(err);
            });
    }

    _handleProjectDetailsErrors(err){
        let showMessage = this.context.showMessage;

        if (!err.response){
            return  showMessage(null, "Verbindungsfehler", "danger");
        }

        switch (err.response.status) {
            case 403:
                return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
            case 404:
                return showMessage(null, "Die Details wurden nicht gefunden", "danger");
            case 422:
                return showMessage(null, "Überprüfen Sie Ihre Angaben", "danger");
            case 500:
                return showMessage(null, "Unbekannter Fehler", "danger");
            default:
                return showMessage(null, "Unbekannter Fehler", "danger");
        }
    }

    render(){
        if (this.state.errorResponse){
            return <Error
                status={this.state.errorResponse.status}
                statusText={this.state.errorResponse.statusText}/>
        }

        if (!this.state.project){
            return  null;
        }

        if (!this.state.policy){
            return  null;
        }

        return(
            <div className={"fimo-content-page"}>
                <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                    <div className="col text-right" style={{maxWidth: "100%"}}>
                        <button className="btn btn-outline-form"
                                type="button"
                                onClick={() => this.props.history.goBack()}>
                            <div>
                                <IoMdArrowRoundBack />
                            </div>
                            Zurück
                        </button>

                        <Can I="update" this={this.state.project.projectDetails}>
                            <Link to={{pathname: `${Paths.PATH_PROJECT_DETAILS}/${this.state.project.projectDetails.id}/edit`,
                                project: this.state.project,
                                policy: this.state.policy}}>
                                <button
                                    className="btn btn-outline-form"
                                    style={{marginRight: "5px"}}>
                                    <div>
                                        <IoMdCreate/>
                                    </div>
                                    Bearbeiten
                                </button>
                            </Link>
                        </Can>
                        <Can I="destroy" this={this.state.project.projectDetails}>
                            <button
                                className="btn btn-outline-form"
                                onClick={(event) => this.deleteProjectDetails(event)}>
                                <div>
                                    <IoIosTrash/>
                                </div>
                                Löschen
                            </button>
                        </Can>
                    </div>
                </div>


                <div className="container" style={{padding: "30px"}}>
                    <div className="details-header">
                        <h4 className="text-fimo-green d-inline-block">{this.state.project.project_name}</h4>
                        {this.state.project.project_number &&
                            <h5>VSNR: {this.state.project.project_number}</h5>}
                    </div>

                    {this.renderProjectDetailsArea()}
                </div>
            </div>
        );
    }

    renderProjectDetailsArea() {
        const insurance = this.state.policy.insurance_company.name;

        switch(insurance) {
            case Constants.INSURANCE_VHV:
                return <ProjectDetailsVHV
                    project={this.state.project}/>
            case Constants.INSURANCE_SWISSRE:
                return  <ProjectDetailsSwissRe
                    project={this.state.project}/>
            case Constants.INSURANCE_AXA:
                return  <ProjectDetailsAXA
                    project={this.state.project}/>
            case Constants.INSURANCE_EULERHERMES:
                return  <ProjectDetailsEulerHermes
                    project={this.state.project}/>
            case Constants.INSURANCE_RV:
                return  <ProjectDetailsRuV
                    project={this.state.project}/>
            default:
                return <div>Diese Versicherung kenne ich leider nicht...</div>
        }
    }

}

export default withRouter(ProjectDetailsContainer);