import React, {Component} from 'react';

import {NotificationContext} from "../contexts/NotificationContext";
import {TAB_TITLE_BASE, TAB_TITLE_CONNECTOR} from "../../config/constants";
import DemoPolicyCard from "./DemoPolicyCard";

import * as DemoData from "./DemoData";
import * as Paths from "../../config/paths";
import {withRouter} from "react-router-dom";

import {IoMdArrowRoundBack, IoIosLocate, IoIosAddCircleOutline, IoMdGrid} from 'react-icons/io'

const clientCompany = {
    company_name: "Musterfirma GmbH"
};

class DemoPortal extends Component {

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            clientCompany: clientCompany,
            policies: DemoData.policies,
            policyData: DemoData.policyData
        };

        this.createUpdateRequest = this.createUpdateRequest.bind(this);
    }

    createUpdateRequest(){
        this.context.showMessage(null, "Eine Aktualisierungsanfrage würde nun erstellt werden", "success");
    }

    renderPolicy(policy){
        return(
            <DemoPolicyCard
                key={policy.policy.id}
                policyData={policy}
            />
        )
    }

    renderPolicies(){
        if (this.state.policies.length === 0){
            return(
                <div>
                    Keine Kautionen vorhanden.
                </div>
            );
        }

        return (
            <div className="container-fluid">
                <div className="row">
                    {this.state.policyData.map(policy => this.renderPolicy(policy))}
                </div>
            </div>
        )
    }

    render(){
        document.title = TAB_TITLE_BASE + TAB_TITLE_CONNECTOR + "Demoportal";

        return (
            <div className={"fimo-content-page"}>
                <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                    <div
                    style={{color: "red"}}>
                        WICHTIG: Hierbei handelt es sich um Beispieldaten!
                    </div>
                    <div className="col text-right" style={{maxWidth: "100%"}}>
                        <div style={{paddingBottom: "10px"}}>
                            <button className="btn btn-outline-form"
                                    onClick={this.createUpdateRequest}>
                                <div>
                                    <IoIosLocate />
                                </div>
                                Aktualisierung anfordern
                            </button>

                        </div>
                    </div>
                </div>

                <div style={{padding: "30px"}}>
                    <h4 className="page-headline">Firma: {this.state.clientCompany.company_name}</h4>
                    <div>
                        {this.renderPolicies()}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(DemoPortal);