import React, {Component} from 'react';
import {NotificationContext} from "../contexts/NotificationContext";
import QuotationRequest from "../../models/QuotationRequest";
import Can from "../Can";

import Table from 'react-bootstrap/Table';
import Error from "../Error";
import {withRouter} from "react-router-dom";

import {
    IoMdArrowRoundBack,
    IoIosPaperPlane,
    IoMdRadioButtonOff,
    IoMdCheckmarkCircleOutline,
    IoMdClose,
    IoMdTrash
} from 'react-icons/io'
import {translateRequestState} from "../../service/languageService";
import {TAB_TITLE_BASE, TAB_TITLE_CONNECTOR} from "../../config/constants";
import DeleteDialog from '../DeleteDialog';
import { PATH_QUOTATION_REQUEST } from '../../config/paths';


class QuotationRequestPage extends Component {

    static contextType = NotificationContext;

    constructor(props) {
        super(props);

        this.state = {
            quotationRequest: null,
            deletionText: "",
            confirmDeleteRequest: () => {}
        };
    }

    componentWillMount() {
        let {id} = this.props.match.params;

        this.getQuotationRequest(id);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            let {id} = this.props.match.params;

            this.getQuotationRequest(id);
        }
    }

    /**
     * Gets the user from the server.
     */
    getQuotationRequest(id) {
        return QuotationRequest.getQuotationRequest(id)
            .then(response => {
                if (response.status === 200) {
                    let quotationRequest = new QuotationRequest(response.body);

                    this.setState({
                        quotationRequest: quotationRequest,
                        errorResponse: null
                    });

                    return;
                }
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response) {
                    return showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Anfrage nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    deleteQuotationRequest() {
        this.state.quotationRequest.deleteQuotationRequest().then(response => {
            if (response.status === 200) {
                this.context.showMessage(null, "Angebotsanfrage gelöscht", "success");

                this.props.history.push(PATH_QUOTATION_REQUEST);
            }
        }).catch(err => {
            this._handleQuotationRequestErrors(err);
        });
    }

    startQuotationRequest() {
        this.state.quotationRequest.startQuotationRequest().then(response => {
            if (response.ok) {
                let quotationRequest = new QuotationRequest(response.body);

                this.setState({
                    quotationRequest: quotationRequest
                });
            }
        }).catch(err => {
            this._handleQuotationRequestErrors(err);
        });
    }

    pauseQuotationRequest() {
        this.state.quotationRequest.pauseQuotationRequest().then(response => {
            if (response.ok) {
                let quotationRequest = new QuotationRequest(response.body);

                this.setState({
                    quotationRequest: quotationRequest
                });
            }
        }).catch(err => {
            this._handleQuotationRequestErrors(err);
        });
    }

    completeQuotationRequest() {
        this.state.quotationRequest.completeQuotationRequest().then(response => {
            if (response.ok) {
                let quotationRequest = new QuotationRequest(response.body);

                this.setState({
                    quotationRequest: quotationRequest
                });
            }
        }).catch(err => {
            this._handleQuotationRequestErrors(err);
        });
    }

    cancelQuotationRequest() {
        this.state.quotationRequest.cancelUpdateRequest().then(response => {
            if (response.ok) {
                let quotationRequest = new QuotationRequest(response.body);

                this.setState({
                    quotationRequest: quotationRequest
                });
            }
        }).catch(err => {
            this._handleQuotationRequestErrors(err);
        });
    }

    _handleQuotationRequestErrors(err) {
        let showMessage = this.context.showMessage;

        if (!err.response) {
            return showMessage(null, "Verbindungsfehler", "danger");
        }

        switch (err.response.status) {
            case 403:
                return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
            case 404:
                return showMessage(null, "Angebotssanfrage nicht gefunden", "danger");
            case 409:
                return showMessage(null, "Status kann nicht geändert werden", "danger");
            case 500:
                return showMessage(null, "Unbekannter Fehler", "danger");
            default:
                return showMessage(null, "Unbekannter Fehler", "danger");
        }
    }

    render() {
        document.title = TAB_TITLE_BASE + TAB_TITLE_CONNECTOR + "Angebotsanfrage";

        if (this.state.errorResponse) {
            return <Error
                status={this.state.errorResponse.status}
                statusText={this.state.errorResponse.statusText}/>
        }

        if (!this.state.quotationRequest) {
            return null;
        }

        const request_state = translateRequestState(this.state.quotationRequest.request_state.request_state);

        return (
            <div className={"fimo-content-page"}>


                {this.state.showDeletionDialog &&
                    <DeleteDialog
                        elementText={this.state.deletionText}
                        cancel={() =>
                            this.setState({
                                showDeletionDialog: false,
                                deletionText: '',
                                confirmDeleteRequest: () => void 0
                            })
                        }
                        confirmDelete={this.state.confirmDeleteRequest}
                    />
                }

                <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                    <div className="col-2" style={{maxWidth: "100%"}}>
                        <button className="btn btn-outline-form"
                                type="button"
                                style={{paddingRight: "60px"}}
                                onClick={() => this.props.history.goBack()}>
                            <div>
                                <IoMdArrowRoundBack/>
                            </div>
                            Zurück
                        </button>
                    </div>

                    <div className={"col-10 text-right"} style={{maxWidth: "100%"}}>
                        <Can I="start" this={this.state.quotationRequest}>
                            <button className="btn btn-outline-form"
                                    type="button"
                                    onClick={() => this.startQuotationRequest()}>
                                <div>
                                    <IoIosPaperPlane/>
                                </div>
                                Anfrage starten
                            </button>
                        </Can>
                        <Can I="pause" this={this.state.quotationRequest}>

                            <button className="btn btn-outline-form"
                                    type="button"
                                    onClick={() => this.pauseQuotationRequest()}>
                                <div>
                                    <IoMdRadioButtonOff/>
                                </div>
                                Anfrage pausieren
                            </button>

                        </Can>
                        <Can I="complete" this={this.state.quotationRequest}>

                            <button className="btn btn-outline-form"
                                    type="button"
                                    onClick={() => this.completeQuotationRequest()}>
                                <div>
                                    <IoMdCheckmarkCircleOutline/>
                                </div>
                                Anfrage abschließen
                            </button>
                        </Can>
                        <Can I="cancel" this={this.state.quotationRequest}>

                            <button className="btn btn-outline-form"
                                    type="button"
                                    onClick={() => this.cancelQuotationRequest()}>
                                <div>
                                    <IoMdClose style={{color: " red"}}/>
                                </div>
                                Anfrage abbrechen
                            </button>

                        </Can>
                        <Can I="destroy" this={this.state.quotationRequest}>

                            <button className="btn btn-outline-form"
                                    type="button"
                                    onClick={(event) =>
                                        {
                                            this.setState({
                                                showDeletionDialog: true,
                                                deletionText: `Angebotsanfrage zu Firma ${this.state.quotationRequest.company_name} von ${this.state.quotationRequest.contact_name}`,
                                                confirmDeleteRequest: () => this.deleteQuotationRequest()
                                            })
                                        }
                                    }>
                                <div>
                                    <IoMdTrash style={{color: " red"}}/>
                                </div>
                                Anfrage löschen
                            </button>
                        </Can>
                    </div>
                </div>

                <div style={{padding: "10px"}}>
                    <h2 className="page-headline">Angebotsanfrage für
                        Firma: {this.state.quotationRequest.company_name}</h2>
                    <div className="container" style={{padding: "30px"}}>
                        <div className="row">
                            <div className="col-md-6">
                                <h4 className="text-fimo-blue">Angaben zum Unternehmen</h4>

                                <Table className="table-borderless">
                                    <tbody>
                                    <tr>
                                        <td>Firma:</td>
                                        <td>{this.state.quotationRequest.company_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Ansprechpartner:</td>
                                        <td>{this.state.quotationRequest.contact_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Adresse:</td>
                                        <td>{this.state.quotationRequest.address}</td>
                                    </tr>
                                    <tr>
                                        <td>Postleitzahl:</td>
                                        <td>{this.state.quotationRequest.zipcode}</td>
                                    </tr>
                                    <tr>
                                        <td>Ort:</td>
                                        <td>{this.state.quotationRequest.city}</td>
                                    </tr>
                                    <tr>
                                        <td>Email:</td>
                                        <td>{this.state.quotationRequest.email}</td>
                                    </tr>
                                    <tr>
                                        <td>Telefon:</td>
                                        <td>{this.state.quotationRequest.phone}</td>
                                    </tr>
                                    <tr>
                                        <td>Fax:</td>
                                        <td>{this.state.quotationRequest.fax}</td>
                                    </tr>
                                    <tr>
                                        <td>Branche:</td>
                                        <td>{this.state.quotationRequest.sector}</td>
                                    </tr>
                                    </tbody>
                                </Table>

                                <h4 className="text-fimo-blue">Weitere Angaben</h4>

                                <Table className="table-borderless">
                                    <tbody>
                                    <tr>
                                        <td>Aufmerksam geworden durch:</td>
                                        <td>
                                            {this.state.quotationRequest.became_aware_by.map(option => {
                                                return <li key={option}>{option}</li>
                                            })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Empfohlen von:</td>
                                        <td>{this.state.quotationRequest.recommended_by}</td>
                                    </tr>
                                    <tr>
                                        <td>Aktuelle Versicherungsgeber:</td>
                                        <td>{this.state.quotationRequest.current_protection_seller}</td>
                                    </tr>
                                    <tr>
                                        <td>Bürgschafts-/Avalrahmen bereits vorhanden:</td>
                                        <td>{this.state.quotationRequest.bonds_exist === '' ?
                                            ''
                                            :
                                            [this.state.quotationRequest.bonds_exist ?
                                                'Ja' :
                                                'Nein']}</td>
                                    </tr>
                                    <tr>
                                        <td>Bestehende Kreditversicherer:</td>
                                        <td>{this.state.quotationRequest.existing_insurance_companies}</td>
                                    </tr>
                                    <tr>
                                        <td>Aktueller Gesamtbedarf an Bürgschaften/Avalen:</td>
                                        <td>{this.state.quotationRequest.current_demand_bonds}</td>
                                    </tr>
                                    <tr>
                                        <td>Benötigte Bürgschafts-/Avalarten:</td>
                                        <td>
                                            {this.state.quotationRequest.required_bond_types.map(bondType => {
                                                return <li key={bondType}>{bondType}</li>
                                            })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Sitzauftraggeber:</td>
                                        <td>{this.state.quotationRequest.client_region}</td>
                                    </tr>
                                    <tr>
                                        <td>Rechts- und Gerichtsstand:</td>
                                        <td>{this.state.quotationRequest.jurisdiction}</td>
                                    </tr>
                                    </tbody>
                                </Table>

                                {!this.state.quotationRequest.suedvers_exist &&
                                    <div>
                                        <h4 className="text-fimo-blue">Angaben zum abgelaufenen Geschäftsjahr</h4>

                                        <Table className="table-borderless">
                                            <tbody>
                                            <tr>
                                                <td>Umsatz:</td>
                                                <td>{this.state.quotationRequest.revenue}</td>
                                            </tr>

                                            <tr>
                                                <td>Ergebnis:</td>
                                                <td>{this.state.quotationRequest.profit}</td>
                                            </tr>

                                            <tr>
                                                <td>Bilanzsumme:</td>
                                                <td>{this.state.quotationRequest.total_assets}</td>
                                            </tr>

                                            <tr>
                                                <td>Eigenkapital:</td>
                                                <td>{this.state.quotationRequest.equity}</td>
                                            </tr>
                                            </tbody>
                                        </Table>

                                        <h4 className="text-fimo-blue">Angaben zum laufenden Geschäft</h4>

                                        <Table className="table-borderless">
                                            <tbody>
                                            <tr>
                                                <td>Auftragsbestand aktuell:</td>
                                                <td>{this.state.quotationRequest.current_business_backlog}</td>
                                            </tr>

                                            <tr>
                                                <td>erwarteter Umsatz:</td>
                                                <td>{this.state.quotationRequest.expected_revenue}</td>
                                            </tr>

                                            <tr>
                                                <td>erwartete Gesamtleistung:</td>
                                                <td>{this.state.quotationRequest.expected_total_output}</td>
                                            </tr>

                                            <tr>
                                                <td>erwarteter Jahresüberschuss:</td>
                                                <td>{this.state.quotationRequest.expected_net_profit}</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                }
                            </div>

                            <div className="col-md-6">
                                <Table className="table-borderless">
                                    <tbody>
                                    <tr>
                                        <td>Aktueller Status:</td>
                                        <td>{request_state}</td>
                                    </tr>

                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(QuotationRequestPage);