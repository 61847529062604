import React, { Component } from 'react';

/**
 * Component showing a dialog where the user can cancel or confirm an deletion request.
 * ---
 * Needs props:
 * - elementText (string): text with short information about the item thats to be deleted
 * - cancel (function): function to execute on click of cancel button
 * - confirmDelete (function): function to execute on click of delete button
 */
class DeleteDialog extends Component {
    render() {
        return (
            <div className={'dialog-background'}>
                <dialog open className={'delete-dialog'}>
                    <h5>Sind Sie sicher, dass sie dieses Element löschen wollen?</h5>
                    <p><strong>{this.props.elementText}</strong></p>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <button className="btn btn-outline-form" style={{marginBottom: "0"}} onClick={() => this.props.cancel()}>
                            Abbrechen
                        </button>
                        <button className="btn btn-outline-form" style={{color: "red", marginBottom: "0"}} onClick={() => this.props.confirmDelete()}>
                            Löschen
                        </button>
                    </div>
                </dialog>
            </div>
        );
    }
}

export default DeleteDialog;
