import React, {Component} from 'react';

import * as DataService from '../../service/dataService';
import {NotificationContext} from "../contexts/NotificationContext";

class ForgotPassword extends Component{

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            email: ''
        };

        this.updateEmail = this.updateEmail.bind(this);
    }

    updateEmail(event){
        this.setState({
            email: event.target.value
        });
    }

    submitEmail(event){
        event.preventDefault();

        let showMessage = this.context.showMessage;

        DataService.forgotPasswort(this.state.email)
            .then(response => {
                if (response.status === 200){
                    return showMessage(null, "Email wurde gesendet", "success");
                }

                showMessage(null, "Unbekannter Fehler", "danger");
            })
            .catch(err => {
                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                switch (err.response.status) {
                    case 404:
                        return showMessage(null, "Email-Adresse wurde nicht gefunden", "danger");
                    case 422:
                        return showMessage(null, "Bitte überprüfen Sie Ihre Angaben", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    render(){
        return(
            <div className="fimo-content-page">

                <div className="container" style={{padding: "30px"}}>
                    <div className="row">
                        <div className="col-md6">

                            <h4 className="text-fimo-blue">Passwort vergessen</h4>

                            <p>Bitte geben Sie Ihre Email-Adresse ein. Im Anschluss wird Ihnen eine Email zugesendet. Klicken Sie
                            auf den Link in dieser Email, um Ihr Passwort zu ändern.</p>

                            <form>
                                <div className="form-row">
                                    <label
                                        className="col-form-label col-1"
                                        style={{minWidth: "150px"}}>
                                        Email:
                                    </label>
                                    <input
                                        className="form-control col-2"
                                        value={this.state.email}
                                        onChange={this.updateEmail}
                                        style={{minWidth: "250px"}}/>
                                </div>

                                <div style={{marginTop: "50px"}}>
                                    <button className="btn btn-primary align-self-center"
                                            onClick={(event) => this.submitEmail(event)}>
                                        Absenden
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default ForgotPassword;