import InsurancePolicy from "./InsurancePolicy";
import * as Paths from "../config/paths";
import request from "superagent";

class Project {

    static get modelName() {
        return 'Project'
    }

    constructor(object){
        Object.assign(this, object, {
            insurance_policy: object.insurance_policy ? new InsurancePolicy(object.insurance_policy) : null})
    }

    /**
     * Gets the Project with the specified ID from the server.
     *
     * @param id The ID of the ClientCompany
     * @returns {Promise<any>}
     */
    static getProject(id){
        const url = `${Paths.URL_PROJECT}/${id}`;

        return new Promise((resolve, reject) => {
            request.get(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Creates the Project on the server.
     *
     * @returns {Promise<any>}
     */
    static createProject(policyId, newProject){
        const url = `${Paths.URL_INSURANCE_POLICIES}/${policyId}${Paths.PATH_PROJECT}`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('Access-Control-Allow-Origin', '*')
                .send(newProject)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Udpdates the project with the new details.
     *
     * @param updatedProject The updated project details
     * @returns {Promise<any>}
     */
    updateProject(updatedProject){
        const url = `${Paths.URL_PROJECT}/${this.id};`

        return new Promise((resolve, reject) => {
            request.put(url)
                .set('Access-Control-Allow-Origin', '*')
                .send(updatedProject)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Gets the latest details for the Project.
     *
     * @returns {Promise<any>}
     */
    getLatestDetails(){
        const url = `${Paths.URL_PROJECT}/${this.id}${Paths.PATH_PROJECT_DETAILS_LATEST}`;

        return new Promise((resolve, reject) => {
            request.get(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) => {
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

    /**
     * Creates new InsurancePolicyDetails for the Project on the server.
     *
     * @param details
     */
    createDetails(details){
        const url = `${Paths.URL_PROJECT}/${this.id}/${Paths.PATH_PROJECT_DETAILS}`;

        return new Promise((resolve, reject) => {
            request.post(url)
                .set('Access-Control-Allow-Origin', '*')
                .send(details)
                .set('accept', 'application/json')
                .set('Authorization', sessionStorage.getItem('auth_token'))
                .end((err, res) =>{
                    if (err){
                        reject(err);
                    }
                    resolve(res);
                });
        });
    }

}

export default Project;