import React, {Component} from 'react';
import {NotificationContext} from "../../contexts/NotificationContext";
import InsurancePolicyDetail from "../../../models/InsurancePolicyDetail";
import {convertProjectDetailsForDisplay} from '../../../service/conversionService';
import * as Constants from "../../../config/constants";

import EditProjectDetailsVHV from "./EditProjectDetailsVHV";
import EditProjectDetailsSwissRe from "./EditProjectDetailsSwissRe";
import EditProjectDetailsAXA from "./EditProjectDetailsAXA";
import EditProjectDetailsEulerHermes from "./EditProjectDetailsEulerHermes";
import EditProjectDetailsRuV from "./EditProjectDetailsRuV";
import Error from "../../Error";
import ability from "../../../Ability";
import {Redirect, withRouter} from "react-router-dom";
import * as Paths from "../../../config/paths";
import InsurancePolicy from "../../../models/InsurancePolicy";
import Project from "../../../models/Project";

import {IoIosSave, IoMdClose} from 'react-icons/io'
import {inputStringToNumber} from "../../../service/formatService";

class EditProjectDetailsFormsContainer extends Component{

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            project: props.location.project || null,
            policy: props.location.policy || null,
            errorResponse: null
        };

        this.updateNewDetails = this.updateNewDetails.bind(this);
        this.updateProjectDetails = this.updateProjectDetails.bind(this);
    }

    componentWillMount() {
        if (!this.props.location.project){
            let {id} = this.props.match.params;

            this.loadProjectDetails(id);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props){
            let {id} = nextProps.match.params;

            this.loadProjectDetails(id);
        }
    }

    loadProjectDetails(id){
        return InsurancePolicyDetail.getInsurancePolicyDetail(id)
            .then(response => {
                if (response.status === 200){
                    let details = new InsurancePolicyDetail(response.body);

                    return details;
                }

                return  null;
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Details nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            })
            .then(details => {
                if (details){
                    this.getProject(details.project_id)
                        .then(project => {
                            if (project) {
                                this.getPolicy(project.insurance_policy_id)
                                    .then(async (policy) => {
                                        project.projectDetails = details;

                                        project.convertedData = await convertProjectDetailsForDisplay(details, policy);

                                        this.setState({
                                            project: project,
                                            policy: policy,
                                            errorResponse: null
                                        });
                                    });
                            }
                        });
                }
            });
    }

    getProject(id){
        return Project.getProject(id)
            .then(response => {
                if (response.status === 200){
                    let project = new Project(response.body);

                    return project;
                }

                return  null;
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Projekt nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    getPolicy(id){
        return InsurancePolicy.getPolicy(id)
            .then(response => {
                if (response.status === 200){
                    let policy = new InsurancePolicy(response.body);

                    return policy;
                }

                return  null;
            })
            .catch(err => {
                let showMessage = this.context.showMessage;

                if (!err.response){
                    return  showMessage(null, "Verbindungsfehler", "danger");
                }

                this.setState({
                    errorResponse: err.response
                });

                switch (err.response.status) {
                    case 403:
                        return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                    case 404:
                        return showMessage(null, "Police nicht gefunden", "danger");
                    case 500:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                    default:
                        return showMessage(null, "Unbekannter Fehler", "danger");
                }
            });
    }

    updateProjectDetails(newDetails){
        this.state.project.projectDetails.updateProjectDetails(newDetails)
            .then(async (response) => {
                if (response.status === 200){
                    let project = this.state.project;

                    project.projectDetails = new InsurancePolicyDetail(response.body);

                    project.convertedData = await convertProjectDetailsForDisplay(response.body,
                        this.state.policy,
                        sessionStorage.getItem('auth_token'));

                    this.setState({
                        project: project
                    });

                    this.context.showMessage(null, "Stand wurde aktualisiert", "success");
                    this.props.history.goBack();
                }
                else {
                    this.context.showMessage(null, "Es ist ein unbekannter Fehler aufgetreten", "danger");
                }
            })
            .catch(err => {
                this._handleProjectDetailsErrors(err);
            });
    }

    _handleProjectDetailsErrors(err){
        let showMessage = this.context.showMessage;

        if (!err.response){
            return  showMessage(null, "Verbindungsfehler", "danger");
        }

        switch (err.response.status) {
            case 403:
                return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
            case 404:
                return showMessage(null, "Die Details wurden nicht gefunden", "danger");
            case 422:
                return showMessage(null, "Überprüfen Sie Ihre Angaben", "danger");
            case 500:
                return showMessage(null, "Unbekannter Fehler", "danger");
            default:
                return showMessage(null, "Unbekannter Fehler", "danger");
        }
    }


    submitProjectDetails(){
        if(!this.state.newDetails) {
            this.context.showMessage(null, "Es gibt keine Änderungen zu speichern.", "danger");
            return;
        }


        let obligos = this.state.project.projectDetails.obligos || [];
        let bonds = this.state.project.projectDetails.bonds || [];
        let singleLimits = this.state.project.projectDetails.single_limits || [];
        let avalPremia = this.state.project.projectDetails.aval_premia || [];
        let collaterals = this.state.project.projectDetails.collaterals || [];
        let bondLimits = this.state.project.projectDetails.bondLimits || [];

        // Delete the properties of the aval classes that shall be removed
        if(this.state.newDetails.avalClassesToRemove) {
            this.state.newDetails.avalClassesToRemove.map(avalClass => {
                let removeTasks = [];

                if (avalClass.id) {
                    let obligoIndex = obligos.findIndex((obligo) => obligo.aval_class.id === avalClass.id);

                    if (obligoIndex > -1) {
                        removeTasks.push(obligos[obligoIndex].deleteObligo());
                        obligos.splice(obligoIndex, 1);
                    }

                    let bondIndex = bonds.findIndex((bond) => bond.aval_class.id === avalClass.id);

                    if (bondIndex > -1) {
                        removeTasks.push(bonds[bondIndex].deleteBond());
                        bonds.splice(bondIndex, 1);
                    }

                    let limitIndex = singleLimits.findIndex((limit) => limit.aval_class.id === avalClass.id);

                    if (limitIndex > -1) {
                        removeTasks.push(singleLimits[limitIndex].deleteSingleLimit());
                        singleLimits.splice(limitIndex, 1);
                    }

                    let premiumIndex = avalPremia.findIndex((premium) => premium.aval_class.id === avalClass.id);

                    if (premiumIndex > -1) {
                        removeTasks.push(avalPremia[premiumIndex].deleteAvalPremium());
                        avalPremia.splice(premiumIndex, 1);
                    }

                }

                return Promise.all(removeTasks);
            });
        }

        // Build the remaining aval classes and their properties to save
        if (collaterals[0]) {
            collaterals[0].nominal = this.state.newDetails.collateralNominal;
            collaterals[0].current = this.state.newDetails.collateralCurrent;
        }
        else if(this.state.newDetails.collateralNominal || this.state.newDetails.collateralCurrent) {
            collaterals.push({
                nominal: this.state.newDetails.collateralNominal,
                current: this.state.newDetails.collateralCurrent
            });
        }

        if (avalPremia[0]) {
            avalPremia[0].yearly_fee_obligo = this.state.newDetails.yearlyFeeObligo;
            avalPremia[0].payment_type = {name: this.state.newDetails.paymentType};
            avalPremia[0].payment_type_id = null;
        }
        else if(this.state.newDetails.yearlyFeeObligo || this.state.newDetails.paymentType) {
            avalPremia.push({
                yearly_fee_obligo: this.state.newDetails.yearlyFeeObligo,
                payment_type: {name: this.state.newDetails.paymentType}
            });
        }


        if(this.state.newDetails.avalClasses) {
            this.state.newDetails.avalClasses.map(avalClass => {
                let classNames = avalClass.name.split(/\s*[\n,]\s*/);

                let avalClassSpecifier = avalClass.avalClass || avalClass.aval_class || "";

                if (avalClass.id) {
                    let obligoIndex = obligos.findIndex((obligo) => obligo.aval_class.id === avalClass.id);

                    if (obligoIndex > -1) {
                        obligos[obligoIndex].amount = avalClass.obligo;
                        obligos[obligoIndex].aval_class_id = null;
                        obligos[obligoIndex].aval_class = {
                            class_names: classNames,
                            aval_class: avalClassSpecifier
                        };
                    } else {
                        obligos.push({
                            aval_class: {
                                class_names: classNames,
                                aval_class: avalClassSpecifier
                            },
                            amount: inputStringToNumber(avalClass.obligo)
                        });
                    }

                    let bondIndex = bonds.findIndex((bond) => {
                        if(bond.aval_class) {
                            return bond.aval_class.id === avalClass.id;
                        }
                    });

                    if (bondIndex > -1) {
                        bonds[bondIndex].amount = avalClass.bond_limit;
                        bonds[bondIndex].aval_class_id = null;
                        bonds[bondIndex].aval_class = {
                            class_names: classNames,
                            aval_class: avalClassSpecifier
                        };
                    } else {
                        bonds.push({
                            aval_class: {
                                class_names: classNames,
                                aval_class: avalClassSpecifier
                            },
                            amount: inputStringToNumber(avalClass.bond_limit)
                        });
                    }

                    let singleLimitIndex = singleLimits.findIndex((singleLimit) => {
                        if(singleLimit.aval_class) {
                            return singleLimit.aval_class.id === avalClass.id;
                        }
                    });

                    if (singleLimitIndex > -1) {
                        singleLimits[singleLimitIndex].amount = avalClass.single_limit;
                        singleLimits[singleLimitIndex].aval_class_id = null;
                        singleLimits[singleLimitIndex].aval_class = {
                            class_names: classNames,
                            aval_class: avalClassSpecifier
                        };
                    } else {
                        singleLimits.push({
                            aval_class: {
                                class_names: classNames,
                                aval_class: avalClassSpecifier
                            },
                            amount: inputStringToNumber(avalClass.single_limit)
                        });
                    }

                    let collateralIndex = collaterals.findIndex((collateral) => {
                        if(collateral.aval_class) {
                            return collateral.aval_class.id === avalClass.id;
                        }
                    });

                    if (collateralIndex > -1) {
                        collaterals[collateralIndex].amount = avalClass.single_limit;
                        collaterals[collateralIndex].aval_class_id = null;
                        collaterals[collateralIndex].aval_class = {
                            class_names: classNames,
                            aval_class: avalClassSpecifier
                        };
                    } else {
                        collaterals.push({
                            aval_class: {
                                class_names: classNames,
                                aval_class: avalClassSpecifier
                            },
                            amount: inputStringToNumber(avalClass.single_limit)
                        });
                    }


                    let premiumIndex = avalPremia.findIndex((premium) => {
                        if(premium.aval_class) {
                            return premium.aval_class.id === avalClass.id;
                        }
                    });

                    if (premiumIndex > -1) {
                        avalPremia[premiumIndex].premium_rate = avalClass.premium_rate;
                        avalPremia[premiumIndex].aval_class_id = null;
                        avalPremia[premiumIndex].aval_class = {
                            class_names: classNames,
                            aval_class: avalClassSpecifier
                        };
                    } else {
                        avalPremia.push({
                            aval_class: {
                                class_names: classNames,
                                aval_class: avalClassSpecifier
                            },
                            premium_rate: inputStringToNumber(avalClass.premium_rate)
                        });
                    }
                } else {
                    obligos.push({
                        aval_class: {
                            class_names: classNames,
                            aval_class: avalClassSpecifier
                        },
                        amount: inputStringToNumber(avalClass.obligo)
                    });

                    bonds.push({
                        aval_class: {
                            class_names: classNames,
                            aval_class: avalClassSpecifier
                        },
                        amount: inputStringToNumber(avalClass.bond_limit)
                    });

                    singleLimits.push({
                        aval_class: {
                            class_names: classNames,
                            aval_class: avalClassSpecifier
                        },
                        amount: inputStringToNumber(avalClass.single_limit)
                    });

                    avalPremia.push({
                        aval_class: {
                            class_names: classNames,
                            aval_class: avalClassSpecifier
                        },
                        premium_rate: inputStringToNumber(avalClass.premium_rate)
                    });

                    collaterals.push({
                        aval_class: {
                            class_names: classNames,
                            aval_class: avalClassSpecifier
                        },
                        collateral: inputStringToNumber(avalClass.collateral)
                    });

                    bondLimits.push({
                        aval_class: {
                            class_names: classNames,
                            aval_class: avalClassSpecifier
                        },
                        bond_limit: inputStringToNumber(avalClass.bond_limit)
                    });
                }
            });
        }

        let newDetails = {
            product_name: this.state.newDetails.productName,
            obligos_sum: inputStringToNumber(this.state.newDetails.obligosSum),
            bond_limit: inputStringToNumber(this.state.newDetails.bondLimit),
            contract_state: this.state.newDetails.policyState,
            remaining_balance: inputStringToNumber(this.state.newDetails.remainingBalance),
            single_limit_max: inputStringToNumber(this.state.newDetails.singleLimit),
            collaterals: collaterals,
            bonds: bonds,
            obligos: obligos,
            single_limits: singleLimits,
            aval_premia: avalPremia,
        };

        this.updateProjectDetails(newDetails);
    }


    updateNewDetails(details) {
        this.setState({
            newDetails: details
        })
    }

    renderSpecificForm(){
        switch (this.state.policy.insurance_company.name) {
            case Constants.INSURANCE_VHV:
                return <EditProjectDetailsVHV
                    project={this.state.project}
                    updateNewDetails={this.updateNewDetails}/>;
            case Constants.INSURANCE_SWISSRE:
                return  <EditProjectDetailsSwissRe
                    project={this.state.project}
                    updateNewDetails={this.updateNewDetails}/>
            case Constants.INSURANCE_AXA:
                return  <EditProjectDetailsAXA
                    project={this.state.project}
                    updateNewDetails={this.updateNewDetails}/>
            case Constants.INSURANCE_EULERHERMES:
                return  <EditProjectDetailsEulerHermes
                    project={this.state.project}
                    updateNewDetails={this.updateNewDetails}/>
            case Constants.INSURANCE_RV:
                return  <EditProjectDetailsRuV
                    project={this.state.project}
                    updateNewDetails={this.updateNewDetails}/>
        }
    }

    render(){
        if (this.state.errorResponse){
            return <Error
                status={this.state.errorResponse.status}
                statusText={this.state.errorResponse.statusText}/>
        }

        if (!this.state.project){
            return  null;
        }

        if (!this.state.policy){
            return  null;
        }

        if (ability.cannot("update", this.state.project.projectDetails)){
            return <Redirect to={Paths.PATH_ROOT}/>
        }

        return(
            <div className={"fimo-content-page"}>
                <div className={"row fimo-background"} style={{maxWidth: "100%", margin: "0px"}}>
                    <div className="col text-right" style={{maxWidth: "100%"}}>
                        <button className="btn btn-outline-form"
                                onClick={() => this.submitProjectDetails()}>
                            <div>
                                <IoIosSave/>
                            </div>
                            Speichern
                        </button>
                        <button className="btn btn-outline-form"
                                type="button"
                                onClick={() => this.props.history.goBack()}>
                            <div>
                                <IoMdClose style={{color: " red"}}/>
                            </div>
                            Abbrechen
                        </button>
                    </div>
                </div>

                <div style={{padding: "30px"}}>
                    <h4 className="page-headline">Neuen Stand setzen</h4>

                    <div className="container">
                        <div className="details-header">
                            <h4 className="text-fimo-green">{this.state.project.project_name}</h4>
                            {this.state.project.project_number &&
                            <h5>VSNR: {this.state.project.project_number}</h5>}
                        </div>
                    </div>

                {this.renderSpecificForm()}

                </div>
            </div>
        )
    }
}

export default withRouter(EditProjectDetailsFormsContainer);
