import {getAvalClasses} from "./dataService";


export async function convertBackendDataToPortalData(detailsData, insuranceCompany, authToken) {
            return await getDataObject(detailsData, authToken);
    }

export async function convertProjectDetailsForDisplay(projectDetails, policy) {
        let authToken = sessionStorage.getItem('auth_token');

        return await getDataObject(projectDetails, authToken);

    }

export async function getDataObject(detailsData, authToken) {

    const avalClasses = await getAvalClasses(detailsData.id, authToken);
    const avalPremia = detailsData.aval_premia;

    const aval_w =  avalPremia ? avalPremia.filter( (aval) => aval.aval_class_id == null) : [];

    const yearlyFeeObligo = aval_w && aval_w.length > 0 && aval_w[0] && aval_w[0].yearly_fee_obligo ? aval_w[0].yearly_fee_obligo : "";
    const paymentType = aval_w && aval_w.length > 0 && aval_w[0] && aval_w[0].payment_type ? aval_w[0].payment_type.name : "";
    const premiumRate = aval_w && aval_w.length > 0 && aval_w[0] && aval_w[0].premium_rate ? aval_w[0].premium_rate : "";

    const collaterals = detailsData.collaterals;

    const col_w = collaterals ? collaterals.filter( (aval) => aval.aval_class_id == null): [];

    const collateralNominal = col_w && col_w.length > 0 && col_w[0] && col_w[0].nominal ? col_w[0].nominal : "";
    const collateralCurrent = col_w && col_w.length > 0 && col_w[0] && col_w[0].current ? col_w[0].current : "";
    const collateralPercent = col_w && col_w.length > 0 && col_w[0] && col_w[0].percent ? col_w[0].percent : "";

    const frontEndObject = {
        product_name: detailsData.product_name,
        obligos_sum: detailsData.obligos_sum,
        bond_limit: detailsData.bond_limit,
        policy_state: detailsData.contract_state,
        single_limit: detailsData.single_limit_max,
        last_update: detailsData.updated_at,
        collateralNominal: collateralNominal,
        collateralCurrent: collateralCurrent,
        collateralPercent: collateralPercent,
        yearly_fee_obligo: yearlyFeeObligo,
        payment_type: paymentType,
        premium_rate: premiumRate,
        remaining_balance: detailsData.remaining_balance,
        avalClasses: []
    };

    for(let i=0; i < avalClasses.length; i++) {
        let avalClassName = getAvalclassAsString(avalClasses[i]);
        let approved = avalClasses[i].approved
        let avalClassId = avalClasses[i].id;

        let obligoObject = detailsData.obligos.find( obligo => obligo.aval_class_id === avalClassId);
        let obligo = obligoObject ? obligoObject.amount : null;

        let singleLimitObject = detailsData.single_limits.find( single_limit => single_limit.aval_class_id === avalClassId);
        let singleLimit = singleLimitObject ? singleLimitObject.amount : null;

        let bondsObject = detailsData.bonds.find( bond => bond.aval_class_id === avalClassId);
        let bond_limit = bondsObject ? bondsObject.amount : null;

        let avalPremiumObject = detailsData.aval_premia.find( avalPremium => avalPremium.aval_class_id === avalClassId);
        let premium_rate = avalPremiumObject ? avalPremiumObject.premium_rate : null;

        let avalClass = {
            id: avalClassId,
            name: avalClassName,
            approved: approved,
            aval_class: avalClasses[i].aval_class,
            obligo: obligo,
            single_limit: singleLimit,
            premium_rate: premium_rate,
            bond_limit: bond_limit
        };

        frontEndObject.avalClasses.push(avalClass);
    }

    return frontEndObject;
}

function getAvalclassAsString(avalClassObj) {
    const classNames = avalClassObj.class_names;
    let classNameString = '';

    for(let i=0; i < classNames.length; i++) {
        classNameString += classNames[i];
        if(i < (classNames.length-1)) {
            classNameString += ", ";
        }
    }

    return classNameString;
}
