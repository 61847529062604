import React, {Component} from 'react';
import {NotificationContext} from "../../contexts/NotificationContext";
import Card from "react-bootstrap/Card";
import {withRouter} from "react-router-dom";
import {inputStringToNumber} from "../../../service/formatService";

class NewProjectDetailsAXA extends Component{

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        this.state = {
            productName: undefined,
            obligosSum: undefined,
            bondLimit: undefined,
            policyState: undefined,
            collateralNominal: undefined,
            collateralCurrent: undefined,
            avalClasses: []
        }

        this.updateProductName = this.updateProductName.bind(this);
        this.updateObligosSum = this.updateObligosSum.bind(this);
        this.updateBondLimit = this.updateBondLimit.bind(this);
        this.updatePolicyState = this.updatePolicyState.bind(this);
        this.updateCollateralNominal = this.updateCollateralNominal.bind(this);
        this.updateCollateralCurrent = this.updateCollateralCurrent.bind(this);
        this.addAvalClass = this.addAvalClass.bind(this);
        this.removeAvalClass = this.removeAvalClass.bind(this);

        this.updateAvalClassName = this.updateAvalClassName.bind(this);
        this.updateAvalClassObligo = this.updateAvalClassObligo.bind(this);
        this.updateAvalClassBondLimit = this.updateAvalClassBondLimit.bind(this);
        this.updateAvalClassSingleLimit = this.updateAvalClassSingleLimit.bind(this);
        this.updateAvalClassPremium = this.updateAvalClassPremium.bind(this);
    }

    updateProductName(event){
        this.setState({
            productName: event.target.value
        }, () => this.updateDetails());
    }

    updateObligosSum(event){
        this.setState({
            obligosSum: event.target.value
        }, () => this.updateDetails());
    }

    updateBondLimit(event){
        this.setState({
            bondLimit: event.target.value
        }, () => this.updateDetails());
    }

    updatePolicyState(event){
        this.setState({
            policyState: event.target.value
        }, () => this.updateDetails());
    }

    updateCollateralNominal(event){
        this.setState({
            collateralNominal: event.target.value
        }, () => this.updateDetails());
    }

    updateCollateralCurrent(event){
        this.setState({
            collateralCurrent: event.target.value
        }, () => this.updateDetails());
    }

    addAvalClass(){
        this.setState({
            avalClasses: this.state.avalClasses.concat({
                name: '',
                avalClass: '',
                obligo: '',
                bondLimit: '',
                singleLimit: '',
                avalPremium: ''
            })
        }, () => this.updateDetails());
    }

    removeAvalClass(index){
        let avalClasses = this.state.avalClasses;
        avalClasses.splice(index, 1);

        this.setState({
            avalClasses: avalClasses
        }, () => this.updateDetails());
    }

    renderHeading(heading) {
        return (
            <div
                className="d-inline-block"
                style={{marginBottom: "16px"}}>
                <h4>{heading}</h4>
            </div>
        );
    }

    updateAvalClassName(event, avalClass, index){
        avalClass.name = event.target.value;

        let avalClasses = this.state.avalClasses;
        avalClasses[index] = avalClass;

        this.setState({
            avalClasses: avalClasses
        }, () => this.updateDetails());
    }

    updateAvalClassObligo(event, avalClass, index){
        avalClass.obligo = event.target.value;

        let avalClasses = this.state.avalClasses;
        avalClasses[index] = avalClass;

        this.setState({
            avalClasses: avalClasses
        }, () => this.updateDetails());
    }

    updateAvalClassBondLimit(event, avalClass, index){
        avalClass.bondLimit = event.target.value;

        let avalClasses = this.state.avalClasses;
        avalClasses[index] = avalClass;

        this.setState({
            avalClasses: avalClasses
        }, () => this.updateDetails());
    }

    updateAvalClassSingleLimit(event, avalClass, index){
        avalClass.singleLimit = event.target.value;

        let avalClasses = this.state.avalClasses;
        avalClasses[index] = avalClass;

        this.setState({
            avalClasses: avalClasses
        }, () => this.updateDetails());
    }

    updateAvalClassPremium(event, avalClass, index){
        avalClass.avalPremium = event.target.value;

        let avalClasses = this.state.avalClasses;
        avalClasses[index] = avalClass;

        this.setState({
            avalClasses: avalClasses
        }, () => this.updateDetails());
    }

    updateDetails(){
        let obligos = [];
        let bonds = [];
        let singleLimits = [];
        let avalPremia = [];
        let collaterals = [];

        collaterals.push({
            nominal: inputStringToNumber(this.state.collateralNominal),
            current: inputStringToNumber(this.state.collateralCurrent)
        });

        this.state.avalClasses.map(avalClass => {
            let classNames = avalClass.name.split(/\s*[\n,]\s*/);

            let avalClassSpecifier = avalClass.avalClass || "";

            obligos.push({
                aval_class: {
                    class_names: classNames,
                    aval_class: avalClassSpecifier
                },
                amount: inputStringToNumber(avalClass.obligo)
            });

            bonds.push({
                aval_class: {
                    class_names: classNames,
                    aval_class: avalClassSpecifier
                },
                amount: inputStringToNumber(avalClass.bondLimit)
            });

            singleLimits.push({
                aval_class: {
                    class_names: classNames,
                    aval_class: avalClassSpecifier
                },
                amount: inputStringToNumber(avalClass.singleLimit)
            });

            avalPremia.push({
                aval_class: {
                    class_names: classNames,
                    aval_class: avalClassSpecifier
                },
                premium_rate: inputStringToNumber(avalClass.avalPremium)
            });
        });

        let newDetails = {
            product_name: this.state.productName,
            obligos_sum: inputStringToNumber(this.state.obligosSum),
            bond_limit: inputStringToNumber(this.state.bondLimit),
            contract_state: this.state.policyState,
            collaterals: collaterals,
            bonds: bonds,
            obligos: obligos,
            single_limits: singleLimits,
            aval_premia: avalPremia
        };

        this.props.setNewDetails(newDetails);
    }

    renderAvalClassForm(avalClass, index){
        return(
            <Card key={index}
                  style={{marginBottom: "20px"}}>
                <Card.Title className="card-header">
                    <button className="btn btn-danger float-right"
                            onClick={() => this.removeAvalClass(index)}>
                        Entfernen
                    </button>
                    <form>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Avalklasse:</label>
                            <input className="col form-control"
                                   value={avalClass.name}
                                   onChange={(event) => this.updateAvalClassName(event, avalClass, index)}/>
                        </div>
                    </form>
                </Card.Title>
                <Card.Body>
                    <form>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Obligo:</label>
                            <input className="col form-control"
                                   value={avalClass.obligo}
                                   onChange={(event) => this.updateAvalClassObligo(event, avalClass, index)}/>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Limit:</label>
                            <input className="col form-control"
                                   value={avalClass.bondLimit}
                                   onChange={(event) => this.updateAvalClassBondLimit(event, avalClass, index)}/>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Einzelstück:</label>
                            <input className="col form-control"
                                   value={avalClass.singleLimit}
                                   onChange={(event) => this.updateAvalClassSingleLimit(event, avalClass, index)}/>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Avalprämie:</label>
                            <input className="col form-control"
                                   value={avalClass.avalPremium}
                                   onChange={(event) => this.updateAvalClassPremium(event, avalClass, index)}/>
                        </div>
                    </form>
                </Card.Body>
            </Card>
        );
    }

    render(){
        return(
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div>
                            {this.renderHeading("Basisinformationen")}

                            <Card className="form-card">
                                <form>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Produkt:</label>
                                        <input className="col form-control"
                                               value={this.state.productName}
                                               onChange={this.updateProductName}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Gesamtobligo:</label>
                                        <input className="col form-control"
                                               value={this.state.obligosSum}
                                               onChange={this.updateObligosSum}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Limit:</label>
                                        <input className="col form-control"
                                               value={this.state.bondLimit}
                                               onChange={this.updateBondLimit}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Status:</label>
                                        <input className="col form-control"
                                               value={this.state.policyState}
                                               onChange={this.updatePolicyState}/>
                                    </div>

                                    <label className="col-sm-4 col-form-label">Sicherheiten:</label>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Soll:</label>
                                        <input className="col form-control"
                                               value={this.state.collateralNominal}
                                               onChange={this.updateCollateralNominal}/>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Ist:</label>
                                        <input className="col form-control"
                                               value={this.state.collateralCurrent}
                                               onChange={this.updateCollateralCurrent}/>
                                    </div>
                                </form>
                            </Card>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div>
                            {this.renderHeading("Avalklassen")}

                            <button
                                className="btn btn-primary float-right"
                                onClick={this.addAvalClass}>Hinzufügen</button>
                        </div>

                        {this.state.avalClasses.map((avalClass, index) => this.renderAvalClassForm(avalClass, index))}
                    </div>

                </div>
            </div>
        );
    }

}

export default withRouter(NewProjectDetailsAXA);
