import React, {Component} from 'react';
import Card from "react-bootstrap/Card";
import * as FormatService from "../../service/formatService";

class ProjectDetailsEulerHermes extends Component {

    renderHeading(heading) {
        return (
            <div style={{marginBottom: "16px"}}>
                <h4>{heading}</h4>
            </div>
        );
    }

    renderLine(attribute, value, indent = false) {
        const style = indent ? {paddingLeft: "16px"} : {paddingLeft: "0px"};

        return (
            <tr style={{fontSize: "1.1em", height: "32px"}}>
                <td style={style}><b>{attribute}:</b></td>
                <td style={{paddingLeft: "32px"}}>{value}</td>
            </tr>
        )
    }

    render() {
        const eulerHermesData = this.props.project.convertedData;

        return (
            <div className="container">
                <div className="row">
                    <div className={"col-md-6"}>
                        {this.renderHeading("Basisinformationen")}
                        <Card style={{paddingLeft: "15px"}}>
                            <table>
                                <tbody>
                                {this.renderLine("Gesamtobligo", FormatService.formatCurrency(eulerHermesData.obligos_sum))}
                                {this.renderLine("Limit", FormatService.formatCurrency(eulerHermesData.bond_limit))}
                                </tbody>
                            </table>
                        </Card>
                    </div>
                    <div className={"col-md-6"}>
                        {this.renderHeading("Avalklassen")}
                        <React.Fragment>
                            {this.renderAvalClasses(eulerHermesData.avalClasses)}
                        </React.Fragment>
                    </div>
                </div>
            </div>
        )
    }


    renderAvalClasses(avalClasses) {
        let avalClassElements = [];

        for (let i = 0; i < avalClasses.length; i++) {
            const obligoValue = avalClasses[i].obligo ? FormatService.formatCurrency(avalClasses[i].obligo) : "";
            const bondLimitValue = avalClasses[i].bond_limit ? FormatService.formatCurrency(avalClasses[i].bond_limit) : "";
            const singletonValue = avalClasses[i].single_limit ? FormatService.formatCurrency(avalClasses[i].single_limit) : "";
            const avalPremiumValue = avalClasses[i].premium_rate ? FormatService.formatPercentage(avalClasses[i].premium_rate) : "";

            if(obligoValue.length > 0 || bondLimitValue.length > 0 || singletonValue.length > 0 || avalPremiumValue.length > 0) {
                const avalClass = (
                    <Card key={`avalClass_panel${i}`} style={{marginBottom: "20px"}}>
                        <Card.Title className="card-header">
                            {avalClasses[i].name}
                        </Card.Title>
                        <Card.Body>
                            <table>
                                <tbody>
                                {this.renderLine("Obligo", obligoValue)}
                                {this.renderLine("Limit", bondLimitValue)}
                                {this.renderLine("Einzelstück", singletonValue)}
                                {this.renderLine("Avalprämie (Beitragssatz)", avalPremiumValue)}
                                </tbody>
                            </table>
                        </Card.Body>
                    </Card>
                );

                avalClassElements.push(avalClass);
            }
        }

        return avalClassElements;
    }

}

export default ProjectDetailsEulerHermes;