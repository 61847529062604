class RequestState {

    static get modelName() {
        return 'RequestState'
    }

    constructor(object){
        Object.assign(this, object)
    }

}

export default RequestState;