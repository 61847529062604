import React, {Component} from 'react';
import image from "../static/extern/thumbnail_Karte Standorte.png";

class PortalText extends Component {


    render() {
        return (
            <div>
                <p className={"title1"}>
                    Ihr vertrauter Partner.
                </p>
                <hr className={"line1"}/>
                <img src={image} width={"480px"} style={{float: "left", paddingRight: "20px"}}/>
                <div style={{marginRight: "50px"}}>
                    <p className={"title2b"}>
                        Aktives Bürgschaftsmanagement
                    </p>
                    <p className={"title2"}>
                        Sicherheiten geben- Liquidität erhalten
                    </p>
                    <p className={"title3"}>
                        Eigenes Expertenteam für alle Bürgschaftsarten
                    </p>
                    <p>
                        Ein eigenes SÜDVERS Team kümmert sich um alle Bürgschaftsarten. Ziel ist dabei immer,
                        die optimale Gestaltung der Kautionsversicherung sicherzustellen. Sie profitieren davon doppelt.
                    </p>
                    <p className={"title3"}>
                        Markttransparenz und schnellere Realisierung
                    </p>
                    <p>
                        Wir verhandeln in Ihrem Auftrag mit allen Risikoträgern, verschaffen Ihnen Transparenz
                        und geben Ihnen den aktuellen Marktüberblick. Sie profitieren von verkürzten Prüfungsprozessen,
                        verbunden mit schnellen Entscheidungen durch die Bürgschaftsgeber.
                    </p>
                    <p className={"title3"}>
                        Größere Handlungsspielräume durch Liquiditätsverbesserung
                    </p>
                    <p>
                        Im Vordergrund steht die Verbesserung der Liquidität Ihres Unternehmens durch Entlastung
                        Ihrer Bankkreditlinien. Wir machen Sie damit unabhängiger und handlungsfähiger.
                        Beispielsweise erhalten Sie durch die Ablösung oder Vermeidung von Sicherheiten
                        zusätzliche freie Mittel für wichtige Investitionen. Zur Umsetzung Ihrer Ideen
                        brauchen Sie Handlungsspielraum – wir erweitern ihn. Verwenden Sie Ihr Geld für die Dinge,
                        die wirklich wichtig sind.
                    </p>
                    <p className={"title3"}>
                        Unser Kautionsportal
                    </p>
                    <p>
                        Mit dem Kautionsportal bieten wir unseren Kunden einen umfassenden Überblick über bestehende
                        Kautionslinien - 24/7/365 - und damit einen umfassenden Service rund um dieses Thema.
                    </p>
                </div>

            </div>
        )
    }

}

export default PortalText;
