import React, {Component} from 'react';

import * as FormatService from '../../service/formatService';
import Can from '../Can'
import {Card} from "react-bootstrap";

import * as Paths from "../../config/paths";
import Link from "react-router-dom/es/Link";

import {IoMdCreate, IoMdAddCircle, IoMdOpen} from 'react-icons/io';



class ProjectCard extends Component{

    constructor(props){
        super(props);

        this.state = {
            project: props.project
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props){
            this.setState({
                project: nextProps.project
            });
        }
    }

    render(){
        return(
            <Card style={{padding: "30px", margin: "10px", width: "400px"}}>
                <div className={"row"} style={{marginRight: "-40px"}}>
                    <div className="col text-right">
                        {this.state.project.convertedData && this.state.project.projectDetails.updated_at &&
                        <Link to={{pathname: `${Paths.PATH_PROJECT_DETAILS}/${this.state.project.projectDetails.id}`,
                            project: this.props.project,
                            policy: this.props.policy}}>
                            <button className="btn btn-outline-policycard">
                                <div>
                                    <IoMdOpen/>
                                </div>
                                Details
                                <br></br>
                                anzeigen
                            </button>
                        </Link>
                        }

                        <Can I="update" on={this.state.project}>
                            <Link to={{pathname: `${Paths.PATH_PROJECTS}/${this.state.project.id}/edit`,
                                project: this.state.project}}>
                                <button
                                    className="btn btn-outline-policycard">
                                    <div>
                                        <IoMdCreate/>
                                    </div>
                                    Police
                                    <br></br>
                                    bearbeiten
                                </button>
                            </Link>
                        </Can>
                        {!(this.state.project.convertedData && this.state.project.projectDetails.updated_at) &&
                        <Can I="create" on="InsurancePolicyDetail">
                            <Link to={{
                                pathname: `${Paths.PATH_PROJECTS}/${this.props.project.id}${Paths.PATH_PROJECT_DETAILS}/new`,
                                project: this.props.project,
                                policy: this.props.policy
                            }}>
                                <button
                                    className="btn btn-outline-policycard">
                                    <div>
                                        <IoMdAddCircle/>
                                    </div>
                                    Stand
                                    <br></br>
                                    initialisieren
                                </button>
                            </Link>
                        </Can>
                        }
                    </div>
                </div>


                {this.state.project.project_number &&
                    <div>
                        <span style={{paddingRight: "10px"}}><b>VSNR:</b></span>
                        <span>{this.state.project.project_number}</span>
                    </div>
                }

                {this.state.project.start_date &&
                    <div>
                        <span style={{paddingRight: "10px"}}><b>Startdatum:</b></span>
                        <span>{FormatService.formatDate(this.state.project.start_date)}</span>
                    </div>
                }

                {this.state.project.convertedData && this.state.project.projectDetails.updated_at ?
                    <div>
                        <div>
                            <span style={{paddingRight: "10px"}}><b>Stand vom:</b></span>
                            <span>{FormatService.formatDate(this.state.project.projectDetails.updated_at)}</span>
                        </div>

                        <div style={{ paddingTop: "12px"}}>
                            <div style={{paddingRight: "10px"}}><b>Auslastung:</b></div>
                            <div>
                                <span>{FormatService.formatCurrency(this.state.project.convertedData.obligos_sum)}</span>
                                <span style={{marginLeft: "5px", marginRight:"5px"}}>/</span>
                                <span>{FormatService.formatCurrency(this.state.project.convertedData.bond_limit)}</span>
                            </div>
                        </div>

                    </div>

                    :

                    <div>
                        <div style={{margin: "10px", color: "#95989a"}}>
                            <span>Noch keine Aktualisierung vorhanden</span>
                        </div>
                    </div>
                }

            </Card>
        );
    }
}

export default ProjectCard;
