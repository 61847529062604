import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import * as Constants from '../../config/constants';
import Error from "../users/UserPage";
import {TAB_TITLE_BASE} from "../../config/constants";
import {TAB_TITLE_CONNECTOR} from "../../config/constants";

const MENU_AXA = Constants.INSURANCE_AXA;
const MENU_VHV = Constants.INSURANCE_VHV;
const MENU_RUV = Constants.INSURANCE_RV;
const MENU_SWISS_RE = Constants.INSURANCE_SWISSRE;
const MENU_EULER_HERMES = Constants.INSURANCE_EULERHERMES;


class FormsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedInsuranceCompany: Constants.INSURANCE_AXA,
            selectedTab: MENU_AXA
        }

    }


    loadForms(insuranceCompany) {
        this.setState({
            selectedInsuranceCompany: insuranceCompany
        })
    }


    switchTab(tabName) {
        this.setState({
            selectedTab: tabName
        }, () => {
            this.loadForms(tabName)
        })
    }


    render() {
        document.title = TAB_TITLE_BASE + TAB_TITLE_CONNECTOR + "Formularwesen";
        const buttonWidth = "100px"

        if (this.state.errorResponse) {
            return <Error
                status={this.state.errorResponse.status}
                statusText={this.state.errorResponse.statusText}/>
        }

        const activeTab = this.state.selectedTab;

        const classesAxa = activeTab === MENU_AXA ? "btn btn-link-menu-selected" : "btn btn-link-menu";
        const classesVhv = activeTab === MENU_VHV ? "btn btn-link-menu-selected" : "btn btn-link-menu";
        const classesRuv = activeTab === MENU_RUV ? "btn btn-link-menu-selected" : "btn btn-link-menu";
        const classesSwiss = activeTab === MENU_SWISS_RE ? "btn btn-link-menu-selected" : "btn btn-link-menu";
        const classesEuler = activeTab === MENU_EULER_HERMES ? "btn btn-link-menu-selected" : "btn btn-link-menu";

        return (
            <div style={{padding: "30px"}}>
                <div className={"btn-group"}>

                    <button className={classesAxa}
                            select="true"
                            style={{minWidth: buttonWidth, outline: "1px !important"}}
                            onClick={this.switchTab.bind(this, MENU_AXA)}>
                        AXA
                    </button>

                    <button className={classesVhv}
                            select="true"
                            style={{minWidth: buttonWidth, outline: "1px !important"}}
                            onClick={this.switchTab.bind(this, MENU_VHV)}>
                        VHV
                    </button>

                    <button className={classesRuv}
                            select="true"
                            style={{minWidth: buttonWidth, outline: "1px !important"}}
                            onClick={this.switchTab.bind(this, MENU_RUV)}>
                        R+V
                    </button>

                    <button className={classesSwiss}
                            select="true"
                            style={{minWidth: buttonWidth, outline: "1px !important"}}
                            onClick={this.switchTab.bind(this, MENU_SWISS_RE)}>
                        Swiss Re
                    </button>

                    <button className={classesEuler}
                            select="true"
                            style={{minWidth: buttonWidth, outline: "1px !important"}}
                            onClick={this.switchTab.bind(this, MENU_EULER_HERMES)}>
                        Euler & Hermes
                    </button>

                </div>
                {this.renderFormData()}
            </div>
        );
    }


    renderButton(menu) {
        const buttonStyle = {
            display: "block",
            color: "#000000"
        }

        if (menu === this.state.selectedInsuranceCompany) {
            buttonStyle.color = "#0000FF";
            buttonStyle.textDecoration = "underline"
        }

        return (
            <button className={"btn btn-link"}
                    style={buttonStyle}
                    onClick={() => this.loadForms(menu)}
            >
                {menu}
            </button>
        );
    }

    renderFormData() {
        return (
            <div style={{paddingTop: "50px"}}>
                <div>
                    <b>{`Formulardaten für ${this.state.selectedInsuranceCompany}`}</b>
                </div>
                <div>
                    <ul>
                        <li>Beispielformular 1</li>
                        <li>Beispielformular 2</li>
                        <li>Beispielformular 3</li>
                    </ul>
                </div>
            </div>
        );
    }

}


export default FormsPage;