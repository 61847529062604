import {
    INSURANCE_AXA,
    INSURANCE_EULERHERMES,
    INSURANCE_RV,
    INSURANCE_SWISSRE,
    INSURANCE_VHV,
    INSURANCE_ERGO,
    INSURANCE_ZURICH,
    INSURANCE_Bayern,
    INSURANCE_Coface,
    INSURANCE_Tokio,
    INSURANCE_CREDENDO,
} from "../config/constants";
import {
    AVAL_REQUEST_AXA,
    AVAL_REQUEST_EULER_HERMES,
    AVAL_REQUEST_RV,
    AVAL_REQUEST_SWISS_RE,
    AVAL_REQUEST_VHV,
    AVAL_INSURANCE_ERGO,
    AVAL_INSURANCE_ZURICH,
    AVAL_INSURANCE_Bayern,
    AVAL_INSURANCE_Coface,
    AVAL_INSURANCE_Tokio,
    AVAL_INSURANCE_CREDENDO,
} from "../config/externalLinks";


export function openAvalRequestURL(companyName) {
    let url;

    switch(companyName) {
        case INSURANCE_ERGO:
        url = AVAL_INSURANCE_ERGO;
            break;
        case INSURANCE_ZURICH:
        url = AVAL_INSURANCE_ZURICH;
            break;
        case INSURANCE_Bayern:
        url = AVAL_INSURANCE_Bayern;
            break;
        case INSURANCE_Coface:
        url = AVAL_INSURANCE_Coface;
            break;
        case INSURANCE_Tokio:
        url = AVAL_INSURANCE_Tokio;
            break;
        case INSURANCE_CREDENDO:
        url = AVAL_INSURANCE_CREDENDO;
            break;
        case INSURANCE_AXA:
            url = AVAL_REQUEST_AXA;
            break;
        case INSURANCE_VHV:
            url = AVAL_REQUEST_VHV;
            break;
        case INSURANCE_RV:
            url = AVAL_REQUEST_RV;
            break;
        case INSURANCE_EULERHERMES:
            url = AVAL_REQUEST_EULER_HERMES;
            break;
        case INSURANCE_SWISSRE:
            url = AVAL_REQUEST_SWISS_RE;
            break;
    }

    window.open(url);
}
