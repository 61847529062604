import request from 'superagent';

import * as Paths from '../config/paths';
import * as Constants from '../config/constants';
import {convertBackendDataToPortalData} from './conversionService';


export async function getClientData(clientCompanyId, authToken) {
    const insurancePolicies = await getInsurancePoliciesOfClient(clientCompanyId, authToken);
    if(!insurancePolicies) {
        throw 500;
    } else if(insurancePolicies.length === 0) {
        return null;
    }

    let insurances = [];
    let numberOfPolicies = 0;

    for(let policy of insurancePolicies) {
        let detailsData = await getPolicyDetailsData(policy.id, authToken);

            if (!detailsData){
                let convertedDataObject = setDisplayData(null, policy);
                insurances.push(convertedDataObject);
                numberOfPolicies++;
                continue;
            }

            let convertedDataObject = await convertBackendDataToPortalData(detailsData, Constants.INSURANCE_ERGO, authToken);
            convertedDataObject = setDisplayData(convertedDataObject, policy);
            insurances.push(convertedDataObject);
            numberOfPolicies++;

    }

    function setDisplayData(convertedDataObject, policy) {
        if(convertedDataObject) {
            convertedDataObject.displayData = {
                policyNumber: policy.policy_number,
                insuranceName: policy.insurance_company.name
            }
        }
        else {
            convertedDataObject = {
                displayData: {
                    policyNumber: policy.policy_number,
                    insuranceName: policy.insurance_company.name
                }
            }
        }
        return convertedDataObject;
    }


    const clientData = {
        insurances: insurances,
        numberOfPolicies: numberOfPolicies
    }

    return clientData;
}


async function getInsurancePoliciesOfClient(clientCompanyId, authToken) {
    const baseUrl = Paths.URL_CLIENT_COMPANIES;
    const requestUrl = `${baseUrl}/${clientCompanyId}/insurance_policies`;

    try {
        const response = await fetch(requestUrl, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": authToken
            }
        });
        if (!response) {
            return {};
        }
        const responseObject = await response.json();

        return responseObject;
    } catch(e) {
        return null;
    }

}


function getPolicyDetailsData(insurancePolicyId, authToken) {
    const baseUrl = Paths.URL_INSURANCE_POLICIES;
    const requestUrl = `${baseUrl}/${insurancePolicyId}${Paths.PATH_POLICY_DETAILS_LATEST}`;

    return new Promise(function (resolve, reject) {
            request
                .get(requestUrl)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', authToken)
                .end((err, res) => {
                        if (err) {
                            resolve(null);
                        }
                        if (res) {
                            resolve(res.body);
                        } else {
                            resolve(null);
                        }
                    }
                );
        }
    );

}


export function getAvalClasses(details_id, authToken) {
    const url = `${Paths.URL_POLICY_DETAILS}/${details_id}${Paths.PATH_AVAL_CLASSES}`;

    return new Promise(function (resolve, reject) {
            request
                .get(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', authToken)
                .end((err, res) => {
                        if (err) {
                            resolve(null);
                        }

                        if (res) {
                            resolve(res.body);
                        } else {
                            resolve(null);
                        }
                    }
                );
        }
    );

}



export function getCompanyList(authToken) {
    const url = Paths.URL_CLIENT_COMPANIES;

    return new Promise(function (resolve, reject) {
            request
                .get(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', authToken.auth_token)
                .end((err, res) => {
                        if (err) {
                            resolve(null);
                        }

                        if (res) {
                            resolve(res.body);
                        } else {
                            resolve(null);
                        }
                    }
                );
        }
    );

}


export function getCompanyListOfUser(userId, authToken) {
    const url = Paths.URL_CLIENT_COMPANIES;

    return new Promise(function (resolve, reject) {
            request
                .get(url)
                .set('Access-Control-Allow-Origin', '*')
                .set('accept', 'application/json')
                .set('Authorization', authToken.auth_token)
                .end((err, res) => {
                        if (err) {
                            reject(err);
                        }

                        if (res) {
                            resolve(res.body);
                        } else {
                            resolve(null);
                        }
                    }
                );
        }
    );

}


export function login(userName, password) {
    const url = `${Paths.URL_LOGIN}`;

    const body = {
        email: userName,
        password: password
    }

    return new Promise( function (resolve, reject) {
        request
            .post(url)
            .set('Access-Control-Allow-Origin', '*')
            .send(body)
            .set('accept', 'application/json')
            .end((err, res) => {
                    if (err) {
                        reject(err);
                    }

                    resolve(res);
                }
            );
    });
}

/**
 * Sends the specified email to the /forgot_password route of the server to initiate the reset password flow.
 *
 * @param email The email of the user.
 * @returns {Promise<any>}
 */
export function forgotPasswort(email) {
    const url = `${Paths.URL_FORGOT_PASSWORD}`;

    let body = {email: email};

    return new Promise( function (resolve, reject) {
        request
            .post(url)
            .set('Access-Control-Allow-Origin', '*')
            .send(body)
            .set('accept', 'application/json')
            .parse(({body}) => body)
            .end((err, res) => {
                    if (err) {
                        reject(err);
                    }

                    resolve(res);
                }
            );
    });
}

/**
 * Sends the new password to the server to replace the old passwword.
 *
 * @param token The token associated with the reset process and the user
 * @param password The new password
 * @returns {Promise<any>}
 */
export function resetPasswort(token, password) {
    const url = `${Paths.URL_RESET_PASSWORD}`;

    let body = {token: token,
        password: password};

    return new Promise( function (resolve, reject) {
        request
            .post(url)
            .set('Access-Control-Allow-Origin', '*')
            .send(body)
            .set('accept', 'application/json')
            .parse(({body}) => body)
            .end((err, res) => {
                    if (err) {
                        reject(err);
                    }

                    resolve(res);
                }
            );
    });
}

/**
 * Creates a new user on the server with the specified details.
 *
 * @param token The token associated with the reset process and the user
 * @param password The new password
 * @returns {Promise<any>}
 */
export function signup(details) {
    const url = `${Paths.URL_SIGNUP}`;

    return new Promise( function (resolve, reject) {
        request
            .post(url)
            .set('Access-Control-Allow-Origin', '*')
            .send(details)
            .set('accept', 'application/json')
            .end((err, res) => {
                    if (err) {
                        reject(err);
                    }

                    resolve(res);
                }
            );
    });
}

export function importEnsurances(details) {
    const url = `${Paths.URL_IMPORT}`;

    return new Promise((resolve, reject) => {
        request.post(url)
            .set('Access-Control-Allow-Origin', '*')
            .set('accept', 'application/json')
            .send(details)
            .set('Authorization', sessionStorage.getItem('auth_token'))
            .end((err, res) => {
                if (err){
                    reject(err);
                }
                resolve(res);
            });
    });
}

export function ExportEnsurances(details) {
    const url = `${Paths.URL_EXPORT}`;

    return new Promise((resolve, reject) => {
        request
            .get(url)
            .set('Access-Control-Allow-Origin', '*')
            .set('accept', "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            .set('Authorization', sessionStorage.getItem('auth_token'))
            .responseType('blob')
            .end((err, res) => {
                if (err){
                    reject(err);
                }
                resolve(res);
            });
    });
}


