export function deleteUserAccount(event, user, showMessage, refresh){
    event.preventDefault();

    user.deleteUser(user)
        .then(response => {
            if (response.status === 200){
                showMessage(null, "Account wurde gelöscht", "success");
                refresh();
            }
        })
        .catch(err => {
            if (!err.response){
                return showMessage(null, "Verbindungsfehler", "danger");
            }

            switch (err.response.status) {
                case 403:
                    return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
                case 404:
                    return showMessage(null, "Benutzer mit 'id'=:id nicht gefunden", "danger");
                case 500:
                    return showMessage(null, "Fehler beim Löschen", "danger");
            }
        });
}
