import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import ClientCompany from "../../models/ClientCompany";
import Select from "react-select";
import {Link, Redirect} from "react-router-dom";
import * as Paths from "../../config/paths";

import PolicyOverview from '../policyPortal/PolicyPortal';
import PolicyDetailsContainer from '../policyDetails/PolicyDetailsContainer';
import {NotificationContext} from "../contexts/NotificationContext";
import {TAB_TITLE_BASE, TAB_TITLE_CONNECTOR} from "../../config/constants";
import * as AuthenticationService from "../../service/authenticationService";
import ClientEmployeeRequest from "../../models/ClientEmployeeRequest";

let saved_selectedClientCompany = null;
let saved_selectedClientCompanyName = null;

class PolicyPortal extends Component{

    static contextType = NotificationContext;

    constructor(props){
        super(props);

        const set_selectedClientCompany = saved_selectedClientCompany ? saved_selectedClientCompany : null;
        const set_selectedClientCompanyName = saved_selectedClientCompanyName ? saved_selectedClientCompanyName : null;

        this.state = {
            clientCompanies: 1,
            clientCompaniesSelectList: [],
            selectedClientCompany: set_selectedClientCompany,
            selectedClientCompanyName: set_selectedClientCompanyName,
            isClient: AuthenticationService.isUserClient()
        }
    }

    initData() {
        if (this.state.isClient) {
            console.log("getting requests");

            ClientEmployeeRequest.getClientEmployeeRequests()
                .then(response => {
                    if (response.status === 200) {
                        let clientEmployeeRequests = response.body;

                        this.setState({
                            pendingClientEmployeeRequests: clientEmployeeRequests
                                .map(request => new ClientEmployeeRequest(request))
                                .filter(request => request.request_state.request_state === "pending")
                        });
                    }
                });
        }
    }

    componentDidMount() {
        this.initData();
        this.loadClientCompanies();
    }

    loadClientCompanies(){
        return ClientCompany.getClientCompanies()
            .then(response => {
                if (response.status === 200){
                    let clientCompanies = [];
                    let clientCompaniesSelectList = [];

                    for (let entry of response.body){
                        let clientCompany = new ClientCompany(entry);

                        clientCompanies.push(clientCompany);
                        clientCompaniesSelectList.push({
                            label: clientCompany.company_name,
                            value: clientCompany
                        });
                    }

                    this.setState({
                        clientCompanies: clientCompanies,
                        clientCompaniesSelectList: clientCompaniesSelectList,
                    });

                    if(!this.state.selectedClientCompany) {
                        this.setState({
                            selectedClientCompany: clientCompanies.length === 1 ? clientCompanies[0] : null
                        });
                    }

                }
            })
            .catch(err => {
                this._handleClientCompanyErrors(err);
            });
    }

    _handleClientCompanyErrors(err){
        let showMessage = this.context.showMessage;

        if (!err.response){
            return  showMessage(null, "Verbindungsfehler", "danger");
        }

        switch (err.response.status) {
            case 403:
                return showMessage(null, "Keine Berechtigung für die Aktion", "danger");
            case 500:
                return showMessage(null, "Unbekannter Fehler", "danger");
            default:
                return showMessage(null, "Unbekannter Fehler", "danger");
        }
    }

    updateSelectedClientCompany(selectedOption){
        this.setState({
            selectedClientCompany: selectedOption.value,
            selectedClientCompanyName: selectedOption.label
        });
    }


    componentWillUnmount() {
        saved_selectedClientCompany = this.state.selectedClientCompany;
        saved_selectedClientCompanyName = this.state.selectedClientCompanyName;
    }

    renderClientCompanySelect(){
        return(
            <div>
                <div style={{display: "inline-block", padding: "30px", minWidth: "400px", maxWidth: "600px"}}>
                    <label className="col-form-label">Wählen Sie die gewünschte Firma:</label>
                    <Select options={this.state.clientCompaniesSelectList}
                            ref={(input) => { this.clientInput = input; }}
                            default={this.state.selectedClientCompanyName}
                            autoFocus={true}
                            onChange={(selectedOption) => this.updateSelectedClientCompany(selectedOption)}/>
                </div>
            </div>
        );
    }

    renderPoliciesOfSelectedClientCompany(){
        if (this.state.selectedClientCompany === null){
            return(
                <div>
                </div>
            );
        }

        return(
            <div>
                <PolicyOverview
                    clientCompany={this.state.selectedClientCompany}/>
            </div>
        );
    }

    render(){
        document.title = TAB_TITLE_BASE + TAB_TITLE_CONNECTOR + "Vertragsübersicht";
        const userIsClient = this.state.isClient;

        if (this.state.selectedPolicy){
            return (
                <PolicyDetailsContainer
                    clientData={this.state.selectedPolicy}/>
            );
        }

        if (this.state.clientCompanies === null ||
            this.state.clientCompanies.length === 0){
            return(
                <div className="container">
                    Sie sind keiner Firma zugeordnet.
                    {userIsClient &&
                        <div>
                            <br/>
                            <Link to={`${Paths.PATH_CLIENT_EMPLOYEE_REQUESTS}/new`}>
                            <button className="btn btn-primary">
                            Zugang anfragen
                            </button>
                            </Link>
                        </div>
                    }
                </div>
            );
        }

        return(
            <div>
                {this.state.clientCompaniesSelectList.length > 1 &&
                    this.renderClientCompanySelect()
                }

                {this.renderPoliciesOfSelectedClientCompany()}
            </div>
        );
    }

}

export default PolicyPortal;
