import React, {Component} from 'react';
import {TAB_TITLE_BASE, TAB_TITLE_CONNECTOR} from "../../config/constants";

class Imprint extends Component{

    render(){
        document.title = TAB_TITLE_BASE + TAB_TITLE_CONNECTOR + "Impressum";

        return(
            <div className="col-md-9" style={{padding: "30px"}}>
                <h2 className="page-headline">
                    Impressum
                </h2>

                <h2
                    style={{margin: "1.75em 0 1.25em",
                        fontSize: "24px"}}>
                    <strong>Ihr Versicherungsmakler im Profil</strong>
                </h2>

                <p
                    style={{margin: "0 0 11px",}}>
                    Vermittlerprofil gemäß den Bestimmungen der EU-Vermittlerrichtlinie i.V.m § 15 VersVermV
                </p>

                <h3
                    style={{fontSize: "20px",
                        marginTop: "22px",
                        marginBottom: "11px"}}>
                    <strong>Firma</strong>
                </h3>

                <div className="row">
                    <div className="col-md-6">
                        <p
                            style={{marginBottom: "1.75em",
                                lineHeight: "1.7em",
                                marginBlockStart: "1em",
                                marginBlockEnd: "1em",
                                marginInlineStart: "0px",
                                marginInlineEnd: "0px"}}>
                            <strong>SÜDVERS Holding GmbH & Co. KG</strong><br/>
                            Reg.-Nr.: D-8N83_510WK-53<br/>
                            HRA 3008 Freiburg<br/>
                            phG: SÜDVERS Holding Geschäftsführungs GmbH, HRB 5949 Freiburg<br/>
                            Geschäftsführer: Manfred Karle, Florian Karle
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <p
                            style={{marginBottom: "1.75em",
                                lineHeight: "1.7em",
                                marginBlockStart: "1em",
                                marginBlockEnd: "1em",
                                marginInlineStart: "0px",
                                marginInlineEnd: "0px"}}>
                            <strong>SÜDVERS Service und Management GmbH</strong><br/>
                            Reg.-Nr.: D-NQWS-9JMAY-32<br/>
                            HRB 707715 Freiburg<br/>
                            Geschäftsführer: Florian Karle, Manfred Karle, Ralf Bender
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <p
                            style={{marginBottom: "1.75em",
                                lineHeight: "1.7em",
                                marginBlockStart: "1em",
                                marginBlockEnd: "1em",
                                marginInlineStart: "0px",
                                marginInlineEnd: "0px"}}>
                            <strong>SÜDVERS GMBH Assekuranzmakler</strong><br/>
                            Reg.-Nr.: D-5EFK-APZG9-86<br/>
                            HRB 702890 Freiburg<br/>
                            Ust.-ID: DE275018222<br/>
                            Geschäftsführer: Florian Karle, Johannes Berger, Ralf Bender, Kim-André Vives, Jessica Reimers, Kolja Wangerin
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <p
                            style={{marginBottom: "1.75em",
                                lineHeight: "1.7em",
                                marginBlockStart: "1em",
                                marginBlockEnd: "1em",
                                marginInlineStart: "0px",
                                marginInlineEnd: "0px"}}>
                            <strong>SÜDVERS Risk-Management GmbH</strong><br/>
                            HRB 4558 Freiburg<br/>
                            Geschäftsführer: Florian Karle
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <p
                            style={{marginBottom: "1.75em",
                                lineHeight: "1.7em",
                                marginBlockStart: "1em",
                                marginBlockEnd: "1em",
                                marginInlineStart: "0px",
                                marginInlineEnd: "0px"}}>
                            <strong>SÜDVERS Kreditversicherungsmakler GmbH</strong><br/>
                            Reg.-Nr.: D-MRBY-H5SQA-05<br/>
                            HRB 6698 Freiburg<br/>
                            Geschäftsführer: Ralf Bender, Markus Kruse
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <p
                            style={{marginBottom: "1.75em",
                                lineHeight: "1.7em",
                                marginBlockStart: "1em",
                                marginBlockEnd: "1em",
                                marginInlineStart: "0px",
                                marginInlineEnd: "0px"}}>
                            <strong>SÜDVERS Vorsorge GmbH</strong><br/>
                            Reg.-Nr.: D-RMXZ-H7J3K-84<br/>
                            HRB 1157 Freiburg<br/>
                            Ust.-ID: DE142209802<br/>
                            Geschäftsführer: Florian Karle, Ralf Bender
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <p
                            style={{marginBottom: "1.75em",
                                lineHeight: "1.7em",
                                marginBlockStart: "1em",
                                marginBlockEnd: "1em",
                                marginInlineStart: "0px",
                                marginInlineEnd: "0px"}}>
                            <strong>Anschrift</strong><br/>
                            Am Altberg 1-3<br/>
                            79280 Au bei Freiburg<br/>
                            Tel.: +49 (0) 761 4582 0<br/>
                            Fax: +49 (0) 761 4582 330<br/>
                            Internet: www.suedvers.de<br/>
                            E-Mail: info@suedvers.de<br/>
                            Organschaftliche Ust.-ID: DE275018222
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <p
                            style={{marginBottom: "1.75em",
                                lineHeight: "1.7em",
                                marginBlockStart: "1em",
                                marginBlockEnd: "1em",
                                marginInlineStart: "0px",
                                marginInlineEnd: "0px"}}>
                            <strong>Erlaubnisbehörde</strong><br/>
                            IHK Freiburg<br/>
                            Schnewlinstr. 11-13<br/>
                            79098 Freiburg<br/>
                        </p>
                    </div>
                </div>

                <h3
                    style={{fontSize: "20px",
                        marginTop: "22px",
                        marginBottom: "11px"}}>
                    <strong>Firma</strong>
                </h3>

                <div className="row">
                    <div className="col-md-6">
                        <p
                            style={{marginBottom: "1.75em",
                                lineHeight: "1.7em",
                                marginBlockStart: "1em",
                                marginBlockEnd: "1em",
                                marginInlineStart: "0px",
                                marginInlineEnd: "0px"}}>
                            <strong>Profion GmbH</strong><br/>
                            Reg.-Nr.: D-74GQ-PVTTW-64<br/>
                            HRB 122398 München<br/>
                            Ust.-ID: DE246123787<br/>
                            Geschäftsführer: Jürgen Kropp, Florian Karle
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <p
                            style={{marginBottom: "1.75em",
                                lineHeight: "1.7em",
                                marginBlockStart: "1em",
                                marginBlockEnd: "1em",
                                marginInlineStart: "0px",
                                marginInlineEnd: "0px"}}>
                            <strong>Anschrift</strong><br/>
                            Heimeranstr. 37<br/>
                            80339 München<br/>
                            Tel.: +49 (0) 89 388372 60<br/>
                            Fax: +49 (0) 89 388372 88<br/>
                            Internet: www.profion.de<br/>
                            E-Mail: info@profion.de
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <p
                            style={{marginBottom: "1.75em",
                                lineHeight: "1.7em",
                                marginBlockStart: "1em",
                                marginBlockEnd: "1em",
                                marginInlineStart: "0px",
                                marginInlineEnd: "0px"}}>
                            <strong>Erlaubnisbehörde</strong><br/>
                            IHK München und Oberbayern<br/>
                            80333 München<br/>
                            Tel.: +49 (0) 89 5116 150<br/>
                            Internet: www.muenchen.ihk.de
                        </p>
                    </div>
                </div>

                <h3
                    style={{fontSize: "20px",
                        marginTop: "22px",
                        marginBottom: "11px"}}>
                    <strong>Firma</strong>
                </h3>

                <div className="row">
                    <div className="col-md-6">
                        <p
                            style={{marginBottom: "1.75em",
                                lineHeight: "1.7em",
                                marginBlockStart: "1em",
                                marginBlockEnd: "1em",
                                marginInlineStart: "0px",
                                marginInlineEnd: "0px"}}>
                            <strong>B&F Consulting AG</strong><br/>
                            Reg.-Nr.: D-1TFL-3OLHC-96<br/>
                            HRB 11976 Kaiserslautern<br/>
                            Steuernummer: 44651/10446<br/>
                            Vorstand: Wolfgang Fröhlich
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <p
                            style={{marginBottom: "1.75em",
                                lineHeight: "1.7em",
                                marginBlockStart: "1em",
                                marginBlockEnd: "1em",
                                marginInlineStart: "0px",
                                marginInlineEnd: "0px"}}>
                            <strong>Anschrift</strong><br/>
                            Freiheitsstraße 13-15<br/>
                            67292 Kirchheimbolanden<br/>
                            Tel.: +49 (0) 6352 4000 60<br/>
                            Fax: +49 (0) 6352 4000 61<br/>
                            Internet: www.bfcag.de<br/>
                            E-Mail: info@bfcag.de
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <p
                            style={{marginBottom: "1.75em",
                                lineHeight: "1.7em",
                                marginBlockStart: "1em",
                                marginBlockEnd: "1em",
                                marginInlineStart: "0px",
                                marginInlineEnd: "0px"}}>
                            <strong>Erlaubnisbehörde</strong><br/>
                            IHK Ludwigshafen,<br/>
                            Ludwigsplatz 2-4,<br/>
                            67059 Ludwigshafen<br/>
                            Tel: + 49 (0)621 59040<br/>
                            Internet: www.pfalz.ihk24.de
                        </p>
                    </div>
                </div>

                <hr
                    style={{marginTop: "22px",
                        marginBottom: "22px",
                        borderTop: "1px solid #3f3e3e"}}></hr>

                <h3
                    style={{fontSize: "20px",
                        marginTop: "22px",
                        marginBottom: "11px"}}>
                    <strong>Registrierungs-Behörde</strong>
                </h3>

                <div className="row">
                    <div className="col-md-6">
                        <p
                            style={{marginBottom: "1.75em",
                                lineHeight: "1.7em",
                                marginBlockStart: "1em",
                                marginBlockEnd: "1em",
                                marginInlineStart: "0px",
                                marginInlineEnd: "0px"}}>
                            Deutscher Industrie- und<br/>
                            Handelskammertag (DIHK) e. V.<br/>
                            Breite Straße 29, 10178 Berlin<br/>
                            Tel.: +49 180 600 585 0<br/>
                            (20 Cent/Anruf)<br/>
                            Internet: www.vermittlerregister.info
                        </p>
                    </div>
                </div>


                <hr
                    style={{marginTop: "22px",
                        marginBottom: "22px",
                        borderTop: "1px solid #3f3e3e"}}></hr>

                <h2
                    style={{margin: "1.75em 0 1.25em",
                        fontSize: "24px",
                        fontStyle: "normal",
                        fontWeight: "400"}}>
                    <strong>Statusinformationen gemäß § 15 Versicherungsvermittlungsverordnung</strong>
                </h2>

                <p
                    style={{marginBottom: "1.75em",
                        lineHeight: "1.7em"}}>
                    Die SÜDVERS GMBH Assekuranzmakler ist nach § 34 d Abs. 1 der Gewerbeordnung in Deutschland als Versicherungsmakler eingetragen und haben die erforderliche Erlaubnis erhalten. Das Register ist im Internet einsehbar unter: www.vermittlerregister.org
                </p>

                <p
                    style={{marginBottom: "1.75em",
                        lineHeight: "1.7em"}}>
                    Unsere Unternehmen halten keine direkte oder indirekte Beteiligung an den Stimmrechten oder am Kapital eines Versicherungsunternehmens. Ein Versicherungsunternehmen hält keine direkte oder indirekte Beteiligung an den Stimmrechten oder am Kapital eines unserer Unternehmen.
                </p>

                <h3
                    style={{fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "20px",
                        marginTop: "22px",
                        marginBottom: "11px",
                        lineHeight: "1.1"}}>
                    <strong>Berufsrechtliche Regelungen</strong>
                </h3>

                <p
                    style={{marginBottom: "1.75em",
                        lineHeight: "1.7em"}}>
                    § 34 c, d Gewerbeordnung (ggf. § 34 e Gewerbeordnung)<br/>
                    §§ 59 – 68 VVG (Versicherungsvertragsgesetz)<br/>
                    VersVermV (Versicherungsvermittlungsverordnung)
                </p>

                <p
                    style={{marginBottom: "1.75em",
                        lineHeight: "1.7em"}}>
                    Die berufsrechtlichen Regelungen können über die vom Bundesministerium der Justiz und von der juris GmbH betriebenen Homepage www.gesetze-im-internet.de eingesehen und abgerufen werden.
                </p>

                <hr
                    style={{marginTop: "22px",
                        marginBottom: "22px",
                        borderTop: "1px solid #3f3e3e"}}></hr>

                <p
                    style={{marginBottom: "1.75em",
                        lineHeight: "1.7em"}}>
                    SÜDVERS bietet seinen Kunden eine Beratung an.
                </p>

                <p
                    style={{marginBottom: "1.75em",
                        lineHeight: "1.7em"}}>
                    SÜDVERS erhält grundsätzlich für die Tätigkeiten für den Kunden eine in den Versicherungsprämien enthaltene Courtage (direkt von den Versicherungsunternehmen). Es werden auch in Absprache mit dem Kunden nettoisierte Versicherungsverträge vermittelt. Für diese erhält SÜDVERS ein zuvor schriftlich vereinbartes Honorar vom Kunden direkt. Darüber hinaus kann SÜDVERS unabhängig von den Kundenverträge für Dienstleistungen, welche für Versicherungsunternehmen erbracht werden, eine gesonderte Vergütung von den Versicherungsunternehmen erhalten.
                </p>

                <hr
                    style={{marginTop: "22px",
                        marginBottom: "22px",
                        borderTop: "1px solid #3f3e3e"}}></hr>

                <p
                    style={{marginBottom: "1.75em",
                        lineHeight: "1.7em"}}>
                    Bei Fragen aus der Versicherungsvermittlung wenden Sie sich bitte zuerst an uns als Ihren Versicherungsmakler oder an die entsprechenden Versicherungsunternehmen.
                </p>

                <p
                    style={{marginBottom: "1.75em",
                        lineHeight: "1.7em"}}>
                    <strong>Information zu den Schlichtungsstellen gemäß § 214 VVG und zur Teilnahme am Streitbeilegungsverfahren gemäß § 36 Verbraucherstreitbeilegungsgesetz</strong>
                </p>

                <p
                    style={{marginBottom: "1.75em",
                        lineHeight: "1.7em"}}>
                    Zur außergerichtlichen Streitbeilegung können untenstehende Schlichtungsstellen angerufen werden. Gemäß § 17 Abs. 4 der Versicherungsvermittlungsverordnung sind wir verpflichtet, an Streitbeilegungsverfahren vor folgenden Verbraucherschlichtungsstellen teilzunehmen:
                </p>

                <p
                    style={{marginBottom: "1.75em",
                        lineHeight: "1.7em"}}>
                    Versicherungsombudsmann e. V.<br/>
                    Postfach 08 06 32<br/>
                    10006 Berlin<br/>
                    www.versicherungsombudsmann.de
                </p>

                <p
                    style={{marginBottom: "1.75em",
                        lineHeight: "1.7em"}}>
                    Ombudsmann für die private Kranken- und Pflegeversicherung<br/>
                    Postfach 06 02 22<br/>
                    10052 Berlin<br/>
                    www.pkv-ombudsmann.de
                </p>

                <p
                    style={{marginBottom: "1.75em",
                        lineHeight: "1.7em"}}>
                    <strong>Online-Streitbeilegung gemäß Art. 14 Abs. 1 Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-VO)</strong><br/>
                    Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS-Plattform) bereit, die Verbraucher unter https://webgate.ec.europa.eu/odr/main/ finden. Verbraucher haben die Möglichkeit, diese Plattform für außergerichtliche Beilegung ihrer Streitigkeiten über vertragliche Verpflichtungen zu nutzen. Gerne können Sie uns auch direkt kontaktieren über nina.hartmann@suedvers.de.
                </p>

                <hr
                    style={{marginTop: "22px",
                        marginBottom: "22px",
                        borderTop: "1px solid #3f3e3e"}}></hr>

                <h2
                    style={{margin: "1.75em 0 1.25em",
                        fontSize: "20px"}}>
                    <strong>Beschwerdemanagement</strong>
                </h2>

                <p
                    style={{marginBottom: "1.75em",
                        lineHeight: "1.7em"}}>
                    Bei Beschwerden über unsere Tätigkeit wenden Sie sich bitte an unsere Beschwerdestelle beschwerde@suedvers.de.
                </p>

                <hr
                    style={{marginTop: "22px",
                        marginBottom: "22px",
                        borderTop: "1px solid #3f3e3e"}}></hr>

                <h2
                    style={{margin: "1.75em 0 1.25em",
                        fontSize: "20px"}}>
                    <strong>Rechtshinweise</strong>
                </h2>

                <p
                    style={{marginBottom: "1.75em",
                        lineHeight: "1.7em"}}>
                    Die SÜDVERS prüft und aktualisiert die Informationen auf ihren Webseiten regelmäßig. Trotz aller Sorgfalt können sich die Daten zwischenzeitlich verändert haben. Eine Haftung oder Garantie für die Aktualität, Richtigkeit und Vollständigkeit der Informationen kann daher nicht übernommen werden. Gleiches gilt auch für Websites, auf die über Hyperlink verwiesen wird. Die SÜDVERS ist für den Inhalt der Websites, die aufgrund einer solchen Verbindung erreicht werden, nicht verantwortlich.
                </p>

                <p
                    style={{marginBottom: "1.75em",
                        lineHeight: "1.7em"}}>
                    Des Weiteren behält sich die SÜDVERS das Recht vor, Änderungen oder Ergänzungen der bereitgestellten Informationen vorzunehmen.
                </p>

                <h2
                    style={{margin: "1.75em 0 1.25em",
                        fontSize: "20px"}}>
                    <strong>Keine anwaltliche Abmahnung ohne vorherige Kontaktaufnahme</strong>
                </h2>

                <p
                    style={{marginBottom: "1.75em",
                        lineHeight: "1.7em"}}>
                    Sollten Inhalt oder Aufmachung dieser Website Rechte Dritter oder gesetzliche Bestimmungen verletzen, bitten wir um kurze Benachrichtigung. Zu Recht beanstandete Passagen werden wir unverzüglich entfernen und Sie entsprechend informieren. Die Einschaltung eines Rechtsbeistandes ist damit nicht erforderlich. Dennoch von Ihnen ohne vorherige Kontaktaufnahme ausgelöste Kosten werden wir vollumfänglich zurückweisen und gegebenenfalls selbst rechtliche Schritte einleiten.
                </p>

                <p
                    style={{marginBottom: "1.75em",
                        lineHeight: "1.7em"}}>
                    Verantwortlicher für journalistisch-redaktionelle Inhalte gemäß § 18 Abs. 2 MStV: Urs Kargl, SÜDVERS GMBH Assekuranzmakler, Am Altberg 1-3, 79280 AuDes Weiteren behält sich die SÜDVERS das Recht vor, Änderungen oder Ergänzungen der bereitgestellten Informationen vorzunehmen.
                </p>

                <h2
                    style={{margin: "1.75em 0 1.25em",
                        fontSize: "20px"}}>
                    <strong>Copyright</strong>
                </h2>

                <p
                    style={{marginBottom: "1.75em",
                        lineHeight: "1.7em"}}>
                    Inhalt und Aufbau der SÜDVERS Website sind urheberrechtlich geschützt. Die Vervielfältigung von Informationen oder Daten, insbesondere die Verwendung von Texten, Textteilen oder Bildmaterial bedarf der vorherigen Zustimmung.
                </p>


            </div>
        )
    }

}

export default Imprint;