import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom'

import * as Constants from '../../config/constants';
import * as Paths from '../../config/paths';

import Can from '../Can';
import ability from '../../Ability';
import * as AuthenticationService from "../../service/authenticationService";
import {USER_CLIENT} from "../../config/constants";
import {USER_FIMO} from "../../config/constants";
import {UEBER_UNS} from "../../config/constants";

import {IoIosMenu} from "react-icons/io";

class Sidebar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedMenu: Constants.MENU_PORTAL,
            isSidebarCollapsed: props.isSidebarCollapsed
        }
    }


    selectMenu(selectedMenu) {
        this.setState({
            selectedMenu: selectedMenu
        });

        this.props.setSelectedMenu(selectedMenu);
    }


    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                selectedMenu: nextProps.selectedMenu,
                isSidebarCollapsed: nextProps.isSidebarCollapsed
            });
        }
    }


    render() {
        const userIsCaseHandler = AuthenticationService.isUserCaseHandler();
        const userIsAdmin = AuthenticationService.isUserAdmin();

        if (this.state.isSidebarCollapsed) {
            return (
                <div className="sidebar-collapsed"
                     style={{
                         paddingTop: "100px",
                         paddingLeft: "20px",
                         backgroundColor: "rgb(35, 70, 55)",
                         position: "fixed"
                     }}>
                    <div className="text-right">
                        <button className="btn menu-toggle menu-toggle-collapsed"
                                onClick={() => this.props.toggleSidebar()}>
                            <IoIosMenu className="menu-image"/>
                        </button>
                    </div>
                </div>
            )
        }
        return (
            <div className="sidebar"
                 style={{
                     paddingTop: "100px",
                     paddingLeft: "20px",
                     backgroundColor: "rgb(35, 70, 55)",
                     position: "absolute"
                 }}>

                <div className="text-right">
                    <button className="btn menu-toggle menu-toggle-expanded"
                            onClick={() => this.props.toggleSidebar()}>
                        <IoIosMenu className="menu-image"/>
                    </button>
                </div>

                {this.props.user !== USER_CLIENT && this.props.user !== USER_FIMO &&
                this.renderButton(Constants.MENU_PORTAL)
                }

                {this.props.user &&
                <Can I="show" this={this.props.user}>
                    {this.renderButton(Constants.MENU_PROFILE, `${Paths.PATH_USERS}/${this.props.user.id}`)}
                </Can>
                }

                {!this.props.user &&
                this.renderButton(Constants.MENU_DEMO, Paths.PATH_DEMO_PORTAL)}

                {ability.cannot("index", "QuotationRequest") &&
                this.renderButton(Constants.MENU_NEW_QUOTATION_REQUEST, `${Paths.PATH_QUOTATION_REQUEST}/new`)}

                {(this.props.user && !userIsAdmin && !userIsCaseHandler) &&
                this.renderButton(Constants.MENU_VERTRAGSUEBERSICHT, Paths.PATH_CLIENT_POLICY_PORTAL)
                }
                {(userIsAdmin || userIsCaseHandler) &&
                <Can I="read" on="ClientCompany">
                    {this.renderButton(Constants.MENU_CLIENT_COMPANIES_OVERVIEW, Paths.PATH_CLIENT_COMPANIES)}
                </Can>
                }
                <Can I="read" on="UpdateRequest">
                    {this.renderButton(Constants.MENU_UPDATE_REQUESTS, Paths.PATH_UPDATE_REQUEST)}
                </Can>
                {(userIsAdmin || userIsCaseHandler) &&
                <Can I="read" on="ClientEmployeeRequest">
                    {this.renderButton(Constants.MENU_CLIENT_COMPANY_REQUESTS, Paths.PATH_CLIENT_EMPLOYEE_REQUESTS)}
                </Can>
                }

                <Can I="index" on="User" >
                    {this.renderButton(Constants.MENU_IMPORT, Paths.PATH_IMPORT)}
                </Can>

                <Can I="index" on="User" >
                    {this.renderButton(Constants.MENU_INSURANCES, Paths.PATH_INSURANCES_COMPANIES)}
                </Can>

                <Can I="index" on="User">
                    {this.renderButton(Constants.MENU_USERS, Paths.PATH_USERS)}
                </Can>
                <Can I="index" on="QuotationRequest">
                    {this.renderButton(Constants.MENU_SHOW_QUOTATION_REQUESTS, Paths.PATH_QUOTATION_REQUEST)}
                </Can>
                {this.props.showForms &&
                this.renderButton(Constants.MENU_FORMULARE, Paths.PATH_FORMS)
                }
                {/*{this.renderButton(Constants.MENU_LEXIKON, Paths.PATH_LEXICON)}*/}
                {this.renderUeberUns(Constants.MENU_ANFAHRT, Paths.PATH_CONTACT, function () {
                    const newWindow = window.open(UEBER_UNS, '_blank', 'noopener,noreferrer')
                    if (newWindow) newWindow.opener = null
                })}

                <div style={{marginTop: "30px"}}/>

                {this.renderSmallButton("Kontaktformular", Paths.PATH_CONTACT_REQUEST)}
                {this.renderSmallButton("Nutzungsbedingungen", Paths.PATH_TERMS_OF_USE)}
                {this.renderSmallButton("Datenschutz", Paths.PATH_INFORMATION_PRIVACY)}
                {this.renderSmallButton("Impressum", Paths.PATH_IMPRINT)}

                <div style={{marginTop: "75px"}}/>

                <div className="text-wrap" style={{color: "white", paddingRight: "8px"}}>
                    <p><b>Kontakt:</b></p>
                    <div>
                        Telefon:
                        <br/>
                        <span style={{paddingLeft: "10px"}}>
                            +49 341 99280-50
                        </span>
                        <br/>
                        Fax:
                        <br/>
                        <span style={{paddingLeft: "10px"}}>
                            +49 341 99280 63650
                        </span>
                        <br/>
                        <div style={{paddingTop: "8px"}}>
                        E-Mail:
                        <br/>
                            <span style={{paddingLeft: "10px"}}>
                                kautionsversicherung@suedvers.de
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    renderButton(menu, path = "/", callback) {
        return (
            <div style={{borderBottom: "1px solid rgba(255,255,255,.12)", padding: "5px", marginRight: "20px"}}>
                <Link to={path}>
                    <button className="btn btn-link btn-link-sidebar text-left text-wrap"
                            disabled={menu === this.state.selectedMenu}
                            onClick={() => callback ? callback() : this.selectMenu(menu)}>
                        {menu}
                    </button>
                </Link>
            </div>
        )
    }

    renderUeberUns(menu, path = "/", callback) {
        return (
            <div style={{borderBottom: "1px solid rgba(255,255,255,.12)", padding: "5px", marginRight: "20px"}}>
                <button className="btn btn-link btn-link-sidebar text-left text-wrap"
                        disabled={menu === this.state.selectedMenu}
                        onClick={() => callback ? callback() : this.selectMenu(menu)}>
                    {menu}
                </button>
            </div>
        )
    }

    renderSmallButton(menu, path = "/", firstButton) {
        return (
            <div style={{
                borderBottom: "1px solid rgba(255,255,255,.12)",
                margin: "5px",
                marginLeft: "25px",
                marginRight: "35px"
            }}>
                <Link to={path}>
                    <button className="btn btn-link-small text-left text-wrap" style={{fontSize: "42px !important"}}
                            disabled={menu === this.state.selectedMenu}
                            onClick={() => this.selectMenu(menu)}>
                        {menu}
                    </button>
                </Link>
            </div>
        )
    }

}


export default Sidebar;
